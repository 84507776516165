// export const API_BASE_URL =
//   process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api";
export const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL ||
  "http://192.168.11.110:8080/apiService/api";
export const ACCESS_TOKEN = "accessToken";
export const AUTHENTICATED = "isAuthenticated";

export const USERNAME_MIN_LENGTH = 3;
export const USERNAME_MAX_LENGTH = 15;

export const EMAIL_MAX_LENGTH = 40;

export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_MAX_LENGTH = 20;

// export const OAUTH2_REDIRECT_URI = "http://localhost:3000/oauth2/redirect";
export const OAUTH2_REDIRECT_URI = "http://192.168.11.110/oauth2/redirect";

export const GOOGLE_AUTH_URL =
  API_BASE_URL + "/oauth2/authorize/google?redirect_uri=" + OAUTH2_REDIRECT_URI;
export const LINKEDIN_AUTH_URL =
  API_BASE_URL +
  "/oauth2/authorize/linkedin?redirect_uri=" +
  OAUTH2_REDIRECT_URI;
