export const offers = [
  {
    id: 1,
    title: "Développeur FULLSTACK JAVA/ReactJS",
    location: "Rabat",
    type: "FIXEDTERM",
    duration: "3 Mois",
    sector: "Secteur IT",
    profil: { name: "", skills: [{ name: "git" }, { name: "java" }] },
    description:
      "SKYLARK Services recrute pour le compte de son client basé sur Rabat des profils UX/UI Designer pour une mission Freelance de longue durée zerhdqsqs",
  },
  {
    id: 2,
    title: "Développeur ANGULAR",
    location: "Casablanca",
    type: "FREELANCE",
    duration: "3 Mois",
    sector: "Secteur IT",
    profil: {
      name: "",
      skills: [
        { name: "git" },
        { name: "angular" },
        { name: "java" },
        { name: "spring " },
        { name: "agile" },
      ],
    },
    description: "",
  },
  {
    id: 3,
    title: "Développeur .Net",
    location: "Casablanca",
    type: "FREELANCE",
    duration: "60 jours",
    sector: "Secteur IT",
    profil: { name: "", skills: [{ name: "git" }, { name: "java" }] },
    description: "",
  },
  {
    id: 4,
    title: "Développeur SAP Hybris",
    location: "Rabat",
    type: "FREELANCE",
    duration: "6 Mois",
    sector: "Secteur IT",
    profil: {
      name: "",
      skills: [
        { name: "Hybris" },
        { name: "Java" },
        // { name: "java" },
        // { name: "spring " },
        // { name: "agile" },
      ],
    },
    description: "",
  },
  {
    id: 5,
    title: "Ingénieur Système Linux & Windows",
    location: "Casablanca",
    type: "FREELANCE",
    duration: "6 Mois",
    sector: "Secteur IT",
    profil: {
      name: "",
      skills: [
        { name: "linux" },
        { name: "windows" },
        { name: "vmware" },
        { name: "apache " },
        { name: "shell" },
      ],
    },
    description: "",
  },
  {
    id: 6,
    title: "Ingénieur Devops ",
    location: "Casablanca",
    type: "FREELANCE",
    duration: "6 Mois",
    sector: "Secteur IT",
    profil: {
      name: "",
      skills: [
        { name: "docker" },
        { name: "kubernetes" },
        { name: "linux" },
        { name: "ainsible" },
        { name: "scrum" },
      ],
    },
    description: "",
  },
  {
    id: 7,
    title: "Développeur Sénior Drupal 8",
    location: "Rabat",
    type: "FREELANCE",
    duration: "6 Mois",
    sector: "Secteur IT",
    profil: {
      name: "",
      skills: [{ name: "php" }, { name: "drupal" }, { name: "javascript" }],
    },
    description: "",
  },
  {
    id: 8,
    title: "Développeur Sénior VueJs",
    location: "Rabat",
    type: "FREELANCE",
    duration: "6 Mois",
    sector: "Secteur IT",
    profil: {
      name: "",
      skills: [{ name: "vuejs" }, { name: "frontend" }, { name: "javascript" }],
    },
    description: "",
  },
  {
    id: 9,
    title: "Développeur Sénior Magento",
    location: "Rabat",
    type: "FREELANCE",
    duration: "6 Mois",
    sector: "Secteur IT",
    profil: {
      name: "",
      skills: [{ name: "magento" }, { name: "php" }, { name: "javascript" }],
    },
    description: "",
  },

  // {
  //   id: 4,
  //   title: "Designer UX/UI",
  //   location: "Casablanca",
  //   type: "Freelance",
  //   duration: "3 Mois",
  //   description: "",
  // },

  // {
  //   id: 5,
  //   title: "Scrum Master",
  //   location: "Casablanca",
  //   type: "Freelance",
  //   duration: "3 Mois",
  //   description: "",
  // },
  // {
  //   id: 6,
  //   title: "Ingénieur Devops",
  //   location: "Casablanca",
  //   type: "Freelance",
  //   duration: "3 Mois",
  //   description: "",
  // },
];

export default offers;
