import React, { Component } from "react";
import { Link } from "react-router-dom";
import { confirmEmail } from "../../util/APIUtils";

import { Circle, Heart, Spinner, Ellipsis } from "react-spinners-css";

class ConfirmEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: { message: "" },
    };
  }

  componentDidMount() {
    let token = this.props.match.params.token;
    confirmEmail(token)
      .then((res) => this.props.history.push("/login"))
      .catch((err) => this.setState({ errors: { message: err.message } }));
  }

  alert(message) {
    return (
      <div className="alert alert-danger" role="alert">
        {message}
      </div>
    );
  }

  render() {
    return (
      <div
        style={{
          // border: "2px solid blue",
          width: "100%",
          height: "100vh",
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            // border: "2px solid blue",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/logo/logo_neotechit.svg`}
            alt="Logo images"
            width="200px"
          />
          <Ellipsis color="#5841D9" />
        </div>
      </div>
    );
  }
}

export default ConfirmEmail;
