import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { FiBox, FiLink, FiUserCheck, FiZap } from "react-icons/fi";

class About extends Component {
  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center ptb--30">
              <div className="col-lg-5 col-md-12">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src={`${process.env.PUBLIC_URL}/assets/images/about/about-1.jpg`}
                    alt="about_image"
                  />
                </div>
              </div>

              <div className="col-lg-7 col-md-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title"> {t("aboutTitle")} </h2>
                    <p className="description">{t("aboutDescription")}</p>
                  </div>

                  <br />

                  <div className="row justify-content-center">
                    {/* <!--team-1--> */}
                    <div className="m-2">
                      <div className="our-team-main">
                        <div className="team-front">
                          <div className="icon">
                            <FiZap />
                          </div>
                          <h4 className="font-weight-bold">
                            {t("aboutSectionOneTitle")}
                          </h4>
                        </div>

                        <div className="team-back">
                          <p className="lead">
                            {t("aboutSectionOneDescription")}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <!--team-1-->

                    <!--team-2--> */}
                    <div className="m-2">
                      <div className="our-team-main">
                        <div className="team-front">
                          <div className="icon">
                            <FiBox />
                          </div>
                          <h4 className="font-weight-bold">
                            {t("aboutSectionTwoTitle")}
                          </h4>
                        </div>

                        <div className="team-back">
                          <p className="lead">
                            {t("aboutSectionTwoDescription")}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <!--team-2--> */}

                    {/* <!--team-3--> */}
                    <div className="m-2">
                      <div className="our-team-main">
                        <div className="team-front">
                          <div className="icon">
                            <FiUserCheck />
                          </div>
                          <h4 className="font-weight-bold">
                            {t("aboutSectionThreeTitle")}
                          </h4>
                        </div>

                        <div className="team-back">
                          <p className="lead">
                            {t("aboutSectionThreeDescription")}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <!--team-3-->

                    <!--team-4--> */}
                    <div className="m-2">
                      <div className="our-team-main">
                        <div className="team-front">
                          <div className="icon">
                            <FiLink />
                          </div>
                          <h4 className="font-weight-bold">
                            {t("aboutSectionFourTitle")}
                          </h4>
                        </div>

                        <div className="team-back">
                          <p className="lead">
                            {t("aboutSectionFourDescription")}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <!--team-4--> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default withTranslation()(About);
