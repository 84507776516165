import React, { Component } from "react";
import Slider from "react-slick";
import { productSlick, slickDot } from "../../page-demo/script";
import { withTranslation } from "react-i18next";

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;

    // product list
    const productsList = [
      {
        index: 1,
        image: "image-1",
        title: `${t("productOneName")}`,
        description: `${t("productOneDescription")}`,
      },
      {
        index: 2,
        image: "image-2",
        title: `${t("productTwoName")}`,
        description: `${t("productTwoDescription")}`,
      },
      {
        index: 3,
        image: "image-3",
        title: `${t("productThreeName")}`,
        description: `${t("productThreeDescription")}`,
      },
      {
        index: 4,
        image: "image-4",
        title: `${t("productFourName")}`,
        description: `${t("productFourDescription")}`,
      },
    ];

    return (
      <div className="portfolio-area bg_color--1">
        <div className="rn-slick-dot">
          <div className="container">
            <div className="section-title">
              <h2 className="title">{t("productsTitle")}</h2>
            </div>

            <div className="slick-space-gutter--15 slickdot--20 mt--40 mt_sm--40">
              <Slider {...productSlick}>
                {productsList.map((value, index) => (
                  <div className="portfolio" key={index}>
                    <div className="thumbnail-inner">
                      <div className={`thumbnail ${value.image}`}></div>
                      <div className={`bg-blr-image ${value.image}`}></div>
                    </div>
                    <div className="content">
                      <div className="inner">
                        <h4>{value.title}</h4>
                        <p className="small">{value.description}</p>
                        <div className="portfolio-button">
                          <a
                            className="rn-btn"
                            href={"/product-details/" + value.index}
                          >
                            View Details
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Product);
