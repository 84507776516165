import React, { Component } from "react";
import {
  getAllJobs,
  getInterviewByApplicantId,
  getCompanyContact,
} from "../util/APIUtils";

import ReactTable from "react-table";
import "react-table/react-table.css";

import { FiDownload } from "react-icons/fi";

import AddApplication from "../application/add";

import Popup from "reactjs-popup";

export default class Offers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      filteredData: [],
      columns: [],
      searchInput: "",
      errors: { message: "" },
    };
  }

  componentDidMount() {
    var applicantId = this.props.currentUser.id;
    getInterviewByApplicantId(applicantId)
      .then((Response) => {
        let data = [];

        console.log(Response);

        // Response.forEach((element) => {
        //   getCompanyContact(element.meetingContact)
        //     .then((res) => {
        //       element.contact = res.content;
        //       data.push(element);
        //       console.log(element);
        //       this.setState({ data: data });
        //     })
        //     .catch((err) => {
        //       console.log(err);
        //     });
        // });
        // this.setState({ data: data });
        this.setState({ data: Response });
      })
      .catch((err) => console.log(err));
  }

  render() {
    var dateFormat = require("dateformat");
    const columns = [
      {
        Header: "Date",
        filterable: true,
        className: "mid",
        Cell: (row) => {
          return <div>{dateFormat(row.original.date, "dd/mm/yyyy")}</div>;
        },
      },
      {
        Header: "Skype",
        filterable: true,
        accessor: "skype",
        className: "mid",
      },
      {
        Header: "Fichier",
        id: "document_name",
        className: "mid",
        Cell: (row) => {
          return (
            <div>
              <button
                // onClick={this.downloadDocument.bind(
                //   this,
                //   row.original.file.name
                // )}
                className="btn btn-success"
              >
                <FiDownload />
              </button>
            </div>
          );
        },
      },
    ];
    return (
      <React.Fragment>
        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title" style={{ color: "#ffffff" }}>
                    Entretiens
                  </h2>
                  {/* <p>Fresh From The Press Discription</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="service-area ptb--80  bg_image bg_image--3"
          style={{ paddingTop: "200px", paddingBottom: "200px" }}
        >
          <div className="container">
            <h4>Entretiens récents :</h4>
            <ReactTable
              className="-highlight"
              columns={columns}
              data={this.state.data}
              minRows={0}
              defaultPageSize={10}
              defaultFilterMethod={this.filterCaseInsensitive}
              getProps={() => {
                return { id: "activityReports" };
              }}
              previousText="Précédent"
              nextText="Prochain"
              loadingText="Chargement..."
              noDataText="Aucune ligne trouvée"
              pageText="Page"
              ofText="de"
              rowsText="Lignes"
              getTdProps={(state, rowInfo, column, instance) => {
                return {
                  onClick: (e) => {
                    if (
                      column.Header !== "Action" &&
                      rowInfo !== undefined &&
                      column.Header !== "Fichier"
                    ) {
                      let path = "/interview/view/" + rowInfo.original.id;
                      this.props.history.push(path);
                    }
                  },
                };
              }}
            ></ReactTable>{" "}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
