import React, { Component } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/lib/Creatable";
import { FormGroup, FormText, Input, Label, Col, Row } from "reactstrap";
// import cities from "../../../util/cities.json";
import countries from "../util/countries.json";
// import bsCustomFileInput from "bs-custom-file-input";
import {
  editApplicant,
  getApplicant,
  getActiveProfils,
  getAllSuppliers,
  getAllSubskills,
  checkEmailAvailability,
} from "../util/APIUtils";
import update from "immutability-helper";

import _, { map } from "underscore";

import Check from "@material-ui/icons/Check";

import EditExperienceTwo from "./experience/editTwo";
import EditDegreeTwo from "./degree/editTwo";
import AddExperience from "./experience/add";
import AddDegree from "./degree/add";

///// Material UI
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import StepConnector from "@material-ui/core/StepConnector";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
/////////////////

import { arraySorterBy } from "../util/functionsUtils";

import PropTypes from "prop-types";

import clsx from "clsx";

import "./style.css";

import FunctionHOC from "../Modal/FunctionHOC";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { FiEdit, FiDelete, FiPlus } from "react-icons/fi";

import StepperForm from "./stepper";

const AddExperienceModal = FunctionHOC(
  AddExperience,
  <React.Fragment>Ajouter Expérience</React.Fragment>,
  (props) => (
    <button
      id="addExperience"
      type="button"
      className="btn "
      onClick={props.toggleModal}
      style={{ background: "#5841D9", color: "#ffffff" }}
    >
      <FiPlus />
    </button>
  )
);

const EditExperienceModal = FunctionHOC(
  EditExperienceTwo,
  <React.Fragment>Editer Expérience</React.Fragment>,
  (props) => (
    <button
      id="editExperienceModal"
      type="button"
      className="btn btn-warning"
      onClick={props.toggleModal}
    >
      <FiEdit />
    </button>
  )
);

const AddDegreeModal = FunctionHOC(
  AddDegree,
  <React.Fragment>Ajouter Diplôme</React.Fragment>,
  (props) => (
    <button
      id="addDegree"
      type="button"
      className="btn "
      style={{ background: "#5841D9", color: "#ffffff" }}
      onClick={props.toggleModal}
    >
      <FiPlus />
    </button>
  )
);

const EditDegreeModal = FunctionHOC(
  EditDegreeTwo,
  <React.Fragment>Editer Diplôme</React.Fragment>,
  (props) => (
    <button
      id="editDegreeModal"
      type="button"
      className="btn btn-warning"
      onClick={props.toggleModal}
    >
      <FiEdit />
    </button>
  )
);

class Ajout extends Component {
  constructor() {
    super();
    this.state = {
      currentStep: 1,
      firstName: "",
      lastName: "",
      phone: "",
      address: "",
      seniority: "",
      email: "",
      isRecruited: false,
      isImported: true,
      applicantStatus: "APPROVED",
      profil: "",
      resume: "",
      oldResume: true,
      supplier: "",
      adressTwo: "",
      city: "",
      country: "Maroc",
      zipCode: "",
      skills: [],
      manualResumeStatus: false,
      manualResume: { updated: false },
      experiences: [],
      degrees: [],
      diplomas: [],
      subskills: [],
      skillsManualResume: [],
      subskillNames: [],
      data: [],
      selectSkills: [],
      filteredData: [],
      columns: [],
      searchInput: "",
      userStatus: "",
      errors: { message: "" },
      modal: false,
      open: false,
      openE: false,
      openD: false,
      openS: false,
      formErrors: {
        firstName: "",
        lastName: "",
        phone: "",
        address: "",
        seniority: "",
        isRecruited: "",
        email: "",
        applicantStatus: "",
        profil: "",
        resume: "",
        manualResume: {
          experiences: "",
          degrees: "",
          skillsManualResume: "",
        },
      },
      errors: { message: "" },
    };

    //Step I
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onResumeChange = this.onResumeChange.bind(this);
    this.onChangeSelect = this.onChangeSelect.bind(this);
    this.onChangeSelectProfil = this.onChangeSelectProfil.bind(this);
    this.onChangeSelectSupplier = this.onChangeSelectSupplier.bind(this);
    this.validateEmailAvailability = this.validateEmailAvailability.bind(this);

    //Step II
    // this.closeModal = this.closeModal=.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModalE = this.closeModalE.bind(this);
    this.openModalE = this.openModalE.bind(this);
    this.closeModalD = this.closeModalD.bind(this);
    this.openModalD = this.openModalD.bind(this);
    this.closeModalS = this.closeModalS.bind(this);
    this.openModalS = this.openModalS.bind(this);
    this.toggle = this.toggle.bind(this);
    this.addExperience = this.addExperience.bind(this);
    this.editExperience = this.editExperience.bind(this);
    this.deleteE = this.deleteE.bind(this);
    this.addDegree = this.addDegree.bind(this);
    this.editDegree = this.editDegree.bind(this);
    this.deleteD = this.deleteD.bind(this);
    this.addManualResumeSkills = this.addManualResumeSkills.bind(this);
  }

  addManualResumeSkills(e) {
    console.log(this);
    this.setState({ skillsManualResume: e });
  }

  addExperience(experience) {
    this.setState(
      (prevState) => ({
        experiences: arraySorterBy(
          [...prevState.experiences, experience],
          "endAt"
        ),
      }),
      () => {
        this.closeModalE();
      }
    );
  }

  editExperience(experience, arrayId) {
    var experiencesSorted = arraySorterBy(
      update(this.state.experiences, {
        [arrayId]: { $set: experience },
      }),
      "endAt"
    );

    this.setState({
      experiences: experiencesSorted,
    });
  }

  deleteE(arrayId) {
    const newExperiences = this.state.experiences.filter(
      (_, i) => i !== arrayId
    );
    this.setState(
      {
        experiences: newExperiences,
      },
      () => {
        console.log(this.state.data);
      }
    );
  }

  addDegree(degree) {
    this.setState(
      (prevState) => ({
        degrees: arraySorterBy([...prevState.degrees, degree], "endAt"),
      }),
      () => {
        this.closeModalD();
      }
    );
  }

  editDegree(degree, arrayId) {
    var degreesSorted = arraySorterBy(
      update(this.state.degrees, {
        [arrayId]: { $set: degree },
      }),
      "endAt"
    );
    this.setState(
      {
        degrees: degreesSorted,
      },
      () => {
        this.closeModalD();
      }
    );
  }

  deleteD(arrayId) {
    const newDegrees = this.state.degrees.filter((_, i) => i !== arrayId);
    this.setState(
      {
        degrees: newDegrees,
      },
      () => {
        console.log(this.state.data);
      }
    );
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  // closeModal() {
  //   console.log("close popup");
  //   this.setState({ open: false });
  //   this.componentDidMount();
  // }

  openModal() {
    this.setState({ open: true });
  }

  closeModalE() {
    this.setState({ openE: false });
  }

  openModalE() {
    this.setState({ openE: true });
  }

  closeModalD() {
    this.setState({ openD: false });
  }

  openModalD() {
    this.setState({ openD: true });
  }

  closeModalS() {
    this.setState({ openS: false });
  }

  openModalS() {
    this.setState({ openS: true });
  }

  componentDidMount() {
    getApplicant(this.props.currentUser.id)
      .then((response) => {
        console.log("applicant", response);

        var profil;
        if (response.content.profil !== null) {
          profil = response.content.profil;
          profil.label = profil.name;
          profil.value = profil.id;
        } else {
          profil = "";
        }

        var supplier = "";

        if (response.content.supplier != null) {
          supplier = response.content.supplier;
          supplier.label = supplier.name;
          supplier.value = supplier.id;
        }

        if (response.content.subskills != null) {
          this.setState({
            skills: response.content.subskills.map((obj) => {
              obj.value = obj.id;
              obj.label = obj.name;
              return obj;
            }),
          });
        }

        let skillsManualResume = [];

        if (
          response.content.manualResume != null &&
          response.content.manualResume.subskills != null
        ) {
          skillsManualResume = response.content.manualResume.subskills.map(
            (obj) => {
              obj.value = obj.id;
              obj.label = obj.name;
              return obj;
            }
          );
        }

        let experiences = [];

        if (
          response.content.manualResume != null &&
          response.content.manualResume.experiences != null
        ) {
          experiences = arraySorterBy(
            response.content.manualResume.experiences,
            "endAt"
          );
        }

        let degrees = [];

        if (
          response.content.manualResume != null &&
          response.content.manualResume.degrees != null
        ) {
          degrees = arraySorterBy(
            response.content.manualResume.degrees,
            "endAt"
          );
        }

        let experiencesNew = experiences.map((obj) => {
          delete obj.id;
          delete obj.location;
          return obj;
        });

        let degreesNew = degrees.map((obj) => {
          delete obj.id;
          return obj;
        });

        var phoneNumber = response.content.phone;
        if (phoneNumber !== null && phoneNumber[0] == "0") {
          phoneNumber = "212" + phoneNumber.substring(1);
        }
        if (phoneNumber === null) {
          phoneNumber = "212";
        }

        var oldResume = true;
        if (response.content.resume === null) {
          oldResume = false;
        }

        this.setState(
          {
            firstName:
              response.content.firstName === null
                ? ""
                : response.content.firstName,
            lastName:
              response.content.lastName === null
                ? ""
                : response.content.lastName,
            phone: phoneNumber,
            address:
              response.content.address === null ? "" : response.content.address,
            seniority:
              response.content.seniority === null
                ? ""
                : response.content.seniority,
            email:
              response.content.email === null ? "" : response.content.email,
            oldEmail:
              response.content.email === null ? "" : response.content.email,
            isRecruited:
              response.content.isRecruited === null
                ? ""
                : response.content.isRecruited,
            isImported:
              response.content.isImported === null
                ? ""
                : response.content.isImported,
            applicantStatus:
              response.content.applicantStatus === null
                ? ""
                : response.content.applicantStatus,
            createdAt:
              response.content.createdAt === null
                ? ""
                : response.content.createdAt,
            profil: profil,
            adressTwo:
              response.content.adressTwo === null
                ? ""
                : response.content.adressTwo,
            city: response.content.city === null ? "" : response.content.city,
            country:
              response.content.country === null ? "" : response.content.country,
            zipCode:
              response.content.zipCode === null ? "" : response.content.zipCode,
            userStatus:
              response.content.userStatus === null
                ? "ACTIVE"
                : response.content.userStatus,
            supplier: supplier,
            skillsManualResume,
            manualResume: {
              experiences: experiencesNew,
              degrees: degreesNew,
              updated: false,
            },
            experiences,
            degrees,
            oldResume,
          },
          () => {
            console.log("phone first charachter", this.state.phone[0]);
          }
        );
      })
      .catch(function (error) {
        console.log(error);
      });

    getAllSubskills()
      .then((Response) => {
        this.setState({ selectSkills: Response });
        this.state.selectSkills.map((obj) => {
          obj.value = obj.id;
          obj.label = obj.name;
          return obj;
        });
      })
      .catch((err) => console.log(err));

    getActiveProfils()
      .then((Response) => {
        this.setState({ selectProfils: Response });
        this.state.selectProfils.map((obj) => {
          obj.value = obj.id;
          obj.label = obj.name;
          return obj;
        });
      })
      .catch((err) => console.log(err));

    getAllSuppliers()
      .then((Response) => {
        this.setState({ selectSuppliers: Response });
        this.state.selectSuppliers.map((obj) => {
          obj.value = obj.id;
          obj.label = obj.name;
          return obj;
        });
      })
      .catch((err) => console.log(err));
  }

  validateEmailAvailability() {
    if (
      this.validateEmail(this.state.email) &&
      this.state.email !== this.state.oldEmail
    ) {
      checkEmailAvailability(this.state.email)
        .then((response) => {
          if (response.available) {
            this.setState((prevState) => ({
              formErrors: {
                ...prevState.formErrors,
                email: "",
              },
            }));
          } else {
            this.setState((prevState) => ({
              formErrors: {
                ...prevState.formErrors,
                email: "Cette adresse e-mail est déjà utilisée",
              },
            }));
          }
        })
        .catch((error) => {
          this.setState((prevState) => ({
            formErrors: {
              ...prevState.formErrors,
              email: "",
            },
          }));
        });
    }
  }

  validateEmail(email) {
    var regexMail = /.+@.+\.[A-Za-z]+$/;
    return regexMail.test(email);
  }

  validatePhoneNumber(phone) {
    var regexPhone = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
    return regexPhone.test(phone);
  }

  validateForm() {
    let {
      firstName,
      lastName,
      phone,
      address,
      seniority,
      email,
      applicantStatus,
      profil,
      resume,
    } = this.state;

    let formErrors = this.state.formErrors;

    formErrors.firstName =
      firstName.length > 0 ? "" : "Veuillez entrer le prénom";
    formErrors.lastName = lastName.length > 0 ? "" : "Veuillez entrer le nom";
    formErrors.seniority =
      seniority.length > 0 ? "" : "Veuillez sélectionner la Séniorité";
    formErrors.phone =
      phone.length > 0 ? "" : "Veuillez sélectionner le numéro de téléphone";
    // formErrors.address =
    //   address.length > 0 ? "" : "Veuillez entrer une adresse";
    formErrors.profil = profil === "" ? "Veuillez sélectionner un profil" : "";
    if (!this.state.oldResume) {
      formErrors.resume = resume === "" ? "Veuillez uploader un CV" : "";
    }
    formErrors.email = email.length > 0 ? "" : "Veuillez entrer un email";
    // formErrors.applicantStatus =
    //   applicantStatus.length > 0
    //     ? ""
    //     : "Veuillez selectionner le statue du candidat";

    if (phone.length > 0) {
      formErrors.phone = this.validatePhoneNumber(phone)
        ? ""
        : "Veuillez saisir un numéro téléphone valide";
    }

    if (email.length > 0) {
      console.log(this.validateEmail(email));
      formErrors.email = this.validateEmail(email)
        ? ""
        : "Veuillez saisir un email valide";
    }

    this.setState({
      formErrors,
    });

    console.log(formErrors);

    return (
      JSON.stringify(formErrors) ===
      JSON.stringify({
        firstName: "",
        lastName: "",
        phone: "",
        address: "",
        seniority: "",
        isRecruited: "",
        email: "",
        applicantStatus: "",
        profil: "",
        resume: "",
        manualResume: {
          experiences: "",
          degrees: "",
          skillsManualResume: "",
        },
      })
    );
  }

  validateManualResume() {
    let { experiences, degrees, skillsManualResume } = this.state;

    let formErrors = this.state.formErrors;

    formErrors.manualResume.experiences =
      experiences.length > 0 ? "" : "Veuillez ajouter les expériences";
    formErrors.manualResume.degrees =
      degrees.length > 0 ? "" : "Veuillez ajouter les diplômes";
    formErrors.manualResume.skillsManualResume =
      skillsManualResume.length > 0
        ? ""
        : "Veuillez sélectionner les compétences";

    this.setState({
      formErrors,
    });

    return (
      JSON.stringify(formErrors.manualResume) ===
      JSON.stringify({
        experiences: "",
        degrees: "",
        skillsManualResume: "",
      })
    );
  }

  onSubmit(e) {
    e.preventDefault();

    let skills = this.state.skills.map((item) => {
      item.name = item.label;
      return item;
    });

    var Candidat = {
      id: this.props.currentUser.id,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phone: this.state.phone,
      seniority: this.state.seniority,
      email: this.state.email,
      isRecruited: this.state.isRecruited,
      isImported: this.state.isImported,
      applicantStatus: "APPROVED",
      address: this.state.address,
      profil: this.state.profil,
      subskills: skills,
      adressTwo: this.state.adressTwo,
      city: this.state.city,
      country: this.state.country,
      zipCode: this.state.zipCode,
      provider: "LOCAL",
      providerId: "",
      userStatus: this.state.userStatus,
      manualResume: {
        experiences: this.state.experiences,
        degrees: this.state.degrees,
        subskills: this.state.skillsManualResume,
        updated: this.state.manualResume.updated,
      },
    };

    console.log(this.state.experiences);
    console.log(this.state.manualResume.experiences);

    if (
      _.isEqual(this.state.degrees, this.state.manualResume.degrees) &&
      _.isEqual(this.state.experiences, this.state.manualResume.experiences)
    ) {
      console.log("not updated");
      Candidat.manualResume.updated = false;
    } else {
      console.log("updated");
      Candidat.manualResume.updated = true;
    }

    console.log(Candidat.manualResume);

    delete Candidat.profil.value;
    delete Candidat.profil.label;

    if (this.state.supplier !== "") {
      let supplier = Object.assign({}, this.state.supplier);

      Candidat.supplier = supplier;

      delete Candidat.supplier.value;
      delete Candidat.supplier.label;
    }

    console.log("Candidat", Candidat);

    const CandidatTxt = JSON.stringify(Candidat);
    // console.log(CandidatTxt);
    let data = new FormData();
    data.append("resume", this.state.resume);
    data.append("applicant", CandidatTxt);

    // if (this.validateManualResume()) {
    // editApplicant(this.props.match.params.Id, data)
    //   .then((res) => this.props.history.push("/Candidat"))
    //   .catch((err) => this.setState({ errors: { message: err.message } }));
    // }

    if (this.state.currentStep === 1) {
      if (this.validateForm()) {
        editApplicant(this.props.match.params.Id, data)
          .then((res) => this.props.history.push("/"))
          // .then((res) => console.log("test"))
          .catch((err) => this.setState({ errors: { message: err.message } }));
      }
    } else {
      editApplicant(this.props.match.params.Id, data)
        .then((res) => this.props.history.push("/"))
        // .then((res) => console.log(res))
        .catch((err) => this.setState({ errors: { message: err.message } }));
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onResumeChange(event) {
    console.log(event);
    this.setState(
      {
        resume: event.target.files[0],
      },
      () => {
        console.log(this.state.resume);
      }
    );
  }

  onChangeSelectProfil(e) {
    if (this.state.profil.skills !== undefined) {
      let skills = this.state.profil.skills.map((obj) => {
        obj.value = obj.id;
        obj.label = obj.name;
        return obj;
      });
      for (let s in skills) {
        this.state.skills = this.state.skills.filter((item) => {
          return item.name !== skills[s].name;
        });
      }
    }

    this.setState({ profil: e });

    if (e.skills !== undefined) {
      let skills = [];
      for (let s in e.skills) {
        skills.push(
          this.state.selectSkills.filter((item) => {
            return item.name === e.skills[s].name;
          })[0]
        );
      }
      this.onChangeSelect(
        Array.from(new Set(this.state.skills.concat(skills)))
      );
    }
  }

  onChangeSelect(e) {
    if (e === null) {
      this.setState({ skills: [] });
    } else {
      this.setState({ skills: e });
    }
  }

  onChangeSelectSupplier(e) {
    this.setState({ supplier: e });
  }

  alert(message) {
    return (
      <div className="alert alert-danger" role="alert">
        {message}
      </div>
    );
  }

  ///////////////////////////////////////////////////////////// STEP BUTTONS

  _next = () => {
    if (this.validateForm()) {
      let currentStep = this.state.currentStep;
      currentStep = currentStep >= 1 ? 2 : currentStep + 1;
      this.setState({
        currentStep,
      });
    }
  };

  _prev = () => {
    let currentStep = this.state.currentStep;
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep,
    });
  };

  previousButton() {
    let currentStep = this.state.currentStep;
    if (currentStep !== 1) {
      return (
        <button
          type="button"
          className="btn btn-secondary btn-block mt-4 col-md-2 float-left"
          onClick={this._prev}
        >
          Précédent
        </button>
      );
    }
    return null;
  }

  nextButton() {
    let currentStep = this.state.currentStep;
    if (currentStep < 2) {
      return (
        <React.Fragment>
          <button
            className="btn mt-4 col-md-2 float-right"
            type="button"
            onClick={this._next}
            style={{
              background: "#5841D9",
              color: "#ffffff",
            }}
          >
            Suivant
          </button>

          <button
            onClick={this.onSubmit.bind(this)}
            className="btn mt-4 col-md-2 float-right"
            style={{
              marginRight: "10px",
              background: "#5841D9",
              color: "#ffffff",
            }}
          >
            Sauvegarder
          </button>
        </React.Fragment>
      );
    }
    return null;
  }

  //////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <React.Fragment>
        {/*Page Title*/}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title" style={{ color: "#ffffff" }}>
                    Profil
                  </h2>
                  {/* <p>Fresh From The Press Discription</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="service-area ptb--80  bg_image bg_image--3"
          // style={{ paddingTop: "200px", paddingBottom: "200px" }}
        >
          <div className="container">
            <div>
              {this.state.errors.message === ""
                ? null
                : this.alert(this.state.errors.message)}

              <div className="card">
                <div className="card-header">
                  <strong>Compléter Informations</strong>
                </div>
                <div className="card-body">
                  <StepperForm
                    stepsUI={["Informations Générales", "CV Personnalisé"]}
                    currentStep={this.state.currentStep}
                  />

                  <div>
                    <Step1
                      currentStep={this.state.currentStep}
                      state={this.state}
                      onChangeDate={this.onChangeDate}
                      onChange={this.onChange}
                      onChangeSelectResource={this.onChangeSelectResource}
                      onChangeSelectOrderSheet={this.onChangeSelectOrderSheet}
                      onFileChange={this.onFileChange}
                      onAttachmentChange={this.onAttachmentChange}
                      onChangeSelectProfil={this.onChangeSelectProfil}
                      onResumeChange={this.onResumeChange}
                      onChangeSelectSupplier={this.onChangeSelectSupplier}
                      onChangeSelect={this.onChangeSelect}
                      validateEmailAvailability={this.validateEmailAvailability}
                    />

                    <Step2
                      currentStep={this.state.currentStep}
                      state={this.state}
                      // closeModal={this.closeModal}
                      openModal={this.openModal}
                      closeModalE={this.closeModalE}
                      openModalE={this.openModalE}
                      closeModalD={this.closeModalD}
                      openModalD={this.openModalD}
                      closeModalS={this.closeModalS}
                      openModalS={this.openModalS}
                      toggle={this.toggle}
                      onSubmit={this.onSubmit}
                      addExperience={this.addExperience}
                      editExperience={this.editExperience}
                      deleteE={this.deleteE}
                      addDegree={this.addDegree}
                      editDegree={this.editDegree}
                      deleteD={this.deleteD}
                      addManualResumeSkills={this.addManualResumeSkills}
                      formErrors={this.state.formErrors}
                    />

                    {this.previousButton()}
                    {this.nextButton()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function Step1(props) {
  var dateFormat = require("dateformat");
  if (props.currentStep !== 1) {
    return null;
  }

  return (
    <form onSubmit={props.onSubmit} onChange={props.onChange}>
      <FormGroup>
        <Label for="lastName">Nom</Label>
        <Input
          id="lastName"
          name="lastName"
          onChange={props.onChange}
          value={props.state.lastName}
        />
        <FormText color="danger">{props.state.formErrors.lastName}</FormText>
      </FormGroup>

      <FormGroup>
        <Label for="firstName">Prénom</Label>
        <Input
          id="firstName"
          name="firstName"
          onChange={props.onChange}
          value={props.state.firstName}
        />
        <FormText color="danger">{props.state.formErrors.firstName}</FormText>
      </FormGroup>

      <FormGroup>
        <Label for="email">Email</Label>
        <Input
          id="email"
          name="email"
          onChange={props.onChange}
          value={props.state.email}
          onBlur={props.validateEmailAvailability}
        />
        <FormText color="danger">{props.state.formErrors.email}</FormText>
      </FormGroup>

      <FormGroup>
        <Label for="phone">Numéro de téléphone</Label>
        {/* <Input
          id="phone"
          name="phone"
          onChange={props.onChange}
          value={props.state.phone}
        /> */}

        <PhoneInput
          inputProps={{
            id: "phone",
          }}
          inputStyle={{ width: "100%" }}
          masks={{ fr: "(...) ..-..-..", ma: ". .. .. .. .." }}
          country={"ma"}
          onlyCountries={["fr", "ma"]}
          value={props.state.phone}
          placeholder="+212 - -- -- -- --"
          onChange={(phone) => {
            console.log(props.state.phone);
            console.log(phone);

            let e = { target: { name: "phone", value: phone } };
            props.onChange(e);
          }}
        />
        <FormText color="danger">{props.state.formErrors.phone}</FormText>
      </FormGroup>

      <FormGroup>
        <Label for="address">Adresse</Label>
        <Input
          id="address"
          name="address"
          onChange={props.onChange}
          value={props.state.address}
        />
        <FormText color="danger">{props.state.formErrors.address}</FormText>
      </FormGroup>

      <FormGroup>
        <Label for="adressTwo">Adresse 2</Label>
        <Input
          id="adressTwo"
          name="adressTwo"
          onChange={props.onChange}
          value={props.state.adressTwo}
        />
        <FormText color="danger">{props.state.formErrors.adressTwo}</FormText>
      </FormGroup>

      <Row form>
        <Col md={6}>
          <FormGroup>
            <Label for="city">Ville</Label>
            <Input
              name="city"
              id="city"
              onChange={props.onChange}
              value={props.state.city}
            ></Input>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="country">Pays</Label>
            <Input
              type="select"
              name="country"
              id="country"
              onChange={props.onChange}
              value={props.state.country}
            >
              <option value="">Sélectionner Pays</option>
              {countries.map((data) => {
                return <option value={data.name}>{data.name}</option>;
              })}
            </Input>
          </FormGroup>
        </Col>
        <Col md={2}>
          <FormGroup>
            <Label for="zipCode">Code Postal</Label>
            <Input
              name="zipCode"
              id="zipCode"
              onChange={props.onChange}
              value={props.state.zipCode}
            />
            <FormText color="danger">{props.state.formErrors.zipCode}</FormText>
          </FormGroup>
        </Col>
      </Row>
      {/* <FormGroup>
        <Label for="applicantStatus">Statue</Label>
        <Input
          type="select"
          name="applicantStatus"
          id="applicantStatus"
          onChange={props.onChange}
          value={props.state.applicantStatus}
        >
          <option value="APPROVED">Actif</option>
          <option value="SUSPENDED">Suspendu</option>
        </Input>
        <FormText color="danger">{props.state.formErrors.status}</FormText>
      </FormGroup> */}

      <FormGroup>
        <Label for="seniority">Séniorité</Label>
        <Input
          type="select"
          name="seniority"
          id="seniority"
          onChange={props.onChange}
          value={props.state.seniority}
        >
          <option value="" disabled>
            Sélectionner la séniorité
          </option>
          <option value="TRAINEE">Stagiaire</option>
          <option value="JUNIOR">Junior</option>
          <option value="CONFIRMED">Confirmé</option>
          <option value="SENIOR">Sénior</option>
          <option value="EXPERT">Expert</option>
          <option value="ARCHITECT">Architecte</option>
          <option value="DIRECTOR">Directeur</option>
        </Input>
        <FormText color="danger">{props.state.formErrors.seniority}</FormText>
      </FormGroup>

      <FormGroup id="supplier">
        <Label for="supplier">Fournisseur </Label>
        <Select
          value={props.state.supplier}
          placeholder="Sélectionner le fournisseur"
          name="supplier"
          options={props.state.selectSuppliers}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={props.onChangeSelectSupplier}
        />
        <FormText color="danger">{props.state.formErrors.supplier}</FormText>
      </FormGroup>

      <FormGroup id="profil">
        <Label for="profil">Profil </Label>
        <Select
          value={props.state.profil}
          placeholder="Sélectionner le profil"
          name="profil"
          options={props.state.selectProfils}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={props.onChangeSelectProfil}
        />
        <FormText color="danger">{props.state.formErrors.profil}</FormText>
      </FormGroup>

      <FormGroup id="skills">
        <Label for="skills">Compétences</Label>
        <CreatableSelect
          id="skills"
          value={props.state.skills}
          isMulti
          placeholder="Sélectionner les compétences"
          name="skills"
          options={props.state.selectSkills}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={props.onChangeSelect}
        />
        <FormText color="danger">{props.state.formErrors.skills}</FormText>
      </FormGroup>

      <FormGroup>
        <Label for="resume">CV</Label>
        <div className="custom-file" style={{ zIndex: 0 }}>
          <input
            id="resume"
            type="file"
            onChange={props.onResumeChange}
            className="custom-file-input"
          />
          <label className="custom-file-label">
            {props.state.resume !== ""
              ? props.state.resume.name
              : "Uploader CV"}
          </label>
        </div>
        <FormText color="danger">{props.state.formErrors.resume}</FormText>
      </FormGroup>
    </form>
  );
}

function Step2(props) {
  var dateFormat = require("dateformat");
  var idApplicant = 0;
  if (props.currentStep !== 2) {
    return null;
  }
  console.log(props);
  return (
    <div>
      <div className="row clearfix">
        <div
          className={"content-side col-lg-12   col-md-12 col-sm-12"}
          style={{ paddingLeft: "40px" }}
        >
          <div className="container-resume">
            <div className="header-resume">
              <h4>Expérience</h4>{" "}
              <AddExperienceModal
                addExperience={props.addExperience}
                idApplicant={idApplicant}
                init={props.componentDidMount}
                close={props.closeModalE}
              />
            </div>

            {props.state.experiences.map((data, key) => (
              <React.Fragment>
                <div className="year-period">
                  {data["endAt"] !== null
                    ? dateFormat(data["endAt"], "yyyy-mm") +
                      " | " +
                      dateFormat(data["startAt"], "yyyy-mm")
                    : "Présent" +
                      " | " +
                      dateFormat(data["startAt"], "yyyy-mm")}
                </div>
                <div className="content-resume">
                  <div className="header-experience">
                    <div className="experience-title">
                      {data["jobTitle"] !== null ? data["jobTitle"] : null}
                    </div>
                    <div className="buttons">
                      <button
                        onClick={props.deleteE.bind(this, key)}
                        className="btn btn-danger"
                        style={{
                          marginRight: "5px",
                        }}
                      >
                        <FiDelete />
                      </button>

                      <EditExperienceModal
                        Id={key}
                        arrayId={key}
                        experience={data}
                                          idApplicant={idApplicant}
                        init={props.componentDidMount}
                        close={props.closeModalE}
                        editExperience={props.editExperience}
                        closeModalE={props.closeModalE}
                      />
                    </div>
                  </div>

                  <div className="company">
                    {" "}
                    {data["company"] !== null ? data["company"] : null}
                  </div>
                  <div>
                    <p>
                      {data["description"] !== null
                        ? data["description"]
                        : null}
                    </p>
                  </div>
                  <div>
                    <lable>Environnement</lable>
                    <p>
                      {data["environment"] !== null
                        ? data["environment"]
                        : null}
                    </p>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>

          <div className="container-resume">
            <div className="header-resume">
              <h4>Formation</h4>{" "}
              <AddDegreeModal
                addDegree={props.addDegree}
                idApplicant={idApplicant}
                init={props.componentDidMount}
                close={props.closeModalD}
              />
            </div>

            {props.state.degrees.map((data, key) => (
              <React.Fragment>
                {" "}
                <div className="year-period">
                  {" "}
                  {data["endAt"] !== null
                    ? dateFormat(data["endAt"], "yyyy") +
                      " | " +
                      dateFormat(data["startAt"], "yyyy")
                    : "Présent" + " | " + dateFormat(data["startAt"], "yyyy")}
                </div>
                <div className="content-resume">
                  <div className="header-experience">
                    <div className="experience-title">
                      {data["diplomaName"] !== null
                        ? data["diplomaName"]
                        : null}
                    </div>
                    <div className="buttons">
                      <button
                        onClick={props.deleteD.bind(this, key)}
                        className="btn btn-danger"
                        style={{
                          marginRight: "5px",
                        }}
                      >
                        <FiDelete />
                      </button>

                      <EditDegreeModal
                        Id={key}
                        arrayId={key}
                        degree={data}
                                          idApplicant={idApplicant}
                        init={props.componentDidMount}
                        close={props.closeModal}
                        editDegree={props.editDegree}
                        closeModalD={props.closeModalD}
                      />

                      {/* <EditDegree
                        Id={key}
                        arrayId={key}
                        degree={data}
                                          idApplicant={idApplicant}
                        init={props.componentDidMount}
                        close={props.closeModal}
                        editDegree={props.editDegree}
                        closeModalD={props.closeModalD}
                      /> */}
                    </div>
                  </div>

                  <div className="company">
                    <p>
                      {" "}
                      {data["schoolName"] !== null ? data["schoolName"] : null}
                    </p>
                  </div>
                  <div></div>
                </div>
              </React.Fragment>
            ))}
          </div>

          <div className="container-resume">
            <div className="header-resume">
              <h4>Compétences</h4>{" "}
              {/* {props.state.manualResumeStatus ? (
                <button
                              onClick={props.deleteManualResume.bind(
                    this,
                    props.state.manualResume.id
                  )}
                  className="btn btn-danger"
                  style={{
                    marginRight: "5px",
                  }}
                >
                  <FiDelete />
                </button>
              ) : (
                <div>
                  {" "}
                  {props.state.skillsManualResume.length === 0 ? (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={props.openModalS}
                    >
                      <i className="fa fa-plus" />
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={props.openModalS}
                    >
                      <i className="fa fa-plus" />
                    </button>
                  )}
                  <Popup
                    open={props.state.openS}
                    modal
                    closeOnDocumentClick
                    onClose={props.closeModalS}
                  >
                    <AddSkills
                      addManualResumeSkills={props.addManualResumeSkills}
                      idApplicant={idApplicant}
                      init={props.componentDidMount}
                      close={props.closeModalS}
                      skillsManualResume={props.state.skillsManualResume}
                    />
                  </Popup>
                </div>
              )} */}
            </div>
            <div className="content-skills">
              <p>
                {props.state.skills
                  .map(function (elem) {
                    if (elem.label === null) {
                      return elem.name;
                    } else {
                      return elem.label;
                    }
                  })
                  .join(" | ")}{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <input
        type="button"
        onClick={props.onSubmit.bind(this)}
        value="Sauvegarder"
        className="btn mt-4 col-md-2 float-right"
        style={{ background: "#5841D9", color: "#ffffff" }}
      />
    </div>
  );
}

export default Ajout;
