import React, { Component } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "./timeline/main.css";

import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import CheckIcon from "@material-ui/icons/Check";
import ForumIcon from "@material-ui/icons/Forum";
import SendIcon from "@material-ui/icons/Send";

import {
  getJob,
  getApplicationByApplicantIdAndOfferId,
  getInterviewByApplicantIdAndOfferId,
  getContactByApplicantIdAndOfferId,
  getResultByApplicantIdAndOfferId,
} from "./util/APIUtils";

export default class Offers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      offerTitle: "",
      operations: [],
      data: [],
      filteredData: [],
      columns: [],
      searchInput: "",
      history: [],
      errors: { message: "" },
    };
  }

  componentDidMount() {
    var offerId = this.props.match.params.offerId;
    var applicantId = this.props.currentUser.id;

    getJob(offerId)
      .then((res) => {
        console.log("res", res);
        this.setState({ offerTitle: res.content.title });
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    getApplicationByApplicantIdAndOfferId(applicantId, offerId)
      .then((response) => {
        console.log(response);
        this.setState({
          applications: [response.content],
        });

        let unsortedOperations = [];
        unsortedOperations = unsortedOperations.concat(this.state.applications);
        unsortedOperations = unsortedOperations.map(function (el) {
          let o = Object.assign({}, el);
          o.type = "Application";
          return o;
        });

        this.state.operations.push.apply(
          this.state.operations,
          unsortedOperations
        );
        this.state.operations.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );
        this.state.operations.reverse();
        console.log(this.state.operations);
        this.setState({
          refresh: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    getInterviewByApplicantIdAndOfferId(applicantId, offerId)
      .then((response) => {
        this.setState({
          interviews: response,
        });

        let unsortedOperations = [];
        unsortedOperations = unsortedOperations.concat(this.state.interviews);
        unsortedOperations = unsortedOperations.map(function (el) {
          let o = Object.assign({}, el);
          o.type = "Interview";
          return o;
        });

        this.state.operations.push.apply(
          this.state.operations,
          unsortedOperations
        );
        this.state.operations.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );
        this.state.operations.reverse();
        console.log(this.state.operations);
        this.setState({
          refresh: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    getContactByApplicantIdAndOfferId(applicantId, offerId)
      .then((response) => {
        console.log(response);
        this.setState({
          contacts: response,
        });

        let unsortedOperations = [];
        unsortedOperations = unsortedOperations.concat(this.state.contacts);
        unsortedOperations = unsortedOperations.map(function (el) {
          let o = Object.assign({}, el);
          o.type = "Contact";
          return o;
        });

        this.state.operations.push.apply(
          this.state.operations,
          unsortedOperations
        );
        this.state.operations.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );
        this.state.operations.reverse();
        console.log(this.state.operations);
        this.setState({
          refresh: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    getResultByApplicantIdAndOfferId(applicantId, offerId)
      .then((response) => {
        this.setState({
          results: response,
        });

        let unsortedOperations = [];
        unsortedOperations = unsortedOperations.concat(this.state.results);
        unsortedOperations = unsortedOperations.map(function (el) {
          let o = Object.assign({}, el);
          o.type = "Result";
          return o;
        });

        this.state.operations.push.apply(
          this.state.operations,
          unsortedOperations
        );
        this.state.operations.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );
        this.state.operations.reverse();
        console.log(this.state.operations);
        this.setState({
          refresh: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    console.log("operations", this.state.operations);
    var dateFormat = require("dateformat");
    return (
      <React.Fragment>
        {/*Page Title*/}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title" style={{ color: "#ffffff" }}>
                    Suivie
                  </h2>
                  {/* <p>Fresh From The Press Discription</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*End Page Title*/}
        {/*Sidebar Page Container*/}
        <div
          className="service-area ptb--80  bg_image bg_image--3"
          style={{ paddingTop: "200px", paddingBottom: "200px" }}
        >
          <div className="container">
            <div>
              <h4>Offre {this.state.offerTitle}</h4>
              <div>
                <VerticalTimeline className="vertical-timeline-custom-line">
                  {this.state.operations.map((operation) => {
                    if (operation["type"] !== null) {
                      switch (operation["type"]) {
                        case "Application":
                          return (
                            <VerticalTimelineElement
                              className="vertical-timeline-element--work"
                              date={dateFormat(
                                operation["date"],
                                "yyyy-mm-dd | HH:mm"
                              )}
                              iconStyle={{
                                background: "#3508CB",
                                color: "#fff",
                              }}
                              contentArrowStyle={{
                                borderRight: "7px solid  #3508CB",
                              }}
                              icon={<SendIcon />}
                            >
                              <h3 className="vertical-timeline-element-title">
                                Postulation
                              </h3>
                              <h4 className="vertical-timeline-element-subtitle">
                                {operation["offer"].title}
                              </h4>
                            </VerticalTimelineElement>
                          );
                        case "Interview":
                          return (
                            <VerticalTimelineElement
                              className="vertical-timeline-element--work"
                              iconStyle={{
                                background: "#3508CB",
                                color: "#fff",
                              }}
                              contentArrowStyle={{
                                borderRight: "7px solid  #3508CB",
                              }}
                              date={dateFormat(
                                operation["date"],
                                "yyyy-mm-dd | HH:mm"
                              )}
                              icon={<ForumIcon />}
                            >
                              <h3 className="vertical-timeline-element-title">
                                Entretien
                              </h3>
                              <h5 className="vertical-timeline-element-subtitle">
                                {operation["address"] +
                                  " | " +
                                  dateFormat(operation["date"], "yyyy-mm")}
                              </h5>
                              <h5 className="vertical-timeline-element-subtitle">
                                {"Contact: " + operation["address"]}
                              </h5>
                              <p></p>
                            </VerticalTimelineElement>
                          );
                        case "Contact":
                          return (
                            <VerticalTimelineElement
                              className="vertical-timeline-element--work"
                              iconStyle={{
                                background: "#3508CB",
                                color: "#fff",
                              }}
                              contentArrowStyle={{
                                borderRight: "7px solid  #3508CB",
                              }}
                              date={dateFormat(
                                operation["date"],
                                "yyyy-mm-dd | HH:mm"
                              )}
                              icon={<ContactPhoneIcon />}
                            >
                              <h3 className="vertical-timeline-element-title">
                                Contact
                              </h3>
                              <h5 className="vertical-timeline-element-subtitle">
                                {"Préavis | " + operation["priorNotice"]}
                              </h5>
                              <h5 className="vertical-timeline-element-subtitle">
                                {"TJM | " + operation["averageDailyRate"]}
                              </h5>
                              <p></p>
                            </VerticalTimelineElement>
                          );
                        case "Result":
                          return (
                            <VerticalTimelineElement
                              className="vertical-timeline-element--work"
                              iconStyle={{
                                background: "#3508CB",
                                color: "#fff",
                              }}
                              contentArrowStyle={{
                                borderRight: "7px solid  #3508CB",
                              }}
                              date={dateFormat(
                                operation["date"],
                                "yyyy-mm-dd | HH:mm"
                              )}
                              icon={<CheckIcon />}
                            >
                              <h3 className="vertical-timeline-element-title">
                                Résultat
                              </h3>
                              <h5 className="vertical-timeline-element-subtitle">
                                {operation["address"] !== "ACCEPTED"
                                  ? "Validé"
                                  : "Non valide"}
                              </h5>
                              <h5 className="vertical-timeline-element-subtitle">
                                {operation["missionStartDate"] === null
                                  ? null
                                  : "Début Mission | " +
                                    dateFormat(
                                      operation["damissionStartDatete"],
                                      "yyyy-mm-dd"
                                    )}
                              </h5>
                              <p></p>
                            </VerticalTimelineElement>
                          );

                        default:
                          return null;
                      }
                    }
                  })}
                </VerticalTimeline>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
