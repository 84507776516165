import React, { Component, Fragment } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import "react-table/react-table.css";

import history from "../history";

import { FiDownload, FiDelete, FiEdit } from "react-icons/fi";

import {
  deleteActivityReport,
  getAllActivityReports,
  getResumeFile,
  getActivityReportsByResource,
} from "../util/APIUtils";

class TableReact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      errors: { message: "" },
    };

    this.delete = this.delete.bind(this);
  }

  componentDidMount() {
    var dateFormat = require("dateformat");
    var resourceId = this.props.currentUser.id;

    getActivityReportsByResource(resourceId)
      .then((Response) => {
        let data = [];
        console.log(Response);
        Response.forEach((element) => {
          data.push({
            id: element.id,
            file: element.file,
            orderSheet: element.orderSheet,
            startDate: dateFormat(element.startDate, "dd/mm/yyyy"),
            endDate: dateFormat(element.endDate, "dd/mm/yyyy"),
            workingDaysCount: element.workingDaysCount.toString(),
            month: element.month.toString(),
            year: element.year.toString(),
            companyName: element.orderSheet.company.name,
          });
        });
        this.setState({ data: data });
      })
      .catch((err) => console.log(err));
  }

  delete(arrayId, id) {
    confirmAlert({
      title: "Confirmation suppression",
      message: "Etes vous sure ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteActivityReport(id)
              .then((res) => {
                let data = this.state.data;
                data = data.filter((item) => item.id != id);
                this.setState({ data: [] });
                this.setState({ data: data });
              })
              .catch((err) =>
                this.setState({ errors: { message: err.message } })
              );
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    console.log(filter);
    console.log(row[id]);
    console.log(typeof row[id]);
    if (row[id] !== null && typeof row[id] === "string") {
      return row[id] !== undefined
        ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
        : true;
    }
  };

  downloadDocument(documentName) {
    console.log(true);
    getResumeFile(documentName)
      .then((response) => response.blob())
      .then(function (myBlob) {
        const url = window.URL.createObjectURL(myBlob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", documentName); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  }

  alert(message) {
    return (
      <div className="alert alert-danger" role="alert">
        {message}
      </div>
    );
  }

  render() {
    var dateFormat = require("dateformat");
    let data;
    if (!this.state.data.length) {
      data = [];
    } else {
      data = this.state.data;
    }

    const columns = [
      {
        Header: "Mois",
        filterable: true,
        accessor: "month",
        className: "mid",
      },
      {
        Header: "Année",
        filterable: true,
        accessor: "year",
        className: "mid",
      },
      {
        Header: "Fichier",
        id: "document_name",
        className: "mid",
        Cell: (row) => {
          return (
            <div>
              <button
                onClick={this.downloadDocument.bind(
                  this,
                  row.original.file.name
                )}
                style={{
                  color: "#ffffff",
                  background: "rgba(95, 66, 253, 0.84)",
                }}
                className="btn"
              >
                <FiDownload />
              </button>
            </div>
          );
        },
      },
      {
        Header: "Client",
        filterable: true,
        accessor: "companyName",
        className: "mid",
      },
      // {
      //   Header: "Bon de commande",
      //   filterable: true,
      //   accessor: "orderSheet",
      //   Cell: row => {
      //     return <span>{row.original.orderSheet.referenceNumber}</span>;
      //   }
      // },
      {
        Header: "Date Début",
        filterable: true,
        accessor: "startDate",
        className: "mid",
      },
      {
        Header: "Date Fin",
        filterable: true,
        accessor: "endDate",
        className: "mid",
      },
      {
        Header: "Nombre de jours",
        filterable: true,
        accessor: "workingDaysCount",
        className: "mid",
        Cell: (row) => {
          return <span>{row.original.workingDaysCount}</span>;
        },
      },
      {
        Header: "Action",
        id: "row",
        className: "mid",
        Cell: (row) => {
          return (
            <div
              style={{
                textAlign: "center",
              }}
            >
              <button
                onClick={this.delete.bind(this, row.index, row.original.id)}
                className="btn btn-danger"
                style={{
                  marginRight: "5px",
                }}
              >
                <FiDelete />
              </button>
              <Link
                to={"/activity-report/edit/" + row.original.id}
                className="btn btn-warning"
              >
                <FiEdit />
              </Link>
            </div>
          );
        },
      },
    ];
    return (
      <React.Fragment>
        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title" style={{ color: "#ffffff" }}>
                    CRAs
                  </h2>
                  {/* <p>Fresh From The Press Discription</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="service-area ptb--80  bg_image bg_image--3"
          style={{ paddingTop: "200px", paddingBottom: "200px" }}
        >
          <div className="container">
            <div>
              <div>
                <div
                  // className="float-right"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingLeft: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <h4
                    style={{
                      marginBottom: "0px",
                    }}
                  >
                    CRAs
                  </h4>
                  {/* <button
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.testRedirect(e);
                        // console.log(e);
                        // history.push("/activity-report/add");
                      }}
                    >
                      This is it
                    </button> */}

                  <Link
                    to={"/activity-report/add"}
                    style={{
                      color: "#ffffff",
                      background: "rgba(95, 66, 253, 0.84)",
                    }}
                    className="btn ml-auto float-right"
                    // style={}
                    id="addButton"
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   console.log(e);
                    //   history.push("/activity-report/add");
                    // }}
                  >
                    Ajouter CRA
                  </Link>
                </div>

                <ReactTable
                  className="-highlight"
                  columns={columns}
                  data={data}
                  minRows={0}
                  defaultPageSize={10}
                  defaultFilterMethod={this.filterCaseInsensitive}
                  getProps={() => {
                    return { id: "activityReports" };
                  }}
                  previousText="Précédent"
                  nextText="Prochain"
                  loadingText="Chargement..."
                  noDataText="Aucune ligne trouvée"
                  pageText="Page"
                  ofText="de"
                  rowsText="Lignes"
                  getTdProps={(state, rowInfo, column, instance) => {
                    return {
                      onClick: (e) => {
                        if (
                          column.Header !== "Action" &&
                          rowInfo !== undefined &&
                          column.Header !== "Fichier"
                        ) {
                          let path =
                            "/supplier/activity-report/" + rowInfo.original.id;
                          this.props.history.push(path);
                        }
                      },
                    };
                  }}
                ></ReactTable>
              </div>
            </div>
          </div>{" "}
        </div>
      </React.Fragment>
    );
  }
}
export default TableReact;
