import React, { Component } from "react";
import PropTypes from "prop-types";

import Select from "react-select";
import { FormGroup, FormText, Input, Label } from "reactstrap";
import {
  editResource,
  getAllApplicants,
  getAllProfils,
  getResource,
  getResumeFile,
} from "../util/APIUtils";

import { FiDownload } from "react-icons/fi";

import { Card, CardBody, Table } from "reactstrap";

export default class files extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anthropometricSheet: "",
      nationalIdCard: "",
      ethicsCharter: "",
      contract: "",
      nationalSocialSecurityFundCard: "",
      photoId: "",
      degree: "",
      resignationLetter: "",
      confidentialityCommitment: "",
      files: {
        NATIONAL_ID_CARD: "",
        ETHICS_CHARTER: "",
        CONTRACT: "",
        NATIONAL_SOCIAL_SECURITY_FUND_CARD: "",
        PHOTO_ID: "",
        DEGREE: "",
        RESIGNATION_LETTER: "",
        CONFIDENTIALITY_COMMITMENT: "",
        ANTHROPOMETRIC_SHEET: "",
        BANK_IDENTITY_CODE: "",
      },

      type: null,

      formErrors: {
        referenceNumber: "",
        missionStartDate: "",
        bankIdentityCode: "",
        type: "",
        applicant: "",
        phone: "",
        seniority: "",
        isRecruited: "",
        email: "",
        applicantStatus: "",
        profil: "",
        resume: "",
      },

      errors: { message: "" },
    };

    this.onFileChange = this.onFileChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  static propTypes = {
    prop: PropTypes,
  };

  componentDidMount() {
    var dateFormat = require("dateformat");

    var resourceId = this.props.currentUser.id;

    getResource(resourceId)
      .then((response) => {
        var profil = response.content.profil;
        profil.label = profil.name;
        profil.value = profil.id;
        console.log("ressource", response);
        response.content.files.map((file) => {
          this.setState({
            files: { ...this.state.files, [file.type]: file.name },
          });
        });
        this.setState({
          id: resourceId,
          firstName: response.content.firstName,
          lastName: response.content.lastName,
          phone: response.content.phone,
          seniority: response.content.seniority,
          email: response.content.email,
          isRecruited: response.content.isRecruited,
          applicantStatus: response.content.applicantStatus,
          profil: profil,
          resume: response.content.resume,
          // files: response.content.files,
          userStatus: response.content.userStatus,
          referenceNumber: "7UEJ7320J",
          missionStartDate: dateFormat(
            response.content.missionStartDate,
            "yyyy-mm-dd"
          ),
          bankIdentityCode: response.content.bankIdentityCode,
          type: response.content.type,
        });
      })
      .catch((err) => console.log(err));
  }

  downloadDocument(documentName) {
    console.log(true);
    getResumeFile(documentName)
      .then((response) => response.blob())
      .then(function (myBlob) {
        const url = window.URL.createObjectURL(myBlob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", documentName); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  }

  onFileChange(e) {
    this.setState({ [e.target.id]: e.target.files[0] });
  }

  onSubmit(e) {
    e.preventDefault();

    let resourceRequest = new FormData();

    let profil = Object.assign({}, this.state.profil);

    delete profil.label;
    delete profil.value;

    const resource = {
      id: this.state.id,
      referenceNumber: this.state.referenceNumber,
      missionStartDate: this.state.missionStartDate + "T00:00:00",
      type: this.state.type,
      bankIdentityCode: this.state.bankIdentityCode,
      resume: this.state.resume,

      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phone: this.state.phone,
      seniority: this.state.seniority,
      email: this.state.email,
      isRecruited: this.state.isRecruited,
      applicantStatus: this.state.applicantStatus,
      profil: profil,
      provider: "LOCAL",
      providerId: "",
      userStatus: this.state.userStatus,
    };

    console.log(resource);

    resourceRequest.append("resource", JSON.stringify(resource));

    if (resource.type === "PERMANENT") {
      resourceRequest.append(
        "anthropometricSheet",
        this.state.anthropometricSheet
      );
      resourceRequest.append("nationalIdCard", this.state.nationalIdCard);
      resourceRequest.append("ethicsCharter", this.state.ethicsCharter);

      resourceRequest.append("contract", this.state.contract);
      resourceRequest.append(
        "nationalSocialSecurityFundCard",
        this.state.nationalSocialSecurityFundCard
      );
      resourceRequest.append("photoId", this.state.photoId);

      resourceRequest.append("degree", this.state.degree);
      resourceRequest.append("resignationLetter", this.state.resignationLetter);
      resourceRequest.append(
        "confidentialityCommitment",
        this.state.confidentialityCommitment
      );
    } else {
      resourceRequest.append(
        "anthropometricSheet",
        this.state.anthropometricSheet
      );
      resourceRequest.append("nationalIdCard", this.state.nationalIdCard);
      resourceRequest.append("ethicsCharter", this.state.ethicsCharter);
      resourceRequest.append("contract", this.state.contract);
    }

    for (let pair of resourceRequest.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    console.log("this is it");

    // if (this.validateForm()) {
    editResource(this.props.currentUser.id, resourceRequest)
      .then((res) => this.props.history.push("/"))
      .catch((err) => console.log(err));
    // }
  }

  alert(message) {
    return (
      <div className="alert alert-danger" role="alert">
        {message}
      </div>
    );
  }

  render() {
    let CDI = (
      <React.Fragment>
        <div className="col-md-6">
          <div className="form-group col-md-12">
            <span>Fiche Anthropométrique</span>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignContent: "center",
              }}
            >
              <input
                className="form-control form-control-lg"
                onChange={this.onFileChange}
                id="anthropometricSheet"
                type="file"
              />
              {this.state.files.ANTHROPOMETRIC_SHEET !== "" && (
                <button
                  onClick={this.downloadDocument.bind(
                    this,
                    this.state.files.ANTHROPOMETRIC_SHEET
                  )}
                  style={{
                    color: "#ffffff",
                    background: "rgba(95, 66, 253, 0.84)",
                  }}
                  className="btn"
                >
                  <FiDownload />
                </button>
              )}
            </div>
          </div>
          <div className="form-group col-md-12">
            <span>Copie CIN légalisé</span>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignContent: "center",
              }}
            >
              <input
                className="form-control form-control-lg"
                onChange={this.onFileChange}
                id="nationalIdCard"
                type="file"
              />

              {this.state.files.NATIONAL_ID_CARD !== "" && (
                <button
                  onClick={this.downloadDocument.bind(
                    this,
                    this.state.files.NATIONAL_ID_CARD
                  )}
                  style={{
                    color: "#ffffff",
                    background: "rgba(95, 66, 253, 0.84)",
                  }}
                  className="btn"
                >
                  <FiDownload />
                </button>
              )}
            </div>
          </div>
          <div className="form-group col-md-12">
            <span>Copie légalisée de la charte d’étique</span>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignContent: "center",
              }}
            >
              <input
                className="form-control form-control-lg"
                onChange={this.onFileChange}
                id="ethicsCharter"
                type="file"
              />

              {this.state.files.ETHICS_CHARTER !== "" && (
                <button
                  onClick={this.downloadDocument.bind(
                    this,
                    this.state.files.ETHICS_CHARTER
                  )}
                  style={{
                    color: "#ffffff",
                    background: "rgba(95, 66, 253, 0.84)",
                  }}
                  className="btn"
                >
                  <FiDownload />
                </button>
              )}
            </div>
          </div>
          <div className="form-group col-md-12">
            <span>Copie de contrat légalisé</span>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignContent: "center",
              }}
            >
              <input
                className="form-control form-control-lg"
                onChange={this.onFileChange}
                id="contract"
                type="file"
              />

              {this.state.files.CONTRACT !== "" && (
                <button
                  onClick={this.downloadDocument.bind(
                    this,
                    this.state.files.CONTRACT
                  )}
                  style={{
                    color: "#ffffff",
                    background: "rgba(95, 66, 253, 0.84)",
                  }}
                  className="btn"
                >
                  <FiDownload />
                </button>
              )}
            </div>
          </div>
          <div className="form-group col-md-12">
            <span>Engagement Individuel de confidentialité</span>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignContent: "center",
              }}
            >
              <input
                className="form-control form-control-lg"
                onChange={this.onFileChange}
                id="confidentialityCommitment"
                type="file"
              />
              {this.state.files.CONFIDENTIALITY_COMMITMENT !== "" && (
                <button
                  onClick={this.downloadDocument.bind(
                    this,
                    this.state.files.CONFIDENTIALITY_COMMITMENT
                  )}
                  style={{
                    color: "#ffffff",
                    background: "rgba(95, 66, 253, 0.84)",
                  }}
                  className="btn"
                >
                  <FiDownload />
                </button>
              )}
            </div>
          </div>

          <div className="form-group col-md-12">
            <span>Copie carte CNSS</span>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignContent: "center",
              }}
            >
              <input
                className="form-control form-control-lg"
                onChange={this.onFileChange}
                id="nationalSocialSecurityFundCard"
                type="file"
              />

              {this.state.files.NATIONAL_SOCIAL_SECURITY_FUND_CARD !== "" && (
                <button
                  onClick={this.downloadDocument.bind(
                    this,
                    this.state.files.NATIONAL_SOCIAL_SECURITY_FUND_CARD
                  )}
                  style={{
                    color: "#ffffff",
                    background: "rgba(95, 66, 253, 0.84)",
                  }}
                  className="btn"
                >
                  <FiDownload />
                </button>
              )}
            </div>
          </div>
          <div className="form-group col-md-12">
            <span>Photos d’identité</span>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignContent: "center",
              }}
            >
              <input
                className="form-control form-control-lg"
                onChange={this.onFileChange}
                id="photoId"
                type="file"
              />

              {this.state.files.PHOTO_ID !== "" && (
                <button
                  onClick={this.downloadDocument.bind(
                    this,
                    this.state.files.PHOTO_ID
                  )}
                  style={{
                    color: "#ffffff",
                    background: "rgba(95, 66, 253, 0.84)",
                  }}
                  className="btn"
                >
                  <FiDownload />
                </button>
              )}
            </div>
          </div>
          <div className="form-group col-md-12">
            <span>Copie des diplômes</span>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignContent: "center",
              }}
            >
              <input
                className="form-control form-control-lg"
                onChange={this.onFileChange}
                id="degree"
                type="file"
              />

              {this.state.files.DEGREE !== "" && (
                <button
                  onClick={this.downloadDocument.bind(
                    this,
                    this.state.files.DEGREE
                  )}
                  style={{
                    color: "#ffffff",
                    background: "rgba(95, 66, 253, 0.84)",
                  }}
                  className="btn"
                >
                  <FiDownload />
                </button>
              )}
            </div>
          </div>
          <div className="form-group col-md-12">
            <span>Lettre de démission signée et légalisé</span>

            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignContent: "center",
              }}
            >
              <input
                className="form-control form-control-lg "
                onChange={this.onFileChange}
                id="resignationLetter"
                type="file"
              />

              {this.state.files.RESIGNATION_LETTER !== "" && (
                <button
                  onClick={this.downloadDocument.bind(
                    this,
                    this.state.files.RESIGNATION_LETTER
                  )}
                  style={{
                    color: "#ffffff",
                    background: "rgba(95, 66, 253, 0.84)",
                  }}
                  className="btn"
                >
                  <FiDownload />
                </button>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );

    let FREELANCE = (
      <div className="col-md-6">
        <div className="form-group col-md-12">
          <span>Fiche Anthropométrique</span>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignContent: "center",
            }}
          >
            <input
              className="form-control form-control-lg"
              onChange={this.onFileChange}
              id="anthropometricSheet"
              type="file"
            />
            {this.state.files.ANTHROPOMETRIC_SHEET !== "" && (
              <button
                onClick={this.downloadDocument.bind(
                  this,
                  this.state.files.ANTHROPOMETRIC_SHEET
                )}
                style={{
                  color: "#ffffff",
                  background: "rgba(95, 66, 253, 0.84)",
                }}
                className="btn"
              >
                <FiDownload />
              </button>
            )}
          </div>
        </div>
        <div className="form-group col-md-12">
          <span>Copie CIN légalisé</span>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignContent: "center",
            }}
          >
            <input
              className="form-control form-control-lg"
              onChange={this.onFileChange}
              id="nationalIdCard"
              type="file"
            />

            {this.state.files.NATIONAL_ID_CARD !== "" && (
              <button
                onClick={this.downloadDocument.bind(
                  this,
                  this.state.files.NATIONAL_ID_CARD
                )}
                style={{
                  color: "#ffffff",
                  background: "rgba(95, 66, 253, 0.84)",
                }}
                className="btn"
              >
                <FiDownload />
              </button>
            )}
          </div>
        </div>
        <div className="form-group col-md-12">
          <span>Copie légalisée de la charte d’étique</span>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignContent: "center",
            }}
          >
            <input
              className="form-control form-control-lg"
              onChange={this.onFileChange}
              id="ethicsCharter"
              type="file"
            />

            {this.state.files.ETHICS_CHARTER !== "" && (
              <button
                onClick={this.downloadDocument.bind(
                  this,
                  this.state.files.ETHICS_CHARTER
                )}
                style={{
                  color: "#ffffff",
                  background: "rgba(95, 66, 253, 0.84)",
                }}
                className="btn"
              >
                <FiDownload />
              </button>
            )}
          </div>
        </div>
        <div className="form-group col-md-12">
          <span>Copie de contrat légalisé</span>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignContent: "center",
            }}
          >
            <input
              className="form-control form-control-lg"
              onChange={this.onFileChange}
              id="contract"
              type="file"
            />

            {this.state.files.CONTRACT !== "" && (
              <button
                onClick={this.downloadDocument.bind(
                  this,
                  this.state.files.CONTRACT
                )}
                style={{
                  color: "#ffffff",
                  background: "rgba(95, 66, 253, 0.84)",
                }}
                className="btn"
              >
                <FiDownload />
              </button>
            )}
          </div>
        </div>
      </div>
    );

    return (
      <React.Fragment>
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title" style={{ color: "#ffffff" }}>
                    Profil
                  </h2>
                  {/* <p>Fresh From The Press Discription</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="service-area ptb--80  bg_image bg_image--3"
          style={{ paddingTop: "200px", paddingBottom: "200px" }}
        >
          <div className="container">
            <Card
              style={{
                marginBottom: "40px",
              }}
            >
              <div className="card-header">
                <strong>Documents</strong>
              </div>
              <div className="card-body">
                <div>
                  <form onSubmit={this.onSubmit}>
                    {this.state.type === "PERMANENT"
                      ? CDI
                      : this.state.type === "FREELANCE"
                      ? FREELANCE
                      : null}
                    <input
                      style={{
                        color: "#ffffff",
                        background: "rgba(95, 66, 253, 0.84)",
                      }}
                      value="Ajouter"
                      type="submit"
                      className="btn btn-block mt-4 col-md-2 float-right"
                    />
                  </form>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
