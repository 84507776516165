import React, { Component } from "react";

import { FormGroup, FormText, Input, Label, Table } from "reactstrap";
import { addDegree } from "../../util/APIUtils";

import Popup from "reactjs-popup";

class Ajout extends Component {
  constructor() {
    super();
    this.state = {
      diplomaName: "",
      schoolName: "",
      startAt: "",
      endAt: "",
      applicant: "",

      formErrors: {
        diplomaName: "",
        schoolName: "",
        startAt: "",
        endAt: "",
        applicant: "",
      },

      skills: [],
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  validateForm() {
    let { diplomaName, schoolName, startAt, endAt, applicant } = this.state;

    let formErrors = this.state.formErrors;

    formErrors.diplomaName =
      diplomaName.length > 0 ? "" : "Veuillez entrez une diplomaName";
    formErrors.schoolName =
      schoolName.length > 0 ? "" : "Veuillez entrez la schoolName";
    formErrors.startAt = startAt.length > 0 ? "" : "Veuillez entrez un date";
    formErrors.endAt = endAt.length > 0 ? "" : "Veuillez entrez un date";

    if (
      formErrors.startAt === "" &&
      formErrors.endAt === "" &&
      startAt > endAt
    ) {
      formErrors.endAt =
        "La date de fin ne peut pas être inférieure au date de début";
    }

    this.setState({
      formErrors,
    });

    return (
      JSON.stringify(formErrors) ===
      JSON.stringify({
        diplomaName: "",
        schoolName: "",
        startAt: "",
        endAt: "",
        applicant: "",
      })
    );
  }

  onSubmit(e) {
    e.preventDefault();

    var idApplicant = this.props.idApplicant;

    const Degree = {
      diplomaName: this.state.diplomaName,
      schoolName: this.state.schoolName,
      startAt: this.state.startAt + "T00:00:00",
      endAt: this.state.endAt + "T00:00:00",
      applicant: null,
    };

    if (this.validateForm()) {
      addDegree(Degree, idApplicant)
        .then((res) => {
          this.props.close();
        })
        .catch((err) => console.log(err));
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <div className="card">
        <div className="card-header">
          <strong>Ajouter diplôme</strong>
        </div>
        <div className="card-body">
          <div>
            <form onSubmit={this.onSubmit} onChange={this.onChange}>
              <FormGroup>
                <Label for="diplomaName">Titre</Label>
                <Input
                  id="diplomaName"
                  name="diplomaName"
                  onChange={this.onChange}
                  value={this.state.diplomaName}
                />
                <FormText color="danger">
                  {this.state.formErrors.diplomaName}
                </FormText>
              </FormGroup>

              <FormGroup>
                <Label for="startAt">Date Début</Label>
                <Input
                  id="startAt"
                  name="startAt"
                  type="date"
                  onChange={this.onChange}
                  value={this.state.startAt}
                />
                <FormText color="danger">
                  {this.state.formErrors.startAt}
                </FormText>
              </FormGroup>

              <FormGroup>
                <Label for="startAt">Date Fin</Label>
                <Input
                  id="endAt"
                  name="endAt"
                  type="date"
                  onChange={this.onChange}
                  value={this.state.endAt}
                />
                <FormText color="danger">
                  {this.state.formErrors.endAt}
                </FormText>
              </FormGroup>

              <FormGroup>
                <Label for="schoolName">Ecole</Label>
                <Input
                  id="schoolName"
                  name="schoolName"
                  type="textarea"
                  onChange={this.onChange}
                  value={this.state.schoolName}
                />
                <FormText color="danger">
                  {this.state.formErrors.schoolName}
                </FormText>
              </FormGroup>

              <input
                type="submit"
                value="Ajouter"
                className="btn btn-success float-right"
              />

              <input
                type="button"
                onClick={this.props.close}
                value="Retour"
                className="btn btn-warning mr-1 float-right"
              />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Ajout;
