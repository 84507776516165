import React, { Component } from "react";

import { Form, FormGroup, FormText, Input, Label, Col, Row } from "reactstrap";

import { editApplicant, addApplication, getApplicant } from "../util/APIUtils";
import { now } from "jquery";

class Editer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resume: null,
      formErrors: {
        resume: "",
      },
      errors: { message: "" },
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onResumeChange = this.onResumeChange.bind(this);
  }

  componentDidMount() {
    console.log(this.props);
  }

  onResumeChange(event) {
    console.log(event);
    this.setState(
      {
        resume: event.target.files[0],
      },
      () => {
        console.log(this.state.resume);
      }
    );
  }

  validateForm() {
    let {
      firstName,
      lastName,
      phone,
      address,
      seniority,
      isRecruited,
      email,
      applicantStatus,
      profil,
    } = this.state;

    let formErrors = this.state.formErrors;

    formErrors.firstName =
      firstName.length > 0 ? "" : "Veuillez entrez le prénom";
    formErrors.lastName = lastName.length > 0 ? "" : "Veuillez entrez le nom";
    formErrors.seniority =
      seniority.length > 0 ? "" : "Veuillez séléctionner la seniorité";
    formErrors.phone =
      phone.length > 0 ? "" : "Veuillez séléctionner le numéro de téléphone";
    formErrors.address =
      address.length > 0 ? "" : "Veuillez entrez une adresse";
    formErrors.profil = profil === "" ? "Veuillez séléctionner un profil" : "";
    formErrors.email = email.length > 0 ? "" : "Veuillez entrez un email";
    formErrors.applicantStatus =
      applicantStatus.length > 0
        ? ""
        : "Veuillez selectionner le statue du candidat";

    if (phone.length > 0) {
      formErrors.phone = this.validatePhoneNumber(phone)
        ? ""
        : "Veuillez saisir un numéro téléphone valide";
    }

    if (email.length > 0) {
      console.log(this.validateEmail(email));
      formErrors.email = this.validateEmail(email)
        ? ""
        : "Veuillez saisir un email valide";
    }
    console.log(formErrors);
    this.setState(
      {
        formErrors,
      },
      () => {}
    );

    return (
      JSON.stringify(formErrors) ===
      JSON.stringify({
        firstName: "",
        lastName: "",
        phone: "",
        address: "",
        seniority: "",
        isRecruited: "",
        email: "",
        applicantStatus: "",
        profil: "",
      })
    );
  }

  onSubmit(e) {
    e.preventDefault();
    console.log(this.state.profil);

    var idApplicant = this.props.idApplicant;
    var idOffer = this.props.idOffer;

    if (this.state.resume != null) {
      // var idApplicant = this.props.currentUser.Id;
      var applicant;
      getApplicant(idApplicant)
        .then((res) => {
          applicant = res.content;

          var applicantForm = new FormData();
          var applicantTxt = JSON.stringify(applicant);

          console.log(res.content);

          applicantForm.append("applicant", applicantTxt);
          applicantForm.append("resume", this.state.resume);

          console.log(this.state.resume);

          editApplicant(idApplicant, applicantForm)
            .then((Response) => {
              console.log(Response);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // var idApplicant = 4;
    // var idOffer = 1;

    var dateNow = new Date();

    var application = {
      date: dateNow.toISOString().substring(0, 10) + "T00:00:00",
    };

    console.log(application);

    addApplication(this.props.idApplicant, this.props.idOffer, application)
      .then((res) => {
        this.props.close();
        this.props.toggleModal();
      })
      .catch((err) => {
        console.log(err);
      });

    this.props.close();
  }

  alert(message) {
    return (
      <div className="alert alert-danger" role="alert">
        {message}
      </div>
    );
  }

  render() {
    return (
      <div
      // className="animated fadeIn col-md-8"
      // style={{
      //   margin: "auto",
      // }}
      >
        {this.state.errors.message === ""
          ? null
          : this.alert(this.state.errors.message)}
        {/* <div className="card">
          <div className="card-header">
            <strong>Postuler</strong>
          </div>
          <div className="card-body">
            <div> */}
        <form onSubmit={this.onSubmit}>
          <FormGroup>
            <Label for="resume">Mettre à jour CV</Label>
            <div class="custom-file" style={{ zIndex: 0 }}>
              <input
                id="resume"
                type="file"
                onChange={this.onResumeChange}
                class="custom-file-input"
              />
              <label class="custom-file-label" for="inputGroupFile01">
                {this.state.resume !== null
                  ? this.state.resume.name
                  : "Sélectionner fichier"}
              </label>
            </div>
            <FormText color="danger">{this.state.formErrors.resume}</FormText>
          </FormGroup>
          <input
            type="submit"
            value="Valider"
            className="btn mt-4 col-md-4 float-right"
            style={{ background: "#5841D9", color: "#ffffff" }}
          />
        </form>
        {/* </div>
          </div>
        </div> */}
      </div>
    );
  }
}

export default Editer;
