import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link } from "react-router-dom";
import { Card, CardBody, Table } from "reactstrap";
import {
  getOrderSheet,
  deleteOrderSheet,
  getResumeFile,
} from "../util/APIUtils";
// import { UserContext } from "../../../contexts/UserContext";

import { FiDownload, FiDelete, FiEdit } from "react-icons/fi";

class Consulter extends Component {
  constructor() {
    super();
    this.state = {
      externalReferenceNumber: "",
      missionDays: "",
      startDate: "",
      endDate: "",
      calculatedEndDate: "",
      remainingReportedDays: "",
      createdAt: "",
      updatedAt: "",
      isActivated: "",
      file: { name: "" },
      resource: "",
      company: "",
      errors: {},
    };

    this.downloadDocument = this.downloadDocument.bind(this);
    this.delete = this.delete.bind(this);
  }

  componentDidMount() {
    var dateFormat = require("dateformat");
    this.setState(
      {
        externalReferenceNumber: this.props.orderSheet.externalReferenceNumber,
        missionDays: this.props.orderSheet.missionDays,
        startDate:
          this.props.orderSheet.startDate !== null
            ? dateFormat(
                new Date(
                  new Date(this.props.orderSheet.startDate).setHours(
                    new Date(this.props.orderSheet.startDate).getHours() + 1
                  )
                ),
                "yyyy-mm-dd"
              )
            : null,
        endDate:
          this.props.orderSheet.endDate !== null
            ? dateFormat(
                new Date(
                  new Date(this.props.orderSheet.endDate).setHours(
                    new Date(this.props.orderSheet.endDate).getHours() + 1
                  )
                ),
                "yyyy-mm-dd"
              )
            : null,
        calculatedEndDate:
          this.props.orderSheet.calculatedEndDate !== null
            ? dateFormat(
                new Date(
                  new Date(this.props.orderSheet.calculatedEndDate).setHours(
                    new Date(
                      this.props.orderSheet.calculatedEndDate
                    ).getHours() + 1
                  )
                ),
                "yyyy-mm-dd"
              )
            : "Non calculé",
        remainingReportedDays: this.props.orderSheet.remainingReportedDays,
        createdAt: "",
        updatedAt: "",
        isActivated: this.props.orderSheet.isActivated,
        file: this.props.orderSheet.file,
        resource: this.props.orderSheet.resource,
        company: this.props.orderSheet.company,
        status: this.props.orderSheet.status,
      },
      console.log(this.state)
    );
  }

  downloadDocument(documentName) {
    console.log(true);
    getResumeFile(documentName)
      .then((response) => response.blob())
      .then(function (myBlob) {
        const url = window.URL.createObjectURL(myBlob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", documentName); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  }

  // downloadDocument(documentName) {
  //   axios({
  //     url: "http://localhost:8080/downloadFile/" + documentName, //your url
  //     method: "GET",
  //     responseType: "blob", // important
  //   }).then((response) => {
  //     const url = window.URL.createObjectURL(new Blob([response.data]));
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", documentName); //or any other extension
  //     document.body.appendChild(link);
  //     link.click();
  //   });
  // }

  delete(id) {
    confirmAlert({
      title: "Confirmation suppression",
      message: "Etes vous sure ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteOrderSheet(id)
              .then(console.log("Deleted"))
              .catch((err) => console.log(err));
            this.setState();
            this.props.history.push("/BC");
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  // static contextType = UserContext

  render() {
    var dateFormat = require("dateformat");
    // var authorities =
    //   this.context.userData.user !== null
    //     ? this.context.userData.user.authorities
    //     : [];
    return (
      <div>
        <Card>
          <CardBody>
            <div className="row">
              <div className="col-lg-12">
                <div className="general-info">
                  <div className="row">
                    <div className="col-lg-12 col-xl-12">
                      <div className="table-responsive">
                        <Table responsive>
                          <tbody>
                            <tr>
                              <th style={{ color: "rgba(95, 66, 253, 0.84)" }}>
                                Référence Externe
                              </th>
                              <td>{this.state.externalReferenceNumber}</td>
                            </tr>
                            <tr>
                              <th style={{ color: "rgba(95, 66, 253, 0.84)" }}>
                                Nombre de jours
                              </th>
                              <td>{this.state.missionDays}</td>
                            </tr>
                            <tr>
                              <th style={{ color: "rgba(95, 66, 253, 0.84)" }}>
                                Reste de jours
                              </th>
                              <td>{this.state.remainingReportedDays}</td>
                            </tr>
                            <tr>
                              <th style={{ color: "rgba(95, 66, 253, 0.84)" }}>
                                Client
                              </th>
                              <td>{this.state.company.name}</td>
                            </tr>
                            <tr>
                              <th style={{ color: "rgba(95, 66, 253, 0.84)" }}>
                                Fichier
                              </th>
                              <td>
                                {/* <button
                                  className="btn btn-info"
                                  onClick={this.downloadDocument.bind(
                                    this,
                                    this.state.file.name
                                  )}
                                  type="button"
                                >
                                  <i className="icon-cloud-download icons font-2xl d-block"></i>
                                </button> */}

                                <button
                                  onClick={this.downloadDocument.bind(
                                    this,
                                    this.state.file.name
                                  )}
                                  style={{
                                    color: "#ffffff",
                                    background: "rgba(95, 66, 253, 0.84)",
                                  }}
                                  className="btn"
                                >
                                  <FiDownload />
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <th style={{ color: "rgba(95, 66, 253, 0.84)" }}>
                                Date de début
                              </th>
                              <td> {this.state.startDate}</td>
                            </tr>
                            <tr>
                              <th style={{ color: "rgba(95, 66, 253, 0.84)" }}>
                                Date de fin
                              </th>
                              <td> {this.state.endDate}</td>
                            </tr>
                            <tr>
                              <th style={{ color: "rgba(95, 66, 253, 0.84)" }}>
                                Date de fin calculé
                              </th>
                              <td> {this.state.calculatedEndDate}</td>
                            </tr>
                            <tr>
                              <th style={{ color: "rgba(95, 66, 253, 0.84)" }}>
                                Etat
                              </th>
                              <td>
                                {" "}
                                {this.state.isActivated === true
                                  ? "ACTIVE"
                                  : "INACTIVE"}
                              </td>
                            </tr>
                            <tr>
                              <th style={{ color: "rgba(95, 66, 253, 0.84)" }}>
                                Statue
                              </th>
                              <td>
                                {this.state.status === "RED" ? (
                                  <span
                                    className="badge badge-danger"
                                    style={{ borderRadius: "15px" }}
                                  >
                                    {" "}
                                    &nbsp;&nbsp;&nbsp;
                                  </span>
                                ) : this.state.status === "GREY" ? (
                                  <span
                                    className="badge badge-secondary"
                                    style={{ borderRadius: "15px" }}
                                  >
                                    {" "}
                                    &nbsp;&nbsp;&nbsp;
                                  </span>
                                ) : this.state.status === "YELLOW" ? (
                                  <span
                                    className="badge badge-warning"
                                    style={{ borderRadius: "15px" }}
                                  >
                                    {" "}
                                    &nbsp;&nbsp;&nbsp;
                                  </span>
                                ) : this.state.status === "GREEN" ? (
                                  <span
                                    className="badge badge-success"
                                    style={{ borderRadius: "15px" }}
                                  >
                                    {" "}
                                    &nbsp;&nbsp;&nbsp;
                                  </span>
                                ) : null}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default Consulter;
