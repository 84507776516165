import React, { Component } from "react";
import { FormGroup, FormText, Input, Label, Table } from "reactstrap";
import { editExperience, getExperience } from "../../util/APIUtils";

import Popup from "reactjs-popup";

class Ajout extends Component {
  constructor() {
    super();
    this.state = {
      jobTitle: "",
      description: "",
      startAt: "",
      endAt: "",
      company: "",
      applicant: "",
      formErrors: {
        jobTitle: "",
        description: "",
        startAt: "",
        endAt: "",
        company: "",
        applicant: "",
      },
      open: false,
      skills: [],
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  componentDidMount() {
    var dateFormat = require("dateformat");
    getExperience(this.props.Id)
      .then((response) => {
        this.setState({
          id: this.props.Id,
          jobTitle: response.content.jobTitle,
          company: response.content.company,
          description: response.content.description,
          startAt: dateFormat(response.content.startAt, "yyyy-mm-dd"),
          endAt: dateFormat(response.content.endAt, "yyyy-mm-dd"),
          applicant: response.content.applicant,
        });
        console.log(this.props.Id);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  validateForm() {
    let {
      jobTitle,
      description,
      startAt,
      endAt,
      company,
      applicant,
    } = this.state;

    let formErrors = this.state.formErrors;

    formErrors.jobTitle =
      jobTitle.length > 0 ? "" : "Veuillez entrez une jobTitle";
    formErrors.description =
      description.length > 0 ? "" : "Veuillez entrez la description";
    formErrors.startAt = startAt.length > 0 ? "" : "Veuillez entrez un date";
    formErrors.endAt = endAt.length > 0 ? "" : "Veuillez entrez un date";
    formErrors.company =
      company === "" ? "Veuillez séléctionner un client" : "";

    if (
      formErrors.startAt === "" &&
      formErrors.endAt === "" &&
      startAt > endAt
    ) {
      formErrors.endAt =
        "La date de fin ne peut pas être inférieure au date de début";
    }

    this.setState({
      formErrors,
    });

    return (
      JSON.stringify(formErrors) ===
      JSON.stringify({
        jobTitle: "",
        description: "",
        startAt: "",
        endAt: "",
        company: "",
        applicant: "",
      })
    );
  }

  closeModal() {
    console.log("close popup");
    this.setState({ open: false });
    this.props.init();
  }

  openModal() {
    this.setState({ open: true });
  }

  onSubmit(e) {
    e.preventDefault();

    const Experience = {
      id: this.state.id,
      jobTitle: this.state.jobTitle,
      company: this.state.company,
      description: this.state.description,
      startAt: this.state.startAt,
      endAt: this.state.endAt,
      applicant: this.state.applicant,
    };

    if (this.validateForm()) {
      editExperience(this.props.idApplicant, Experience)
        .then((res) => this.closeModal())
        .catch((err) => console.log(err));
    }
    this.props.close();
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <div>
        <button
          type="button"
          className="btn btn-warning"
          onClick={this.openModal}
        >
          <i className="fa fa-edit" />
        </button>
        <Popup
          open={this.state.open}
          modal
          closeOnDocumentClick
          onClose={this.closeModal}
        >
          <div>
            <div className="card">
              <div className="card-header">
                <strong>Editer experience</strong>
              </div>
              <div className="card-body">
                <div>
                  <form onSubmit={this.onSubmit} onChange={this.onChange}>
                    <FormGroup>
                      <Label for="jobTitle">Titre</Label>
                      <Input
                        id="jobTitle"
                        name="jobTitle"
                        onChange={this.onChange}
                        value={this.state.jobTitle}
                      />
                      <FormText color="danger">
                        {this.state.formErrors.jobTitle}
                      </FormText>
                    </FormGroup>

                    <FormGroup>
                      <Label for="startAt">Date Début</Label>
                      <Input
                        id="startAt"
                        name="startAt"
                        type="date"
                        onChange={this.onChange}
                        value={this.state.startAt}
                      />
                      <FormText color="danger">
                        {this.state.formErrors.startAt}
                      </FormText>
                    </FormGroup>

                    <FormGroup>
                      <Label for="startAt">Date Fin</Label>
                      <Input
                        id="endAt"
                        name="endAt"
                        type="date"
                        onChange={this.onChange}
                        value={this.state.endAt}
                      />
                      <FormText color="danger">
                        {this.state.formErrors.endAt}
                      </FormText>
                    </FormGroup>

                    <FormGroup>
                      <Label for="company">Entreprise</Label>
                      <Input
                        id="company"
                        name="company"
                        onChange={this.onChange}
                        value={this.state.company}
                      />
                      <FormText color="danger">
                        {this.state.formErrors.company}
                      </FormText>
                    </FormGroup>

                    <FormGroup>
                      <Label for="description">Description</Label>
                      <Input
                        id="description"
                        name="description"
                        type="textarea"
                        onChange={this.onChange}
                        value={this.state.description}
                      />
                      <FormText color="danger">
                        {this.state.formErrors.description}
                      </FormText>
                    </FormGroup>

                    <input
                      type="submit"
                      value="Editer"
                      className="btn btn-success float-right"
                    />

                    <input
                      type="button"
                      onClick={this.closeModal}
                      value="Retour"
                      className="btn btn-warning mr-1 float-right"
                    />

                    {/* <input
                      type="submit"
                      value="Editer"
                      className="btn btn-success btn-block mt-4 col-md-2 float-right"
                    /> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Popup>
      </div>
    );
  }
}

export default Ajout;
