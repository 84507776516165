// import axios from "axios";
import React, { Component, Fragment } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link } from "react-router-dom";
import { Card, CardBody, Table } from "reactstrap";
import {
  getOrderSheet,
  deleteOrderSheet,
  getResumeFile,
} from "../../util/APIUtils";

import { FiDownload } from "react-icons/fi";

class Consulter extends Component {
  constructor() {
    super();
    this.state = {
      externalReferenceNumber: "",
      missionDays: "",
      startDate: "",
      endDate: "",
      calculatedEndDate: "",
      remainingReportedDays: "",
      createdAt: "",
      updatedAt: "",
      isActivated: "",
      file: null,
      resource: "",
      company: "",
      errors: {},
    };

    this.downloadDocument = this.downloadDocument.bind(this);
    this.delete = this.delete.bind(this);
  }

  componentDidMount() {
    var dateFormat = require("dateformat");
    getOrderSheet(this.props.match.params.Id)
      .then((response) => {
        this.setState({
          externalReferenceNumber: response.content.externalReferenceNumber,
          missionDays: response.content.missionDays,
          startDate:
            response.content.startDate !== null
              ? dateFormat(response.content.startDate, "yyyy-mm-dd")
              : null,
          endDate:
            response.content.endDate !== null
              ? dateFormat(response.content.endDate, "yyyy-mm-dd")
              : null,
          calculatedEndDate:
            response.content.calculatedEndDate !== null
              ? dateFormat(response.content.calculatedEndDate, "yyyy-mm-dd")
              : null,
          remainingReportedDays: response.content.remainingReportedDays,
          createdAt: "",
          updatedAt: "",
          isActivated: response.content.isActivated,
          file: response.content.file,
          resource: response.content.resource,
          company: response.content.company,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  downloadDocument(documentName) {
    getResumeFile(documentName)
      .then((response) => response.blob())
      .then(function (myBlob) {
        const url = window.URL.createObjectURL(myBlob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", documentName); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  }

  delete(id) {
    confirmAlert({
      title: "Confirmation suppression",
      message: "Etes vous sure ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteOrderSheet(id)
              .then(console.log("Deleted"))
              .catch((err) => console.log(err));
            this.setState();
            this.props.history.push("/BC");
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    var dateFormat = require("dateformat");
    return (
      <React.Fragment>
        {/*Page Title*/}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title" style={{ color: "#ffffff" }}>
                    CRAs
                  </h2>
                  {/* <p>Fresh From The Press Discription</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*End Page Title*/}
        {/*Sidebar Page Container*/}
        <div
          className="service-area ptb--80  bg_image bg_image--3"
          style={{ paddingTop: "200px", paddingBottom: "200px" }}
        >
          <div className="container">
            <div>
              <Card style={{ marginTop: "10px", marginBottom: "10px" }}>
                <CardBody>
                  <h3
                    style={{
                      marginBottom: "40px",
                      display: "inline-block",
                    }}
                  >
                    Détails bon de commande :
                  </h3>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="general-info">
                        <div className="row">
                          <div className="col-lg-12 col-xl-6">
                            <div className="table-responsive">
                              <Table responsive>
                                <tbody>
                                  <tr>
                                    <th
                                      style={{
                                        color: "rgba(95, 66, 253, 0.84)",
                                      }}
                                    >
                                      Réference Externe
                                    </th>
                                    <td>
                                      {this.state.externalReferenceNumber}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th
                                      style={{
                                        color: "rgba(95, 66, 253, 0.84)",
                                      }}
                                    >
                                      Nombre de jours
                                    </th>
                                    <td>{this.state.missionDays}</td>
                                  </tr>
                                  <tr>
                                    <th
                                      style={{
                                        color: "rgba(95, 66, 253, 0.84)",
                                      }}
                                    >
                                      Reste de jours
                                    </th>
                                    <td>{this.state.remainingReportedDays}</td>
                                  </tr>
                                  <tr>
                                    <th
                                      style={{
                                        color: "rgba(95, 66, 253, 0.84)",
                                      }}
                                    >
                                      Client
                                    </th>
                                    <td>{this.state.company.name}</td>
                                  </tr>
                                  <tr>
                                    <th
                                      style={{
                                        color: "rgba(95, 66, 253, 0.84)",
                                      }}
                                    >
                                      Fichier
                                    </th>
                                    <td>
                                      <button
                                        style={{
                                          color: "#ffffff",
                                          background: "rgba(95, 66, 253, 0.84)",
                                        }}
                                        className="btn"
                                        onClick={this.downloadDocument.bind(
                                          this,
                                          this.state.file
                                        )}
                                        type="button"
                                      >
                                        <FiDownload />
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </div>
                          <div className="col-lg-12 col-xl-6">
                            <div className="table-responsive" />
                            <Table responsive>
                              <tbody>
                                <tr>
                                  <th
                                    style={{
                                      color: "rgba(95, 66, 253, 0.84)",
                                    }}
                                  >
                                    Date de début
                                  </th>
                                  <td>
                                    {" "}
                                    {dateFormat(
                                      this.state.startDate,
                                      "dd/mm/yyyy"
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <th
                                    style={{
                                      color: "rgba(95, 66, 253, 0.84)",
                                    }}
                                  >
                                    Date de fin
                                  </th>
                                  <td>
                                    {" "}
                                    {dateFormat(
                                      this.state.endDate,
                                      "dd/mm/yyyy"
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <th
                                    style={{
                                      color: "rgba(95, 66, 253, 0.84)",
                                    }}
                                  >
                                    Date de fin calculé
                                  </th>
                                  <td>
                                    {" "}
                                    {dateFormat(
                                      this.state.calculatedEndDate,
                                      "dd/mm/yyyy"
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <th
                                    style={{
                                      color: "rgba(95, 66, 253, 0.84)",
                                    }}
                                  >
                                    Etat
                                  </th>
                                  <td>
                                    {" "}
                                    {this.state.isActivated === "true"
                                      ? "ACTIVE"
                                      : "INACTIVE"}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card style={{ marginTop: "10px", marginBottom: "10px" }}>
                <CardBody>
                  <h5
                    style={{
                      marginBottom: "40px",
                      display: "inline-block",
                    }}
                  >
                    Ressource :
                  </h5>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="general-info">
                        <div className="row">
                          <div className="col-lg-12 col-xl-12">
                            <div className="table-responsive">
                              <Table responsive>
                                <tbody>
                                  <tr>
                                    <th
                                      style={{
                                        color: "rgba(95, 66, 253, 0.84)",
                                      }}
                                    >
                                      Nom
                                    </th>
                                  </tr>

                                  <tr>
                                    <td>
                                      {this.state.resource.firstName +
                                        " " +
                                        this.state.resource.lastName}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>{" "}
        </div>
      </React.Fragment>
    );
  }
}

export default Consulter;
