import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class ServiceDetails extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Service Details" />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
          currentUser={this.props.currentUser}
          isAuthenticated={this.props.isAuthenticated}
          handleLogout={this.props.handleLogout}
          {...this.props}
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title" style={{ color: "#ffffff" }}>
                    Conseil et consulting
                  </h2>
                  {/* <p>Fresh From The Press Discription</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--50 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="w-100"
                            src={`/assets/images/service/s_21.jpeg`}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title">Quoi</h4>
                          <p>
                            {" "}
                            <strong>Consulting. </strong>
                            Ce terme d’origine anglo-saxonne s’est aujourd’hui
                            imposé dans le monde de l’entreprise. Toutefois, la
                            réalité qui se cache derrière ce terme est souvent
                            mal perçue et parfois mal comprise, le conseil ou le
                            consulting est une vision sur le contrôle d’un
                            projet donnée qui visent à améliorer le processus de
                            production et de gestion des blocages
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title">Notre cœur de mission</h4>

                          <p>
                            Neo tech IT accompagne ses clients dans le pilotage
                            de leurs projets les plus stratégiques. Au cœur de
                            leurs nouveaux enjeux et dans un contexte mouvant,
                            nous conseillons les grandes organisations vers la
                            performance de leurs activités IT & Métier nous
                            sommes là pour vous guidez dans votre évolution
                            selon les meilleures pratiques et méthodologies
                            internationales
                          </p>
                          <p>
                            Notre réseau est composé de 200 000 profils suivis,
                            ce qui nous permette d’avoir une large vision sur le
                            marché des compétences, 10 000 profils déjà
                            sélectionnés et 800 offres traitées
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative">
                          <img
                            className="w-100"
                            src={`/assets/images/service/s_22.jpeg`}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="w-100"
                            src={`/assets/images/service/s_23.jpeg`}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title">Nos axes de consulting</h4>
                          <p>
                            Afin de vous offrir les meilleurs des profils, nos
                            candidats passent jusqu’à 3 entretiens avec nos
                            experts techniques en 3 étapes essentielles :
                          </p>
                          <ul className="liststyle">
                            <li>
                              <strong>Agile : </strong>
                              Cette approche est bénéfique pour les projets
                              créatifs avec des objectifs flexibles et pouvant
                              être modifiés à mi-chemin
                            </li>
                            <li>
                              <strong>eXtreme Programming (XP) : </strong>
                              EP est efficace et met l’accent sur la simplicité.
                              Les équipes travaillent à un rythme soutenable, ce
                              qui leur permet de maintenir un niveau optimal de
                              qualité
                            </li>
                            <li>
                              <strong>Waterfall : </strong>
                              Une planification approfondie s’inspire de cette
                              approche, et cette rigueur aboutit souvent à des
                              échéanciers et à des budgets plus précis
                            </li>
                            <li>
                              <strong>PMBOK du PMI : </strong>
                              L’avantage principal du PMBOK est qu’il s’agit
                              d’un guide de connaissances et de bonnes pratiques
                              créé par des professionnels du management de
                              projet qui le mettent à jour régulièrement
                            </li>
                            <li>Et bien plus …</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default ServiceDetails;
