import React, { Component } from "react";
import {
  FaClock,
  FaRedoAlt,
  FaRegListAlt,
  FaUsers,
  FaFileInvoice,
} from "react-icons/fa";
import { withTranslation } from "react-i18next";
import Slider from "react-slick";
import { serviceSlick } from "../../page-demo/script";

class ServiceTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth <= 767,
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    this.setState({
      isMobile: window.innerWidth <= 767,
    });
  }

  render() {
    const { t } = this.props;

    // services sections data list
    const ServiceList = [
      {
        icon: <FaUsers />,
        title: `${t("servicesSetcionOneTitle")}`,
        properties: [
          `${t("servicesSetcionOneDetailOne")}`,
          `${t("servicesSetcionOneDetailTwo")}`,
          `${t("servicesSetcionOneDetailThree")}`,
        ],
      },
      {
        icon: <FaClock />,
        title: `${t("servicesSetcionTwoTitle")}`,
        properties: [
          `${t("servicesSetcionTwoDetailOne")}`,
          `${t("servicesSetcionTwoDetailTwo")}`,
          `${t("servicesSetcionTwoDetailThree")}`,
        ],
      },
      {
        icon: <FaRedoAlt />,
        title: `${t("servicesSetcionThreeTitle")}`,
        properties: [
          `${t("servicesSetcionThreeDetailOne")}`,
          `${t("servicesSetcionThreeDetailTwo")}`,
          `${t("servicesSetcionThreeDetailThree")}`,
        ],
      },
      {
        icon: <FaRegListAlt />,
        title: `${t("servicesSetcionFourTitle")}`,
        properties: [
          `${t("servicesSetcionFourDetailOne")}`,
          `${t("servicesSetcionFourDetailTwo")}`,
          `${t("servicesSetcionFourDetailThree")}`,
        ],
      },
      {
        icon: <FaFileInvoice />,
        title: `${t("servicesSetcionFiveTitle")}`,
        properties: [
          `${t("servicesSetcionFiveDetailOne")}`,
          `${t("servicesSetcionFiveDetailTwo")}`,
          `${t("servicesSetcionFiveDetailThree")}`,
        ],
      },
      {
        icon: <FaFileInvoice />,
        title: `${t("servicesSetcionSixTitle")}`,
        properties: [
          `${t("servicesSetcionSixDetailOne")}`,
          `${t("servicesSetcionSixDetailTwo")}`,
          `${t("servicesSetcionSixDetailThree")}`,
        ],
      },
    ];

    return (
      <div className="portfolio-area bg_color--1">
        <div className="rn-slick-dot">
          <div className="container">
            <div className="section-title">
              <h2 className="title">{t("servicesTitle")}</h2>
              <p className="description">{t("servicesDescription")}</p>
            </div>

            <div className="slick-space-gutter--15 slickdot--20 mt--40 mt_sm--40">
              <Slider {...serviceSlick}>
                {ServiceList.map((val, i) => (
                  <div
                    className="service service__style--2 cards"
                    key={i}
                  >
                    <div className="icons">{val.icon}</div>
                    <div className="content">
                      <h5 className="titles text-center">{val.title}</h5>
                      <br />
                      <ul>
                        {val.properties.map((v, i) => (
                          <li key={i}>{val.properties[i]}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(ServiceTwo);
