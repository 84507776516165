import React, { Component, Fragment } from "react";
import {
  getOrderSheetsBySupllier,
  getAllOrderSheets,
  getResumeFile,
  getLastOrderSheets,
} from "../../util/APIUtils";
import ReactTable from "react-table";
import "react-table/react-table.css";

import { FiDownload } from "react-icons/fi";

export default class OrderSheets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      filteredData: [],
      columns: [],
      searchInput: "",
      errors: { message: "" },
    };
  }

  componentDidMount() {
    var dateFormat = require("dateformat");

    var idSupplier = this.props.currentUser.id;

    getOrderSheetsBySupllier(idSupplier)
      .then((Response) => {
        let data = [];
        Response.forEach((element) => {
          data.push({
            id: element.id,
            file: element.file,
            company: element.company,
            remainingReportedDays: element.remainingReportedDays.toString(),
            missionDays: element.missionDays.toString(),
            startDate:
              element.startDate !== null
                ? dateFormat(element.startDate, "dd/mm/yyyy")
                : null,
            endDate:
              element.endDate !== null
                ? dateFormat(element.endDate, "dd/mm/yyyy")
                : null,
            calculatedEndDate: element.calculatedEndDate,
            createdAt:
              element.createdAt !== null
                ? dateFormat(element.createdAt, "dd/mm/yyyy")
                : null,
            status: element.status,
            companyName: element.company.name,
            resource: element.resource,
          });
        });
        this.setState({ data: data });
      })
      .catch((err) => console.log(err));
  }

  downloadDocument(documentName) {
    console.log(true);
    getResumeFile(documentName)
      .then((response) => response.blob())
      .then(function (myBlob) {
        const url = window.URL.createObjectURL(myBlob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", documentName); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  }

  render() {
    var dateFormat = require("dateformat");

    const columns = [
      {
        Header: "Ressource",
        id: "resource",
        className: "mid",
        Cell: (row) => {
          return (
            <div
              style={{
                textAlign: "center",
              }}
            >
              {row.original.resource.lastName +
                " " +
                row.original.resource.firstName}
            </div>
          );
        },
      },
      {
        Header: "Fichier",
        id: "document_name",
        className: "mid",
        Cell: (row) => {
          return (
            <div
              style={{
                textAlign: "center",
              }}
            >
              <button
                style={{
                  color: "#ffffff",
                  background: "rgba(95, 66, 253, 0.84)",
                }}
                onClick={this.downloadDocument.bind(
                  this,
                  row.original.file.name
                )}
                className="btn"
              >
                <FiDownload />
              </button>
            </div>
          );
        },
      },
      {
        Header: "Client",
        id: "companyName",
        filterable: true,
        accessor: "companyName",
        className: "mid",
      },
      {
        Header: "Date Création",
        id: "createdAt",
        filterable: true,
        accessor: "createdAt",
        className: "mid",
      },
      {
        Header: "Date Début",
        id: "startDate",
        filterable: true,
        accessor: "startDate",
        className: "mid",
      },
      {
        Header: "Date Fin",
        id: "endDate",
        filterable: true,
        accessor: "endDate",
        className: "mid",
      },
      {
        Header: "Date Fin Calculé",
        id: "calculatedEndDate",
        filterable: true,
        accessor: "calculatedEndDate",
        className: "mid",
      },

      {
        Header: "Reste de jours",
        id: "remainingReportedDays",
        filterable: true,
        accessor: "remainingReportedDays",
        className: "mid",
      },

      //   {
      //     Header: "Action",
      //     id: "row",
      //     Cell: (row) => {
      //       return (
      //         <div
      //           style={{
      //             textAlign: "center",
      //           }}
      //         >
      //           <button
      //             onClick={this.delete.bind(this, row.index, row.original.id)}
      //             className="btn btn-danger"
      //             style={{
      //               marginRight: "5px",
      //             }}
      //           >
      //             <i className="fa fa-trash-o" />
      //           </button>
      //         </div>
      //       );
      //     },
      //   },
    ];

    return (
      <React.Fragment>
        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title" style={{ color: "#ffffff" }}>
                    Bons de commandes
                  </h2>
                  {/* <p>Fresh From The Press Discription</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="service-area ptb--80  bg_image bg_image--3"
          style={{ paddingTop: "200px", paddingBottom: "200px" }}
        >
          <div className="container">
            <div>
              <div>
                <h4>Bon de commandes récents :</h4>

                <ReactTable
                  className="-highlight"
                  columns={columns}
                  data={this.state.data}
                  pageSize={
                    this.state.data.length > 10 ? 10 : this.state.data.length
                  }
                  defaultFilterMethod={this.filterCaseInsensitive}
                  previousText="Précédent"
                  nextText="Prochain"
                  loadingText="Chargement..."
                  noDataText="Aucune ligne trouvée"
                  pageText="Page"
                  ofText="de"
                  rowsText="Lignes"
                  getProps={() => {
                    return { id: "orderSheets" };
                  }}
                  getTdProps={(state, rowInfo, column, instance) => {
                    return {
                      onClick: (e) => {
                        if (
                          column.Header !== "Action" &&
                          rowInfo !== undefined &&
                          column.Header !== "Fichier"
                        ) {
                          let path =
                            "/supplier/ordersheet/" + rowInfo.original.id;
                          this.props.history.push(path);
                        }
                      },
                    };
                  }}
                ></ReactTable>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
