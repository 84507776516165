import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import frJSON from "./locale/fr.json";
import enJSON from "./locale/en.json";
i18n.use(initReactI18next).init({
  resources: {
    FR: { ...frJSON },
    US: { ...enJSON },
  },
  lng: "US",
});
