import { API_BASE_URL, ACCESS_TOKEN } from "../constants";

const request = (options) => {
  const headers = new Headers({
    "Content-Type": "application/json",
    Accept: "application/json",
  });

  if (options.type === "Data") {
    headers.delete("Content-Type");
    // headers.delete("Accept");
  }

  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem(ACCESS_TOKEN)
    );
  }

  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);
  console.log(options);

  if (options.type === "Download") {
    return fetch(options.url, options).then((response) => {
      if (!response.ok) {
        return Promise.reject(response);
      }
      return response;
    });
  }

  return fetch(options.url, options).then((response) =>
    response.json().then((json) => {
      if (!response.ok) {
        return Promise.reject(json);
      }
      return json;
    })
  );
};

//Statistics Dashboard

export function login(loginRequest) {
  return request({
    url: API_BASE_URL + "/auth/signin",
    method: "POST",
    body: JSON.stringify(loginRequest),
  });
}

export function renewPassowrd(email) {
  return request({
    url: API_BASE_URL + "/auth/forgot-password",
    method: "POST",
    body: email,
  });
}

export function confirmResetPassowrd(token) {
  return request({
    url: API_BASE_URL + "/auth/confirm-reset",
    method: "POST",
    body: token,
  });
}

export function resetPassword(user) {
  return request({
    url: API_BASE_URL + "/auth/reset-password",
    method: "POST",
    body: user,
    type: "Data",
  });
}

export function signup(signupRequest) {
  return request({
    url: API_BASE_URL + "/auth/signup",
    method: "POST",
    body: JSON.stringify(signupRequest),
  });
}

export function checkUsernameAvailability(username) {
  return request({
    url: API_BASE_URL + "/user/checkUsernameAvailability?username=" + username,
    method: "GET",
  });
}

export function checkEmailAvailability(email) {
  return request({
    url: API_BASE_URL + "/user/checkEmailAvailability?email=" + email,
    method: "GET",
  });
}

export function confirmEmail(token) {
  return request({
    url: API_BASE_URL + "/auth/confirm-email",
    method: "POST",
    body: token,
  });
}

export function getCurrentUser() {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/user/me",
    method: "POST",
  });
}

export function getUserProfile(username) {
  return request({
    url: API_BASE_URL + "/users/" + username,
    method: "GET",
  });
}

export function getUserStatistics() {
  return request({
    url: API_BASE_URL + "/recruiters/statistics",
    method: "POST",
  });
}

export function getApplicantsAddedByRecruiterBetweenDates(
  id,
  statisticsRequest
) {
  return request({
    url: API_BASE_URL + "/admins/statistics/applicants/recruiter/" + id,
    method: "POST",
    body: statisticsRequest,
    type: "Data",
  });
}

export function getMetricsBetweenDates(metricRequest) {
  return request({
    url: API_BASE_URL + "/admins/statistics/metrics/",
    method: "POST",
    body: metricRequest,
    type: "Data",
  });
}

export function getResignedCountBetweenTwoDates(statisticsRequest) {
  return request({
    url: API_BASE_URL + "/admins/statistics/applicants/resigned/",
    method: "POST",
    body: statisticsRequest,
    type: "Data",
  });
}

export function getUserStatisticsTwo(statisticsRequest) {
  return request({
    url: API_BASE_URL + "/admins/statistics/update",
    method: "POST",
    body: statisticsRequest,
    type: "Data",
  });
}

export function getMetrics(year) {
  return request({
    url: API_BASE_URL + "/admins/statistics/metrics/" + year,
    method: "POST",
  });
}

export function getAdminStatistics() {
  return request({
    url: API_BASE_URL + "/admins/statistics",
    method: "POST",
  });
}

export function getResumeFile(resumeName) {
  return request({
    url: API_BASE_URL + "/downloadFile/" + resumeName,
    method: "POST",
    type: "Download",
    body: null,
  });
}

///////////////////:

export function getActiveProfils() {
  return request({
    url: API_BASE_URL + "/profils/active",
    method: "POST",
  });
}

// ~~~~~~~~~~~~~~~~ API Config  ~~~~~~~~~~~~~~~~ //

export function getConfig(id) {
  return request({
    url: API_BASE_URL + "/properties/" + id,
    method: "POST",
  });
}

export function getAllConfigs() {
  return request({
    url: API_BASE_URL + "/properties/all",
    method: "POST",
  });
}

export function editConfig(config) {
  return request({
    url: API_BASE_URL + "/properties/",
    method: "PUT",
    body: JSON.stringify(config),
  });
}

export function getMailConfig(id) {
  return request({
    url: API_BASE_URL + "/properties/" + id,
    method: "POST",
  });
}

export function deleteMailConfig(id) {
  return request({
    url: API_BASE_URL + "/properties/" + id,
    method: "POST",
  });
}

// ~~~~~~~~~~~~~~~~ API Batch  ~~~~~~~~~~~~~~~~ //

export function getBatchJobConfig(id) {
  return request({
    url: API_BASE_URL + "/batchJobConfigs/" + id,
    method: "POST",
  });
}

export function getAllBatchJobConfigs() {
  return request({
    url: API_BASE_URL + "/batchJobConfigs/all",
    method: "POST",
  });
}

export function editBatchJobConfig(jobRequest) {
  return request({
    url: API_BASE_URL + "/batchJobConfigs/",
    method: "PUT",
    body: JSON.stringify(jobRequest),
  });
}

// ~~~~~~~~~~~~~~~~ API USER  ~~~~~~~~~~~~~~~~ //

export function getProfile() {
  return request({
    url: API_BASE_URL + "/user/profile",
    method: "POST",
  });
}

export function editProfile(skillRequest) {
  return request({
    url: API_BASE_URL + "/user/",
    method: "PUT",
    body: JSON.stringify(skillRequest),
  });
}

// ~~~~~~~~~~~~~~~~ API Task  ~~~~~~~~~~~~~~~~ //

export function getTask(taskId) {
  return request({
    url: API_BASE_URL + "/tasks/" + taskId,
    method: "POST",
  });
}

export function getAllTasks() {
  return request({
    url: API_BASE_URL + "/tasks/all",
    method: "POST",
  });
}

export function getAllTasksBetweenDates(dates) {
  return request({
    url: API_BASE_URL + "/tasks/dates",
    method: "POST",
    body: dates,
    type: "Data",
  });
}

export function getConnectedUserAllTasks() {
  return request({
    url: API_BASE_URL + "/tasks/Recruiter",
    method: "POST",
  });
}

export function getTasksBetweenDatesForConnectedUser(dates) {
  return request({
    url: API_BASE_URL + "/tasks/Recruiter/dates",
    method: "POST",
    body: dates,
    type: "Data",
  });
}

export function editTask(taskRequest) {
  return request({
    url: API_BASE_URL + "/tasks/",
    method: "PUT",
    body: JSON.stringify(taskRequest),
  });
}

export function deleteTask(idJob) {
  return request({
    url: API_BASE_URL + "/tasks/" + idJob,
    method: "DELETE",
  });
}

// ~~~~~~~~~~~~~~~~ API Role  ~~~~~~~~~~~~~~~~ //

export function getRole(roleId) {
  return request({
    url: API_BASE_URL + "/roles/" + roleId,
    method: "POST",
  });
}

export function getAllRoles() {
  return request({
    url: API_BASE_URL + "/roles/all",
    method: "POST",
  });
}

export function editRole(roleRequest) {
  return request({
    url: API_BASE_URL + "/roles/",
    method: "PUT",
    body: JSON.stringify(roleRequest),
  });
}

// ~~~~~~~~~~~~~~~~ API Holiday  ~~~~~~~~~~~~~~~~ //

export function getHoliday(holidayId) {
  return request({
    url: API_BASE_URL + "/holidays/" + holidayId,
    method: "POST",
  });
}

export function getAllHolidays() {
  return request({
    url: API_BASE_URL + "/holidays/all",
    method: "POST",
  });
}

export function addHoliday(holidayRequest) {
  return request({
    url: API_BASE_URL + "/holidays/",
    method: "POST",
    body: JSON.stringify(holidayRequest),
  });
}

export function editHoliday(holidayRequest) {
  return request({
    url: API_BASE_URL + "/holidays/",
    method: "PUT",
    body: JSON.stringify(holidayRequest),
  });
}

export function deleteHoliday(idJob) {
  return request({
    url: API_BASE_URL + "/holidays/" + idJob,
    method: "DELETE",
  });
}

// ~~~~~~~~~~~~~~~~ API JOB  ~~~~~~~~~~~~~~~~ //

export function getJob(jobId) {
  return request({
    url: API_BASE_URL + "/offers/" + jobId,
    method: "POST",
  });
}

export function getJobByCompany(companyId) {
  return request({
    url: API_BASE_URL + "/offers/company/" + companyId,
    method: "POST",
  });
}

export function getAllJobs() {
  return request({
    url: API_BASE_URL + "/offers/all",
    method: "POST",
  });
}

export function getAllJobsForWebsite() {
  return request({
    url: API_BASE_URL + "/offers/all/website",
    method: "POST",
  });
}

export function addJob(jobRequest) {
  return request({
    url: API_BASE_URL + "/offers/",
    method: "POST",
    body: JSON.stringify(jobRequest),
  });
}

export function editJob(jobId, jobRequest) {
  return request({
    url: API_BASE_URL + "/offers/",
    method: "PUT",
    body: JSON.stringify(jobRequest),
  });
}

export function editJobState(jobId) {
  return request({
    url: API_BASE_URL + "/offers/etat/" + jobId,
    method: "GET",
  });
}

export function deleteJob(idJob) {
  return request({
    url: API_BASE_URL + "/offers/" + idJob,
    method: "DELETE",
  });
}

export function launchJobsCampaign() {
  return request({
    url: API_BASE_URL + "/tasks/testMail",
    method: "POST",
  });
}

// ~~~~~~~~~~~~~~~~ API COMPANY  ~~~~~~~~~~~~~~~~ //

export function getCompany(companyId) {
  return request({
    url: API_BASE_URL + "/companies/" + companyId,
    method: "POST",
  });
}

export function getAllCompanies() {
  return request({
    url: API_BASE_URL + "/companies/all",
    method: "POST",
  });
}

export function getAllCompaniesPagination(pageSize, page, sorted, filtered) {
  return request({
    url: API_BASE_URL + "/companies/all/" + page + "/" + pageSize,
    method: "POST",
  });
}

export function getAllCompaniesCount() {
  return request({
    url: API_BASE_URL + "/companies/all/count",
    method: "POST",
  });
}

export function addCompany(companyRequest) {
  return request({
    url: API_BASE_URL + "/companies/",
    method: "POST",
    body: JSON.stringify(companyRequest),
  });
}

export function editCompany(companyId, companyRequest) {
  return request({
    url: API_BASE_URL + "/companies/",
    method: "PUT",
    body: JSON.stringify(companyRequest),
  });
}

export function deleteCompany(idCompany) {
  return request({
    url: API_BASE_URL + "/companies/" + idCompany,
    method: "DELETE",
  });
}

// ~~~~~~~~~~~~~~~~ API SUPPLIER  ~~~~~~~~~~~~~~~~ //

export function getSupplier(supplierId) {
  return request({
    url: API_BASE_URL + "/suppliers/" + supplierId,
    method: "POST",
  });
}

export function getAllSuppliers() {
  return request({
    url: API_BASE_URL + "/suppliers/all",
    method: "POST",
  });
}

export function addSupplier(supplierRequest) {
  return request({
    url: API_BASE_URL + "/suppliers/",
    method: "POST",
    body: JSON.stringify(supplierRequest),
  });
}

export function editSupplier(supplierId, supplierRequest) {
  return request({
    url: API_BASE_URL + "/suppliers/",
    method: "PUT",
    body: JSON.stringify(supplierRequest),
  });
}

export function deleteSupplier(idSupplier) {
  return request({
    url: API_BASE_URL + "/suppliers/" + idSupplier,
    method: "DELETE",
  });
}

// ~~~~~~~~~~~~~~~~ API Admin  ~~~~~~~~~~~~~~~~ //

export function getAdmin(adminId) {
  return request({
    url: API_BASE_URL + "/admins/" + adminId,
    method: "POST",
  });
}

export function getAllAdmins() {
  return request({
    url: API_BASE_URL + "/admins/all",
    method: "POST",
  });
}

export function addAdmin(adminRequest) {
  return request({
    url: API_BASE_URL + "/admins/",
    method: "POST",
    body: adminRequest,
    type: "Data",
  });
}

export function editAdmin(adminRequest) {
  return request({
    url: API_BASE_URL + "/admins/",
    method: "PUT",
    body: adminRequest,
    type: "Data",
  });
}

export function deleteAdmin(idAdmin) {
  return request({
    url: API_BASE_URL + "/admins/" + idAdmin,
    method: "DELETE",
  });
}

// ~~~~~~~~~~~~~~~~ API Recruiter  ~~~~~~~~~~~~~~~~ //

export function getRecruiter(recruiterId) {
  return request({
    url: API_BASE_URL + "/recruiters/" + recruiterId,
    method: "POST",
  });
}

export function getAllRecruiters() {
  return request({
    url: API_BASE_URL + "/recruiters/all",
    method: "POST",
  });
}

export function addRecruiter(recruiterRequest) {
  return request({
    url: API_BASE_URL + "/recruiters/",
    method: "POST",
    body: recruiterRequest,
    type: "Data",
  });
}

export function editRecruiter(recruiterRequest) {
  return request({
    url: API_BASE_URL + "/recruiters/",
    method: "PUT",
    body: recruiterRequest,
    type: "Data",
  });
}

export function deleteRecruiter(idRecruiter) {
  return request({
    url: API_BASE_URL + "/recruiters/" + idRecruiter,
    method: "DELETE",
  });
}

// ~~~~~~~~~~~~~~~~ API APPLICANT & SEARCH ~~~~~~~~~~~~~~~~ //

export function advancedSearchApplicant(
  specs,
  pageSize,
  page,
  sorted,
  filtered
) {
  return request({
    url: API_BASE_URL + "/applicants/specs/" + page + "/" + pageSize + specs,
    method: "POST",
    body: null,
  });
}

export function advancedSearchApplicantCount(specs) {
  return request({
    url: API_BASE_URL + "/applicants/specs/count" + specs,
    method: "POST",
    body: null,
  });
}

export function getApplicant(applicantId) {
  return request({
    url: API_BASE_URL + "/applicants/" + applicantId,
    method: "POST",
    body: null,
  });
}

export function getAllApplicants() {
  return request({
    url: API_BASE_URL + "/applicants/all",
    method: "POST",
  });
}

export function getAllApplicantsCount() {
  return request({
    url: API_BASE_URL + "/applicants/all/count",
    method: "POST",
  });
}

export function getAllApplicantsPagination(pageSize, page, sorted, filtered) {
  return request({
    url: API_BASE_URL + "/applicants/all/" + page + "/" + pageSize,
    method: "POST",
  });
}

export function addApplicant(applicantRequest) {
  return request({
    url: API_BASE_URL + "/applicants/",
    method: "POST",
    body: applicantRequest,
    type: "Data",
  });
}

export function changeApplicantState(applicantId) {
  return request({
    url: API_BASE_URL + "/applicants/etat/" + applicantId,
    method: "GET",
  });
}

export function editApplicant(applicantId, applicantRequest) {
  return request({
    url: API_BASE_URL + "/applicants/",
    method: "PUT",
    body: applicantRequest,
    type: "Data",
  });
}

export function deleteApplicant(idApplicant) {
  return request({
    url: API_BASE_URL + "/applicants/" + idApplicant,
    method: "DELETE",
  });
}

export function searchApplicants(applicantRequest) {
  return request({
    url: API_BASE_URL + "/applicants/search/skillMarks",
    method: "POST",
    body: JSON.stringify(applicantRequest),
  });
}

export function scoreApplicant(idApplicant) {
  return request({
    url: API_BASE_URL + "/applicants/score/" + idApplicant,
    method: "POST",
  });
}

// ~~~~~~~~~~~~~~~~ API ORDERSHEET  ~~~~~~~~~~~~~~~~ //

export function getOrderSheet(orderSheetId) {
  return request({
    url: API_BASE_URL + "/orderSheets/" + orderSheetId,
    method: "POST",
    body: null,
  });
}

export function getLastOrderSheets(resourceId) {
  return request({
    url: API_BASE_URL + "/orderSheets/resource/" + resourceId,
    method: "POST",
    body: null,
  });
}

export function getAllOrderSheets() {
  return request({
    url: API_BASE_URL + "/orderSheets/all",
    method: "POST",
  });
}

export function getOrderSheetsByResource(resourceId) {
  return request({
    url: API_BASE_URL + "/orderSheets/resource/" + resourceId,
    method: "POST",
  });
}

export function getOrderSheetsBySupllier(supplierId) {
  return request({
    url: API_BASE_URL + "/orderSheets/supplier/" + supplierId,
    method: "POST",
  });
}

export function addOrderSheet(orderSheetRequest) {
  return request({
    url: API_BASE_URL + "/orderSheets/",
    method: "POST",
    body: orderSheetRequest,
    type: "Data",
  });
}

export function editOrderSheet(orderSheetId, orderSheetRequest) {
  return request({
    url: API_BASE_URL + "/orderSheets/",
    method: "PUT",
    body: orderSheetRequest,
    type: "Data",
  });
}

export function deleteOrderSheet(idOrderSheet) {
  return request({
    url: API_BASE_URL + "/orderSheets/" + idOrderSheet,
    method: "DELETE",
  });
}

// ~~~~~~~~~~~~~~~~ API ACTIVITYREPORT DATA ~~~~~~~~~~~~ //

export function getActivityReportFile(request) {
  return request({
    url: API_BASE_URL + "/downloadActivityReport/",
    method: "POST",
    body: JSON.stringify(request),
  });
}

export function getActivityReportData(activityReportId) {
  return request({
    url: API_BASE_URL + "/activityReportDatas/" + activityReportId,
    method: "POST",
    body: null,
  });
}

export function getMissingActivityReports(filterFields) {
  return request({
    url: API_BASE_URL + "/resources/search/activityReports",
    method: "POST",
    body: filterFields,
    type: "Data",
  });
}

export function getAllActivityReportDatas() {
  return request({
    url: API_BASE_URL + "/activityReportDatas/all",
    method: "POST",
  });
}

export function addActivityReportData(activityReportRequest) {
  return request({
    url: API_BASE_URL + "/activityReportDatas/",
    method: "POST",
    body: JSON.stringify(activityReportRequest),
  });
}

export function editActivityReportData(
  activityReportId,
  activityReportRequest
) {
  return request({
    url: API_BASE_URL + "/activityReportDatas/",
    method: "PUT",
    body: activityReportRequest,
    type: "Data",
  });
}

export function deleteActivityReportData(idActivityReport) {
  return request({
    url: API_BASE_URL + "/activityReportDatas/" + idActivityReport,
    method: "DELETE",
  });
}

// ~~~~~~~~~~~~~~~~ API ACTIVITYREPORT  ~~~~~~~~~~~~~~~~ //

export function getActivityReport(activityReportId) {
  return request({
    url: API_BASE_URL + "/activityReports/" + activityReportId,
    method: "POST",
    body: null,
  });
}

export function getAllActivityReports() {
  return request({
    url: API_BASE_URL + "/activityReports/all",
    method: "POST",
  });
}

export function getActivityReportsByResource(resourceId) {
  return request({
    url: API_BASE_URL + "/activityReports/resource/" + resourceId,
    method: "POST",
  });
}

export function getActivityReportsBySupllier(supplierId) {
  return request({
    url: API_BASE_URL + "/activityReports/supplier/" + supplierId,
    method: "POST",
  });
}

export function addActivityReport(activityReportRequest) {
  return request({
    url: API_BASE_URL + "/activityReports/",
    method: "POST",
    body: activityReportRequest,
    type: "Data",
  });
}

// export function changeActivityReportState(activityReportId) {
//   return request({
//     url: API_BASE_URL + "/activityReports/etat/" + activityReportId,
//     method: "GET"
//   });
// }

export function editActivityReport(activityReportId, activityReportRequest) {
  return request({
    url: API_BASE_URL + "/activityReports/",
    method: "PUT",
    body: activityReportRequest,
    type: "Data",
  });
}

export function deleteActivityReport(idActivityReport) {
  return request({
    url: API_BASE_URL + "/activityReports/" + idActivityReport,
    method: "DELETE",
  });
}

// ~~~~~~~~~~~~~~~~ API RESOURCE  ~~~~~~~~~~~~~~~~ //

export function getResource(resourceId) {
  return request({
    url: API_BASE_URL + "/resources/" + resourceId,
    method: "POST",
  });
}

export function getAllResources() {
  return request({
    url: API_BASE_URL + "/resources/all",
    method: "POST",
  });
}

export function getResourcesBySupplier(supplierId) {
  return request({
    url: API_BASE_URL + "/resources/supplier/" + supplierId,
    method: "POST",
  });
}

export function searchResources(skillMarks) {
  return request({
    url: API_BASE_URL + "/resources/search/skillMarks",
    method: "POST",
    body: JSON.stringify(skillMarks),
  });
}

export function addResource(resourceRequest) {
  return request({
    url: API_BASE_URL + "/resources/",
    method: "POST",
    body: resourceRequest,
    type: "Data",
  });
}

export function changeResourceState(resourceId) {
  return request({
    url: API_BASE_URL + "/resources/etat/" + resourceId,
    method: "GET",
  });
}

export function editResource(resourceId, resourceRequest) {
  return request({
    url: API_BASE_URL + "/resources/",
    method: "PUT",
    body: resourceRequest,
    type: "Data",
  });
}

export function deleteResource(idResource, date, force) {
  return request({
    url: API_BASE_URL + "/resources/" + idResource + "/" + force,
    method: "DELETE",
    body: date,
  });
}

export function getResourceFiles(idResource) {
  return request({
    url: API_BASE_URL + "/resources/" + idResource,
    method: "DELETE",
  });
}

// ~~~~~~~~~~~~~~~~ API PROFIL  ~~~~~~~~~~~~~~~~ //

export function getProfil(profilId) {
  return request({
    url: API_BASE_URL + "/profils/" + profilId,
    method: "POST",
  });
}

export function getVAlidProfils() {
  return request({
    url: API_BASE_URL + "/profils/valid",
    method: "GET",
  });
}

export function getValidAndProposedProfilesForCompany(companyId) {
  return request({
    url: API_BASE_URL + "/profils/validAndProposed/" + companyId,
    method: "GET",
  });
}

export function getProfilSkills(profilId) {
  return request({
    url: API_BASE_URL + "/profils/skills/" + profilId,
    method: "GET",
  });
}

export function getAllProfils() {
  return request({
    url: API_BASE_URL + "/profils/all",
    method: "POST",
  });
}

export function addProfil(profilRequest) {
  return request({
    url: API_BASE_URL + "/profils/",
    method: "POST",
    body: JSON.stringify(profilRequest),
  });
}

export function editProfil(profilId, profilRequest) {
  return request({
    url: API_BASE_URL + "/profils/",
    method: "PUT",
    body: JSON.stringify(profilRequest),
  });
}

export function deleteProfil(profilId) {
  return request({
    url: API_BASE_URL + "/profils/" + profilId,
    method: "DELETE",
  });
}

export function validateProfil(profilId) {
  return request({
    url: API_BASE_URL + "/profils/valider/" + profilId,
    method: "GET",
  });
}

export function validateProfilSkill(profilId, skillId) {
  return request({
    url: API_BASE_URL + "/profils/skills/valid/" + profilId + "/" + skillId,
    method: "GET",
  });
}

// ~~~~~~~~~~~~~~~~ API SKILL  ~~~~~~~~~~~~~~~~ //

export function getSkill(skillId) {
  return request({
    url: API_BASE_URL + "/skills/" + skillId,
    method: "POST",
  });
}

export function getAllSkills() {
  return request({
    url: API_BASE_URL + "/skills/all",
    method: "POST",
  });
}

export function addSkill(skillRequest) {
  return request({
    url: API_BASE_URL + "/skills/",
    method: "POST",
    body: JSON.stringify(skillRequest),
  });
}

export function editSkill(skillId, skillRequest) {
  return request({
    url: API_BASE_URL + "/skills/",
    method: "PUT",
    body: JSON.stringify(skillRequest),
  });
}

export function deleteSkill(idSkill) {
  return request({
    url: API_BASE_URL + "/skills/" + idSkill,
    method: "DELETE",
  });
}

export function validateSkill(skillId) {
  return request({
    url: API_BASE_URL + "/skills/valider/" + skillId,
    method: "GET",
  });
}

export function getValidandProposedSkillsForCompany(companyId) {
  return request({
    url: API_BASE_URL + "/skills/validAndProposed/" + companyId,
    method: "GET",
  });
}

// ~~~~~~~~~~~~~~~~ API DDCUMENT  ~~~~~~~~~~~~~~~~ //

export function getDocument(documentId) {
  return request({
    url: API_BASE_URL + "/documents/" + documentId,
    method: "GET",
  });
}

export function getApplicantDocuments(applicantId) {
  return request({
    url: API_BASE_URL + "/documents/applicants/" + applicantId,
    method: "GET",
  });
}

export function getAllDocuments() {
  return request({
    url: API_BASE_URL + "/documents",
    method: "GET",
  });
}

export function addDocument(documentRequest) {
  return request({
    url: API_BASE_URL + "/documents",
    method: "POST",
    body: documentRequest,
    type: "Data",
  });
}

export function editDocument(documentId, documentRequest) {
  return request({
    url: API_BASE_URL + "/documents/" + documentId,
    method: "PUT",
    body: documentRequest,
    type: "Data",
  });
}

export function deleteDocument(documentId) {
  return request({
    url: API_BASE_URL + "/documents/" + documentId,
    method: "DELETE",
  });
}

export function searchDocumentBySkills(skillsRequest) {
  return request({
    url: API_BASE_URL + "/documents/search",
    method: "POST",
    body: JSON.stringify(skillsRequest),
  });
}

// ~~~~~~~~~~~~~~~~ API COMPANY CONTACT  ~~~~~~~~~~~~~~~~ //

export function getCompanyContact(companyContactId) {
  return request({
    url: API_BASE_URL + "/companyContacts/" + companyContactId,
    method: "POST",
  });
}

export function getCompanyContactsByCompanyId(companyId) {
  return request({
    url: API_BASE_URL + "/companyContacts/company/" + companyId,
    method: "POST",
  });
}

export function getAllCompanyContacts() {
  return request({
    url: API_BASE_URL + "/companyContacts",
    method: "POST",
  });
}

export function addCompanyContact(companyContactRequest, companyId) {
  return request({
    url: API_BASE_URL + "/companyContacts/add/" + companyId,
    method: "POST",
    body: JSON.stringify(companyContactRequest),
  });
}

export function editCompanyContact(companyContactId, companyContactRequest) {
  console.log(companyContactId);
  console.log(companyContactRequest);
  return request({
    url: API_BASE_URL + "/companyContacts/edit/" + companyContactId,
    method: "PUT",
    body: JSON.stringify(companyContactRequest),
  });
}

export function deleteCompanyContact(idCompanyContact) {
  return request({
    url: API_BASE_URL + "/companyContacts/" + idCompanyContact,
    method: "DELETE",
  });
}

// ~~~~~~~~~~~~~~~~ API CONTACT  ~~~~~~~~~~~~~~~~ //

export function getContact(contactId) {
  return request({
    url: API_BASE_URL + "/contacts/" + contactId,
    method: "POST",
  });
}

export function getContactByApplicantId(applicantId) {
  return request({
    url: API_BASE_URL + "/contacts/applicant/" + applicantId,
    method: "POST",
  });
}

export function getContactByApplicantIdAndOfferId(applicantId, offerId) {
  return request({
    url:
      API_BASE_URL + "/contacts/applicant/offer/" + applicantId + "/" + offerId,
    method: "POST",
  });
}

export function getAllContacts() {
  return request({
    url: API_BASE_URL + "/contacts/",
    method: "POST",
  });
}

export function addContact(contactRequest) {
  return request({
    url: API_BASE_URL + "/contacts/",
    method: "POST",
    body: JSON.stringify(contactRequest),
  });
}

export function editContact(contactId, contactRequest) {
  return request({
    url: API_BASE_URL + "/contacts/",
    method: "PUT",
    body: JSON.stringify(contactRequest),
  });
}

export function deleteContact(idContact) {
  return request({
    url: API_BASE_URL + "/contacts/" + idContact,
    method: "DELETE",
  });
}

// ~~~~~~~~~~~~~~~~ API INTERVIEW  ~~~~~~~~~~~~~~~~ //

export function getInterview(interviewId) {
  return request({
    url: API_BASE_URL + "/interviews/" + interviewId,
    method: "POST",
  });
}

export function getInterviewByApplicantId(applicantId) {
  return request({
    url: API_BASE_URL + "/interviews/applicant/" + applicantId,
    method: "POST",
  });
}

export function getInterviewByApplicantIdAndOfferId(applicantId, offerId) {
  return request({
    url:
      API_BASE_URL +
      "/interviews/applicant/offer/" +
      applicantId +
      "/" +
      offerId,
    method: "POST",
  });
}

export function getAllInterviews() {
  return request({
    url: API_BASE_URL + "/interviews/",
    method: "POST",
  });
}

export function addInterview(interviewRequest) {
  return request({
    url: API_BASE_URL + "/interviews/",
    method: "POST",
    body: JSON.stringify(interviewRequest),
  });
}

export function editInterview(interviewId, interviewRequest) {
  return request({
    url: API_BASE_URL + "/interviews/",
    method: "PUT",
    body: JSON.stringify(interviewRequest),
  });
}

export function deleteInterview(idInterview) {
  return request({
    url: API_BASE_URL + "/interviews/" + idInterview,
    method: "DELETE",
  });
}

// ~~~~~~~~~~~~~~~~ API RESULT  ~~~~~~~~~~~~~~~~ //

export function getResult(resultId) {
  return request({
    url: API_BASE_URL + "/results/" + resultId,
    method: "POST",
  });
}

export function getResultByApplicantId(applicantId) {
  return request({
    url: API_BASE_URL + "/results/applicant/" + applicantId,
    method: "POST",
  });
}

export function getResultByApplicantIdAndOfferId(applicantId, offerId) {
  return request({
    url:
      API_BASE_URL + "/results/applicant/offer/" + applicantId + "/" + offerId,
    method: "POST",
  });
}

export function getAllResults() {
  return request({
    url: API_BASE_URL + "/results/",
    method: "POST",
  });
}

export function addResult(resultRequest) {
  return request({
    url: API_BASE_URL + "/results/",
    method: "POST",
    body: JSON.stringify(resultRequest),
  });
}

export function editResult(resultId, resultRequest) {
  return request({
    url: API_BASE_URL + "/results/",
    method: "PUT",
    body: JSON.stringify(resultRequest),
  });
}

export function deleteResult(idResult) {
  return request({
    url: API_BASE_URL + "/results/" + idResult,
    method: "DELETE",
  });
}

// ~~~~~~~~~~~~~~~~ API EXPERIENCES  ~~~~~~~~~~~~~~~~ //

export function getExperience(experienceId) {
  return request({
    url: API_BASE_URL + "/experiences/" + experienceId,
    method: "POST",
  });
}

export function getExperienceByApplicantId(applicantId) {
  return request({
    url: API_BASE_URL + "/experiences/all/applicant/" + applicantId,
    method: "POST",
  });
}

export function addExperience(applicantId, experienceRequest) {
  return request({
    url: API_BASE_URL + "/experiences/applicant/" + applicantId,
    method: "POST",
    body: JSON.stringify(experienceRequest),
  });
}

export function editExperience(applicantId, experienceRequest) {
  return request({
    url: API_BASE_URL + "/experiences/applicant/" + applicantId,
    method: "PUT",
    body: JSON.stringify(experienceRequest),
  });
}

export function deleteExperience(idExperience) {
  return request({
    url: API_BASE_URL + "/experiences/" + idExperience,
    method: "DELETE",
  });
}

// ~~~~~~~~~~~~~~~~ API DEGREES  ~~~~~~~~~~~~~~~~ //

export function getDegree(degreeId) {
  return request({
    url: API_BASE_URL + "/degrees/" + degreeId,
    method: "POST",
  });
}

export function getDegreeByApplicantId(applicantId) {
  return request({
    url: API_BASE_URL + "/degrees/all/applicant/" + applicantId,
    method: "POST",
  });
}

export function addDegree(degreeRequest, applicantId) {
  return request({
    url: API_BASE_URL + "/degrees/applicant/" + applicantId,
    method: "POST",
    body: JSON.stringify(degreeRequest),
  });
}

export function editDegree(applicantId, degreeRequest) {
  return request({
    url: API_BASE_URL + "/degrees/applicant/" + applicantId,
    method: "PUT",
    body: JSON.stringify(degreeRequest),
  });
}

export function deleteDegree(idDegree) {
  return request({
    url: API_BASE_URL + "/degrees/" + idDegree,
    method: "DELETE",
  });
}

// ~~~~~~~~~~~~~~~~ API APPLICATIONS  ~~~~~~~~~~~~~~~~ //

export function getApplication(applicationId) {
  return request({
    url: API_BASE_URL + "/applications/" + applicationId,
    method: "POST",
  });
}

export function getApplicationByApplicantId() {
  return request({
    url: API_BASE_URL + "/applications/all",
    method: "POST",
  });
}

export function getApplicationByApplicantIdAndOfferId(applicantId, offerId) {
  return request({
    url: API_BASE_URL + "/applications/apply/" + applicantId + "/" + offerId,
    method: "POST",
  });
}

export function addApplication(applicantId, offerId, applicationRequest) {
  return request({
    url: API_BASE_URL + "/applications/" + applicantId + "/" + offerId,
    method: "POST",
    body: JSON.stringify(applicationRequest),
  });
}

export function editApplication(applicationRequest) {
  return request({
    url: API_BASE_URL + "/applications/",
    method: "PUT",
    body: JSON.stringify(applicationRequest),
  });
}

export function deleteApplication(idDegree) {
  return request({
    url: API_BASE_URL + "/applications/" + idDegree,
    method: "DELETE",
  });
}

//added

export function getActivityReportFileByBody(activityReportRequest) {
  return request({
    url: API_BASE_URL + "/downloadActivityReport/body",
    method: "POST",
    type: "Download",
    body: JSON.stringify(activityReportRequest),
  });
}

export function getActiveOrderSheets(resourceId) {
  return request({
    url: API_BASE_URL + "/orderSheets/resource/" + resourceId,
    method: "POST",
    body: null,
  });
}

// ~~~~~~~~~~~~~~~~ API SUBSKILL  ~~~~~~~~~~~~~~~~ //

export function getSubskill(subskillId) {
  return request({
    url: API_BASE_URL + "/subskills/" + subskillId,
    method: "POST",
  });
}

export function getAllSubskills() {
  return request({
    url: API_BASE_URL + "/subskills/all",
    method: "POST",
  });
}

export function addSubskill(subskillRequest) {
  return request({
    url: API_BASE_URL + "/subskills/",
    method: "POST",
    body: JSON.stringify(subskillRequest),
  });
}

export function editSubskill(subskillId, subskillRequest) {
  return request({
    url: API_BASE_URL + "/subskills/",
    method: "PUT",
    body: JSON.stringify(subskillRequest),
  });
}

export function deleteSubskill(idSubskill) {
  return request({
    url: API_BASE_URL + "/subskills/" + idSubskill,
    method: "DELETE",
  });
}

// ~~~~~~~~~~~~~~~~ NOTIFICATIONS  ~~~~~~~~~~~~~~~~ //

export function getManualResumeStatus(resourceId) {
  return request({
    url: API_BASE_URL + "/resources/manualResumeStatus/" + resourceId,
    method: "POST",
  });
}

export function getMissingActivityReportsForResource(resourceId) {
  return request({
    url: API_BASE_URL + "/resources/missingActivityReports/" + resourceId,
    method: "POST",
  });
}

export function getMissingFilesForResource(resourceId) {
  return request({
    url: API_BASE_URL + "/resources/filesMissing/" + resourceId,
    method: "POST",
  });
}

// ~~~~~~~~~~~~~~~~ MANUAL RESUME  ~~~~~~~~~~~~~~~~ //

export function addManualResume(manualResumeRequest, applicantId) {
  return request({
    url: API_BASE_URL + "/manualResume/applicant/" + applicantId,
    method: "POST",
    body: JSON.stringify(manualResumeRequest),
  });
}

export function deleteManualResume(idManualResume) {
  return request({
    url: API_BASE_URL + "/manualResume/" + idManualResume,
    method: "DELETE",
  });
}

export function getManualResumeByApplicantId(applicantId) {
  return request({
    url: API_BASE_URL + "/manualResume/get/applicant/" + applicantId,
    method: "POST",
  });
}
