import React, { Component, Fragment } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import { Link } from "react-router-dom";
import { Card, CardBody, Table } from "reactstrap";
import { Input } from "reactstrap";
import {
  deleteResource,
  getResource,
  getResumeFile,
} from "../../util/APIUtils";
import { FiDownload } from "react-icons/fi";
class Consulter extends Component {
  constructor() {
    super();
    this.state = {
      date: new Date().toLocaleDateString("en-CA"),
      firstName: "",
      lastName: "",
      phone: "",
      address: "",
      seniority: "",
      email: "",
      isRecruited: "",
      applicantStatus: "",
      profil: "",
      resume: "",
      type: "",

      referenceNumber: "",
      missionStartDate: "",
      bankIdentityCode: "",

      anthropometricSheet: null,
      nationalIdCard: null,
      ethicsCharter: null,
      contract: null,
      nationalSocialSecurityFundCard: null,
      photoId: null,
      degree: null,
      resignationLetter: null,
      confidentialityCommitment: null,

      errors: {},
    };
    this.downloadDocument = this.downloadDocument.bind(this);
    this.delete = this.delete.bind(this);
  }

  componentDidMount() {
    var dateFormat = require("dateformat");
    getResource(this.props.match.params.Id)
      .then((response) => {
        console.log();
        let anthropometricSheet = response.content.files.filter(
          (value, index, array) => {
            return value.type === "ANTHROPOMETRIC_SHEET";
          }
        );
        let nationalIdCard = response.content.files.filter(
          (value, index, array) => {
            return value.type === "NATIONAL_ID_CARD";
          }
        );
        let ethicsCharter = response.content.files.filter(
          (value, index, array) => {
            return value.type === "ETHICS_CHARTER";
          }
        );
        let contract = response.content.files.filter((value, index, array) => {
          return value.type === "CONTRACT";
        });
        let nationalSocialSecurityFundCard = response.content.files.filter(
          (value, index, array) => {
            return value.type === "NATIONAL_SOCIAL_SECURITY_FUND_CARD";
          }
        );
        let photoId = response.content.files.filter((value, index, array) => {
          return value.type === "PHOTO_ID";
        });
        let degree = response.content.files.filter((value, index, array) => {
          return value.type === "DEGREE";
        });
        let resignationLetter = response.content.files.filter(
          (value, index, array) => {
            return value.type === "RESIGNATION_LETTER";
          }
        );
        let confidentialityCommitment = response.content.files.filter(
          (value, index, array) => {
            return value.type === "CONFIDENTIALITY_COMMITMENT";
          }
        );
        this.setState({
          firstName: response.content.firstName,
          lastName: response.content.lastName,
          phone: response.content.phone,
          address: response.content.address,
          seniority: response.content.seniority,
          email: response.content.email,
          isRecruited: response.content.isRecruited,
          applicantStatus: response.content.applicantStatus,
          profil: response.content.profil,
          type: response.content.type,
          resume: response.content.resume,
          referenceNumber: response.content.referenceNumber,
          missionStartDate:
            response.content.missionStartDate !== null
              ? dateFormat(response.content.startDate, "yyyy-mm-dd")
              : null,
          bankIdentityCode: response.content.bankIdentityCode,
          calculatedMissionEndDate:
            response.content.calculatedMissionEndDate !== null
              ? dateFormat(response.content.startDate, "yyyy-mm-dd")
              : null,
          remainingMissionDays: response.content.remainingMissionDays,
          anthropometricSheet:
            anthropometricSheet[0] !== undefined
              ? anthropometricSheet[0]
              : null,
          nationalIdCard:
            nationalIdCard[0] !== undefined ? nationalIdCard[0] : null,
          ethicsCharter:
            ethicsCharter[0] !== undefined ? ethicsCharter[0] : null,
          contract: contract[0] !== undefined ? contract[0] : null,
          nationalSocialSecurityFundCard:
            nationalSocialSecurityFundCard[0] !== undefined
              ? nationalSocialSecurityFundCard[0]
              : null,
          photoId: photoId[0] !== undefined ? photoId[0] : null,
          degree: degree[0] !== undefined ? degree[0] : null,
          resignationLetter:
            resignationLetter[0] !== undefined ? resignationLetter[0] : null,
          confidentialityCommitment:
            confidentialityCommitment[0] !== undefined
              ? confidentialityCommitment[0]
              : null,
          data: [response],
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  downloadDocument(documentName) {
    getResumeFile(documentName)
      .then((response) => response.blob())
      .then((myBlob) => {
        const url = window.URL.createObjectURL(myBlob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", documentName); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  }

  delete(id) {
    let today = new Date();
    today = today.toLocaleDateString("en-CA");
    today = this.state.date;
    confirmAlert({
      title: "Confirmation Démission",
      childrenElement: () => (
        <div>
          <p>Date de démission : </p>
          <Input
            id="date"
            name="date"
            type="date"
            onChange={this.onChange}
            defaultValue={new Date().toLocaleDateString("en-CA")}
          />
          <br></br>
          <p>Etes vous sure ? </p>
        </div>
      ),
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteResource(id, this.state.date, false)
              .then((res) => this.props.history.push("/Ressource"))
              .catch((err) => {
                if (err.message === "la resource n'a pas envoyé tous les CRA") {
                  confirmAlert({
                    title: "Forcer la Démission",
                    message:
                      "La ressource n'a pas fournis tous les CRA, voulez-vous forcer la démission :",
                    buttons: [
                      {
                        label: "Yes",
                        onClick: () => {
                          deleteResource(id, this.state.date, true)
                            .then((res) =>
                              this.props.history.push("/Ressource")
                            )
                            .catch((err) => console.log(err));
                        },
                      },
                      {
                        label: "No",
                        onClick: () => {},
                      },
                    ],
                  });
                } else {
                  console.log(err);
                }
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    let active = <span className="badge badge-primary">ACTIVE</span>;
    let blacklist = <span className="badge badge-secondary">BLACKLISTER</span>;
    return (
      <React.Fragment>
        {/*Page Title*/}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title" style={{ color: "#ffffff" }}>
                    CRAs
                  </h2>
                  {/* <p>Fresh From The Press Discription</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*End Page Title*/}
        {/*Sidebar Page Container*/}
        <div
          className="service-area ptb--80  bg_image bg_image--3"
          style={{ paddingTop: "200px", paddingBottom: "200px" }}
        >
          <div className="container">
            <div>
              <Card style={{ marginTop: "10px", marginBottom: "10px" }}>
                <CardBody>
                  <h3
                    style={{
                      marginBottom: "40px",
                      display: "inline-block",
                    }}
                  >
                    Détails Ressource :
                  </h3>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="general-info">
                        <div className="row">
                          <div className="col-lg-12 col-xl-6">
                            <div className="table-responsive">
                              <Table responsive>
                                <tbody>
                                  <tr>
                                    <th
                                      style={{
                                        color: "rgba(95, 66, 253, 0.84)",
                                      }}
                                    >
                                      Matricule
                                    </th>
                                    <td>{this.state.referenceNumber}</td>
                                  </tr>
                                  <tr>
                                    <th
                                      style={{
                                        color: "rgba(95, 66, 253, 0.84)",
                                      }}
                                    >
                                      Nom
                                    </th>
                                    <td>{this.state.lastName}</td>
                                  </tr>
                                  <tr>
                                    <th
                                      style={{
                                        color: "rgba(95, 66, 253, 0.84)",
                                      }}
                                    >
                                      Prenom
                                    </th>
                                    <td>{this.state.firstName}</td>
                                  </tr>
                                  <tr>
                                    <th
                                      style={{
                                        color: "rgba(95, 66, 253, 0.84)",
                                      }}
                                    >
                                      Email
                                    </th>
                                    <td>{this.state.email}</td>
                                  </tr>
                                  <tr>
                                    <th
                                      style={{
                                        color: "rgba(95, 66, 253, 0.84)",
                                      }}
                                    >
                                      Telephone
                                    </th>
                                    <td>{this.state.phone}</td>
                                  </tr>
                                  <tr>
                                    <th
                                      style={{
                                        color: "rgba(95, 66, 253, 0.84)",
                                      }}
                                    >
                                      Adresse
                                    </th>
                                    <td>{this.state.address}</td>
                                  </tr>
                                  <tr>
                                    <th
                                      style={{
                                        color: "rgba(95, 66, 253, 0.84)",
                                      }}
                                    >
                                      CV
                                    </th>
                                    <td>
                                      <button
                                        style={{
                                          color: "#ffffff",
                                          background: "rgba(95, 66, 253, 0.84)",
                                        }}
                                        className="btn"
                                        // onClick={this.downloadDocument.bind(
                                        //   this,
                                        //   this.state.resume.name
                                        // )}
                                        type="button"
                                      >
                                        <FiDownload />
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </div>
                          <div className="col-lg-12 col-xl-6">
                            <div className="table-responsive" />
                            <Table responsive>
                              <tbody>
                                <tr>
                                  <th
                                    style={{
                                      color: "rgba(95, 66, 253, 0.84)",
                                    }}
                                  >
                                    Type
                                  </th>
                                  <td>{this.state.type}</td>
                                </tr>
                                <tr>
                                  <th
                                    style={{
                                      color: "rgba(95, 66, 253, 0.84)",
                                    }}
                                  >
                                    Séniorité
                                  </th>
                                  <td>{this.state.seniority}</td>
                                </tr>
                                <tr>
                                  <th
                                    style={{
                                      color: "rgba(95, 66, 253, 0.84)",
                                    }}
                                  >
                                    Etat
                                  </th>
                                  <td>
                                    {this.state.status !== "SUSPENDED"
                                      ? active
                                      : blacklist}
                                  </td>
                                </tr>
                                <tr>
                                  <th
                                    style={{
                                      color: "rgba(95, 66, 253, 0.84)",
                                    }}
                                  >
                                    RIB
                                  </th>
                                  <td>{this.state.bankIdentityCode}</td>
                                </tr>
                                <tr>
                                  <th
                                    style={{
                                      color: "rgba(95, 66, 253, 0.84)",
                                    }}
                                  >
                                    Date Début Mission
                                  </th>
                                  <td>{this.state.missionStartDate}</td>
                                </tr>
                                <tr>
                                  <th
                                    style={{
                                      color: "rgba(95, 66, 253, 0.84)",
                                    }}
                                  >
                                    Date Fin Calculé
                                  </th>
                                  <td>{this.state.calculatedMissionEndDate}</td>
                                </tr>
                                <tr>
                                  <th
                                    style={{
                                      color: "rgba(95, 66, 253, 0.84)",
                                    }}
                                  >
                                    Reste des jours
                                  </th>
                                  <td>{this.state.remainingMissionDays}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card style={{ marginTop: "10px", marginBottom: "10px" }}>
                <CardBody>
                  <h3
                    style={{
                      marginBottom: "40px",
                      display: "inline-block",
                    }}
                  >
                    Documents :
                  </h3>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="general-info">
                        <div className="row">
                          <div className="col-lg-12 col-xl-6">
                            <div className="table-responsive">
                              <Table responsive>
                                <tbody>
                                  <tr>
                                    <th
                                      style={{
                                        color: "rgba(95, 66, 253, 0.84)",
                                      }}
                                    >
                                      Contrat
                                    </th>
                                    <td>
                                      {this.state.contract !== null ? (
                                        <button
                                          className="btn btn-info"
                                          onClick={this.downloadDocument.bind(
                                            this,
                                            this.state.contract.name
                                          )}
                                          type="button"
                                        >
                                          <i className="icon-cloud-download icons font-2xl d-block"></i>
                                        </button>
                                      ) : (
                                        "non saisie"
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th
                                      style={{
                                        color: "rgba(95, 66, 253, 0.84)",
                                      }}
                                    >
                                      Fiche Anthropometrique
                                    </th>
                                    <td>
                                      {this.state.anthropometricSheet !==
                                      null ? (
                                        <button
                                          className="btn btn-info"
                                          onClick={this.downloadDocument.bind(
                                            this,
                                            this.state.anthropometricSheet.name
                                          )}
                                          type="button"
                                        >
                                          <i className="icon-cloud-download icons font-2xl d-block"></i>
                                        </button>
                                      ) : (
                                        "non saisie"
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th
                                      style={{
                                        color: "rgba(95, 66, 253, 0.84)",
                                      }}
                                    >
                                      CIN
                                    </th>
                                    <td>
                                      {this.state.nationalIdCard !== null ? (
                                        <button
                                          className="btn btn-info"
                                          onClick={this.downloadDocument.bind(
                                            this,
                                            this.state.nationalIdCard.name
                                          )}
                                          type="button"
                                        >
                                          <i className="icon-cloud-download icons font-2xl d-block"></i>
                                        </button>
                                      ) : (
                                        "non saisie"
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th
                                      style={{
                                        color: "rgba(95, 66, 253, 0.84)",
                                      }}
                                    >
                                      Engagement de confidentialité
                                    </th>
                                    <td>
                                      {this.state.confidentialityCommitment !==
                                      null ? (
                                        <button
                                          className="btn btn-info"
                                          onClick={this.downloadDocument.bind(
                                            this,
                                            this.state.confidentialityCommitment
                                              .name
                                          )}
                                          type="button"
                                        >
                                          <i className="icon-cloud-download icons font-2xl d-block"></i>
                                        </button>
                                      ) : (
                                        "non saisie"
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th
                                      style={{
                                        color: "rgba(95, 66, 253, 0.84)",
                                      }}
                                    >
                                      Charte d'étique
                                    </th>
                                    <td>
                                      {this.state.ethicsCharter !== null ? (
                                        <button
                                          className="btn btn-info"
                                          onClick={this.downloadDocument.bind(
                                            this,
                                            this.state.ethicsCharter.name
                                          )}
                                          type="button"
                                        >
                                          <i className="icon-cloud-download icons font-2xl d-block"></i>
                                        </button>
                                      ) : (
                                        "non saisie"
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </div>
                          <div className="col-lg-12 col-xl-6">
                            <div className="table-responsive" />
                            <Table responsive>
                              <tbody>
                                <tr>
                                  <th
                                    style={{
                                      color: "rgba(95, 66, 253, 0.84)",
                                    }}
                                  >
                                    Diplome
                                  </th>
                                  <td>
                                    {this.state.degree !== null ? (
                                      <button
                                        className="btn btn-info"
                                        onClick={this.downloadDocument.bind(
                                          this,
                                          this.state.degree.name
                                        )}
                                        type="button"
                                      >
                                        <i className="icon-cloud-download icons font-2xl d-block"></i>
                                      </button>
                                    ) : (
                                      "non saisie"
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <th
                                    style={{
                                      color: "rgba(95, 66, 253, 0.84)",
                                    }}
                                  >
                                    Lettre de démission
                                  </th>
                                  <td>
                                    {this.state.resignationLetter !== null ? (
                                      <button
                                        className="btn btn-info"
                                        onClick={this.downloadDocument.bind(
                                          this,
                                          this.state.resignationLetter.name
                                        )}
                                        type="button"
                                      >
                                        <i className="icon-cloud-download icons font-2xl d-block"></i>
                                      </button>
                                    ) : (
                                      "non saisie"
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <th
                                    style={{
                                      color: "rgba(95, 66, 253, 0.84)",
                                    }}
                                  >
                                    Carte CNSS
                                  </th>
                                  <td>
                                    {this.state
                                      .nationalSocialSecurityFundCard !==
                                    null ? (
                                      <button
                                        className="btn btn-info"
                                        onClick={this.downloadDocument.bind(
                                          this,
                                          this.state
                                            .nationalSocialSecurityFundCard.name
                                        )}
                                        type="button"
                                      >
                                        <i className="icon-cloud-download icons font-2xl d-block"></i>
                                      </button>
                                    ) : (
                                      "non saisie"
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <th
                                    style={{
                                      color: "rgba(95, 66, 253, 0.84)",
                                    }}
                                  >
                                    Photo
                                  </th>
                                  <td>
                                    {this.state.photoId !== null ? (
                                      <button
                                        className="btn btn-info"
                                        onClick={this.downloadDocument.bind(
                                          this,
                                          this.state.photoId.name
                                        )}
                                        type="button"
                                      >
                                        <i className="icon-cloud-download icons font-2xl d-block"></i>
                                      </button>
                                    ) : (
                                      "non saisie"
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Consulter;
