import React, { Component } from "react";
import { confirmResetPassowrd, resetPassword } from "../../util/APIUtils";

class RenewPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      retypePassword: "",
      formErrors: {
        email: "",
        password: "",
        retypePassword: "",
      },
      errors: { message: "" },
    };
    this.validateForm = this.validateForm.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    let token = this.props.match.params.token;
    confirmResetPassowrd(token)
      .then((res) => {
        this.setState({
          email: res.content,
        });
      })
      .catch((err) => this.setState({ errors: { message: err.message } }));
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  validateEmail(email) {
    var regexMail = /.+@.+\.[A-Za-z]+$/;
    return regexMail.test(email);
  }

  validatePassword(password) {
    var regexMail = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
    return regexMail.test(password);
  }

  validateForm() {
    let { email, password, retypePassword } = this.state;
    let formErrors = this.state.formErrors;

    formErrors.email =
      email.length > 0 ? "" : "Veuillez entrez un email de connexion";

    formErrors.password =
      password.length > 0 ? "" : "Veuillez entrez un mot de passe";

    formErrors.password =
      retypePassword.length > 0
        ? ""
        : "Veuillez entrez à nouveau le mot de passe";

    if (email.length > 0) {
      formErrors.email = this.validateEmail(email)
        ? ""
        : "Veuillez saisir un email valide";
    }

    if (password.length > 0) {
      formErrors.password = this.validatePassword(password)
        ? ""
        : "Veuillez saisir un mot de passe valide, contenant au moins 8 caractères, une lettre majuscule, une lettre miniscule et un digit";
    }

    if (retypePassword.length > 0) {
      formErrors.password =
        password === retypePassword
          ? ""
          : "Veuillez saisir le même mot de passe";
    }

    this.setState({
      formErrors,
    });

    return (
      formErrors.email === "" &&
      formErrors.password === "" &&
      formErrors.retypePassword === ""
    );
  }

  onSubmit(e) {
    e.preventDefault();

    let token = this.props.match.params.token;

    const user = {
      email: this.state.email,
      password: this.state.password,
    };

    const userTxt = JSON.stringify(user);

    let data = new FormData();
    data.append("user", userTxt);
    data.append("token", token);

    if (this.validateForm()) {
      resetPassword(data)
        .then((res) => this.props.history.push("/login"))
        .catch((err) => this.setState({ errors: { message: err.message } }));
    }
  }

  alert(message) {
    return (
      <div className="alert alert-danger" role="alert">
        {message}
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        {/* Start Breadcrump Area */}
        <div
          style={{ height: "10vh" }}
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  {/* <h2 className="title" style={{ color: "#ffffff" }}>CONNEXION</h2> */}
                  {/* <p>Fresh From The Press Discription</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <section
          className="contact-page-section"
          style={{
            height: "60vh",
            width: "600px",
            margin: "auto",
            marginTop: "130px",
          }}
        >
          <div className="auto-container">
            <div className="inner-container">
              <h2>Réinitialiser mot de passe</h2>
              <div className="row clearfix">
                {/* Form Column */}
                <div className="form-column col-lg-12 col-md-12 col-sm-12">
                  <div className="inner-column">
                    {this.state.errors.message === ""
                      ? null
                      : this.alert(this.state.errors.message)}
                    {/*Contact Form*/}
                    <div className="contact-form">
                      <form onSubmit={this.onSubmit} id="contact-form">
                        <div className="form-group">
                          <input
                            type="email"
                            placeholder="Email"
                            name="email"
                            value={this.state.email}
                            disabled
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="password"
                            placeholder="Mot de passe"
                            name="password"
                            value={this.state.password}
                            onChange={this.onChange}
                          />
                          <p className="form-text error">
                            {this.state.formErrors.password}
                          </p>
                        </div>
                        <div className="form-group">
                          <input
                            type="password"
                            placeholder="Retaper le Mot de passe"
                            name="retypePassword"
                            value={this.state.retypePassword}
                            onChange={this.onChange}
                          />
                          <p className="form-text error">
                            {this.state.formErrors.retypePassword}
                          </p>
                        </div>
                        <div className="form-group">
                          <button
                            type="submit"
                            className="theme-btn"
                            style={{ float: "right" }}
                          >
                            Réinitialiser
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Team Page Section */}
      </React.Fragment>
    );
  }
}

export default RenewPassword;
