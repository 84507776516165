import React, { Component } from "react";
import { FormGroup, FormText, Input, Label, Table } from "reactstrap";

import Popup from "reactjs-popup";

class Edit extends Component {
  constructor() {
    super();
    this.state = {
      jobTitle: "",
      description: "",
      environment: "",
      startAt: "",
      endAt: "",
      company: "",
      applicant: "",
      formErrors: {
        jobTitle: "",
        description: "",
        environment: "",
        startAt: "",
        endAt: "",
        company: "",
        applicant: "",
      },
      open: false,
      skills: [],
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    var dateFormat = require("dateformat");

    this.setState({
      id: this.props.Id,
      jobTitle: this.props.experience.jobTitle,
      company: this.props.experience.company,
      description: this.props.experience.description,
      environment: this.props.experience.environment,
      startAt: dateFormat(this.props.experience.startAt, "yyyy-mm-dd"),
      endAt: dateFormat(this.props.experience.endAt, "yyyy-mm-dd"),
      applicant: this.props.experience.applicant,
    });
  }

  validateForm() {
    let {
      jobTitle,
      description,
      environment,
      startAt,
      endAt,
      company,
      applicant,
    } = this.state;

    let formErrors = this.state.formErrors;

    formErrors.jobTitle =
      jobTitle.length > 0 ? "" : "Veuillez entrer l'intitulé du poste";
    formErrors.description =
      description.length > 0 ? "" : "Veuillez entrer la description";
    formErrors.startAt = startAt.length > 0 ? "" : "Veuillez entrer un date";
    // formErrors.endAt = endAt.length > 0 ? "" : "Veuillez entrer un date";

    if (startAt !== "" && endAt !== "" && startAt < endAt) {
      formErrors.endAt = "";
    }

    formErrors.company =
      company === "" ? "Veuillez sélectionner un client" : "";

    if (
      formErrors.startAt === "" &&
      formErrors.endAt === "" &&
      endAt !== "" &&
      startAt > endAt
    ) {
      formErrors.endAt =
        "La date de fin ne peut pas être inférieure au date de début";
    }

    this.setState({
      formErrors,
    });

    return (
      JSON.stringify(formErrors) ===
      JSON.stringify({
        jobTitle: "",
        description: "",
        environment: "",
        startAt: "",
        endAt: "",
        company: "",
        applicant: "",
      })
    );
  }

  onSubmit(e) {
    e.preventDefault();

    const Experience = {
      jobTitle: this.state.jobTitle,
      description: this.state.description,
      environment: this.state.environment,
      startAt: this.state.startAt + "T00:00:00",
      company: this.state.company,
    };

    this.state.endAt === ""
      ? (Experience.endAt = null)
      : (Experience.endAt = this.state.endAt + "T00:00:00");

    if (this.validateForm()) {
      this.props.editExperience(Experience, this.props.arrayId);
      this.props.toggleModal();
    }
  }

  onChange(e) {
    console.log(e.target.value);
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <form
        onSubmit={this.onSubmit}
        onChange={this.onChange}
        style={{ width: "600px" }}
      >
        <FormGroup>
          <Label for="jobTitle">Titre</Label>
          <Input
            id="jobTitle"
            name="jobTitle"
            onChange={this.onChange}
            value={this.state.jobTitle}
          />
          <FormText color="danger">{this.state.formErrors.jobTitle}</FormText>
        </FormGroup>

        <FormGroup>
          <Label for="startAt">Date Début</Label>
          <Input
            id="startAt"
            name="startAt"
            type="date"
            onChange={this.onChange}
            value={this.state.startAt}
          />
          <FormText color="danger">{this.state.formErrors.startAt}</FormText>
        </FormGroup>

        <FormGroup>
          <Label for="startAt">Date Fin</Label>
          <Input
            id="endAt"
            name="endAt"
            type="date"
            onChange={this.onChange}
            value={this.state.endAt}
          />
          <FormText color="danger">{this.state.formErrors.endAt}</FormText>
        </FormGroup>

        <FormGroup>
          <Label for="company">Entreprise</Label>
          <Input
            id="company"
            name="company"
            onChange={this.onChange}
            value={this.state.company}
          />
          <FormText color="danger">{this.state.formErrors.company}</FormText>
        </FormGroup>

        <FormGroup>
          <Label for="description">Description</Label>
          <Input
            id="description"
            name="description"
            type="textarea"
            onChange={this.onChange}
            value={this.state.description}
          />
          <FormText color="danger">
            {this.state.formErrors.description}
          </FormText>
        </FormGroup>

        <FormGroup>
          <Label for="environment">Environnement</Label>
          <Input
            id="environment"
            name="environment"
            type="textarea"
            onChange={this.onChange}
            value={this.state.environment}
          />
          <FormText color="danger">
            {this.state.formErrors.environment}
          </FormText>
        </FormGroup>

        <input
          id="editExperience"
          type="submit"
          value="Éditer"
          className="btn btn-success float-right"
        />

        <input
          id="cancelEditExperience"
          type="button"
          onClick={this.props.toggleModal}
          value="Retour"
          className="btn btn-warning mr-1 float-right"
        />
      </form>
    );
  }
}

export default Edit;
