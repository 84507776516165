import React, { Component, Fragment } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import moment from "moment";
import "./styles.css";

// import { useAlert } from "react-alert";

import {
  addActivityReport,
  getAllResources,
  getResource,
  getActiveOrderSheets,
  getActivityReportFileByBody,
  getAllHolidays,
  getLastOrderSheets,
} from "../util/APIUtils";

import ReactDataGrid from "react-data-grid";

import {
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
} from "reactstrap";
//

import { FiZoomIn } from "react-icons/fi";

import update from "immutability-helper";
import FunctionHOC from "../Modal/FunctionHOC";
import Details from "./ConsulterBC";

const OrderSheetDetails = FunctionHOC(
  Details,
  <React.Fragment>
    Détails Bon de Commande &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
  </React.Fragment>,
  (props) => <React.Fragment />
);

let monDisabledCells = [];
let tueDisabledCells = [];
let wedDisabledCells = [];
let thuDisabledCells = [];
let friDisabledCells = [];
let satDisabledCells = [];
let sunDisabledCells = [];
let holidaysCells = [];

const monCellFormatter = ({ row, value }) => {
  let format = monDisabledCells.filter((obj) => {
    if (obj.week === row.week) return obj;
  });
  const gridElement = document.getElementsByClassName("react-grid-Cell");
  holidaysCells.map((hol) => {
    if (gridElement[hol] !== undefined)
      gridElement[hol].classList.add("react-grid-Cell-backgound");
  });

  if (format.length !== 0) return <div> </div>;
  else return <div>{value}</div>;
};

const tueCellFormatter = ({ row, value }) => {
  let format = tueDisabledCells.filter((obj) => {
    if (obj.week === row.week) return obj;
  });
  if (format.length !== 0) return <div> </div>;
  else return <div>{value}</div>;
};

const wedCellFormatter = ({ row, value }) => {
  let format = wedDisabledCells.filter((obj) => {
    if (obj.week === row.week) return obj;
  });
  if (format.length !== 0) return <div> </div>;
  else return <div>{value}</div>;
};

const thuCellFormatter = ({ row, value }) => {
  let format = thuDisabledCells.filter((obj) => {
    if (obj.week === row.week) return obj;
  });
  if (format.length !== 0) return <div> </div>;
  else return <div>{value}</div>;
};

const friCellFormatter = ({ row, value }) => {
  let format = friDisabledCells.filter((obj) => {
    if (obj.week === row.week) return obj;
  });
  if (format.length !== 0) return <div> </div>;
  else return <div>{value}</div>;
};

const satCellFormatter = ({ row, value }) => {
  let format = satDisabledCells.filter((obj) => {
    if (obj.week === row.week) return obj;
  });
  if (format.length !== 0) return <div> </div>;
  else return <div>{value}</div>;
};

const sunCellFormatter = ({ row, value }) => {
  let format = sunDisabledCells.filter((obj) => {
    if (obj.week === row.week) return obj;
  });
  if (format.length !== 0) return <div> </div>;
  else return <div>{value}</div>;
};

const columnsWeek = [
  { key: "week", name: "", editable: false, resizable: true, width: 300 },
  {
    key: "mon",
    name: "Lundi",
    editable: function (row) {
      let format = monDisabledCells.filter((obj) => {
        if (obj.week === row.week) return obj;
      });
      return format.length === 0;
    },
    formatter: monCellFormatter,
    width: 120,
  },
  {
    key: "tue",
    name: "Mardi",
    editable: function (row) {
      let format = tueDisabledCells.filter((obj) => {
        if (obj.week === row.week) return obj;
      });
      return format.length === 0;
    },
    formatter: tueCellFormatter,
    width: 120,
  },
  {
    key: "wed",
    name: "Mercredi",
    editable: function (row) {
      let format = wedDisabledCells.filter((obj) => {
        if (obj.week === row.week) return obj;
      });
      return format.length === 0;
    },
    formatter: wedCellFormatter,
    width: 120,
  },
  {
    key: "thu",
    name: "Jeudi",
    editable: function (row) {
      let format = thuDisabledCells.filter((obj) => {
        if (obj.week === row.week) return obj;
      });
      return format.length === 0;
    },
    formatter: thuCellFormatter,
    width: 120,
  },
  {
    key: "fri",
    name: "Vendredi",
    editable: function (row) {
      let format = friDisabledCells.filter((obj) => {
        if (obj.week === row.week) return obj;
      });
      return format.length === 0;
    },
    formatter: friCellFormatter,
    width: 120,
  },
  {
    key: "sat",
    name: "Samedi",
    editable: function (row) {
      let format = satDisabledCells.filter((obj) => {
        if (obj.week === row.week) return obj;
      });
      return format.length === 0;
    },
    formatter: satCellFormatter,
    width: 120,
  },
  {
    key: "sun",
    name: "Dimanche",
    editable: function (row) {
      let format = sunDisabledCells.filter((obj) => {
        if (obj.week === row.week) return obj;
      });
      return format.length === 0;
    },
    formatter: sunCellFormatter,
    width: 120,
  },
  {
    key: "rem",
    name: "Remarque",
    editable: function (row) {
      let format = sunDisabledCells.filter((obj) => {
        if (obj.week === row.week) return obj;
      });
      return format.length === 0;
    },
    formatter: sunCellFormatter,
  },
];

class AddActivityReport extends Component {
  constructor() {
    super();

    this.state = {
      currentStep: 1,
      enableCellSelect: true,
      startDate: "",
      endDate: "",
      workingDaysCount: "",
      month: "",
      // year: "",
      createdAt: "",
      updatedAt: "",
      file: "",
      orderSheet: "",
      resource: "",
      calculatedEndDate: "",
      sumWorkingDays: 0,
      rowsWeek: [],
      comments: [],
      selectOrderSheet: [],
      resourceOrderSheets: [],
      holidays: [],
      showOrderSheets: false,
      attachment: "",
      open: false,
      formErrors: {
        startDate: "",
        endDate: "",
        month: "",
        // year: "",
        orderSheet: "",
        file: "",
      },
      formWarning: {
        orderSheet: "",
      },
      errors: { message: "" },
    };

    this.onChange = this.onChange.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onChangeDates = this.onChangeDates.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.onAttachmentChange = this.onAttachmentChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeSelectResource = this.onChangeSelectResource.bind(this);
    this.onChangeSelectOrderSheet = this.onChangeSelectOrderSheet.bind(this);
    this.onChooseOrderSheet = this.onChooseOrderSheet.bind(this);
    this.clearData = this.clearData.bind(this);
    this.downloadActivityReportFile = this.downloadActivityReportFile.bind(
      this
    );
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    var dateFormat = require("dateformat");
    let sDate, eDate, sDateInput, eDateInput;
    let eDates = [];
    let sDates = [];

    var resourceId = this.props.currentUser.id;
    getResource(resourceId)
      .then((Response) => {
        this.setState({
          resource: Response,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    getLastOrderSheets(resourceId)
      .then((Response) => {
        console.log(Response);
        this.setState(
          {
            resourceOrderSheets: Response,
            selectOrderSheet: Response.filter((o) => {
              eDates = [];
              sDates = [];
              sDate = Date.parse(o.startDate);
              eDate = Date.parse(o.endDate);
              sDateInput = Date.parse(this.state.startDate);
              eDateInput = Date.parse(this.state.endDate);
              eDates.push(eDate);
              eDates.push(eDateInput);
              const minDate = new Date(Math.min(...eDates));
              sDates.push(sDate);
              sDates.push(sDateInput);
              const maxDate = new Date(Math.max(...sDates));
              if (isNaN(sDate) || isNaN(eDate)) {
                console.log(o);
                o.value = o.id;
                o.label =
                  o.company.name +
                  " => " +
                  o.externalReferenceNumber +
                  " " +
                  " (" +
                  o.remainingReportedDays +
                  ")";
                return o;
              } else if (minDate > maxDate) {
                console.log(o);
                o.value = o.id;
                let statDate =
                  o.startDate !== null
                    ? dateFormat(
                        new Date(
                          new Date(o.startDate).setHours(
                            new Date(o.startDate).getHours() + 1
                          )
                        ),
                        "dd-mm-yyyy"
                      )
                    : " ";
                let endDate =
                  o.endDate !== null
                    ? dateFormat(
                        new Date(
                          new Date(o.endDate).setHours(
                            new Date(o.endDate).getHours() + 1
                          )
                        ),
                        "dd-mm-yyyy"
                      )
                    : " ";
                let calculatedEndDate =
                  o.calculatedEndDate !== null
                    ? dateFormat(
                        new Date(
                          new Date(o.calculatedEndDate).setHours(
                            new Date(o.calculatedEndDate).getHours() + 1
                          )
                        ),
                        "dd-mm-yyyy"
                      )
                    : " ";
                o.label =
                  o.company.name +
                  " => " +
                  o.externalReferenceNumber +
                  " " +
                  statDate +
                  " | " +
                  endDate +
                  " (" +
                  o.remainingReportedDays +
                  ") >> " +
                  calculatedEndDate;
                return o;
              }
            }),
          },
          () => {
            console.log(this.state.selectOrderSheet);
          }
        );
      })
      .catch((err) => console.log(err));

    getAllResources()
      .then((Response) => {
        this.setState({ selectResources: Response });
        this.state.selectResources.map((obj) => {
          obj.value = obj.id;
          obj.label = obj.firstName + " " + obj.lastName;
          return obj;
        });
      })
      .catch((err) => console.log(err));

    let holidaysName = {
      FATIH_MUHARRAM: "1er Moharram",
      GREEN_MARCH_DAY: "Anniversaire de la Marche Verte",
      EID_AL_MAWLED: "Aïd Al Mawlid",
      EID_AL_MAWLED_HOLIDAY: "Aïd Al Mawlid 2éme jour",
      INDEPENDENCE_DAY: "Fête de l'indépendance",
      NEW_YEAR_DAY: "Nouvel an",
      PROCLAMATION_OF_INDEPENDENCE: "Manifeste de l'indépendance",
      LABOUR_DAY: "Fête du travail",
      EID_SGHIR: "Aïd Al Fitr",
      EID_SGHIR_HOLIDAY: "Aïd Al Fitr 2éme jour",
      ENTHRONEMENT: "Fête du Trône",
      EID_KBIR: "Aïd Al Adha",
      EID_KBIR_HOLIDAY: "Aïd Al Adha 2éme jour",
      OUED_EDDAHAB_DAY: "Libération de Oued Eddahab",
      REVOLUTION_DAY: "Révolution du Roi et du peuple",
      YOUTH_DAY: "Fête de la Jeunesse",
    };
    getAllHolidays()
      .then((Response) => {
        let holidays = Response.map(function (entry) {
          let holiday = {
            id: "",
            name: "",
            startDate: "",
            endDate: "",
          };
          holiday.id = entry.id;
          holiday.name = holidaysName[entry.name];
          holiday.startDate = new Date(entry.date);
          holiday.endDate = new Date(entry.date);
          return holiday;
        });
        this.setState({ holidays: holidays });
      })
      .catch((err) => this.setState({ errors: { message: err.message } }));
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    console.log("month : ", e.target.value);
  }

  onChangeSelectOrderSheet(e) {
    this.setState({ orderSheet: e }, () => {
      this.getWeeks(this.state.startDate, this.state.endDate);
    });
    this.onChooseOrderSheet(e);
  }

  onChooseOrderSheet(e) {
    if (this.state.selectOrderSheet.length > 1) {
      let listOrderSheet = this.state.selectOrderSheet.filter((o) => {
        if (
          o.endDate !== null &&
          o.startDate !== null &&
          o.remainingReportedDays > 0
        )
          return o;
      });
      if (
        listOrderSheet.length !== 0 &&
        (e.endDate === null || e.startDate === null)
      ) {
        this.setState({
          formWarning: {
            orderSheet:
              "Veuillez choisir le BC dont les dates début et fin sont spécifiés",
          },
        });
      } else if (e === "") {
        this.setState({
          formErrors: {
            orderSheet: "Veuillez selectionner une bc",
          },
        });
      } else {
        this.setState({
          formErrors: {
            orderSheet: "",
          },
        });
      }
    }
  }

  onChangeSelectResource(e) {
    this.setState({ resource: e, orderSheet: "" }, () => {
      getActiveOrderSheets(this.state.resource.id)
        .then((Response) => {
          this.setState({ resourceOrderSheets: Response });
        })
        .catch();
    });
  }

  onChangeDates(e) {
    var dateFormat = require("dateformat");
    this.setState((prevState) => ({
      formErrors: {
        ...prevState.formErrors,
        orderSheet: "",
        endDate: "",
        startDate: "",
      },
      formWarning: {
        ...prevState.formWarning,
        orderSheet: "",
      },
    }));
    if (e.target.id === "endDate" && this.state.startDate === "")
      this.setState((prevState) => ({
        formErrors: {
          ...prevState.formErrors,
          endDate: "Vous devez seléctionner la date de début",
        },
      }));
    else if (e.target.id === "startDate" && this.state.endDate === "")
      this.setState({
        startDate: e.target.value,
        weekStartOfstartDate: this.getMonday(e.target.value),
      });
    else if (
      (e.target.id === "startDate" && e.target.value > this.state.endDate) ||
      (e.target.id === "endDate" && e.target.value < this.state.startDate)
    )
      this.setState((prevState) => ({
        formErrors: {
          ...prevState.formErrors,
          endDate: "La date de fin doit dépasser la date de début",
        },
      }));
    else {
      this.setState(
        {
          startDate:
            e.target.id === "startDate" ? e.target.value : this.state.startDate,
          endDate:
            e.target.id !== "startDate" ? e.target.value : this.state.endDate,
          weekStartOfstartDate:
            e.target.id === "startDate"
              ? this.getMonday(e.target.value)
              : this.state.weekStartOfstartDate,
        },
        () => {
          let sDate, eDate, sDateInput, eDateInput;
          let eDates = [];
          let sDates = [];
          this.getWeeks(this.state.startDate, this.state.endDate);
          this.setState(
            {
              orderSheet: "",
              selectOrderSheet: this.state.resourceOrderSheets.filter((o) => {
                eDates = [];
                sDates = [];
                sDateInput = Date.parse(this.state.startDate);
                eDateInput = Date.parse(this.state.endDate);
                sDate = Date.parse(dateFormat(o.startDate, "yyyy-mm-dd"));
                eDate = Date.parse(dateFormat(o.endDate, "yyyy-mm-dd"));
                eDates.push(eDate);
                eDates.push(eDateInput);
                const minDate = new Date(Math.min(...eDates));
                sDates.push(sDate);
                sDates.push(sDateInput);
                const maxDate = new Date(Math.max(...sDates));
                if (o.startDate === null || o.endDate === null) {
                  o.value = o.id;
                  o.label = o.externalReferenceNumber;
                  return o;
                } else if (minDate > maxDate) {
                  o.value = o.id;
                  let statDate =
                    o.startDate !== null
                      ? dateFormat(
                          new Date(
                            new Date(o.startDate).setHours(
                              new Date(o.startDate).getHours() + 1
                            )
                          ),
                          "dd-mm-yyyy"
                        )
                      : " ";
                  let endDate =
                    o.endDate !== null
                      ? dateFormat(
                          new Date(
                            new Date(o.endDate).setHours(
                              new Date(o.endDate).getHours() + 1
                            )
                          ),
                          "dd-mm-yyyy"
                        )
                      : " ";
                  let calculatedEndDate =
                    o.calculatedEndDate !== null
                      ? dateFormat(
                          new Date(
                            new Date(o.calculatedEndDate).setHours(
                              new Date(o.calculatedEndDate).getHours() + 1
                            )
                          ),
                          "dd-mm-yyyy"
                        )
                      : " ";
                  o.label = o.externalReferenceNumber;
                  return o;
                }
              }),
            },
            () => {
              let orderSheets = this.state.selectOrderSheet.filter((o) => {
                sDateInput = Date.parse(this.state.startDate);
                eDateInput = Date.parse(this.state.endDate);
                sDate = Date.parse(dateFormat(o.startDate, "yyyy-mm-dd"));
                eDate = Date.parse(dateFormat(o.endDate, "yyyy-mm-dd"));
                if (sDate <= sDateInput && eDateInput <= eDate) return o;
              });
              if (this.state.selectOrderSheet.length === 0)
                this.setState((prevState) => ({
                  formErrors: {
                    ...prevState.formErrors,
                    orderSheet:
                      "La période saisie ne correspond à aucun bon de commande",
                  },
                }));
              else if (orderSheets.length === 0)
                this.setState((prevState) => ({
                  formWarning: {
                    ...prevState.formWarning,
                    orderSheet:
                      "Veuillez diviser la période saisie sur les bons de commande",
                  },
                }));
            }
          );
        }
      );
    }
  }

  onChangeDate(e) {
    var dateFormat = require("dateformat");
    let sDate, eDate, sDateInput, eDateInput;
    let eDates = [];
    let sDates = [];
    this.setState({
      selectOrderSheet: [],
    });
    this.state.formWarning.orderSheet = "";
    if (e.target.id === "startDate") {
      // this.setState({
      //   startDate: e.target.value,
      //   weekStartOfstartDate: this.getMonday(e.target.value),
      // });
      if (this.state.endDate !== "") {
        if (e.target.value < this.state.endDate) {
          let formErrors = this.state.formErrors;
          formErrors.endDate = "La date de fin doit dépasser la date de début";
          this.setState({ formErrors });
        } else {
          let formErrors = this.state.formErrors;
          formErrors.endDate = "";
          this.setState(
            {
              startDate: e.target.value,
              weekStartOfstartDate: this.getMonday(e.target.value),
              formErrors,
            },
            () => {
              this.getWeeks(this.state.startDate, this.state.endDate);
            }
          );
          this.setState(
            {
              orderSheet: "",
              selectOrderSheet: this.state.resourceOrderSheets.filter((o) => {
                eDates = [];
                sDates = [];
                sDate = Date.parse(o.startDate);
                eDate = Date.parse(o.endDate);
                sDateInput = Date.parse(this.state.startDate);
                eDateInput = Date.parse(e.target.value);
                eDates.push(eDate);
                eDates.push(eDateInput);
                const minDate = new Date(Math.min(...eDates));
                sDates.push(sDate);
                sDates.push(sDateInput);
                const maxDate = new Date(Math.max(...sDates));
                if (isNaN(sDate) || isNaN(eDate)) {
                  console.log(o);
                  o.value = o.id;
                  o.label =
                    o.company.name +
                    " => " +
                    o.externalReferenceNumber +
                    " " +
                    " (" +
                    o.remainingReportedDays +
                    ")";
                  return o;
                } else if (minDate > maxDate) {
                  console.log(o);
                  o.value = o.id;
                  let statDate =
                    o.startDate !== null
                      ? dateFormat(
                          new Date(
                            new Date(o.startDate).setHours(
                              new Date(o.startDate).getHours() + 1
                            )
                          ),
                          "dd-mm-yyyy"
                        )
                      : " ";
                  let endDate =
                    o.endDate !== null
                      ? dateFormat(
                          new Date(
                            new Date(o.endDate).setHours(
                              new Date(o.endDate).getHours() + 1
                            )
                          ),
                          "dd-mm-yyyy"
                        )
                      : " ";
                  let calculatedEndDate =
                    o.calculatedEndDate !== null
                      ? dateFormat(
                          new Date(
                            new Date(o.calculatedEndDate).setHours(
                              new Date(o.calculatedEndDate).getHours() + 1
                            )
                          ),
                          "dd-mm-yyyy"
                        )
                      : " ";
                  o.label =
                    o.company.name +
                    " => " +
                    o.externalReferenceNumber +
                    " " +
                    statDate +
                    " | " +
                    endDate +
                    " (" +
                    o.remainingReportedDays +
                    ") >> " +
                    calculatedEndDate;
                  return o;
                }
              }),
            },
            () => {
              let orderSheets = this.state.selectOrderSheet.filter((o) => {
                if (sDate <= sDateInput && eDateInput <= eDate) return o;
              });
              if (orderSheets.length === 0) {
                let formWarning = this.state.formWarning;
                formWarning.orderSheet =
                  "Veuillez diviser la période saisie sur les bons de commande";
                this.setState({ formWarning });
              } else if (this.state.selectOrderSheet.length === 0) {
                let formErrors = this.state.formErrors;
                formErrors.orderSheet =
                  "La période saisie ne correspond à aucun bon de commande";
                this.setState({ formErrors });
              } else {
                let formErrors = this.state.formErrors;
                formErrors.orderSheet = "";
                this.setState({ formErrors });
              }
            }
          );
        }
      } else {
        this.setState({
          startDate: e.target.value,
          weekStartOfstartDate: this.getMonday(e.target.value),
        });
      }
    } else {
      if (this.state.startDate !== "") {
        if (e.target.value < this.state.startDate) {
          let formErrors = this.state.formErrors;
          formErrors.endDate = "La date de fin doit dépasser la date de début";
          this.setState({ formErrors });
        } else {
          let formErrors = this.state.formErrors;
          formErrors.endDate = "";
          this.setState({ endDate: e.target.value, formErrors }, () => {
            this.getWeeks(this.state.startDate, this.state.endDate);
          });
          this.setState(
            {
              orderSheet: "",
              selectOrderSheet: this.state.resourceOrderSheets.filter((o) => {
                eDates = [];
                sDates = [];
                sDate = Date.parse(o.startDate);
                eDate = Date.parse(o.endDate);
                sDateInput = Date.parse(this.state.startDate);
                eDateInput = Date.parse(e.target.value);
                eDates.push(eDate);
                eDates.push(eDateInput);
                const minDate = new Date(Math.min(...eDates));
                sDates.push(sDate);
                sDates.push(sDateInput);
                const maxDate = new Date(Math.max(...sDates));
                if (isNaN(sDate) || isNaN(eDate)) {
                  o.value = o.id;
                  o.label =
                    o.company.name +
                    " => " +
                    o.externalReferenceNumber +
                    " " +
                    " (" +
                    o.remainingReportedDays +
                    ")";
                  return o;
                } else if (minDate > maxDate) {
                  o.value = o.id;
                  let statDate =
                    o.startDate !== null
                      ? dateFormat(
                          new Date(
                            new Date(o.startDate).setHours(
                              new Date(o.startDate).getHours() + 1
                            )
                          ),
                          "dd-mm-yyyy"
                        )
                      : " ";
                  let endDate =
                    o.endDate !== null
                      ? dateFormat(
                          new Date(
                            new Date(o.endDate).setHours(
                              new Date(o.endDate).getHours() + 1
                            )
                          ),
                          "dd-mm-yyyy"
                        )
                      : " ";
                  let calculatedEndDate =
                    o.calculatedEndDate !== null
                      ? dateFormat(
                          new Date(
                            new Date(o.calculatedEndDate).setHours(
                              new Date(o.calculatedEndDate).getHours() + 1
                            )
                          ),
                          "dd-mm-yyyy"
                        )
                      : " ";
                  o.label =
                    o.company.name +
                    " => " +
                    o.externalReferenceNumber +
                    " " +
                    statDate +
                    " | " +
                    endDate +
                    " (" +
                    o.remainingReportedDays +
                    ") >> " +
                    calculatedEndDate;
                  return o;
                }
              }),
            },
            () => {
              let orderSheets = this.state.selectOrderSheet.filter((o) => {
                if (sDate <= sDateInput && eDateInput <= eDate) return o;
              });
              if (orderSheets.length === 0) {
                let formWarning = this.state.formWarning;
                formWarning.orderSheet =
                  "Veuillez diviser la période saisie sur les bons de commande";
                this.setState({ formWarning });
              } else if (this.state.selectOrderSheet.length === 0) {
                let formErrors = this.state.formErrors;
                formErrors.orderSheet =
                  "La période saisie ne correspond à aucun bon de commande";
                this.setState({ formErrors });
              } else {
                let formErrors = this.state.formErrors;
                formErrors.orderSheet = "";
                this.setState({ formErrors });
              }
            }
          );
        }
      } else {
        let formErrors = this.state.formErrors;
        formErrors.endDate = "Vous devez seléctionner la date de début";
        this.setState({ formErrors });
      }
    }
  }

  onFileChange(event) {
    this.setState(
      {
        file: event.target.files[0],
      },
      () => {
        console.log(this.state.file);
      }
    );
  }

  openModal() {
    this.setState({ open: true });
  }
  closeModal() {
    this.setState({ open: false });
  }

  onAttachmentChange(event) {
    this.setState({
      attachment: event.target.files[0],
    });
  }

  validateForm() {
    let { startDate, endDate, month, orderSheet, file } = this.state;

    let formErrors = this.state.formErrors;

    formErrors.month = month.length > 0 ? "" : "Veuillez selectiooner un mois";
    // formErrors.year = year.length > 0 ? "" : "Veuillez entrer l'année'";
    formErrors.startDate =
      startDate.length > 0 ? "" : "Veuillez entrer la date de début";
    formErrors.endDate =
      endDate.length > 0 ? "" : "Veuillez entrer la date de fin";
    formErrors.file = file === "" ? "Veuillez uploader le ficher du cra" : "";

    formErrors.orderSheet =
      orderSheet === "" ? "Veuillez selectionner une bc" : "";

    if (
      formErrors.startDate === "" &&
      formErrors.endDate === "" &&
      startDate > endDate
    ) {
      formErrors.endDate =
        "La date de fin ne peut pas être inférieure au date de début";
    }

    // if (this.state.selectOrderSheet.length > 1) {
    //   let listOrderSheet = this.state.selectOrderSheet.filter((o) => {
    //     if (
    //       o.endDate !== null &&
    //       o.startDate !== null &&
    //       o.remainingReportedDays > 0
    //     )
    //       return o;
    //   });
    //   if (
    //     listOrderSheet.length !== 0 &&
    //     (orderSheet.endDate === null || orderSheet.startDate === null)
    //   ) {
    //     formErrors.orderSheet =
    //       "Veuillez choisir le BC dont les dates début et fin sont spécifiés";
    //   }
    // }

    console.log(formErrors);

    this.setState({
      formErrors,
    });

    let emptyFormErrors = {
      startDate: "",
      endDate: "",
      month: "",
      // year: "",
      orderSheet: "",
      file: "",
    };

    return Object.keys(emptyFormErrors).every(
      (key) => emptyFormErrors[key] === formErrors[key]
    );
  }

  getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1);
    return new Date(d.setDate(diff));
  }

  getSunday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + 7;
    return new Date(d.setDate(diff));
  }

  getDates(startDate, stopDate) {
    var dateArray = [];
    var currentDate = moment(startDate);
    var stopDate = moment(stopDate);
    while (currentDate <= stopDate) {
      dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
      currentDate = moment(currentDate).add(1, "days");
    }
    return dateArray;
  }

  weeksBetween(d1, d2) {
    return Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000));
  }

  setDays(days) {
    this.setState({ days });
  }

  getWeeks(start, end) {
    var weekArray = [];
    let count = 0;
    let startDay, endDay, limitDay;
    monDisabledCells = [];
    tueDisabledCells = [];
    wedDisabledCells = [];
    thuDisabledCells = [];
    friDisabledCells = [];
    satDisabledCells = [];
    sunDisabledCells = [];

    var nbrWeeks = this.weeksBetween(
      this.getMonday(start),
      this.getSunday(this.getSunday(end))
    );

    startDay = Date.parse(start);
    endDay = Date.parse(end);
    limitDay = Date.parse(this.state.orderSheet.endDate);

    var weeks = [];
    var startMonth = moment(this.getMonday(start));

    for (var i = 0; i < nbrWeeks - 1; i++) {
      var end = startMonth.clone();
      var startWeek = startMonth.clone();
      end.add(6, "days");

      weeks.push({
        start: startMonth.toDate(),
        end: end.toDate(),
      });

      count++;
      if (
        Date.parse(startMonth) < startDay ||
        endDay < Date.parse(startMonth) ||
        limitDay < Date.parse(startMonth)
      ) {
        monDisabledCells.push({
          week:
            startWeek.format("YYYY-MM-DD") + " | " + end.format("YYYY-MM-DD"),
        });
      } else {
        let nonWorkingDays = this.state.holidays.filter((hol) => {
          if (Date.parse(hol.startDate) === Date.parse(startMonth)) return hol;
        });
        if (nonWorkingDays.length !== 0) holidaysCells.push(count);
      }
      startMonth.add(1, "days");
      count++;
      if (
        Date.parse(startMonth) < startDay ||
        endDay < Date.parse(startMonth) ||
        limitDay < Date.parse(startMonth)
      ) {
        tueDisabledCells.push({
          week:
            startWeek.format("YYYY-MM-DD") + " | " + end.format("YYYY-MM-DD"),
        });
      } else {
        let nonWorkingDays = this.state.holidays.filter((hol) => {
          if (Date.parse(hol.startDate) === Date.parse(startMonth)) return hol;
        });
        if (nonWorkingDays.length !== 0) holidaysCells.push(count);
      }
      startMonth.add(1, "days");
      count++;
      if (
        Date.parse(startMonth) < startDay ||
        endDay < Date.parse(startMonth) ||
        limitDay < Date.parse(startMonth)
      ) {
        wedDisabledCells.push({
          week:
            startWeek.format("YYYY-MM-DD") + " | " + end.format("YYYY-MM-DD"),
        });
      } else {
        let nonWorkingDays = this.state.holidays.filter((hol) => {
          if (Date.parse(hol.startDate) === Date.parse(startMonth)) return hol;
        });
        if (nonWorkingDays.length !== 0) holidaysCells.push(count);
      }
      startMonth.add(1, "days");
      count++;
      if (
        Date.parse(startMonth) < startDay ||
        endDay < Date.parse(startMonth) ||
        limitDay < Date.parse(startMonth)
      ) {
        thuDisabledCells.push({
          week:
            startWeek.format("YYYY-MM-DD") + " | " + end.format("YYYY-MM-DD"),
        });
      }

      let nonWorkingDays = this.state.holidays.filter((hol) => {
        if (
          Date.parse(hol.startDate) ===
          Date.parse(startMonth.format("MMM-DD-YYYY"))
        )
          return hol;
      });
      if (nonWorkingDays.length !== 0) holidaysCells.push(count);
      startMonth.add(1, "days");
      count++;
      if (
        Date.parse(startMonth) < startDay ||
        endDay < Date.parse(startMonth) ||
        limitDay < Date.parse(startMonth)
      ) {
        friDisabledCells.push({
          week:
            startWeek.format("YYYY-MM-DD") + " | " + end.format("YYYY-MM-DD"),
        });
      } else {
        let nonWorkingDays = this.state.holidays.filter((hol) => {
          if (Date.parse(hol.startDate) === Date.parse(startMonth)) return hol;
        });
        if (nonWorkingDays.length !== 0) holidaysCells.push(count);
      }
      startMonth.add(1, "days");
      count++;
      if (
        Date.parse(startMonth) < startDay ||
        endDay < Date.parse(startMonth) ||
        limitDay < Date.parse(startMonth)
      ) {
        satDisabledCells.push({
          week:
            startWeek.format("YYYY-MM-DD") + " | " + end.format("YYYY-MM-DD"),
        });
      } else {
        let nonWorkingDays = this.state.holidays.filter((hol) => {
          if (Date.parse(hol.startDate) === Date.parse(startMonth)) return hol;
        });
        if (nonWorkingDays.length !== 0) holidaysCells.push(count);
      }
      startMonth.add(1, "days");
      count++;
      if (
        Date.parse(startMonth) < startDay ||
        endDay < Date.parse(startMonth) ||
        limitDay < Date.parse(startMonth)
      ) {
        sunDisabledCells.push({
          week:
            startWeek.format("YYYY-MM-DD") + " | " + end.format("YYYY-MM-DD"),
        });
      } else {
        let nonWorkingDays = this.state.holidays.filter((hol) => {
          if (Date.parse(hol.startDate) === Date.parse(startMonth)) return hol;
        });
        if (nonWorkingDays.length !== 0) holidaysCells.push(count);
      }
      startMonth.add(1, "days");
      count++;

      weekArray.push({
        week: startWeek.format("YYYY-MM-DD") + " | " + end.format("YYYY-MM-DD"),
        mon: 0,
        tue: 0,
        wed: 0,
        thu: 0,
        fri: 0,
        sat: 0,
        sun: 0,
      });
    }

    this.setState({
      rowsWeek: weekArray,
      comments: Array(weekArray.length).fill(""),
    });

    console.log("NUMBER OF WEEKS", weekArray.length);

    return weeks;
  }

  clearData() {
    this.state.rowsWeek.map((week) => {
      week.mon = 0;
      week.tue = 0;
      week.wed = 0;
      week.thu = 0;
      week.fri = 0;
      week.sat = 0;
      week.sun = 0;
      return week;
    });
    this.setState({ errors: { message: "" } });
    this.setState(
      {
        sumWorkingDays: 0,
        enableCellSelect: true,
        days: [],
      },
      () => {
        this.getWeeks(this.state.startDate, this.state.endDate);
      }
    );
  }

  downloadActivityReportFile() {
    // getActivityReportFile().then().catch();

    let orderSheet = Object.assign({}, this.state.orderSheet);

    const CRA = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      workingDaysCount: this.state.workingDaysCount,
      month: this.state.month,
      year: this.state.year,
      createdAt: this.state.createdAt,
      updatedAt: this.state.updatedAt,
      orderSheet: orderSheet,
      activityReportData: { days: this.state.days },
    };

    getActivityReportFileByBody(CRA)
      .then((response) => response.blob())
      .then(function (myBlob) {
        const url = window.URL.createObjectURL(myBlob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "cra.pdf");
        document.body.appendChild(link);
        link.click();
      });
  }

  validatePhoneNumber(phone) {
    var regexPhone = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
    return regexPhone.test(phone);
  }

  validateFloatNumber(value) {
    var regexPhone = /[+-]?([0-9]*[.])?[0-9]+/g;
    let maxCondition = true;
    this.setState({ errors: { message: "" } });
    // if (Number(value) > 1) {
    //   this.setState({
    //     errors: { message: "La valeur max est 1 car l'unité est en jours" },
    //   });
    //   maxCondition = false;
    // }
    return regexPhone.test(value) && maxCondition;
  }

  onGridRowsUpdated = ({ fromRow, toRow, updated, cellKey }) => {
    console.log("onGridRowsUpdated");
    console.log(fromRow);
    console.log(toRow);
    console.log(updated);
    console.log(cellKey);
    console.log("Errors ==> ", this.state.errors);
    if (cellKey === "rem") {
      if (fromRow !== toRow) return null;
      this.setState(
        (prevState) => {
          const rowsWeek = prevState.rowsWeek.slice();
          for (let i = fromRow; i <= toRow; i++) {
            rowsWeek[i] = { ...rowsWeek[i], ...updated };
          }
          return { rowsWeek };
        },
        () => {
          console.log(this.state.rowsWeek);
        }
      );
      this.setState((prevState) => {
        console.log(prevState.comments);
        const comments = update(prevState.comments, {
          [fromRow]: { $set: updated.rem },
        });
        return { comments };
      });
      return null;
    }
    if (this.validateFloatNumber(updated[cellKey])) {
      if (fromRow !== toRow) {
        let nb = toRow - fromRow + 1;
        let val = Number(updated[cellKey]) * nb;
        if (
          this.state.sumWorkingDays + val >
          this.state.orderSheet.remainingReportedDays
        ) {
          let msg =
            "Veuillez saisir une quantité qui est inférieur à " +
            this.state.orderSheet.remainingReportedDays;
          this.setState({ errors: { message: msg } });
          toRow = fromRow;
        }
      }
      if (
        this.state.sumWorkingDays + Number(updated[cellKey]) >
        this.state.orderSheet.remainingReportedDays
      ) {
        updated[cellKey] =
          this.state.orderSheet.remainingReportedDays -
          this.state.sumWorkingDays;
        let msg =
          "La quantité doit étre inférieur à " +
          this.state.orderSheet.remainingReportedDays +
          " qui vous reste dans le Bon de commande";
        this.setState({ errors: { message: msg } });
      }
      this.setState(
        (prevState) => {
          const rowsWeek = prevState.rowsWeek.slice();
          for (let i = fromRow; i <= toRow; i++) {
            rowsWeek[i] = { ...rowsWeek[i], ...updated };
          }
          return { rowsWeek };
        },
        () => {
          let sumWorkingDays = 0;
          for (let i = 0; i < this.state.rowsWeek.length; i++) {
            sumWorkingDays += Number(this.state.rowsWeek[i].mon);
            sumWorkingDays += Number(this.state.rowsWeek[i].tue);
            sumWorkingDays += Number(this.state.rowsWeek[i].wed);
            sumWorkingDays += Number(this.state.rowsWeek[i].thu);
            sumWorkingDays += Number(this.state.rowsWeek[i].fri);
            sumWorkingDays += Number(this.state.rowsWeek[i].sat);
            sumWorkingDays += Number(this.state.rowsWeek[i].sun);
          }
          this.setState({ sumWorkingDays }, () => {
            if (this.state.orderSheet.remainingReportedDays <= sumWorkingDays) {
              this.setState({
                enableCellSelect: false,
                errors: {
                  message:
                    "Vous avez consommé toute la quantité de ce bon de commande",
                },
              });
            }
          });

          var startDate = this.state.startDate;

          var days = [];

          var weekStartOfstartDate = this.state.weekStartOfstartDate;

          for (let i = 0; i < this.state.rowsWeek.length; i++) {
            days.push(
              {
                date: weekStartOfstartDate,
                numberHoursWorked: Number(this.state.rowsWeek[i].mon),
              },
              {
                date: moment(weekStartOfstartDate).add(1, "days").toDate(),
                numberHoursWorked: Number(this.state.rowsWeek[i].tue),
              },
              {
                date: moment(weekStartOfstartDate).add(2, "days").toDate(),
                numberHoursWorked: Number(this.state.rowsWeek[i].wed),
              },
              {
                date: moment(weekStartOfstartDate).add(3, "days").toDate(),
                numberHoursWorked: Number(this.state.rowsWeek[i].thu),
              },
              {
                date: moment(weekStartOfstartDate).add(4, "days").toDate(),
                numberHoursWorked: Number(this.state.rowsWeek[i].fri),
              },
              {
                date: moment(weekStartOfstartDate).add(5, "days").toDate(),
                numberHoursWorked: Number(this.state.rowsWeek[i].sat),
              },
              {
                date: moment(weekStartOfstartDate).add(6, "days").toDate(),
                numberHoursWorked: Number(this.state.rowsWeek[i].sun),
              }
            );

            weekStartOfstartDate = moment(weekStartOfstartDate)
              .add(7, "days")
              .toDate();
          }
          this.setState({ days });
          //   console.log(days);
        }
      );
    }
  };

  onSubmit(e) {
    e.preventDefault();

    let orderSheet = Object.assign({}, this.state.orderSheet);

    console.log("comments", this.state.comments);

    const CRA = {
      startDate: this.state.startDate + "T00:00:00",
      endDate: this.state.endDate + "T00:00:00",
      workingDaysCount: this.state.sumWorkingDays,
      month: this.state.month.substring(5, 7),
      year: this.state.month.substring(0, 4),
      createdAt: this.state.createdAt,
      updatedAt: this.state.updatedAt,
      orderSheet: orderSheet,
      activityReportData: {
        days: this.state.days,
        comments: this.state.comments,
      },
    };

    console.log("comments", this.state.comments);

    console.log(CRA);

    delete CRA.orderSheet.value;
    delete CRA.orderSheet.label;

    const CRATxt = JSON.stringify(CRA);
    let data = new FormData();
    console.log(this.state.file);
    data.append("file", this.state.file);
    data.append("attachment", this.state.attachment);
    data.append("activityReport", CRATxt);

    console.log(CRATxt);

    if (this.validateForm()) {
      addActivityReport(data)
        .then((res) => this.props.history.push("/activity-report"))
        .catch((err) => this.setState({ errors: { message: err.message } }));
    }
  }

  alert(message) {
    return (
      <div className="alert alert-danger" role="alert">
        {message}
      </div>
    );
  }

  _next = () => {
    if (this.validateForm()) {
      let currentStep = this.state.currentStep;
      currentStep = currentStep >= 1 ? 2 : currentStep + 1;
      this.setState({
        currentStep: currentStep,
        sumWorkingDays: 0,
      });
      this.onGridRowsUpdated({
        fromRow: "0",
        toRow: "0",
        updated: { sum: "0" },
        cellKey: "sum",
      });
    }
  };

  _prev = () => {
    let currentStep = this.state.currentStep;
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep: currentStep,
      sumWorkingDays: 0,
    });
  };

  previousButton() {
    let currentStep = this.state.currentStep;
    if (currentStep !== 1) {
      return (
        <button
          type="button"
          className="btn btn-block mt-4 col-md-2 float-left"
          style={{
            color: "#ffffff",
            background: "rgba(95, 66, 253, 0.84)",
          }}
          onClick={this._prev}
        >
          Previous
        </button>
      );
    }
    return null;
  }

  nextButton() {
    let currentStep = this.state.currentStep;
    if (currentStep < 2) {
      return (
        <button
          style={{
            color: "#ffffff",
            background: "rgba(95, 66, 253, 0.84)",
          }}
          className="btn btn-block mt-4 col-md-2 float-right"
          type="button"
          onClick={this._next}
        >
          Next
        </button>
      );
    }
    return null;
  }

  render() {
    var dateFormat = require("dateformat");
    return (
      <React.Fragment>
        {/*Page Title*/}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title" style={{ color: "#ffffff" }}>
                    CRAs
                  </h2>
                  {/* <p>Fresh From The Press Discription</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*End Page Title*/}
        {/*Sidebar Page Container*/}
        <div
          className="service-area ptb--80  bg_image bg_image--3"
          style={{ paddingTop: "200px", paddingBottom: "200px" }}
        >
          <div className="container">
            <div>
              <Card>
                <CardHeader>
                  <h3>Ajouter Cra</h3>
                </CardHeader>
                <CardBody>
                  <div>
                    {" "}
                    <Step1
                      currentStep={this.state.currentStep}
                      state={this.state}
                      onChangeDate={this.onChangeDates}
                      onChange={this.onChange}
                      onChangeSelectResource={this.onChangeSelectResource}
                      onChangeSelectOrderSheet={this.onChangeSelectOrderSheet}
                      onFileChange={this.onFileChange}
                      openModal={this.openModal}
                      closeModal={this.closeModal}
                      onAttachmentChange={this.onAttachmentChange}
                    />
                  </div>

                  <Step2
                    currentStep={this.state.currentStep}
                    state={this.state}
                    onGridRowsUpdated={this.onGridRowsUpdated}
                    enableCellSelect={this.state.enableCellSelect}
                    onSubmit={this.onSubmit}
                    clearData={this.clearData}
                    downloadActivityReportFile={this.downloadActivityReportFile}
                  />
                  {this.previousButton()}
                  {this.nextButton()}
                </CardBody>
              </Card>
            </div>{" "}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function Step1(props) {
  //   const alert = useAlert();
  if (props.currentStep !== 1) {
    return null;
  }

  return (
    <div>
      {" "}
      {props.state.formWarning.orderSheet === "" ? null : (
        <div className="alert alert-warning" role="alert">
          {" "}
          {props.state.formWarning.orderSheet}
        </div>
      )}
      <form onSubmit={props.onSubmit}>
        <OrderSheetDetails
          id="taskModal"
          orderSheet={props.state.orderSheet}
          close={() => props.closeModal()}
          taskModal={props.state.open}
        />
        {/* <FormGroup id="resource">
              <Label for="resource">Ressource </Label>
              <Select
                value={props.state.resource}
                placeholder="Select ressource"
                name="resource"
                options={props.state.selectResources}
                classNamePrefix="select"
                onChange={props.onChangeSelectResource}
              />
              <FormText color="danger">
                {props.state.formErrors.resource}
              </FormText>
            </FormGroup> */}

        <FormGroup>
          <Label for="startDate">Date début</Label>
          <Input
            id="startDate"
            name="startDate"
            type="date"
            onChange={props.onChangeDate}
            value={props.state.startDate}
          />
          <FormText color="danger">{props.state.formErrors.startDate}</FormText>
        </FormGroup>

        <FormGroup>
          <Label for="endDate">Date fin</Label>
          <Input
            id="endDate"
            name="endDate"
            type="date"
            onChange={props.onChangeDate}
            value={props.state.endDate}
            onClick={props.onFocusSelectOrderSheet}
          />
          <FormText color="danger">{props.state.formErrors.endDate}</FormText>
        </FormGroup>

        <FormGroup id="orderSheet">
          <Label for="orderSheet">Bon de commande </Label>
          <Row>
            <Col className="col-11">
              <Select
                value={props.state.orderSheet}
                placeholder="Select Ordre Sheet"
                name="orderSheet"
                options={props.state.selectOrderSheet}
                classNamePrefix="orderSheet"
                onChange={props.onChangeSelectOrderSheet}
              />
            </Col>
            <Col className="col-1" style={{ padding: "0px" }}>
              <Button
                className="btn btn-success"
                onClick={() =>
                  props.state.orderSheet !== "" ? props.openModal() : null
                }
              >
                <FiZoomIn />
              </Button>
            </Col>
          </Row>
          <FormText color="danger">
            {props.state.formErrors.orderSheet}
          </FormText>
          {/* <FormText color="warning">
            {props.state.formWarning.orderSheet}
          </FormText> */}
        </FormGroup>

        <FormGroup>
          <Label for="month">Année/ Mois</Label>
          <Input
            id="month"
            type="month"
            name="month"
            onChange={props.onChange}
            value={props.state.month}
          />
          <FormText color="danger">{props.state.formErrors.month}</FormText>
        </FormGroup>

        {/* <FormGroup>
          <Label for="file">Fichier</Label>
          <input
            id="file"
            type="file"
            name="file"
            className="form-control form-control-lg"
            onChange={props.onFileChange}
          />
          <FormText color="danger">{props.state.formErrors.file}</FormText>
        </FormGroup>

        <FormGroup>
          <Label for="attachment">Justificatif</Label>
          <input
            id="attachment"
            type="file"
            name="attachment"
            className="form-control form-control-lg"
            onChange={props.onAttachmentChange}
          />
          <FormText color="danger">
            {props.state.formErrors.attachment}
          </FormText>
        </FormGroup> */}

        <FormGroup>
          <Label for="file">Fichier</Label>
          <div className="custom-file" style={{ zIndex: 0 }}>
            <input
              id="file"
              type="file"
              onChange={props.onFileChange}
              className="custom-file-input"
            />
            <label className="custom-file-label">
              {props.state.file !== ""
                ? props.state.file.name
                : "Aucun fichier sélectionné"}
            </label>
          </div>
          <FormText color="danger">{props.state.formErrors.resume}</FormText>
        </FormGroup>

        <FormGroup>
          <Label for="attachment">Justificatif</Label>
          <div className="custom-file" style={{ zIndex: 0 }}>
            <input
              id="attachment"
              type="file"
              onChange={props.onAttachmentChange}
              className="custom-file-input"
            />
            <label className="custom-file-label">
              {props.state.attachment !== ""
                ? props.state.attachment.name
                : "Aucun fichier sélectionné"}
            </label>
          </div>
          <FormText color="danger">{props.state.formErrors.resume}</FormText>
        </FormGroup>

        {/* <input
        type="submit"
        className="btn btn-success btn-block mt-4 col-md-2 float-right"
        value="Valider"
      /> */}
      </form>
    </div>
  );
}

function Step2(props) {
  if (props.currentStep !== 2) {
    return null;
  }
  console.log(props);
  return (
    <div>
      <p style={{ float: "right" }}>
        <span className="badge badge-light">SOMME</span> :{" "}
        {props.state.sumWorkingDays}
      </p>
      <p style={{ float: "left" }}>
        <span className="badge badge-light">Le reste du bon de commande</span> :{" "}
        {props.state.orderSheet.remainingReportedDays}
      </p>
      <ReactDataGrid
        columns={columnsWeek}
        rowGetter={(i) => props.state.rowsWeek[i]}
        rowsCount={10}
        onGridRowsUpdated={props.onGridRowsUpdated}
        enableCellSelect={props.enableCellSelect}
      />
      <input
        type="button"
        onClick={props.onSubmit}
        className="btn btn-success btn-block mt-4 col-md-2 float-right"
        value="Valider"
      />
      <input
        type="button"
        onClick={props.clearData}
        className="btn btn-warning btn-block mt-4 col-md-2 float-right"
        value="clear"
      />
      <input
        style={{ width: "80%" }}
        type="button"
        onClick={props.downloadActivityReportFile}
        className="btn btn-primary btn-block mt-4 col-md-2 float-right"
        value="Télécharger"
      />
    </div>
  );
}

export default AddActivityReport;
