import React, { Component } from "react";
import { getAllJobs } from "../util/APIUtils";

import { RichText, Date } from "prismic-reactjs";

import { linkResolver } from "../const";

import { FiMapPin, FiBriefcase } from "react-icons/fi";

import { AiOutlineHourglass } from "react-icons/ai";
import { FiCode } from "react-icons/fi";
import { FcDepartment } from "react-icons/fc";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { BsBriefcase } from "react-icons/bs";

import offersData from "./data";

const ContactType = {
  FIXEDTERM: "CDD",
  PERMANENT: "CDI",
  FREELANCE: "Freelance",
};

export default class Offers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      filteredData: [],
      columns: [],
      searchInput: "",
      open: false,
      isDesktop: false,
      errors: { message: "" },
    };

    this.updatePredicate = this.updatePredicate.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ open: true });
  }
  closeModal() {
    this.setState({ open: false });
    this.componentDidMount();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 500 });
  }

  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
    getAllJobs()
      .then((Response) => {
        let data = [];
        // if (this.props.authenticated === true) {
        //   Response.forEach((element) => {
        //     var applicantId = this.props.currentUser.id;
        //     var offerId = element.id;
        //     getApplicationByApplicantIdAndOfferId(applicantId, offerId)
        //       .then((res) => {
        //         console.log(res);
        //         var applied;
        //         if (res.content === null) {
        //           applied = false;
        //         } else {
        //           applied = true;
        //         }
        //         data.push({
        //           id: element.id,
        //           referenceNumber: element.referenceNumber,
        //           externalReferenceNumber: element.externalReferenceNumber,
        //           title: element.title,
        //           summary: element.summary,
        //           seniority: element.seniority,
        //           status: element.status,
        //           type: element.type,
        //           location: element.location,
        //           duration: element.duration,
        //           source: element.source,
        //           startAt: element.startAt,
        //           endAt: element.endAt,
        //           createdAt: element.createdAt,
        //           updatedAt: element.updatedAt,
        //           profil: element.profil,
        //           company: element.company,
        //           skillMarks: element.skillMarks,
        //           applied,
        //         });
        //         this.setState({ data: data });
        //       })
        //       .catch((err) => {
        //         console.log(err);
        //       });
        //   });
        // } else {
        //   Response.forEach((element) => {
        //     var applied = false;
        //     data.push({
        //       id: element.id,
        //       referenceNumber: element.referenceNumber,
        //       externalReferenceNumber: element.externalReferenceNumber,
        //       title: element.title,
        //       summary: element.summary,
        //       seniority: element.seniority,
        //       status: element.status,
        //       type: element.type,
        //       location: element.location,
        //       duration: element.duration,
        //       source: element.source,
        //       startAt: element.startAt,
        //       endAt: element.endAt,
        //       createdAt: element.createdAt,
        //       updatedAt: element.updatedAt,
        //       profil: element.profil,
        //       company: element.company,
        //       skillMarks: element.skillMarks,
        //       applied,
        //     });
        //     this.setState({ data: data });
        //   });
        // }
        console.log(data);
        console.log("Response", Response);

        Response.forEach((element) => {
          var applied = false;
          data.push({
            id: element.id,
            referenceNumber: element.referenceNumber,
            externalReferenceNumber: element.externalReferenceNumber,
            title: element.title,
            summary: element.summary,
            shortSummary: element.shortSummary,
            sector: element.sector,
            availability: element.availability,
            seniority: element.seniority,
            status: element.status,
            type: element.type,
            location: element.location,
            duration: element.duration,
            source: element.source,
            startAt: element.startAt,
            endAt: element.endAt,
            createdAt: element.createdAt,
            updatedAt: element.updatedAt,
            profil: element.profil,
            company: element.company,
            skillMarks: element.skillMarks,
            applied,
          });
          this.setState({ data: data });
        });
      })
      .catch((err) => console.log(err));
  }

  toHashTag(text) {
    return "#" + text.toLowerCase().split(" ").join("_") + " ";
  }

  render() {
    var dateFormat = require("dateformat");
    return (
      <React.Fragment>
        {/*Page Title*/}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title" style={{ color: "#ffffff" }}>
                    Offres
                  </h2>
                  {/* <p>Fresh From The Press Discription</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Start Service Area */}
        <div className="service-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--30">
                  <h2>Nos Offres</h2>
                  {/* <p>
                    There are many variations of passages of Lorem Ipsum
                    available, <br /> but the majority have suffered alteration.
                  </p> */}
                </div>
              </div>
            </div>
            <div className="row service-one-wrapper">
              {/* {ServiceList.map((val, i) => (
                <div
                  className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                  key={i}
                >
                  <a href="/service-details">
                    <div className="service service__style--2">
                      <div className="icon">{val.icon}</div>

                      <div className="content">
                        <h3 style={{ fontSize: "18px" }} className="title">{val.title}</h3>
                        <p>{val.description}</p>
                      </div>
                    </div>
                  </a>
                </div>
              ))} */}

              {/* {RichText.render("<h2>THIS IS IT</h2>", linkResolver)}

              <div
                dangerouslySetInnerHTML={{
                  __html: "<h2>THIS IS IT</h2>",
                }}
              ></div> */}

              {offersData.map((job) => {
                console.log(job);
                return (
                  <React.Fragment>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                      <a href={"/offres/y/" + job.id}>
                        <div
                          className="service service__style--2"
                          style={{
                            border: "1px solid #3B0ACD",
                            marginBottom: "10px",
                          }}
                        >
                          <div className="icon">
                            <FiCode />
                          </div>

                          <div className="content">
                            <div>
                              <h3
                                style={{ fontSize: "18px" }}
                                className="title"
                              >
                                {job.title}
                              </h3>
                            </div>
                            <div
                              className="params-div"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                className="params"
                                style={{
                                  alignItems: "center",
                                }}
                              >
                                {job.location}
                                <FiMapPin
                                  style={{
                                    marginLeft: "5px",
                                  }}
                                />
                              </div>
                              <div
                                className="params"
                                style={{
                                  alignItems: "center",
                                }}
                              >
                                {ContactType[job.type]}
                                <FiBriefcase
                                  style={{
                                    marginLeft: "5px",
                                  }}
                                />
                              </div>
                              <div
                                className="params"
                                style={{
                                  alignItems: "center",
                                }}
                              >
                                {job.duration}
                                <AiOutlineHourglass
                                  style={{
                                    marginLeft: "4px",
                                  }}
                                />
                              </div>
                            </div>
                            <p style={{ fontSize: "14px", textIndent: "8px" }}>
                              Pour le compte de notre client, #NeoTechIT recrute{" "}
                              <br />
                              <FiBriefcase
                                style={{
                                  marginLeft: "4px",
                                  marginRight: "3px",
                                }}
                              />
                              {job.title}
                              <br />
                              <AiOutlineHourglass
                                style={{
                                  marginLeft: "4px",
                                  marginRight: "3px",
                                }}
                              />
                              {job.duration}
                              <br />
                              <FiMapPin
                                style={{
                                  marginLeft: "4px",
                                  marginRight: "3px",
                                }}
                              />
                              {job.location}
                              <br />
                              <FiBriefcase
                                style={{
                                  marginLeft: "4px",
                                  marginRight: "3px",
                                }}
                              />
                              {ContactType[job.type]}
                              <br />
                              <HiOutlineOfficeBuilding
                                style={{
                                  marginLeft: "4px",
                                  marginRight: "3px",
                                }}
                              />
                              {"Secteur IT"}
                            </p>

                            <p style={{ fontSize: "14px", textIndent: "8px" }}>
                              Si vous êtes intéressés prière de nous envoyer
                              votre CV, disponibilité et TJM à l'adresse
                              suivante : recrutement@neo-techit.com
                            </p>
                            <p
                              style={{
                                display: "block",
                                fontSize: "14px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "",
                                wordWrap: "break-word",
                                // color: "#3B0ACD",
                              }}
                              className="title"
                            >
                              {job.profil.skills.map((skill) => {
                                return this.toHashTag(skill.name);
                              })}
                              {job.profil.skills.map((skill) => {
                                return this.toHashTag(skill.name);
                              })}
                              {job.profil.skills.map((skill) => {
                                return this.toHashTag(skill.name);
                              })}
                              {job.profil.skills.map((skill) => {
                                return this.toHashTag(skill.name);
                              })}
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
        {/* End Service Area */}
      </React.Fragment>
    );
  }
}
