// React Required
import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Alert from "react-s-alert";

// Css
import "./index.scss";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";
import "react-phone-input-2/lib/style.css";

// Main
import Header from "./component/header/Header";
import FooterTwo from "./component/footer/FooterTwo";

// Home layout
import MainDemo from "./home/MainDemo";
import Startup from "./home/Startup";
import Paralax from "./home/Paralax";
import HomePortfolio from "./home/HomePortfolio";
import DigitalAgency from "./home/DigitalAgency";
import CreativeAgency from "./home/CreativeAgency";
import PersonalPortfolio from "./home/PersonalPortfolio";
import Business from "./home/Business";
import StudioAgency from "./home/StudioAgency";
import PortfolioLanding from "./home/PortfolioLanding";
import CreativeLanding from "./home/CreativeLanding";
import HomeParticles from "./home/HomeParticles";
import CreativePortfolio from "./home/CreativePortfolio";
import DesignerPortfolio from "./home/DesignerPortfolio";
import InteriorLanding from "./home/Interior";
import CorporateBusiness from "./home/CorporateBusiness";

// Dark Home Layout
import DarkMainDemo from "./dark/MainDemo";
import DarkPortfolioLanding from "./dark/PortfolioLanding";

// Element Layout
import Service from "./elements/Service";
import ServiceDetails from "./elements/ServiceDetails";
import About from "./elements/About";
import NosService from "./elements/service/ServiceTwo";
import Contact from "./elements/Contact";
import PortfolioDetails from "./elements/PortfolioDetails";
import Blog from "./elements/Blog";
import BlogDetails from "./elements/BlogDetails";
import error404 from "./elements/error404";

// Blocks Layout
import Team from "./blocks/Team";
import Counters from "./blocks/Counters";
import Testimonial from "./blocks/Testimonial";
import Portfolio from "./blocks/Portfolio";
import VideoPopup from "./blocks/VideoPopup";
import Gallery from "./blocks/Gallery";
import Brand from "./blocks/Brand";
import ProgressBar from "./blocks/ProgressBar";
import ContactForm from "./blocks/ContactForm";
import GoogleMap from "./blocks/GoogleMap";
import Columns from "./blocks/Columns";
import PricingTable from "./blocks/PricingTable";

// Services
import ServiceOne from "./services/one";
import ServiceTwo from "./services/two";
import ServiceThree from "./services/three";
import ServiceFour from "./services/four";

// Auth
import Login from "./old/user/login/Login";
import Signup from "./old/user/register/signup";
import ConfirmEmail from "./old/user/register/confirmEmail";
import ForgotPassword from "./old/user/forgotPassword/ForgotPassword";
import RenewPassword from "./old/user/forgotPassword/RenewPassword";

// Applicant
import Profil from "./old/applicant.js";
import EditProfil from "./old/applicant/Edit";
import Resume from "./old/resume/index";
import Suivie from "./old/suivie";
import Interview from "./old/interview/tabletwo";
import InterviewView from "./old/interview/view";
import OfferViewT from "./old/offer-t/viewtwo";
import OfferViewTT from "./old/offer-t/viewT";
import OfferTableT from "./old/offer-t/tabletwo";

// Ressources
import Files from "./old/resource/files";
import ActivityReport from "./old/activityReport/table";
import ViewActivityReport from "./old/activityReport/view";
import AddActivityReport from "./old/activityReport/add-new";
import EditActivityReport from "./old/activityReport/edit-new";
import OrderSheets from "./old/orderSheet/table";
import ViewOrderSheet from "./old/orderSheet/view";

// Supplier
import ActivityReportBySupplier from "./old/supplier/activityReport/table";
import ViewActivityReportBySupplier from "./old/supplier/activityReport/view";
import OrderSheetsBySupplier from "./old/supplier/orderSheet/table";
import ViewOrderSheetBySupplier from "./old/supplier/orderSheet/view";
import ResourcesBySupplier from "./old/supplier/resource/table";
import ViewResourceBySupplier from "./old/supplier/resource/view";

// Offers
import JobOne from "./old/offer/job-one";
import JobTwo from "./old/offer/job-two";
import JobThree from "./old/offer/job-three";
import JobFour from "./old/offer/job-four";
import JobFive from "./old/offer/job-five";
import JobSix from "./old/offer/job-six";
import Job7 from "./old/offer/job-7";
import Job8 from "./old/offer/job-8";
import Job9 from "./old/offer/job-9";

// Products
import ProductDetails from "./elements/product/ProductDetailsOne.jsx";

// Constants
import { ACCESS_TOKEN, AUTHENTICATED } from "./old/constants";

// App Utils
import {
  getManualResumeStatus,
  getMissingActivityReportsForResource,
  getMissingFilesForResource,
} from "./old/util/APIUtils";
import OAuth2RedirectHandler from "./old/user/oauth2/OAuth2RedirectHandler";

//
import * as serviceWorker from "./serviceWorker";

// i18n
import "./i18n.js";

// Bootstrap
import "bootstrap/dist/js/bootstrap.min.js";
import ProductDetailsOne from "./elements/product/ProductDetailsOne.jsx";
import ProductDetailsTwo from "./elements/product/ProductDetailsTwo.jsx";
import ProductDetailsThree from "./elements/product/ProductDetailsThree.jsx";
import ProductDetailsFour from "./elements/product/ProductDetailsFour.jsx";

class Root extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser:
        localStorage.getItem(ACCESS_TOKEN) !== null
          ? JSON.parse(
              JSON.parse(atob(localStorage.getItem(ACCESS_TOKEN).split(".")[1]))
                .user
            )
          : null,
      isAuthenticated:
        localStorage.getItem(AUTHENTICATED) === "true" ? true : false,
      expirtionTime:
        localStorage.getItem(ACCESS_TOKEN) !== null
          ? JSON.parse(atob(localStorage.getItem(ACCESS_TOKEN).split(".")[1]))
              .exp * 1000
          : 0,
      isLoading: false,
      comeFromLogin: false,
      sideDrawerOpen: false,
      notifications: [],
    };

    console.log(props);

    this.handleLogout = this.handleLogout.bind(this);
    this.loadCurrentUser = this.loadCurrentUser.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.testRedirect = this.testRedirect.bind(this);
  }

  loadCurrentUser(history) {
    this.setState(
      {
        currentUser:
          localStorage.getItem(ACCESS_TOKEN) !== null
            ? JSON.parse(
                JSON.parse(
                  atob(localStorage.getItem(ACCESS_TOKEN).split(".")[1])
                ).user
              )
            : null,
        isAuthenticated:
          localStorage.getItem(AUTHENTICATED) === true ? true : false,
        expirtionTime:
          localStorage.getItem(ACCESS_TOKEN) !== null
            ? JSON.parse(atob(localStorage.getItem(ACCESS_TOKEN).split(".")[1]))
                .exp * 1000
            : 0,
        isLoading: false,
        comeFromLogin: true,
      },
      () => {}
    );
    console.log(this.state);
    if (this.state.comeFromLogin) {
      if (this.state.currentUser.authorities[0].authority === "ROLE_RESOURCE") {
        getManualResumeStatus(this.state.currentUser.id)
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });

        getMissingActivityReportsForResource(this.state.currentUser.id)
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });

        getMissingFilesForResource(this.state.currentUser.id)
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      }

      if (
        this.state.currentUser.authorities[0].authority === "ROLE_APPLICANT"
      ) {
        getManualResumeStatus(this.state.currentUser.id)
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      console.log(history);
      // history.push("/");
    }
  }

  handleLogout(history) {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.setItem(AUTHENTICATED, false);

    this.setState({
      currentUser: null,
      isAuthenticated: false,
    });

    history.push("/");
  }

  testRedirect(redirectTo = "/") {
    this.props.history.push("/activity-report/add");
  }

  handleLogin(history) {
    this.setState({
      comeFromLogin: true,
    });
    this.loadCurrentUser(history);
  }

  render() {
    console.log(process.env.PUBLIC_URL);
    console.log(process.env.NODE_ENV);
    return (
      <BrowserRouter basename={"/"}>
        <Switch>
          {/* <Route exact path={`/`} component={Demo} /> */}

          <Route
            exact
            path={`/`}
            render={(props) => (
              <MainDemo
                authenticated={this.state.isAuthenticated}
                currentUser={this.state.currentUser}
                handleLogout={this.handleLogout}
                expTime={this.state.expirtionTime}
                onLogin={this.handleLogin}
                {...props}
              />
            )}
          />
          <Route exact path={`/dark-main-demo`} component={DarkMainDemo} />
          <Route exact path={`/startup`} component={Startup} />
          <Route exact path={`/paralax`} component={Paralax} />

          <Route exact path={`/digital-agency`} component={DigitalAgency} />
          <Route exact path={`/creative-agency`} component={CreativeAgency} />
          <Route
            exact
            path={`/personal-portfolio`}
            component={PersonalPortfolio}
          />
          <Route exact path={`/studio-agency`} component={StudioAgency} />
          <Route exact path={`/business`} component={Business} />
          <Route exact path={`/portfolio-home`} component={HomePortfolio} />
          <Route
            exact
            path={`/portfolio-landing`}
            component={PortfolioLanding}
          />
          <Route exact path={`/creative-landing`} component={CreativeLanding} />
          <Route exact path={`/home-particles`} component={HomeParticles} />
          <Route
            exact
            path={`/dark-portfolio-landing`}
            component={DarkPortfolioLanding}
          />
          <Route
            exact
            path={`/designer-portfolio`}
            component={DesignerPortfolio}
          />
          <Route
            exact
            path={`/creative-portfolio`}
            component={CreativePortfolio}
          />
          <Route exact path={`/interior-landing`} component={InteriorLanding} />
          <Route
            exact
            path={`/corporate-business`}
            component={CorporateBusiness}
          />

          {/* Element Layot */}
          <Route exact path={`/serviceTwo`} component={NosService} />
          <Route exact path={`/service`} component={Service} />
          <Route exact path={`/service-details`} component={ServiceDetails} />

          {/* Product Details Pages Routes */}
          <Route
            exact
            path={`/product-details/1`}
            component={ProductDetailsOne}
          />
          <Route
            exact
            path={`/product-details/2`}
            component={ProductDetailsTwo}
          />
          <Route
            exact
            path={`/product-details/3`}
            component={ProductDetailsThree}
          />
          <Route
            exact
            path={`/product-details/4`}
            component={ProductDetailsFour}
          />

          <Route
            exact
            path={`/contact`}
            component={(props) => (
              <Contact
                currentUser={this.state.currentUser}
                isAuthenticated={this.state.isAuthenticated}
                handleLogout={this.handleLogout}
                {...props}
              />
            )}
          />
          <Route exact path={`/about`} component={About} />
          <Route
            exact
            path={`/portfolio-details`}
            component={PortfolioDetails}
          />
          <Route exact path={`/blog`} component={Blog} />
          <Route exact path={`/blog-details`} component={BlogDetails} />

          {/* Blocks Elements  */}
          <Route exact path={`/team`} component={Team} />
          <Route exact path={`/counters`} component={Counters} />
          <Route exact path={`/testimonial`} component={Testimonial} />
          <Route exact path={`/portfolio`} component={Portfolio} />
          <Route exact path={`/video-popup`} component={VideoPopup} />
          <Route exact path={`/gallery`} component={Gallery} />
          <Route exact path={`/clint-logo`} component={Brand} />
          <Route exact path={`/progressbar`} component={ProgressBar} />
          <Route exact path={`/contact-form`} component={ContactForm} />
          <Route exact path={`/google-map`} component={GoogleMap} />
          <Route exact path={`/columns`} component={Columns} />
          <Route exact path={`/pricing-table`} component={PricingTable} />

          {/**************************************** Services ****************************************/}

          <Route
            exact
            path={`/services/0`}
            component={(props) => (
              <ServiceOne
                currentUser={this.state.currentUser}
                isAuthenticated={this.state.isAuthenticated}
                handleLogout={this.handleLogout}
                {...props}
              />
            )}
          />

          <Route
            exact
            path={`/services/1`}
            component={(props) => (
              <ServiceTwo
                currentUser={this.state.currentUser}
                isAuthenticated={this.state.isAuthenticated}
                handleLogout={this.handleLogout}
                {...props}
              />
            )}
          />

          <Route
            exact
            path={`/services/2`}
            component={(props) => (
              <ServiceThree
                currentUser={this.state.currentUser}
                isAuthenticated={this.state.isAuthenticated}
                handleLogout={this.handleLogout}
                {...props}
              />
            )}
          />

          <Route
            exact
            path={`/services/3`}
            component={(props) => (
              <ServiceFour
                currentUser={this.state.currentUser}
                isAuthenticated={this.state.isAuthenticated}
                handleLogout={this.handleLogout}
                {...props}
              />
            )}
          />

          <Route
            exact
            path="/confirm-email/:token"
            component={(props) => (
              <ConfirmEmail
                onLogin={this.handleLogin}
                authenticated={this.state.authenticated}
                {...props}
              />
            )}
          ></Route>

          <React.Fragment>
            {/* <Helmet pageTitle="Main Demo" /> */}
            <Route
              component={(props) => (
                <Header
                  headertransparent="header--transparent"
                  colorblack="color--black"
                  logoname="logo.png"
                  currentUser={this.state.currentUser}
                  isAuthenticated={this.state.isAuthenticated}
                  handleLogout={this.handleLogout}
                  onLogin={this.handleLogin}
                  {...props}
                />
              )}
            ></Route>
            {/**************************************** Auth ****************************************/}

            <Route
              exact
              path="/login"
              component={(props) => (
                <Login
                  onLogin={this.handleLogin}
                  authenticated={this.state.authenticated}
                  {...props}
                />
              )}
            ></Route>

            <Route
              exact
              path="/register"
              component={(props) => (
                <Signup
                  onLogin={this.handleLogin}
                  authenticated={this.state.authenticated}
                  {...props}
                />
              )}
            ></Route>

            <Route
              exact
              path="/forgot-pwd"
              component={(props) => (
                <ForgotPassword
                  onLogin={this.handleLogin}
                  authenticated={this.state.authenticated}
                  {...props}
                />
              )}
            ></Route>

            <Route
              exact
              path="/confirm-reset/:token"
              component={(props) => (
                <RenewPassword
                  onLogin={this.handleLogin}
                  authenticated={this.state.authenticated}
                  {...props}
                />
              )}
            />

            {/**************************************** Applicant ****************************************/}

            <Route
              path="/resume"
              render={(props) => (
                <Resume
                  authenticated={this.state.isAuthenticated}
                  currentUser={this.state.currentUser}
                  handleLogout={this.handleLogout}
                  expTime={this.state.expirtionTime}
                  {...props}
                />
              )}
            ></Route>

            <Route
              path="/suivie/:offerId"
              render={(props) => (
                <Suivie
                  authenticated={this.state.isAuthenticated}
                  currentUser={this.state.currentUser}
                  handleLogout={this.handleLogout}
                  expTime={this.state.expirtionTime}
                  {...props}
                />
              )}
            ></Route>

            <Route
              path="/profil"
              render={(props) => (
                <Profil
                  authenticated={this.state.isAuthenticated}
                  currentUser={this.state.currentUser}
                  handleLogout={this.handleLogout}
                  expTime={this.state.expirtionTime}
                  {...props}
                  {...props}
                />
              )}
            ></Route>

            <Route
              path="/profilv"
              render={(props) => (
                <EditProfil
                  authenticated={this.state.isAuthenticated}
                  currentUser={this.state.currentUser}
                  handleLogout={this.handleLogout}
                  expTime={this.state.expirtionTime}
                  {...props}
                  {...props}
                />
              )}
            ></Route>

            <Route
              path="/offres/v/:Id"
              exact
              render={(props) => (
                <OfferViewT
                  authenticated={this.state.isAuthenticated}
                  currentUser={this.state.currentUser}
                  handleLogout={this.handleLogout}
                  expTime={this.state.expirtionTime}
                  {...props}
                />
              )}
            />

            <Route
              path="/offres/y/1"
              exact
              render={(props) => (
                <JobOne
                  authenticated={this.state.isAuthenticated}
                  currentUser={this.state.currentUser}
                  handleLogout={this.handleLogout}
                  expTime={this.state.expirtionTime}
                  {...props}
                />
              )}
            />

            <Route
              path="/offres/y/2"
              exact
              render={(props) => (
                <JobTwo
                  authenticated={this.state.isAuthenticated}
                  currentUser={this.state.currentUser}
                  handleLogout={this.handleLogout}
                  expTime={this.state.expirtionTime}
                  {...props}
                />
              )}
            />

            <Route
              path="/offres/y/3"
              exact
              render={(props) => (
                <JobThree
                  authenticated={this.state.isAuthenticated}
                  currentUser={this.state.currentUser}
                  handleLogout={this.handleLogout}
                  expTime={this.state.expirtionTime}
                  {...props}
                />
              )}
            />

            <Route
              path="/offres/y/4"
              exact
              render={(props) => (
                <JobFour
                  authenticated={this.state.isAuthenticated}
                  currentUser={this.state.currentUser}
                  handleLogout={this.handleLogout}
                  expTime={this.state.expirtionTime}
                  {...props}
                />
              )}
            />

            <Route
              path="/offres/y/5"
              exact
              render={(props) => (
                <JobFive
                  authenticated={this.state.isAuthenticated}
                  currentUser={this.state.currentUser}
                  handleLogout={this.handleLogout}
                  expTime={this.state.expirtionTime}
                  {...props}
                />
              )}
            />

            <Route
              path="/offres/y/6"
              exact
              render={(props) => (
                <JobSix
                  authenticated={this.state.isAuthenticated}
                  currentUser={this.state.currentUser}
                  handleLogout={this.handleLogout}
                  expTime={this.state.expirtionTime}
                  {...props}
                />
              )}
            />

            <Route
              path="/offres/y/7"
              exact
              render={(props) => (
                <Job7
                  authenticated={this.state.isAuthenticated}
                  currentUser={this.state.currentUser}
                  handleLogout={this.handleLogout}
                  expTime={this.state.expirtionTime}
                  {...props}
                />
              )}
            />

            <Route
              path="/offres/y/8"
              exact
              render={(props) => (
                <Job8
                  authenticated={this.state.isAuthenticated}
                  currentUser={this.state.currentUser}
                  handleLogout={this.handleLogout}
                  expTime={this.state.expirtionTime}
                  {...props}
                />
              )}
            />

            <Route
              path="/offres/y/9"
              exact
              render={(props) => (
                <Job9
                  authenticated={this.state.isAuthenticated}
                  currentUser={this.state.currentUser}
                  handleLogout={this.handleLogout}
                  expTime={this.state.expirtionTime}
                  {...props}
                />
              )}
            />

            <Route
              path="/offres/t/:Id"
              exact
              render={(props) => (
                <OfferViewTT
                  onLogin={this.handleLogin}
                  authenticated={this.state.isAuthenticated}
                  currentUser={this.state.currentUser}
                  handleLogout={this.handleLogout}
                  expTime={this.state.expirtionTime}
                  {...props}
                />
              )}
            />

            <Route
              path="/offres"
              exact
              render={(props) => (
                <OfferTableT
                  authenticated={this.state.isAuthenticated}
                  currentUser={this.state.currentUser}
                  handleLogout={this.handleLogout}
                  expTime={this.state.expirtionTime}
                  {...props}
                />
              )}
            />

            <Route
              path="/interview"
              exact
              render={(props) => (
                <Interview
                  authenticated={this.state.isAuthenticated}
                  currentUser={this.state.currentUser}
                  handleLogout={this.handleLogout}
                  expTime={this.state.expirtionTime}
                  {...props}
                />
              )}
            ></Route>

            <Route
              path="/interview/view/:Id"
              exact
              render={(props) => (
                <InterviewView
                  authenticated={this.state.isAuthenticated}
                  currentUser={this.state.currentUser}
                  handleLogout={this.handleLogout}
                  expTime={this.state.expirtionTime}
                  {...props}
                />
              )}
            ></Route>

            {/**************************************** Ressources ***************************************/}

            <Route
              path="/files"
              exact={true}
              render={(props) => (
                <Files
                  authenticated={this.state.isAuthenticated}
                  currentUser={this.state.currentUser}
                  handleLogout={this.handleLogout}
                  expTime={this.state.expirtionTime}
                  {...props}
                />
              )}
            ></Route>

            <Route
              path="/activity-report"
              exact={true}
              render={(props) => (
                <ActivityReport
                  authenticated={this.state.isAuthenticated}
                  currentUser={this.state.currentUser}
                  handleLogout={this.handleLogout}
                  expTime={this.state.expirtionTime}
                  {...props}
                  testRedirect={this.testRedirect}
                />
              )}
            ></Route>

            <Route
              path="/activity-report/view/:Id"
              exact={true}
              render={(props) => (
                <ViewActivityReport
                  authenticated={this.state.isAuthenticated}
                  currentUser={this.state.currentUser}
                  handleLogout={this.handleLogout}
                  expTime={this.state.expirtionTime}
                  {...props}
                />
              )}
            ></Route>

            <Route
              path="/activity-report/add"
              exact={true}
              render={(props) => (
                <AddActivityReport
                  authenticated={this.state.isAuthenticated}
                  currentUser={this.state.currentUser}
                  handleLogout={this.handleLogout}
                  expTime={this.state.expirtionTime}
                  {...props}
                />
              )}
            ></Route>

            <Route
              path="/activity-report/edit/:Id"
              exact={true}
              render={(props) => (
                <EditActivityReport
                  authenticated={this.state.isAuthenticated}
                  currentUser={this.state.currentUser}
                  handleLogout={this.handleLogout}
                  expTime={this.state.expirtionTime}
                  {...props}
                />
              )}
            ></Route>

            <Route
              path="/ordersheet"
              exact={true}
              render={(props) => (
                <OrderSheets
                  authenticated={this.state.isAuthenticated}
                  currentUser={this.state.currentUser}
                  handleLogout={this.handleLogout}
                  expTime={this.state.expirtionTime}
                  {...props}
                />
              )}
            ></Route>

            <Route
              path="/ordersheet/:Id"
              render={(props) => (
                <ViewOrderSheet
                  authenticated={this.state.isAuthenticated}
                  currentUser={this.state.currentUser}
                  handleLogout={this.handleLogout}
                  expTime={this.state.expirtionTime}
                  {...props}
                />
              )}
            ></Route>

            {/**************************************** supplier ***************************************/}

            <Route
              path="/supplier/activity-report"
              exact={true}
              render={(props) => (
                <ActivityReportBySupplier
                  authenticated={this.state.isAuthenticated}
                  currentUser={this.state.currentUser}
                  handleLogout={this.handleLogout}
                  expTime={this.state.expirtionTime}
                  {...props}
                />
              )}
            ></Route>
            <Route
              path="/supplier/activity-report/:Id"
              exact={true}
              render={(props) => (
                <ViewActivityReportBySupplier
                  authenticated={this.state.isAuthenticated}
                  currentUser={this.state.currentUser}
                  handleLogout={this.handleLogout}
                  expTime={this.state.expirtionTime}
                  {...props}
                />
              )}
            ></Route>
            <Route
              path={`/supplier/ordersheet`}
              exact={true}
              render={(props) => (
                <OrderSheetsBySupplier
                  authenticated={this.state.isAuthenticated}
                  currentUser={this.state.currentUser}
                  handleLogout={this.handleLogout}
                  expTime={this.state.expirtionTime}
                  {...props}
                />
              )}
            ></Route>

            <Route
              path="/supplier/ordersheet/:Id"
              exact={true}
              render={(props) => (
                <ViewOrderSheetBySupplier
                  authenticated={this.state.isAuthenticated}
                  currentUser={this.state.currentUser}
                  handleLogout={this.handleLogout}
                  expTime={this.state.expirtionTime}
                  {...props}
                />
              )}
            ></Route>

            <Route
              path="/supplier/resource"
              exact={true}
              render={(props) => (
                <ResourcesBySupplier
                  authenticated={this.state.isAuthenticated}
                  currentUser={this.state.currentUser}
                  handleLogout={this.handleLogout}
                  expTime={this.state.expirtionTime}
                  {...props}
                />
              )}
            ></Route>

            <Route
              path="/supplier/resource/:Id"
              exact={true}
              render={(props) => (
                <ViewResourceBySupplier
                  authenticated={this.state.isAuthenticated}
                  currentUser={this.state.currentUser}
                  handleLogout={this.handleLogout}
                  expTime={this.state.expirtionTime}
                  {...props}
                />
              )}
            ></Route>

            <Route
              path="/oauth2/redirect"
              component={OAuth2RedirectHandler}
            ></Route>

            <FooterTwo />
          </React.Fragment>

          <Route path={`/404`} component={error404} />
          <Route component={error404} />
        </Switch>
        <Alert
          stack={{ limit: 3 }}
          timeout={3000}
          position="top-right"
          effect="slide"
          offset={65}
        />
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register();
