import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class ServiceDetails extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Service Details" />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
          currentUser={this.props.currentUser}
          isAuthenticated={this.props.isAuthenticated}
          handleLogout={this.props.handleLogout}
          {...this.props}
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title" style={{ color: "#ffffff" }}>
                    Election des compétences
                  </h2>
                  {/* <p>Fresh From The Press Discription</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--50 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="w-100"
                            src={`/assets/images/service/s_111.jpeg`}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title">Quoi</h4>
                          <p>
                            Le <strong>sourcing</strong> c’est un
                            <strong>
                              {" "}
                              processus de recherche pour le recrutement
                            </strong>
                            . Il a pour objectif d’
                            <strong>identifier des profils précis </strong>
                            répondant à une liste de critères de sélection pour
                            une <strong>mission professionnel</strong> donné.
                          </p>
                          <p>
                            Le <strong>sourcing</strong> c’est avant tout une
                            histoire de choix et d’identification
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title">Notre Démarche</h4>

                          <p>
                            Nous choisissons nos CV avec soin et finesse et nous
                            sélectionnons les plus compétents des candidats en
                            toute certitude et rapidité ; Plus de
                            <strong> 50 CV</strong> présentés par offre et
                            <strong> 3 envoyés</strong> dans les
                            <strong>
                              {" "}
                              1ères heures après réception de l’offre Client
                            </strong>
                          </p>
                          <p>
                            Notre réseau est composé de
                            <strong> 200 000 profils </strong>
                            suivis, ce qui nous permette d’avoir une large
                            vision sur le marché des compétences,
                            <strong> 10 000 profils</strong> déjà sélectionnés
                            et
                            <strong> 800 offres</strong> traitées
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative">
                          <img
                            className="w-100"
                            src={`/assets/images/service/s_12.png`}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="w-100"
                            src={`/assets/images/service/s_131.jpeg`}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title">Entretien et Qualification</h4>
                          <p>
                            Afin de vous offrir les meilleurs des profils, nos
                            candidats passent jusqu’à 3 entretiens avec
                            <strong> nos experts techniques</strong> en 3 étapes
                            essentielles :
                          </p>
                          <ul className="liststyle">
                            <li>
                              <strong>Questionnaire</strong> : ensemble de
                              question bien sélectionnée sur mesure du besoin
                              client
                            </li>
                            <li>
                              <strong>RH </strong>: Analyse du comportement et
                              de l’engagement des candidats et négociation du
                              tarif
                            </li>
                            <li>
                              <strong>TECHNIQUE</strong> : Entretien ciblé pour
                              mesurer les compétences techniques du profil
                              choisi
                            </li>
                          </ul>
                          <p>
                            En CDI ou freelance ; enfin, une décision d’embauche
                            commune est réalisée pour finaliser le
                            <strong> recrutement</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row sercice-details-content align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title">Satisfaction IN-OUT</h4>
                          <p>
                            Notre billet de succès c’est la satisfaction de nos
                            clients en premier ordre ainsi que nos
                            collaborateurs ; En vue de financement nos
                            ressources sont payées toujours à temps
                            <strong>
                              {" "}
                              sans attente le paiement du client.
                            </strong>
                          </p>
                          <p>
                            Nos candidats sont toujours les bienvenus, retenu ou
                            non ils font désormais partie de notre CVthèque.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative">
                          <img
                            className="w-100"
                            src={`/assets/images/service/s_14.jpeg`}
                            alt="Service Images"
                          />
                          {/* <ModalVideo
                            channel="youtube"
                            isOpen={this.state.isOpen}
                            videoId="ZOoVOfieAF8"
                            onClose={() => this.setState({ isOpen: false })}
                          />
                          <button
                            className="video-popup"
                            onClick={this.openModal}
                          >
                            <span className="play-icon"></span>
                          </button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default ServiceDetails;
