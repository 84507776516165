import React, { Component } from "react";
import {
  FiCast,
  FiLayers,
  FiUsers,
  FiMonitor,
  FiChevronUp,
  FiMapPin,
  FiBriefcase,
} from "react-icons/fi";
import { AiOutlineHourglass } from "react-icons/ai";
import { IoBriefcaseOutline } from "react-icons/io";
import { FiCode } from "react-icons/fi";
import ScrollToTop from "react-scroll-up";

import { Link } from "react-router-dom";

import { getAllJobs, getAllJobsForWebsite } from "../../old/util/APIUtils";
import { FcDepartment } from "react-icons/fc";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { BsBriefcase } from "react-icons/bs";

import offersData from "./data";

const ContactType = {
  FIXEDTERM: "CDD",
  PERMANENT: "CDI",
  FREELANCE: "Freelance",
};

const ServiceList = [
  {
    icon: <FiCode />,
    title: "Consultant Sharepoint",
    description: "",
  },
  {
    icon: <FiCode />,
    title: "Développeur Mobile Android/IOS",
    description:
      "I throw myself down among the tall grass by the stream as I lie close to the earth.",
  },
  {
    icon: <FiCode />,
    title: "Développeur SpringBoot/ReactJs",
    description: () => {
      return (
        <div>
          - Développeur expérimenté SharePoint à autonomie sur l'ensemble des
          tâches liées au développement SharePoint <br /> - Paramétrage de
          l'environnement <br /> - développement Front/back office <br /> -
          gestion du déploiement des solutions
        </div>
      );
    },
  },
];

class Service extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      filteredData: [],
      columns: [],
      searchInput: "",
      open: false,
      isDesktop: false,
      errors: { message: "" },
    };

    // this.updatePredicate = this.updatePredicate.bind(this);
    // this.openModal = this.openModal.bind(this);
    // this.closeModal = this.closeModal.bind(this);
  }

  toHashTag(text) {
    return "#" + text.toLowerCase().split(" ").join("_") + " ";
  }

  componentDidMount() {
    // this.updatePredicate();
    // window.addEventListener("resize", this.updatePredicate);
    getAllJobs()
      .then((Response) => {
        let data = [];
        // if (this.props.authenticated === true) {
        //   Response.forEach((element) => {
        //     var applicantId = this.props.currentUser.id;
        //     var offerId = element.id;
        //     getApplicationByApplicantIdAndOfferId(applicantId, offerId)
        //       .then((res) => {
        //         console.log(res);
        //         var applied;
        //         if (res.content === null) {
        //           applied = false;
        //         } else {
        //           applied = true;
        //         }
        //         data.push({
        //           id: element.id,
        //           referenceNumber: element.referenceNumber,
        //           externalReferenceNumber: element.externalReferenceNumber,
        //           title: element.title,
        //           summary: element.summary,
        //           seniority: element.seniority,
        //           status: element.status,
        //           type: element.type,
        //           location: element.location,
        //           duration: element.duration,
        //           source: element.source,
        //           startAt: element.startAt,
        //           endAt: element.endAt,
        //           createdAt: element.createdAt,
        //           updatedAt: element.updatedAt,
        //           profil: element.profil,
        //           company: element.company,
        //           skillMarks: element.skillMarks,
        //           applied,
        //         });
        //         this.setState({ data: data });
        //       })
        //       .catch((err) => {
        //         console.log(err);
        //       });
        //   });
        // } else {
        //   Response.forEach((element) => {
        //     var applied = false;
        //     data.push({
        //       id: element.id,
        //       referenceNumber: element.referenceNumber,
        //       externalReferenceNumber: element.externalReferenceNumber,
        //       title: element.title,
        //       summary: element.summary,
        //       seniority: element.seniority,
        //       status: element.status,
        //       type: element.type,
        //       location: element.location,
        //       duration: element.duration,
        //       source: element.source,
        //       startAt: element.startAt,
        //       endAt: element.endAt,
        //       createdAt: element.createdAt,
        //       updatedAt: element.updatedAt,
        //       profil: element.profil,
        //       company: element.company,
        //       skillMarks: element.skillMarks,
        //       applied,
        //     });
        //     this.setState({ data: data });
        //   });
        // }
        console.log(data);
        console.log("Response", Response);

        Response.forEach((element) => {
          var applied = false;
          data.push({
            id: element.id,
            referenceNumber: element.referenceNumber,
            externalReferenceNumber: element.externalReferenceNumber,
            title: element.title,
            summary: element.summary,
            shortSummary: element.shortSummary,
            sector: element.sector,
            availability: element.availability,
            seniority: element.seniority,
            status: element.status,
            type: element.type,
            location: element.location,
            duration: element.duration,
            source: element.source,
            startAt: element.startAt,
            endAt: element.endAt,
            createdAt: element.createdAt,
            updatedAt: element.updatedAt,
            profil: element.profil,
            company: element.company,
            skillMarks: element.skillMarks,
            applied,
          });
          this.setState({ data: data });
        });
      })
      .catch((err) => console.log(err));
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Service Area */}
        <div className="service-area pt-5 mt-5">
          <div className="container">
            {/* <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--30">
                  <h2>Nos Offres</h2>
                  
                </div>
              </div>
            </div> */}
            <div className="row service-one-wrapper">
              {/* {ServiceList.map((val, i) => (
                <div
                  className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                  key={i}
                >
                  <a href="/service-details">
                    <div className="service service__style--2">
                      <div className="icon">{val.icon}</div>

                      <div className="content">
                        <h3 style={{ fontSize: "18px" }} className="title">{val.title}</h3>
                        <p>{val.description}</p>
                      </div>
                    </div>
                  </a>
                </div>
              ))} */}

              {/* <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                <a href="/service-details">
                  <div className="service service__style--2">
                    <div className="icon">
                      <FiCode />
                    </div>

                    <div className="content">
                      <div>
                        <h3 style={{ fontSize: "18px" }} className="title">
                          Consultant Sharepoint
                        </h3>
                      </div>
                      <div
                        className="params-div"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          className="params"
                          style={{
                            alignItems: "center",
                          }}
                        >
                          Casablanca
                          <FiMapPin
                            style={{
                              marginLeft: "5px",
                            }}
                          />
                        </div>
                        <div
                          className="params"
                          style={{
                            alignItems: "center",
                          }}
                        >
                          Freelance
                          <FiBriefcase
                            style={{
                              marginLeft: "5px",
                            }}
                          />
                        </div>
                        <div
                          className="params"
                          style={{
                            alignItems: "center",
                          }}
                        >
                          6 Mois
                          <AiOutlineHourglass
                            style={{
                              marginLeft: "4px",
                            }}
                          />
                        </div>
                      </div>
                      <p style={{ fontSize: "14px" }}>
                        Neo Tech IT recrute pour le compte de son client basé
                        sur Casablanca des profils Sharepoint pour une mission
                        Freelance de longue durée
                      </p>
                      <p style={{ fontSize: "14px" }}>
                        Mission
                        <ul>
                          <li>
                            Développeur expérimenté SharePoint à autonomie sur
                            l'ensemble des tâches liées au développement
                            SharePoint
                          </li>
                          <li>Paramétrage de l'environnement</li>
                          <li>Développement Front/back office</li>
                          <li>Gestion du déploiement des solutions</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </a>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                <a href="/service-details">
                  <div className="service service__style--2">
                    <div className="icon">
                      <FiCode />
                    </div>

                    <div className="content">
                      <h3 style={{ fontSize: "18px" }} className="title">
                        Développeur SpringBoot/ReactJs
                      </h3>
                      <div
                        className="params-div"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          className="params"
                          style={{
                            alignItems: "center",
                          }}
                        >
                          Casablanca
                          <FiMapPin
                            style={{
                              marginLeft: "5px",
                            }}
                          />
                        </div>
                        <div
                          className="params"
                          style={{
                            alignItems: "center",
                          }}
                        >
                          Freelance
                          <FiBriefcase
                            style={{
                              marginLeft: "5px",
                            }}
                          />
                        </div>
                        <div
                          className="params"
                          style={{
                            alignItems: "center",
                          }}
                        >
                          6 Mois
                          <AiOutlineHourglass
                            style={{
                              marginLeft: "4px",
                            }}
                          />
                        </div>
                      </div>
                      <p style={{ fontSize: "14px" }}>
                        Neo Tech IT recrute pour le compte de son client basé
                        sur Casablanca des profils SpringBoot/ReactJs pour une
                        mission Freelance de longue durée
                      </p>
                      <p style={{ fontSize: "14px" }}>
                        Mission
                        <ul>
                          <li>
                            Intervenir sur le backend et le frontend d’une
                            application web/API
                          </li>
                          <li>
                            Configurer et mettre en service des infrastructures
                            matérielles
                          </li>
                          <li>Concevoir et exploiter une base de données</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </a>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                <a href="/service-details">
                  <div className="service service__style--2">
                    <div className="icon">
                      <FiCode />
                    </div>

                    <div className="content">
                      <h3 style={{ fontSize: "18px" }} className="title">
                        Développeur Android/IOS
                      </h3>
                      <div
                        className="params-div"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          className="params"
                          style={{
                            alignItems: "center",
                          }}
                        >
                          Casablanca
                          <FiMapPin
                            style={{
                              marginLeft: "5px",
                            }}
                          />
                        </div>
                        <div
                          className="params"
                          style={{
                            alignItems: "center",
                          }}
                        >
                          Freelance
                          <FiBriefcase
                            style={{
                              marginLeft: "5px",
                            }}
                          />
                        </div>
                        <div
                          className="params"
                          style={{
                            alignItems: "center",
                          }}
                        >
                          6 Mois
                          <AiOutlineHourglass
                            style={{
                              marginLeft: "4px",
                            }}
                          />
                        </div>
                      </div>
                      <p style={{ fontSize: "14px" }}>
                        Neo Tech IT recrute pour le compte de son client basé
                        sur Casablanca des profils Android Et/Ou IOS pour une
                        mission Freelance de longue durée
                      </p>
                      <p style={{ fontSize: "14px" }}>
                        Mission
                        <ul>
                          <li>
                            Réaliser la conception technique et le développement
                            des nouveaux produits en assurant une conformité vis
                            à vis de l’architecture logicielle cible
                          </li>
                          <li>Avoir le niveau d’engagement nécessaire</li> 
                        </ul>
                      </p>
                    </div>
                  </div>
                </a>
              </div> */}

              {offersData.reverse().map((job, key) => {
                console.log(job);

                if (key < 3)
                  return (
                    // <React.Fragment>
                    //   <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                    //     {/* <a href={"/offres/y/" + job.id}> */}
                    //     <Link to={"/offres/y/" + job.id}>
                    //       <div
                    //         className="service service__style--2"
                    //         style={{
                    //           border: "1px solid #3B0ACD",
                    //           marginBottom: "10px",
                    //         }}
                    //       >
                    //         <div className="icon">
                    //           <FiCode />
                    //         </div>

                    //         <div className="content">
                    //           <div>
                    //             <h3
                    //               style={{ fontSize: "18px" }}
                    //               className="title"
                    //             >
                    //               {job.title}
                    //             </h3>
                    //           </div>
                    //           <div
                    //             className="params-div"
                    //             style={{
                    //               display: "flex",
                    //               justifyContent: "space-between",
                    //             }}
                    //           >
                    //             <div
                    //               className="params"
                    //               style={{
                    //                 alignItems: "center",
                    //               }}
                    //             >
                    //               {job.location}
                    //               <FiMapPin
                    //                 style={{
                    //                   marginLeft: "5px",
                    //                 }}
                    //               />
                    //             </div>
                    //             <div
                    //               className="params"
                    //               style={{
                    //                 alignItems: "center",
                    //               }}
                    //             >
                    //               {ContactType[job.type]}
                    //               <FiBriefcase
                    //                 style={{
                    //                   marginLeft: "5px",
                    //                 }}
                    //               />
                    //             </div>
                    //             <div
                    //               className="params"
                    //               style={{
                    //                 alignItems: "center",
                    //               }}
                    //             >
                    //               {job.duration}
                    //               <AiOutlineHourglass
                    //                 style={{
                    //                   marginLeft: "4px",
                    //                 }}
                    //               />
                    //             </div>
                    //           </div>
                    //           <p
                    //             style={{
                    //               fontSize: "14px",
                    //               textIndent: "8px",
                    //             }}
                    //           >
                    //             Pour le compte de notre client, #NeoTechIT
                    //             recrute <br />
                    //             <FiBriefcase
                    //               style={{
                    //                 marginLeft: "4px",
                    //                 marginRight: "3px",
                    //               }}
                    //             />
                    //             {job.title}
                    //             <br />
                    //             <AiOutlineHourglass
                    //               style={{
                    //                 marginLeft: "4px",
                    //                 marginRight: "3px",
                    //               }}
                    //             />
                    //             {job.duration}
                    //             <br />
                    //             <FiMapPin
                    //               style={{
                    //                 marginLeft: "4px",
                    //                 marginRight: "3px",
                    //               }}
                    //             />
                    //             {job.location}
                    //             <br />
                    //             <FiBriefcase
                    //               style={{
                    //                 marginLeft: "4px",
                    //                 marginRight: "3px",
                    //               }}
                    //             />
                    //             {ContactType[job.type]}
                    //             <br />
                    //             <HiOutlineOfficeBuilding
                    //               style={{
                    //                 marginLeft: "4px",
                    //                 marginRight: "3px",
                    //               }}
                    //             />
                    //             {"Secteur IT"}
                    //           </p>

                    //           <p
                    //             style={{
                    //               fontSize: "14px",
                    //               textIndent: "8px",
                    //             }}
                    //           >
                    //             Si vous êtes intéressés prière de nous envoyer
                    //             votre CV, disponibilité et TJM à l'adresse
                    //             suivante : recrutement@neo-techit.com
                    //           </p>
                    //           <p
                    //             style={{
                    //               display: "block",
                    //               fontSize: "14px",
                    //               whiteSpace: "nowrap",
                    //               overflow: "hidden",
                    //               textOverflow: "",
                    //               wordWrap: "break-word",
                    //               // color: "#3B0ACD",
                    //             }}
                    //             className="title"
                    //           >
                    //             {job.profil.skills.map((skill) => {
                    //               return this.toHashTag(skill.name);
                    //             })}
                    //             {job.profil.skills.map((skill) => {
                    //               return this.toHashTag(skill.name);
                    //             })}
                    //             {job.profil.skills.map((skill) => {
                    //               return this.toHashTag(skill.name);
                    //             })}
                    //             {job.profil.skills.map((skill) => {
                    //               return this.toHashTag(skill.name);
                    //             })}
                    //           </p>
                    //         </div>
                    //       </div>
                    //     </Link>{" "}
                    //     {/* </a> */}
                    //   </div>
                    // </React.Fragment>
                    <></>
                  );
              })}
            </div>
          </div>
        </div>
        {/* End Service Area */}
      </React.Fragment>
    );
  }
}
export default Service;
