import React, { Component } from "react";

import { FormGroup, FormText, Input, Label, Table } from "reactstrap";
import { getAllSubskills, addManualResume } from "../../util/APIUtils";

import CreatableSelect from "react-select/lib/Creatable";

import Popup from "reactjs-popup";

class Ajout extends Component {
  constructor() {
    super();
    this.state = {
      skills: [],

      selectSkills: [],

      manualResume: {
        subskills: [],
        applicant: null,
      },

      formErrors: { data: "" },
      errors: {},
    };

    this.onChangeSelect = this.onChangeSelect.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    getAllSubskills()
      .then((Response) => {
        this.setState({ selectSkills: Response });
        this.state.selectSkills.map((obj) => {
          obj.value = obj.id;
          obj.label = obj.name;
          return obj;
        });
      })
      .catch((err) => console.log(err));
  }

  validateForm() {
    let { data } = this.state;

    let formErrors = this.state.formErrors;

    if (data.size() === 0) {
      formErrors.data = "Veuillez entrez une diplomaName";
    }

    this.setState({
      formErrors,
    });

    return (
      JSON.stringify(formErrors) ===
      JSON.stringify({
        data: "",
      })
    );
  }

  onSubmit(e) {
    e.preventDefault();

    var idApplicant = this.props.idApplicant;

    const ManualResume = {
      subskills: this.state.skills,
      applicant: null,
    };

    // if (this.validateForm()) {
    addManualResume(ManualResume, idApplicant)
      .then((res) => {
        this.props.close();
      })
      .catch((err) => console.log(err));
    // }
  }

  onChangeSelect(e) {
    if (e === null) {
      this.setState({ skills: [] });
    } else {
      this.setState({ skills: e });
    }
  }

  render() {
    return (
      <div className="card">
        <div className="card-header">
          <strong>Ajouter skills</strong>
        </div>
        <div className="card-body">
          <div>
            <form onSubmit={this.onSubmit}>
              <FormGroup id="skills">
                <Label for="skills">Skills</Label>
                <CreatableSelect
                  id="skills"
                  value={this.state.skills}
                  isMulti
                  placeholder="Select skills"
                  name="skills"
                  options={this.state.selectSkills}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={this.onChangeSelect}
                />
                <FormText color="danger">
                  {this.state.formErrors.skills}
                </FormText>
              </FormGroup>

              <input
                type="submit"
                value="Ajouter"
                className="btn btn-success float-right"
              />

              <input
                type="button"
                onClick={this.props.close}
                value="Retour"
                className="btn btn-warning mr-1 float-right"
              />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Ajout;
