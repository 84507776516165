import React, { Component } from "react";

class BrandTwo extends Component {
  render() {
    return (
      <React.Fragment>
        <ul className="brand-style-2">
          <li>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/brand/marjane.png`}
              alt="marjane"
            />
          </li>

          <li>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/brand/marjane_market.jpg`}
              alt="marjane_market"
            />
          </li>

          <li>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/brand/rma.jpg`}
              alt="rma"
            />
          </li>

          <li>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/brand/ocp.png`}
              alt="ocp"
            />
          </li>

          <li>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/brand/orange.png`}
              alt="orange"
            />
          </li>

          <li>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/brand/teal.jpg`}
              alt="teal"
            />
          </li>

          <li>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/brand/ibm.png`}
              alt="ibm"
            />
          </li>

          <li>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/brand/bnp.png`}
              alt="bnp"
            />
          </li>

          <li>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/brand/sqli.jpg`}
              alt="sqli"
            />
          </li>

          <li>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/brand/docaposte.png`}
              alt="docaposte"
            />
          </li>

          <li>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/brand/inetum.png`}
              alt="inetum"
            />
          </li>

          <li>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/brand/cadm.png`}
              alt="cadm"
            />
          </li>

          <li>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/brand/safran.png`}
              alt="safran"
            />
          </li>

          <li>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/brand/idemia.png`}
              alt="idemia"
            />
          </li>

          <li>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/brand/cimr.png`}
              alt="cimr"
            />
          </li>

          <li>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/brand/atos.jpg`}
              alt="atos"
            />
          </li>

          <li>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/brand/obs.png`}
              alt="obs"
            />
          </li>

          <li>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/brand/electroplanet.png`}
              alt="electroplanet"
            />
          </li>

          <li>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/brand/ovs.png`}
              alt="ovs"
            />
          </li>

          <li>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/brand/inwi.png`}
              alt="inwi"
            />
          </li>
        </ul>
      </React.Fragment>
    );
  }
}
export default BrandTwo;
