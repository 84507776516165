import React, { Component } from "react";
import PhoneInput from "react-phone-input-2";
import emailjs from "emailjs-com";
import { withTranslation } from "react-i18next";

class RequestDemoModal extends Component {
  constructor() {
    super();
    this.state = {
      from_fname: "",
      from_lname: "",
      from_phone: "",
      from_email: "",
      from_company: "",
      from_date: "",
      from_comment: "",
      response: "",
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleChangePhone = (phone) => {
    this.setState({ from_phone: phone });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const {
      from_fname,
      from_lname,
      from_phone,
      from_email,
      from_company,
      from_date,
      from_comment,
    } = this.state;

    // email params
    const templateParams = {
      from_fname,
      from_lname,
      from_phone,
      from_email,
      from_company,
      from_date,
      from_comment,
      productName: this.props.productName,
    };

    // send email with emailjs library
    emailjs
      .send(
        "service_wb294bm",
        "template_rpuyvlh",
        templateParams,
        "vM1bvwI5Unz2ZQflP"
      )
      .then((response) => {
        this.setState({ response: "Demande sent successfully!" });
      })
      .catch((error) => {
        this.setState({ response: "Failed to send demande." });
      });

    // clear state
    this.setState({
      from_fname: "",
      from_lname: "",
      from_phone: "",
      from_email: "",
      from_company: "",
      from_date: "",
      from_comment: "",
    });
  };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <button
          className="btn text-uppercase font-weight-bold"
          data-toggle="modal"
          data-target="#exampleModal"
          style={{
            fontSize: "12px",
            fontFamily: "inherit",
            padding: "10px",
            backgroundColor: "#00d1ff",
          }}
        >
          {t("requestDemoBtnValue")}
        </button>

        <div
          className="modal text-left fade"
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{t("requestDemoModalTitle")}</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {/* Form  */}
                <form onSubmit={this.handleSubmit}>
                  <div className="form-row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationDefault01">
                        {t("formLabelFirstName")}{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="validationDefault01"
                        name="from_fname"
                        value={this.state.from_fname}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationDefault02">
                        {t("formLabelLastName")}{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="validationDefault02"
                        name="from_lname"
                        value={this.state.from_lname}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationDefault05">
                        {t("formLabelPhone")}{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <PhoneInput
                        id="validationDefault05"
                        country={"ma"}
                        countryCodeEditable={false}
                        value={this.state.from_phone}
                        onChange={this.handleChangePhone}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationDefault03">
                        {t("formLabelEmail")}{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="validationDefault03"
                        name="from_email"
                        value={this.state.from_email}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationDefault06">
                        {t("formLabelCompany")}{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="validationDefault06"
                        name="from_company"
                        value={this.state.from_company}
                        onChange={this.handleChange}
                        required
                      />
                    </div>

                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationDefault07">
                        {" "}
                        {t("formLabelDate")}{" "}
                      </label>
                      <input
                        type="datetime-local"
                        className="form-control"
                        id="validationDefault07"
                        name="from_date"
                        value={this.state.from_date}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col-12 mb-3">
                      <label htmlFor="validationDefault08">
                        {t("formLabelComment")}
                      </label>
                      <textarea
                        className="form-control"
                        id="validationDefault08"
                        name="from_comment"
                        value={this.state.from_comment}
                        onChange={this.handleChange}
                        rows={5}
                      ></textarea>
                    </div>
                  </div>

                  {/* Submit button  */}
                  <button className="btn btn-primary mb-3" type="submit">
                    {t("formRequestDemoBtnValue")}
                  </button>

                  {this.state.response && <p>{this.state.response}</p>}
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(RequestDemoModal);
