import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";
import { withTranslation } from "react-i18next";
import RequestDemoModal from "./RequestDemoModal";
import "./productDetails.css";

class ProductDetailsOne extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Controle 360" />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div className="bg-gradient pt--180 pb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="text-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/360_logo.png`}
                    width={250}
                    alt="Controle 360"
                  />
                  <h2 className="product-header-title text-white">
                    {t("product360HeaderDescription")}
                  </h2>
                  <p className="product-header-title-description text-white">
                    {t("product360HeaderSubDescription")}
                  </p>

                  <video controls className="controle360-video my-5">
                    <source
                      src={`${process.env.PUBLIC_URL}/assets/images/360_video.mp4`}
                      type="video/mp4"
                    />
                  </video>

                  <RequestDemoModal productName="Controle 360" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* the problem */}
        <div className="overview">
          <div className="container">
            <div className="div-1 text-center d-none d-lg-block">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/product/p1/img13.png`}
                className="rounded float-left"
                alt="..."
              />
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/product/p1/img12.png`}
                className="rounded"
                alt="..."
                style={{ marginTop: "100px" }}
              />

              <img
                src={`${process.env.PUBLIC_URL}/assets/images/product/p1/img11.png`}
                className="rounded float-right"
                alt="..."
              />
            </div>

            <div className="div-2 text-center my-5">
              <h5 className="mb-2">{t("product360OverviewTitle")}</h5>
              <p>{t("product360OverviewDescription")}</p>
            </div>

            <div className="div-3 controle360 text-center">
              <p className="font-weight-bold text-white">
                <span> &#x275D;</span> {t("product360OverviewQuote")}
                <span> &#x275E;</span>
              </p>
            </div>
          </div>
        </div>

        {/* features */}
        <div className="card-features pt--120">
          <div className="container">
            <h3 className="text-center">{t("product360FeaturesTitle")}</h3>
            <div className="row justify-content-center mt-5">
              <div className="col-xs-12 col-md-6 col-lg-4 mb-3">
                <div className="feature f1">
                  <div className="content">
                    <h6 className="text-white">
                      {t("product360FeatureOneTitle")}
                    </h6>
                    <p>{t("product360FeatureOneDescription")}</p>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-6 col-lg-4 mb-3">
                <div className="feature f2">
                  <div className="content">
                    <h6 className="text-white">
                      {t("product360FeatureTwoTitle")}
                    </h6>
                    <p>{t("product360FeatureTwoDescription")}</p>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-6 col-lg-4">
                <div className="feature f3">
                  <div className="content">
                    <h6 className="text-white">
                      {t("product360FeatureThreeTitle")}
                    </h6>
                    <p>{t("product360FeatureThreeDescription")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* use cases */}
        <div className="usecases bg-gradient pt--50 pb--30">
          <h3 className="text-center text-white">
            {t("product360UseCasesTitle")}
          </h3>
          <div className="container">
            <div className="row justify-content-center mt-5">
              <div className="col-12 col-lg-4 col-md-6 mb-3">
                <div className="usecase">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/product/p1/supermarket.png`}
                    alt="..."
                  />
                  <div className="content">
                    <h6 className="text-white">
                      {t("product360UseCaseOneTitle")}
                    </h6>
                    <p>{t("product360UseCaseOneDescription")}</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-md-6 mb-3">
                <div className="usecase">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/product/p1/gradient.png`}
                    alt="..."
                  />
                  <div className="content">
                    {" "}
                    <h6 className="text-white">
                      {t("product360UseCaseTwoTitle")}
                    </h6>
                    <p>{t("product360UseCaseTwoDescription")}</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-md-6">
                <div className="usecase">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/product/p1/group.png`}
                    alt="..."
                  />
                  <div className="content">
                    <h6 className="text-white">
                      {t("product360UseCaseThreeTitle")}
                    </h6>
                    <p>{t("product360UseCaseThreeDescription")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* performance and statistics */}
        <div className="container">
          <div className="controle360-screenshot-1 pt--80">
            <div className="row">
              <div className="col-12 col-lg-7">
                <div className="screenshot">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/product/p1/img4.png`}
                    alt="..."
                  />
                </div>
              </div>
              <div className="col-12 col-lg-5">
                <div className="description">
                  <h3>{t("product360ScreenshotOneTitle")}</h3>
                  <p>{t("product360ScreenshotOneDescription")}</p>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/product/p1/img3.png`}
                    alt="..."
                    className="d-none d-lg-block"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* offered services */}
        <div className="container">
          <div className="controle360-screenshot-2 pt--50">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-5">
                <div className="description">
                  <h3 className="mb-3">{t("product360ScreenshotTwoTitle")}</h3>
                  <div className="mb-3">
                    <h5>{t("product360ScreenshotTwoItemOneTitle")}</h5>
                    <p>{t("product360ScreenshotTwoItemOneDescription")}</p>
                  </div>
                  <div className="mb-3">
                    <h5>{t("product360ScreenshotTwoItemTwoTitle")}</h5>
                    <p>{t("product360ScreenshotTwoItemTwoDescription")}</p>
                  </div>
                  <div className="mb-3">
                    <h5>{t("product360ScreenshotTwoItemThreeTitle")}</h5>
                    <p>{t("product360ScreenshotTwoItemThreeDescription")}</p>
                  </div>
                  <div className="mb-3">
                    <h5>{t("product360ScreenshotTwoItemFourTitle")}</h5>
                    <p>{t("product360ScreenshotTwoItemFourDescription")}</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-7">
                <div className="screenshot">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/product/p1/img2.png`}
                    alt="..."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* flexibility and customization */}
        <div className="container">
          <div className="controle360-screenshot-3">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-7">
                <div className="screenshot-3">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/product/p1/img4.png`}
                    alt="..."
                  />
                </div>
              </div>
              <div className="col-12 col-lg-5">
                <div className="screenshot-3-description">
                  <h3 className="mb-3">
                    {t("product360ScreenshotThreeTitle")}
                  </h3>
                  <div className="mb-3">
                    <h5>{t("product360ScreenshotThreeItemOneTitle")}</h5>
                    <p>{t("product360ScreenshotThreeItemOneDescription")}</p>
                  </div>
                  <div>
                    <h5>{t("product360ScreenshotThreeItemTwoTitle")}</h5>
                    <p>{t("product360ScreenshotThreeItemTwoDescription")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* product details footer */}
        <div className="product-footer bg-gradient text-center py-5 my-5">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/360_logo.png`}
            width={250}
            alt="Controle 360"
          />
          <p className="text-white">{t("product360FooterDescription")}</p>
          <button
            className="btn text-uppercase font-weight-bold"
            data-toggle="modal"
            data-target="#exampleModal"
            style={{
              fontSize: "12px",
              fontFamily: "inherit",
              padding: "10px",
              backgroundColor: "#00d1ff",
            }}
          >
            {t("requestDemoBtnValue")}
          </button>
        </div>

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default withTranslation()(ProductDetailsOne);
