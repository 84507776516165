import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class SliderOne extends Component {
  constructor(props) {
    super(props);
  }

  handleResize() {}
  render() {
    const { t } = this.props;
    return (
      <div className="slider-activation">
        {/* Start Single Slide */}
        <div className="slide slide-style-1 slider-fixed--height d-flex align-items-center bg_image bg_image--1">
          <div className="container position-relative">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner">
                  <h1 className="title text-white">
                    {t("sliderOneMainTitle")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Single Slide */}
      </div>
    );
  }
}
export default withTranslation()(SliderOne);
