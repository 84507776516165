import React, { Component, Fragment } from "react";
import {
  ACCESS_TOKEN,
  AUTHENTICATED,
  GOOGLE_AUTH_URL,
  LINKEDIN_AUTH_URL,
} from "../../constants";
import { Redirect } from "react-router-dom";
import linkedinLogo from "../../img/linkedin.png";
import googleLogo from "../../img/google.png";
import { login } from "../../util/APIUtils";
import Alert from "react-s-alert";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";
import "./Login.css";

import { Link } from "react-router-dom";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usernameOrEmail: "",
      password: "",
      errors: { message: "" },
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    // If the OAuth2 login encounters an error, the user is redirected to the /login page with an error.
    // Here we display the error and then remove the error query parameter from the location.
    // if (this.props.location.state && this.props.location.state.error) {
    //   setTimeout(() => {
    //     Alert.error(this.props.location.state.error, {
    //       timeout: 5000,
    //     });
    //     this.props.history.replace({
    //       pathname: this.props.location.pathname,
    //       state: {},
    //     });
    //   }, 100);
    // }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(event) {
    event.preventDefault();
    const loginRequest = Object.assign({}, this.state);
    login(loginRequest)
      .then((response) => {
        localStorage.setItem(ACCESS_TOKEN, response.accessToken);
        localStorage.setItem(AUTHENTICATED, true);
        this.props.onLogin(this.props.history);
      })
      .catch((error) => {
        if (error.status === 401) {
          this.setState({
            errors: {
              message:
                "Your Username or Password is incorrect.Please try again!",
            },
          });
        } else {
          this.setState({
            errors: {
              message:
                error.message ||
                "Sorry! Something went wrong. Please try again!",
            },
          });
        }
      });
  }

  alert(message) {
    return (
      <div className="alert alert-danger" role="alert">
        {message}
      </div>
    );
  }

  render() {
    console.log("this.props ModalLogin", this.props);
    if (this.props.authenticated) {
      return (
        <Redirect
          to={{
            pathname: "/",
            state: { from: this.props.location },
          }}
        />
      );
    }
    console.log("window.length", window.length);
    return (
      <React.Fragment>
        <div className="row clearfix">
          {/* Form Column */}
          <div
            className="form-column col-lg-12 col-md-12 col-sm-12"
            // style={{ width: "50%" }}
          >
            <div className="inner-column">
              {this.state.errors.message === ""
                ? null
                : this.alert(this.state.errors.message)}
              {/*Contact Form*/}
              <div className="contact-form">
                <form
                  onSubmit={this.onSubmit}
                  id="contact-form"
                  style={{ width: "500px" }}
                  // style={{ width: window.length > 500 ? "500px" : "" }}
                >
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Email"
                      name="usernameOrEmail"
                      value={this.state.usernameOrEmail}
                      onChange={this.onChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      placeholder="Mot de passe"
                      autoComplete="current-password"
                      name="password"
                      value={this.state.password}
                      onChange={this.onChange}
                    />
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn col-lg-12"
                      style={{
                        fontFamily: "inherit",
                        color: "#ffffff",
                        background: "rgba(95, 66, 253, 0.84)",
                      }}
                    >
                      S'identifier
                    </button>
                  </div>
                  <div className="form-group">
                    <Link
                      to="/forgot-pwd"
                      className="btn col-lg-12"
                      style={{
                        color: "rgba(95, 66, 253, 0.84)",
                        border: "1px solid rgba(95, 66, 253, 0.84)",
                        // whiteSpace: "nowrap",
                      }}
                    >
                      Mot de passe oublié ?
                    </Link>
                  </div>
                  <div className="form-group">
                    <Link
                      to="/register"
                      className="btn col-lg-12"
                      style={{
                        color: "rgba(95, 66, 253, 0.84)",
                        border: "1px solid rgba(95, 66, 253, 0.84)",
                      }}
                    >
                      S'inscrire
                    </Link>
                  </div>
                  <div className="form-group">
                    <a href={GOOGLE_AUTH_URL}>
                      <button
                        style={{
                          border: "1px solid #F55549",
                          width: "100%",
                          borderRadius: "5px",
                          height: "50px",
                        }}
                      >
                        <img
                          src={googleLogo}
                          alt="Google"
                          style={{ width: "30px", margin: "5px" }}
                        />
                        S'authentifier avec Google
                      </button>
                    </a>
                  </div>

                  <div className="form-group">
                    <a href={LINKEDIN_AUTH_URL}>
                      <button
                        style={{
                          border: "1px solid #0076B4",
                          width: "100%",
                          borderRadius: "5px",
                          height: "50px",
                        }}
                      >
                        <img
                          src={linkedinLogo}
                          alt="Linkedin"
                          style={{ width: "30px", margin: "5px" }}
                        />
                        S'authentifier avec Linkedin
                      </button>
                    </a>
                  </div>
                </form>
                {/* <a href="./signup" className="col-12 btn btn-dark">
                          vous avez besoin d'un compte ?{" "}
                          <a href="./signup">Créez-le maintenant</a>
                        </a> */}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Login;
