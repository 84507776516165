import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";
import { withTranslation } from "react-i18next";
import RequestDemoModal from "./RequestDemoModal";
import "./productDetails.css";

class ProductDetailsThree extends Component {
  constructor() {
    super();
    this.state = {
      phoneValue: "",
    };
  }

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="AI Fraud Detection" />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div className="bg-gradient pt--120 pb--190">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="text-center pt--100">
                  <h2 className="product-header-title text-white">
                    {t("productFdiHeaderDescription")}
                  </h2>
                  <p className="product-header-title-description text-white">
                    {t("productFdiHeaderSubDescription")}
                  </p>

                  <RequestDemoModal productName="AI Fraud Detection" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        <div className="product-header-img text-center">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/fdi_header.png`}
            alt="fdi_header"
            className="rounded"
          />
        </div>

        {/* product overview */}
        <div className="overview">
          <div className="container">
            <div className="div-1 text-center d-none d-lg-block">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/product/p2/img4.png`}
                className="rounded float-left"
                alt="..."
              />
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/product/p2/img3.png`}
                className="rounded"
                alt="..."
                style={{ marginTop: "100px" }}
              />

              <img
                src={`${process.env.PUBLIC_URL}/assets/images/product/p2/img2.png`}
                className="rounded float-right"
                alt="..."
              />
            </div>

            <div className="div-2 text-center my-5">
              <h5 className="mb-2">{t("productFdiOverviewTitle")}</h5>
              <p>{t("productFdiOverviewDescription")}</p>
            </div>

            <div className="div-3 ai-fraud text-center">
              <p className="font-weight-bold text-white">
                <span className="small">
                  {t("productFdiOverviewQuoteTitle")}
                </span>
                <br></br>
                <span> &#x275D;</span> {t("productFdiOverviewQuote")}
                <span> &#x275E;</span>
              </p>
            </div>
          </div>
        </div>

        {/* features */}
        <div className="card-features pt--120">
          <div className="container">
            <h4 className="text-center">{t("productFdiFeaturesTitle")}</h4>
            <div className="row mt-5">
              <div className="col-xs-12 col-md-6 col-lg-4 mb-3">
                <div className="feature card-f1">
                  <div className="content">
                    <h6 className="text-white">
                      {t("productFdiFeatureOneTitle")}
                    </h6>
                    <p>{t("productFdiFeatureOneDescription")}</p>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-6 col-lg-4 mb-3">
                <div className="feature card-f2">
                  <div className="content">
                    <h6 className="text-white">
                      {t("productFdiFeatureTwoTitle")}
                    </h6>
                    <p>{t("productFdiFeatureTwoDescription")}</p>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-6 col-lg-4 mb-3">
                <div className="feature card-f3">
                  <div className="content">
                    <h6 className="text-white">
                      {t("productFdiFeatureThreeTitle")}
                    </h6>
                    <p>{t("productFdiFeatureThreeDescription")}</p>
                  </div>
                </div>
              </div>

              <div className="col-xs-12 col-md-6 col-lg-4 mb-3">
                <div className="feature card-f4">
                  <div className="content">
                    <h6 className="text-white">
                      {t("productFdiFeatureFourTitle")}
                    </h6>
                    <p>{t("productFdiFeatureFourDescription")}</p>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-6 col-lg-4 mb-3">
                <div className="feature card-f5">
                  <div className="content">
                    <h6 className="text-white">
                      {t("productFdiFeatureFiveTitle")}
                    </h6>
                    <p>{t("productFdiFeatureFiveDescription")}</p>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-6 col-lg-4">
                <div className="feature card-f6">
                  <div className="content">
                    <h6 className="text-white">
                      {t("productFdiFeatureSixTitle")}
                    </h6>
                    <p>{t("productFdiFeatureSixDescription")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* use cases */}
        <div className="ai-fraud-usecases bg-gradient pt--50 pb--30">
          <h3 className="text-center text-white">{t("productFdiWhyTitle")}</h3>
          <div className="container">
            <div className="row justify-content-center mt-5">
              <div className="col-12 col-lg-4 col-md-6 mb-3">
                <div className="usecase">
                  <p> {t("productFdiWhyOne")}</p>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-md-6 mb-3">
                <div className="usecase">
                  <p> {t("productFdiWhyTwo")}</p>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-md-6 mb-3">
                <div className="usecase">
                  <p> {t("productFdiWhyThree")}</p>
                </div>
              </div>

              <div className="col-12 col-lg-4 col-md-6 mb-3">
                <div className="usecase">
                  <p> {t("productFdiWhyFour")}</p>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-md-6">
                <div className="usecase">
                  <p> {t("productFdiWhyFive")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* outline */}
        <div className="ai-fraud-outline text-center py-5 my-5">
          <h4>{t("productFdiOutlineTitle")}</h4>
          <p>{t("productFdiOutlineDescription")}</p>
        </div>

        {/* product details footer */}
        <div className="product-footer bg-gradient text-center py-5 my-5">
          <h6 className=" text-white">{t("productFdiFooterDescriptionOne")}</h6>
          <p className="text-white">{t("productFdiFooterDescriptionTwo")}</p>
          <button
            className="btn text-uppercase font-weight-bold"
            data-toggle="modal"
            data-target="#exampleModal"
            style={{
              fontSize: "12px",
              fontFamily: "inherit",
              padding: "10px",
              backgroundColor: "#00d1ff",
            }}
          >
            {t("requestDemoBtnValue")}
          </button>
        </div>

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default withTranslation()(ProductDetailsThree);
