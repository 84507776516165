import React, { Component } from "react";
import { renewPassowrd } from "../../util/APIUtils";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      sent: false,
      formErrors: {
        email: "",
      },
      errors: { message: "" },
    };
    this.validateForm = this.validateForm.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    console.log(this.props);
  }

  validateEmail(email) {
    var regexMail = /.+@.+\.[A-Za-z]+$/;
    return regexMail.test(email);
  }

  validateForm() {
    let { email } = this.state;
    let formErrors = this.state.formErrors;

    formErrors.email =
      email.length > 0 ? "" : "Veuillez entrez un email de récupération";

    if (email.length > 0) {
      formErrors.email = this.validateEmail(email)
        ? ""
        : "Veuillez saisir un email valide";
    }

    this.setState({
      formErrors,
    });

    return (
      JSON.stringify(formErrors) ===
      JSON.stringify({
        email: "",
      })
    );
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(event) {
    event.preventDefault();
    let email = this.state.email;
    console.log(email);
    if (this.validateForm()) {
      renewPassowrd(email)
        .then((res) => {
          // this.props.history.push("/renew-email");
          this.setState({ sent: true, errors: { message: "" } });
        })
        .catch((err) => this.setState({ errors: { message: err.message } }));
    }
  }

  alert(message) {
    return (
      <div className="alert alert-danger" role="alert">
        {message}
      </div>
    );
  }

  mailSent() {
    return (
      <div className="alert alert-warning" role="alert">
        Un lien de réinitialisation de mot de passe à été envoyé à l'adresse
        email
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        {/* Start Breadcrump Area */}
        <div
          style={{ height: "10vh" }}
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  {/* <h2 className="title" style={{ color: "#ffffff" }}>CONNEXION</h2> */}
                  {/* <p>Fresh From The Press Discription</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <section
          className="contact-page-section"
          style={{
            height: "60vh",
            width: "600px",
            margin: "auto",
            paddingTop: "200px",
            paddingBottom: "200px",
          }}
        >
          <div className="auto-container">
            <div className="inner-container">
              <h2>Mot de passe oublié?</h2>
              <div className="row clearfix">
                {/* Form Column */}
                <div className="form-column col-lg-12 col-md-12 col-sm-12">
                  <div className="inner-column">
                    {this.state.errors.message === ""
                      ? null
                      : this.alert(this.state.errors.message)}
                    {this.state.sent ? this.mailSent() : null}
                    {/*Contact Form*/}
                    <div
                      className="contact-form"
                      style={{ display: this.state.sent ? "none" : "" }}
                    >
                      <p>Vous pouvez réinitialiser votre mot de passe ici.</p>

                      <form onSubmit={this.onSubmit} id="contact-form">
                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="Email"
                            name="email"
                            value={this.state.email}
                            onChange={this.onChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <button
                            type="submit"
                            className="btn"
                            style={{
                              color: "#ffffff",
                              float: "right",
                              background: "rgba(95, 66, 253, 0.84)",
                            }}
                          >
                            Réinitialiser
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Team Page Section */}
      </React.Fragment>
    );
  }
}

export default ForgotPassword;
