import React, { Component, Fragment } from "react";
import CountUp from "react-countup";
import { withTranslation } from "react-i18next";
import VisibilitySensor from "react-visibility-sensor";

class CounterOne extends Component {
  state = {
    didViewCountUp: false,
  };
  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ didViewCountUp: true });
    }
  };
  render() {
    const { t } = this.props;

    let Data = [
      {
        countNum: `${40}`,
        countTitle: `${t("figureOneValue")}`,
      },
      {
        countNum: 150,
        countTitle: `${t("figureTwoValue")}`,
      },

      {
        countNum: 30,
        countTitle: `${t("figureThreeValue")}`,
      },
      {
        countNum: 60,
        countTitle: `${t("figureFourValue")}`,
      },
    ];

    return (
      <React.Fragment>
        <div className="row">
          {Data.map((value, index) => (
            <div
              className="counterup_style--1 col-lg-3 col-md-3 col-sm-6 col-12"
              key={index}
            >
              <h5 className="counter">
                <VisibilitySensor
                  onChange={this.onVisibilityChange}
                  offset={{ top: 10 }}
                  delayedCall
                >
                  <CountUp
                    end={this.state.didViewCountUp ? value.countNum : 0}
                  />
                </VisibilitySensor>
                {value.countTitle == "Revenue" ? "M" : ""}
              </h5>
              <p className="description" style={{ fontWeight: "bold" }}>
                {value.countTitle}
              </p>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default withTranslation()(CounterOne);
