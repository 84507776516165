import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import { Card, CardBody, Table } from "reactstrap";
import {
  getInterview,
  deleteCompany,
  deleteJob,
  editJob,
  getJob,
} from "../util/APIUtils";

class Consulter extends Component {
  constructor() {
    super();
    this.state = {
      referenceNumber: "",
      externalReferenceNumber: "",
      title: "",
      summary: "",
      seniority: "",
      status: "",
      type: "",
      location: "",
      duration: "",
      source: "",
      startAt: "",
      endAt: "",
      createdAt: "",
      updatedAt: "",
      profil: { name: "" },
      company: {
        id: "",
        name: "",
        address: "",
        phone: "",
        mail: "",
        email: "",
      },
      skillMarks: [],
      etat: "",
      // offer: { endAt: "" },
      formErrors: {
        endAt: "",
      },
    };

    this.onChangeEndDate = this.onChangeEndDate.bind(this);
  }

  componentDidMount() {
    var dateFormat = require("dateformat");
    getInterview(this.props.match.params.Id)
      .then((response) => {
        this.setState({
          type: this.props.match.params.Type,
          date: dateFormat(response.content.date, "yyyy-mm-dd | hh:mm"),
          applicant: response.content.applicant,
          offer: response.content.offer,
          meetingContact: response.content.offer.company.contacts.find(
            (obj) => obj.isDefault === true
          ),
          address: response.content.address,
          skype: response.content.skype,
          cin: response.content.cin,
          comment: response.content.comment,
        });
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  changeState(id) {
    let offer = Object.assign({}, this.state.offer);
    offer.status = "ACTIVE";
    editJob(id, offer)
      .then((response) => {
        console.log(response);
        this.setState({});
      })
      .catch((error) => console.log(error));
  }

  onChangeEndDate(e) {
    let endAt = e.target.value;
    console.log(endAt);
    this.setState((prevState) => ({
      offer: {
        ...prevState.offer,
        endAt: endAt,
      },
    }));
  }

  validateNewEndAt() {
    let { endAt, offer } = this.state;
    let formErrors = this.state.formErrors;
    console.log(endAt);
    console.log(offer.endAt);
    formErrors.endAt = endAt < offer.endAt ? "" : "Date invalide";
    this.setState({ formErrors });
    return (
      JSON.stringify(formErrors) ===
      JSON.stringify({
        endAt: "",
      })
    );
  }

  onSubmit() {
    var dateFormat = require("dateformat");
    let offer = Object.assign({}, this.state.offer);
    offer.status = "ACTIVE";
    editJob(null, offer)
      .then((response) => {
        this.setState((prevState) => ({
          endAt: dateFormat(response.content.endAt, "yyyy-mm-dd"),
          status: response.content.status,
          formErrors: {
            endAt: "",
          },
        }));
      })
      .catch((error) => console.log(error));
  }

  deleteClient(idDatabase) {
    confirmAlert({
      title: "Confirmation suppression",
      message: "Etes vous sure ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteCompany(idDatabase)
              .then(console.log("Deleted"))
              .catch((err) => console.log(err));
            this.setState();
            let path = "/Offre";
            this.props.history.push(path);
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  deleteOffre(idDatabase) {
    confirmAlert({
      title: "Confirmation suppression",
      message: "Etes vous sure ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteJob(idDatabase)
              .then(console.log("Deleted"))
              .catch((err) => console.log(err));
            this.setState();
            let path = "/Offre";
            this.props.history.push(path);
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    return (
      <React.Fragment>
        {/*Page Title*/}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title" style={{ color: "#ffffff" }}>
                    Entretien
                  </h2>
                  {/* <p>Fresh From The Press Discription</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*End Page Title*/}
        {/*Sidebar Page Container*/}
        <div
          className="service-area ptb--80  bg_image bg_image--3"
          style={{ paddingTop: "200px", paddingBottom: "200px" }}
        >
          <div className="container">
            <div>
              <Card style={{ marginTop: "10px", marginBottom: "10px" }}>
                <CardBody>
                  <h3
                    style={{
                      marginBottom: "40px",
                      display: "inline-block",
                    }}
                  >
                    Détails Entretien :
                  </h3>
                  <div className="float-right">
                    {/* <button
                          onClick={this.delete.bind(
                            this,
                            this.props.match.params.Id
                          )}
                          className="btn btn-danger"
                          style={{
                            marginRight: "5px",
                          }}
                        >
                          <i className="fa fa-trash-o" />
                        </button> */}
                    {/* <Link
                          to={
                            "/Candidat/Operation/" +
                            this.props.match.params.Type.toLowerCase() +
                            "/Edit/" +
                            this.props.match.params.Id
                          }
                          className="btn btn-warning"
                        >
                          <i className="fa fa-edit" />
                        </Link> */}
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="general-info">
                        <div className="row">
                          <div className="col-lg-12 col-xl-6">
                            <div className="table-responsive">
                              <Table responsive>
                                <tbody>
                                  <tr>
                                    <th
                                      style={{
                                        color: "rgba(95, 66, 253, 0.84)",
                                      }}
                                    >
                                      Candidat
                                    </th>
                                    <td>
                                      {this.state.applicant === undefined
                                        ? ""
                                        : this.state.applicant.firstName +
                                          " " +
                                          this.state.applicant.lastName}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th
                                      style={{
                                        color: "rgba(95, 66, 253, 0.84)",
                                      }}
                                    >
                                      Client
                                    </th>
                                    <td>
                                      {this.state.offer === undefined
                                        ? ""
                                        : this.state.offer.company.name}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th
                                      style={{
                                        color: "rgba(95, 66, 253, 0.84)",
                                      }}
                                    >
                                      Offre
                                    </th>
                                    <td>
                                      {this.state.offer === undefined
                                        ? ""
                                        : this.state.offer.referenceNumber}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th
                                      style={{
                                        color: "rgba(95, 66, 253, 0.84)",
                                      }}
                                    >
                                      Type
                                    </th>
                                    <td>{this.state.type}</td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </div>
                          <div className="col-lg-12 col-xl-6">
                            <div className="table-responsive" />
                            <Table responsive>
                              <tbody>
                                <tr>
                                  <th
                                    style={{
                                      color: "rgba(95, 66, 253, 0.84)",
                                    }}
                                  >
                                    Date & Heure
                                  </th>
                                  <td>{this.state.date}</td>
                                </tr>
                                <tr>
                                  <th
                                    style={{
                                      color: "rgba(95, 66, 253, 0.84)",
                                    }}
                                  >
                                    Lieux
                                  </th>
                                  <td>{this.state.address}</td>
                                </tr>
                                <tr>
                                  <th
                                    style={{
                                      color: "rgba(95, 66, 253, 0.84)",
                                    }}
                                  >
                                    Personne à contacter
                                  </th>
                                  <td>
                                    {this.state.meetingContact === undefined
                                      ? ""
                                      : this.state.meetingContact.firstName +
                                        " " +
                                        this.state.meetingContact.lastName}
                                  </td>
                                </tr>
                                <tr>
                                  <th
                                    style={{
                                      color: "rgba(95, 66, 253, 0.84)",
                                    }}
                                  >
                                    Skype
                                  </th>
                                  <td>{this.state.skype}</td>
                                </tr>
                                <tr>
                                  <th
                                    style={{
                                      color: "rgba(95, 66, 253, 0.84)",
                                    }}
                                  >
                                    Numéro CIN
                                  </th>
                                  <td>{this.state.cin}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card style={{ marginTop: "10px", marginBottom: "10px" }}>
                <CardBody>
                  <h5 style={{ marginBottom: "20px" }}>Commentaire :</h5>
                  <p>
                    {this.state.comment} Inter haec Orfitus praefecti potestate
                    regebat urbem aeternam ultra modum delatae dignitatis sese
                    efferens insolenter, vir quidem prudens et forensium
                    negotiorum oppido gnarus, sed splendore liberalium
                    doctrinarum minus quam nobilem decuerat institutus, quo
                    administrante seditiones sunt concitatae graves ob inopiam
                    vini: huius avidis usibus vulgus intentum ad motus asperos
                    excitatur et crebros. Et olim licet otiosae sint tribus
                    pacataeque centuriae et nulla suffragiorum certamina set
                    Pompiliani redierit securitas temporis, per omnes tamen
                    quotquot sunt partes terrarum, ut domina suscipitur et
                    regina et ubique patrum reverenda cum auctoritate canities
                    populique Romani nomen circumspectum et verecundum. Ultima
                    Syriarum est Palaestina per intervalla magna protenta,
                    cultis abundans terris et nitidis et civitates habens
                    quasdam egregias, nullam nulli cedentem sed sibi vicissim
                    velut ad perpendiculum aemulas: Caesaream, quam ad honorem
                    Octaviani principis exaedificavit Herodes, et Eleutheropolim
                    et Neapolim itidemque Ascalonem Gazam aevo superiore
                    exstructas.
                  </p>
                </CardBody>
              </Card>
            </div>{" "}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Consulter;
