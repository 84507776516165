import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    fontSize: 20,
    fontWeight: 600,
    // fontStyle: "italic",
    color: "#5841D9",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    // top: theme.spacing(1),
    // color: theme.palette.grey[500],
    color: "#5841D9",
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" timeout="2000" ref={ref} {...props} />;
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    // width: 800,
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const withModal = (WrappedComponent, header, viewComponent) => {
  class HOC extends React.Component {
    constructor() {
      super();
      this.toggle = this.toggle.bind(this);
      this.renderViewComponent = this.renderViewComponent.bind(this);
      this.state = {
        open: false,
        openTaskModal: false,
      };
    }

    componentDidUpdate(prevProps) {
      if (this.props.id === "taskModal") {
        if (this.props.taskModal) {
          this.setState({
            openTaskModal: true,
          });
          this.toggle();
        }
      }
    }

    toggle() {
      this.setState({
        open: !this.state.open,
      });

      if (this.props.id === "taskModal") {
        if (this.state.openTaskModal === true) {
          this.setState(
            {
              openTaskModal: false,
            },
            () => {
              this.props.close();
            }
          );
        }
      }
    }
    /**
     * If viewComponent is an object then onClick would trigger the modal.
     * If it's a function then the toggleModal prop will
     * be passed and the component would have to manually trigger the modal
     */
    renderViewComponent(viewComponent, props) {
      if (typeof viewComponent === "function") {
        return viewComponent(props);
      }

      return <div onClick={this.toggle}>{viewComponent}</div>;
    }

    render() {
      const modalProps = {
        toggleModal: this.toggle,
      };

      return (
        <React.Fragment>
          {this.renderViewComponent(viewComponent, {
            ...this.props,
            ...modalProps,
          })}

          <Dialog
            onClose={this.toggle}
            TransitionComponent={Transition}
            aria-labelledby="customized-dialog-title"
            open={this.state.open}
            maxWidth="xl"
            Width="auto"
            // style={{ width: "50%" }}
          >
            <DialogTitle id="customized-dialog-title" onClose={this.toggle}>
              {header}
            </DialogTitle>
            <DialogContent id="modalContent">
              <WrappedComponent {...this.props} toggleModal={this.toggle} />
            </DialogContent>

            {this.props.delete ? (
              <DialogActions>
                <Button
                  id="yes"
                  onClick={() => {
                    this.props.deleteFunction(this.toggle);
                  }}
                  // color="primary"
                >
                  Oui
                </Button>
                <Button
                  id="no"
                  onClick={() => {
                    this.toggle();
                  }}
                  // color="primary"
                >
                  Non
                </Button>
              </DialogActions>
            ) : null}
          </Dialog>
        </React.Fragment>
      );
    }
  }

  return HOC;
};

export default withModal;
