import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactDataGrid from "react-data-grid";
import { Link } from "react-router-dom";
import { Card, CardBody, Table } from "reactstrap";
import {
  getActivityReport,
  deleteActivityReport,
  getActivityReportFile,
  getResumeFile,
} from "../util/APIUtils";

const columnsWeek = [
  { key: "week", name: "", editable: false },
  { key: "mon", name: "Lundi", editable: true },
  { key: "tue", name: "Mardi", editable: true },
  { key: "wed", name: "Mercredi", editable: true },
  { key: "thu", name: "Jeudi", editable: true },
  { key: "fri", name: "Vendredi", editable: true },
  { key: "sat", name: "Samedi", editable: true },
  { key: "sun", name: "Dimanche", editable: true },
];

class Consulter extends Component {
  constructor() {
    super();
    this.state = {
      startDate: "",
      endDate: "",
      workingDaysCount: "",
      month: "",
      year: "",
      createdAt: "",
      updatedAt: "",
      file: "",
      orderSheet: null,
      resource: "",
      rowsWeek: [],
      errors: {},
    };

    this.downloadDocument = this.downloadDocument.bind(this);

    this.downloadActivityReportFile = this.downloadActivityReportFile.bind(
      this
    );
    this.delete = this.delete.bind(this);
  }

  componentDidMount() {
    var dateFormat = require("dateformat");
    getActivityReport(this.props.match.params.Id)
      .then((response) => {
        this.setState(
          {
            startDate: response.content.startDate,
            endDate: response.content.endDate,
            workingDaysCount: response.content.workingDaysCount,
            month: response.content.month,
            year: response.content.year,
            createdAt: response.content.createdAt,
            updatedAt: response.content.updatedAt,
            file: response.content.file,
            orderSheet: response.content.orderSheet,
            resource: response.content.orderSheet.resource,
            activityReportData: response.content.activityReportData,
          },
          () => {
            console.log(this.state.activityReportData);
            let Days = Object.assign([], this.state.activityReportData.days);
            console.log(this.state.activityReportData.days);
            Days.sort(function (a, b) {
              if (a.date < b.date) {
                return -1;
              } else {
                return 1;
              }
            });
            console.log(Days);
            var numberWeeks = Days.length / 7;
            console.log(numberWeeks);
            let rowsWeek = [];
            for (var i = 0; i < numberWeeks; i++) {
              var j = i * 7;
              rowsWeek.push({
                week:
                  dateFormat(Days[j].date, "yyyy-mm-dd") +
                  " | " +
                  dateFormat(Days[j + 6].date, "yyyy-mm-dd"),
                mon: Days[j].numberHoursWorked,
                tue: Days[j + 1].numberHoursWorked,
                wed: Days[j + 2].numberHoursWorked,
                thu: Days[j + 3].numberHoursWorked,
                fri: Days[j + 4].numberHoursWorked,
                sat: Days[j + 5].numberHoursWorked,
                sun: Days[j + 6].numberHoursWorked,
              });
            }
            this.setState({ rowsWeek });
          }
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  downloadDocument(documentName) {
    getResumeFile(documentName)
      .then((response) => response.blob())
      .then(function (myBlob) {
        const url = window.URL.createObjectURL(myBlob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", documentName); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  }

  downloadActivityReportFile() {
    getActivityReportFile(this.props.match.params.Id)
      .then((response) => response.blob())
      .then(function (myBlob) {
        const url = window.URL.createObjectURL(myBlob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "cra.pdf");
        document.body.appendChild(link);
        link.click();
      });
  }

  delete(id) {
    confirmAlert({
      title: "Confirmation suppression",
      message: "Etes vous sure ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            console.log(id);
            deleteActivityReport(id)
              .then((res) => {
                console.log("Deleted");
                let path = "/activity-report";
                this.props.history.push(path);
              })
              .catch((err) => console.log(err));
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    var dateFormat = require("dateformat");
    return (
      <div>
        {/*Page Title*/}
        <section
          className="page-title"
          style={{ backgroundImage: "url(images/background/laptop.jpg)" }}
        >
          <div className="auto-container">
            <div className="content">
              <h1>CRAs </h1>
              <ul className="page-breadcrumb">
                <li>
                  <a href="./">Home</a>
                </li>
                <li>CRAs </li>
              </ul>
            </div>
          </div>
        </section>

        {/*End Page Title*/}
        {/*Sidebar Page Container*/}
        <div className="sidebar-page-container">
          <div className="auto-container">
            <div className="row clearfix">
              {/*Content Side*/}

              {this.props.authenticated !== true ? null : (
                <div
                  className="sidebar-side col-lg-3 col-md-12 col-sm-12"
                  style={{ "box-shadow": "0px 0px 15px rgba(0,0,0,0.20)" }}
                >
                  <aside className="sidebar default-sidebar">
                    {/* Banner Widget*/}
                    <div className="sidebar-widget banner-widget">
                      <div
                        className="widget-content"
                        style={{ backgroundImage: "url(images/Gray.jpg)" }}
                      >
                        <div className="logo">
                          <img src="images/resource/profile.png" alt="" />
                        </div>
                        <h2>
                          {this.props.currentUser != null
                            ? this.props.currentUser.username
                            : null}
                        </h2>
                      </div>
                    </div>

                    {/*Blog Category Widget*/}

                    <div className="sidebar-widget sidebar-blog-category">
                      <ul className="blog-cat">
                        <li>
                          <a href="/profil">Profil</a>
                        </li>
                        <li>
                          <a href="/resume">CV</a>
                        </li>
                        <li>
                          <a href="/ordersheet">Bons de commandes</a>
                        </li>
                        <li>
                          <a href="/activity-report">CRAs</a>
                        </li>
                      </ul>
                    </div>
                  </aside>
                </div>
              )}

              <div
                className={
                  this.props.authenticated !== true
                    ? "content-side col-lg-9 col-md-12 col-sm-12"
                    : "content-side col-lg-9 col-md-12 col-sm-12"
                }
                style={{ "padding-left": "40px" }}
              >
                <div className="services-single">
                  <div>
                    <Card
                      style={{
                        marginBottom: "40px",
                      }}
                    >
                      <CardBody>
                        <h3
                          style={{
                            marginBottom: "40px",
                            display: "inline-block",
                          }}
                        >
                          Détails CRA :
                        </h3>
                        <div className="float-right">
                          <button
                            onClick={this.delete.bind(
                              this,
                              this.props.match.params.Id
                            )}
                            className="btn btn-danger"
                            style={{
                              marginRight: "5px",
                            }}
                          >
                            <i className="fa fa-trash-o"></i>
                          </button>
                          <Link
                            to={
                              "/activity-report/edit/" +
                              this.props.match.params.Id
                            }
                            className="btn btn-warning"
                          >
                            <i className="fa fa-edit"></i>
                          </Link>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="general-info">
                              <div className="row">
                                <div className="col-lg-12 col-xl-6">
                                  <div className="table-responsive">
                                    <Table responsive>
                                      <tbody>
                                        <tr>
                                          <th
                                            style={{
                                              color: "rgba(95, 66, 253, 0.84)",
                                            }}
                                          >
                                            Nombre de jours
                                          </th>
                                          <td>{this.state.workingDaysCount}</td>
                                        </tr>
                                        <tr>
                                          <th
                                            style={{
                                              color: "rgba(95, 66, 253, 0.84)",
                                            }}
                                          >
                                            Client
                                          </th>
                                          <td>
                                            {this.state.orderSheet === null
                                              ? ""
                                              : this.state.orderSheet.company
                                                  .name}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th
                                            style={{
                                              color: "rgba(95, 66, 253, 0.84)",
                                            }}
                                          >
                                            Mois
                                          </th>
                                          <td>
                                            {" "}
                                            {this.state.month +
                                              "/" +
                                              this.state.year}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-xl-6">
                                  <div className="table-responsive" />
                                  <Table responsive>
                                    <tbody>
                                      <tr>
                                        <th
                                          style={{
                                            color: "rgba(95, 66, 253, 0.84)",
                                          }}
                                        >
                                          Date de début
                                        </th>
                                        <td>
                                          {" "}
                                          {dateFormat(
                                            this.state.startDate,
                                            "dd/mm/yyyy"
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th
                                          style={{
                                            color: "rgba(95, 66, 253, 0.84)",
                                          }}
                                        >
                                          Date de fin
                                        </th>
                                        <td>
                                          {" "}
                                          {dateFormat(
                                            this.state.endDate,
                                            "dd/mm/yyyy"
                                          )}
                                        </td>
                                      </tr>

                                      <tr>
                                        <th
                                          style={{
                                            color: "rgba(95, 66, 253, 0.84)",
                                          }}
                                        >
                                          Fichier
                                        </th>
                                        <td>
                                          <button
                                            onClick={this.downloadDocument.bind(
                                              this,
                                              this.state.file.name
                                            )}
                                            className="btn btn-success"
                                          >
                                            <i className="fa fa-cloud-download" />
                                          </button>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    <Card
                      style={{
                        marginBottom: "40px",
                      }}
                    >
                      <CardBody>
                        <h5
                          style={{
                            marginBottom: "40px",
                            display: "inline-block",
                          }}
                        >
                          Bon de commande :
                        </h5>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="general-info">
                              <div className="row">
                                <div className="col-lg-12 col-xl-12">
                                  <div className="table-responsive">
                                    <Table responsive>
                                      <tbody>
                                        <tr>
                                          <th
                                            style={{
                                              color: "rgba(95, 66, 253, 0.84)",
                                            }}
                                          >
                                            Nom
                                          </th>
                                        </tr>

                                        <tr>
                                          <td>
                                            {this.state.resource === null
                                              ? ""
                                              : this.state.resource.firstName +
                                                " " +
                                                this.state.resource.lastName}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <div className="row">
                          <div className="col-lg-10">
                            <h5
                              style={{
                                marginBottom: "40px",
                                display: "inline-block",
                              }}
                            >
                              Jours CRA
                            </h5>
                          </div>
                          <div className="col-lg-2">
                            <input
                              style={{ width: "80%" }}
                              type="button"
                              onClick={this.downloadActivityReportFile}
                              className="btn btn-primary btn-block float-right"
                              value="Télécharger"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="general-info">
                              <div className="row">
                                <div className="col-lg-12 col-xl-12">
                                  <div className="table-responsive">
                                    <ReactDataGrid
                                      columns={columnsWeek}
                                      rowGetter={(i) => this.state.rowsWeek[i]}
                                      rowsCount={10}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Consulter;
