import React, { Component } from "react";

import { FormGroup, FormText, Input, Label, Table } from "reactstrap";

class Edit extends Component {
  constructor() {
    super();
    this.state = {
      diplomaName: "",
      schoolName: "",
      startAt: "",
      endAt: "",
      applicant: "",
      formErrors: {
        diplomaName: "",
        schoolName: "",
        startAt: "",
        endAt: "",
        applicant: "",
      },
      open: false,
      skills: [],
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    console.log(this.props.open);
    var dateFormat = require("dateformat");
    this.setState({
      id: this.props.Id,
      diplomaName: this.props.degree.diplomaName,
      schoolName: this.props.degree.schoolName,
      startAt: dateFormat(this.props.degree.startAt, "yyyy-mm-dd"),
      endAt: dateFormat(this.props.degree.endAt, "yyyy-mm-dd"),
      applicant: this.props.degree.applicant,
    });
  }

  validateForm() {
    let { diplomaName, schoolName, startAt, endAt, applicant } = this.state;

    let formErrors = this.state.formErrors;

    formErrors.diplomaName =
      diplomaName.length > 0 ? "" : "Veuillez entrer le nom du diplôme";
    formErrors.schoolName =
      schoolName.length > 0 ? "" : "Veuillez entrer le nom de l'école";
    formErrors.startAt = startAt.length > 0 ? "" : "Veuillez entrer un date";
    // formErrors.endAt = endAt.length > 0 ? "" : "Veuillez entrer un date";

    if (startAt !== "" && endAt !== "" && startAt < endAt) {
      formErrors.endAt = "";
    }

    if (
      formErrors.startAt === "" &&
      formErrors.endAt === "" &&
      endAt !== "" &&
      startAt > endAt
    ) {
      formErrors.endAt =
        "La date de fin ne peut pas être inférieure au date de début";
    }

    this.setState({
      formErrors,
    });

    return (
      JSON.stringify(formErrors) ===
      JSON.stringify({
        diplomaName: "",
        schoolName: "",
        startAt: "",
        endAt: "",
        applicant: "",
      })
    );
  }

  onSubmit(e) {
    e.preventDefault();

    const Degree = {
      diplomaName: this.state.diplomaName,
      schoolName: this.state.schoolName,
      startAt: this.state.startAt + "T00:00:00",
    };

    this.state.endAt === ""
      ? (Degree.endAt = null)
      : (Degree.endAt = this.state.endAt + "T00:00:00");

    if (this.validateForm()) {
      this.props.editDegree(Degree, this.props.arrayId);
      this.props.toggleModal();
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <form
        onSubmit={this.onSubmit}
        onChange={this.onChange}
        style={{ width: "600px" }}
      >
        <FormGroup>
          <Label for="diplomaName">Titre</Label>
          <Input
            id="diplomaName"
            name="diplomaName"
            onChange={this.onChange}
            value={this.state.diplomaName}
          />
          <FormText color="danger">
            {this.state.formErrors.diplomaName}
          </FormText>
        </FormGroup>

        <FormGroup>
          <Label for="startAt">Date Début</Label>
          <Input
            id="startAt"
            name="startAt"
            type="date"
            onChange={this.onChange}
            value={this.state.startAt}
          />
          <FormText color="danger">{this.state.formErrors.startAt}</FormText>
        </FormGroup>

        <FormGroup>
          <Label for="startAt">Date Fin</Label>
          <Input
            id="endAt"
            name="endAt"
            type="date"
            onChange={this.onChange}
            value={this.state.endAt}
          />
          <FormText color="danger">{this.state.formErrors.endAt}</FormText>
        </FormGroup>

        <FormGroup>
          <Label for="schoolName">Ecole</Label>
          <Input
            id="schoolName"
            name="schoolName"
            type="textarea"
            onChange={this.onChange}
            value={this.state.schoolName}
          />
          <FormText color="danger">{this.state.formErrors.schoolName}</FormText>
        </FormGroup>

        <input
          id="editDegree"
          type="submit"
          value="Éditer"
          className="btn btn-success float-right"
        />

        <input
          id="cancelEditDegree"
          type="button"
          onClick={this.props.toggleModal}
          value="Retour"
          className="btn btn-warning mr-1 float-right"
        />
      </form>
    );
  }
}

export default Edit;
