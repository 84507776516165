import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";
import { withTranslation } from "react-i18next";
import RequestDemoModal from "./RequestDemoModal";
import "./productDetails.css";

class ProductDetailsThree extends Component {
  constructor() {
    super();
    this.state = {
      phoneValue: "",
    };
  }

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="In-Store Advertising (PLV)" />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div className="bg-gradient pt--120 pb--190">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="text-center pt--100">
                  <h2 className="product-header-title text-white">
                    {t("productIsaHeaderDescription")}
                  </h2>
                  <p className="product-header-title-description text-white">
                    {t("productIsaHeaderSubDescription")}
                  </p>

                  <RequestDemoModal productName="In-Store Advertising (PLV)" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        <div className="product-header-img text-center">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/isa_header.png`}
            alt="isa_header"
            className="rounded"
          />
        </div>

        {/* product overview */}
        <div className="container">
          <div className="overview">
            <div className="div-2 text-center my-5">
              <p>{t("productIsaOverview")}</p>
            </div>

            <div className="div-3 plv text-center">
              <p className="font-weight-bold text-white">
                <span> &#x275D;</span> {t("productIsaOverviewQuote")}
                <span> &#x275E;</span>
              </p>
            </div>
          </div>
        </div>

        {/* features */}
        <div className="container">
          <div className="features pt--50 mt-5">
            <h4 className="text-center">{t("productIsaFeaturesTitle")}</h4>
            <div className="row justify-content-center mt-5">
              <div className="col-xs-12 col-md-6 mb-3">
                <div className="feature text-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/product/p3/check_circle.png`}
                    alt="..."
                  />
                  <div className="feature-content">
                    <h6>{t("productIsaFeatureOneTitle")}</h6>
                    <p>{t("productIsaFeatureOneDescription")}</p>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-6 mb-3">
                <div className="feature text-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/product/p3/check_circle.png`}
                    alt="..."
                  />
                  <div className="feature-content">
                    <h6>{t("productIsaFeatureTwoTitle")}</h6>
                    <p>{t("productIsaFeatureTwoDescription")}</p>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-6 mb-3">
                <div className="feature text-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/product/p3/check_circle.png`}
                    alt="..."
                  />
                  <div className="feature-content">
                    <h6>{t("productIsaFeatureThreeTitle")}</h6>
                    <p>{t("productIsaFeatureThreeDescription")}</p>
                  </div>
                </div>
              </div>

              <div className="col-xs-12 col-md-6">
                <div className="feature text-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/product/p3/check_circle.png`}
                    alt="..."
                  />
                  <div className="feature-content">
                    <h6>{t("productIsaFeatureFourTitle")}</h6>
                    <p>{t("productIsaFeatureFourDescription")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* use cases */}
        <div className="usecases bg-gradient pt--50 pb--30">
          <h3 className="text-center text-white">
            {t("productIsaKeyFeaturesTitle")}
          </h3>
          <div className="container">
            <div className="row mt-5">
              <div className="col-12 col-lg-4 col-md-6 mb-3">
                <div className="usecase">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/product/p3/cost.png`}
                    alt="..."
                  />
                  <div className="content">
                    <h6 className="text-white">
                      {t("productIsaKeyFeatureOneTitle")}
                    </h6>
                    <p>{t("productIsaKeyFeatureOneDescription")}</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-md-6 mb-3">
                <div className="usecase">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/product/p3/printer.png`}
                    alt="..."
                  />
                  <div className="content">
                    <h6 className="text-white">
                      {t("productIsaKeyFeatureTwoTitle")}
                    </h6>
                    <p>{t("productIsaKeyFeatureTwoDescription")}</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-md-6">
                <div className="usecase">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/product/p3/megaphone.png`}
                    alt="..."
                  />
                  <div className="content">
                    <h6 className="text-white">
                      {t("productIsaKeyFeatureThreeTitle")}
                    </h6>
                    <p>{t("productIsaKeyFeatureThreeDescription")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* benefits */}
        <div className="plv-screenshot mt-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-7">
                <div className="screenshot">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/product/p3/img2.png`}
                    alt="..."
                  />
                </div>
              </div>
              <div className="col-12 col-lg-5">
                <div className="screenshot-description">
                  <h3 className="mb-3">{t("productIsaScreenshotTitle")}</h3>
                  <div className="mb-3">
                    <h5>{t("productIsaScreenshotItemOneTitle")}</h5>
                    <p>{t("productIsaScreenshotItemOneDescription")}</p>
                  </div>
                  <div className="mb-3">
                    <h5>{t("productIsaScreenshotItemTwoTitle")}</h5>
                    <p>{t("productIsaScreenshotItemTwoDescription")}</p>
                  </div>
                  <div>
                    <h5>{t("productIsaScreenshotItemThreeTitle")}</h5>
                    <p>{t("productIsaScreenshotItemThreeDescription")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* product details footer */}
        <div className="product-footer bg-gradient text-center py-5 my-5">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/isa_footer.png`}
            alt="isa_footer"
            className="picture rounded"
          />

          <p className="text-white">{t("productIsaFooterDescription")}</p>

          <button
            className="btn text-uppercase font-weight-bold"
            data-toggle="modal"
            data-target="#exampleModal"
            style={{
              fontSize: "12px",
              fontFamily: "inherit",
              padding: "10px",
              backgroundColor: "#00d1ff",
            }}
          >
            {t("requestDemoBtnValue")}
          </button>
        </div>

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default withTranslation()(ProductDetailsThree);
