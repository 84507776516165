import React, { Component } from "react";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import {
  deleteExperience,
  deleteDegree,
  deleteManualResume,
  getAllJobs,
  getExperienceByApplicantId,
  getDegreeByApplicantId,
  getManualResumeByApplicantId,
} from "../util/APIUtils";

import EditExperience from "./experience/edit";
import EditDegree from "./degree/edit";
import EditSkills from "./skill/edit";
import AddExperience from "./experience/add";
import AddDegree from "./degree/add";
import AddSkills from "./skill/add";

import Popup from "reactjs-popup";
import { confirmAlert } from "react-confirm-alert";

export default class Offers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      manualResumeStatus: false,
      manualResume: {},
      experiences: [],
      diplomas: [],
      subskills: [],
      subskillNames: [],
      data: [],
      selectSkills: [],
      filteredData: [],
      columns: [],
      searchInput: "",
      errors: { message: "" },
      modal: false,
      open: false,
      openE: false,
      openD: false,
      openS: false,
    };

    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);

    this.closeModalE = this.closeModalE.bind(this);
    this.openModalE = this.openModalE.bind(this);

    this.closeModalD = this.closeModalD.bind(this);
    this.openModalD = this.openModalD.bind(this);

    this.closeModalS = this.closeModalS.bind(this);
    this.openModalS = this.openModalS.bind(this);

    this.toggle = this.toggle.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  componentDidMount() {
    console.log(this.props.currentUser);
    let idApplicant = this.props.currentUser.id;
    getExperienceByApplicantId(idApplicant)
      .then((Response) => {
        let experiences = [];
        Response.forEach((element) => {
          experiences.push({
            id: element.id,
            jobTitle: element.jobTitle,
            company: element.company,
            description: element.description,
            startAt: element.startAt,
            endAt: element.endAt,
            applicant: element.applicant,
          });
        });
        this.setState({ experiences });
        console.log(experiences);
        console.log(this.props);
      })
      .catch((err) => console.log(err));

    getDegreeByApplicantId(idApplicant)
      .then((Response) => {
        let diplomas = [];
        Response.forEach((element) => {
          diplomas.push({
            id: element.id,
            diplomaName: element.diplomaName,
            schoolName: element.schoolName,
            startAt: element.startAt,
            endAt: element.endAt,
            applicant: element.applicant,
          });
        });
        this.setState({ diplomas });
        console.log(diplomas);
        console.log(this.props);
      })
      .catch((err) => console.log(err));

    getAllJobs()
      .then((Response) => {
        let data = [];
        Response.forEach((element) => {
          data.push({
            id: element.id,
            referenceNumber: element.referenceNumber,
            externalReferenceNumber: element.externalReferenceNumber,
            title: element.title,
            summary: element.summary,
            seniority: element.seniority,
            status: element.status,
            type: element.type,
            location: element.location,
            duration: element.duration,
            source: element.source,
            startAt: element.startAt,
            endAt: element.endAt,
            createdAt: element.createdAt,
            updatedAt: element.updatedAt,
            profil: element.profil,
            company: element.company,
            skillMarks: element.skillMarks,
          });
        });
        this.setState({ data: data });
        console.log(data);
        console.log(this.props);
      })
      .catch((err) => console.log(err));

    console.log("--------------------------------------------------");

    getManualResumeByApplicantId(idApplicant)
      .then((Response) => {
        console.log(Response);
        let subskillNames = [];

        Response.subskills.map((data, key) => {
          subskillNames.push(data.name);
        });

        this.setState({
          manualResume: Response,
          subskills: Response.subskills,
          subskillNames,
          manualResumeStatus: true,
        });
      })
      .catch((Error) => {
        this.setState({
          subskills: [],
          subskillNames: [],
          manualResumeStatus: false,
        });
        console.log(Error);
      });
  }

  deleteD(arrayId, id) {
    confirmAlert({
      title: "Confirmation suppression",
      message: "Etes vous sure ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteDegree(id)
              .then((res) => {
                this.componentDidMount();
              })
              .catch((err) =>
                this.setState({ errors: { message: err.message } })
              );
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  deleteE(arrayId, id) {
    confirmAlert({
      title: "Confirmation suppression",
      message: "Etes vous sure ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteExperience(id)
              .then((res) => {
                this.componentDidMount();
              })
              .catch((err) =>
                this.setState({ errors: { message: err.message } })
              );
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  deleteManualResume(id) {
    // window.alert("YESSS U CAN DO IT");
    confirmAlert({
      title: "Confirmation suppression",
      message: "Etes vous sure ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteManualResume(id)
              .then((res) => {
                this.componentDidMount();
              })
              .catch((err) =>
                this.setState({ errors: { message: err.message } })
              );
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  closeModal() {
    console.log("close popup");
    this.setState({ open: false });
    this.componentDidMount();
  }

  openModal() {
    this.setState({ open: true });
  }

  closeModalE() {
    console.log("close popup");
    this.setState({ openE: false });
    this.componentDidMount();
  }

  openModalE() {
    this.setState({ openE: true });
  }

  closeModalD() {
    console.log("close popup");
    this.setState({ openD: false });
    this.componentDidMount();
  }

  openModalD() {
    this.setState({ openD: true });
  }

  closeModalS() {
    console.log("close popup");
    this.setState({ openS: false });
    this.componentDidMount();
  }

  openModalS() {
    this.setState({ openS: true });
  }

  render() {
    var idApplicant = this.props.currentUser.id;
    var dateFormat = require("dateformat");
    return (
      <React.Fragment>
        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title" style={{ color: "#ffffff" }}>
                    CRAs
                  </h2>
                  {/* <p>Fresh From The Press Discription</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="service-area ptb--80  bg_image bg_image--3"
          style={{ paddingTop: "200px", paddingBottom: "200px" }}
        >
          <div className="container">
            <div>
              <Card>
                <CardHeader>
                  <h4>Ajouter CV</h4>
                </CardHeader>
                <CardBody>
                  {/*Content Side*/}

                  <div
                    className={
                      this.props.authenticated !== true
                        ? "content-side col-lg-12 col-md-12 col-sm-12"
                        : "content-side col-lg-12   col-md-12 col-sm-12"
                    }
                    style={{ paddingLeft: "40px" }}
                  >
                    <div className="services-single">
                      <h4
                        style={{
                          display: "inline-block",
                        }}
                      >
                        Experience
                      </h4>
                      <div
                        style={{
                          display: "inline-block",
                          float: "right",
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={this.openModalE}
                        >
                          <i className="fa fa-plus" />
                        </button>
                        <Popup
                          open={this.state.openE}
                          modal
                          closeOnDocumentClick
                          onClose={this.closeModalE}
                        >
                          <AddExperience
                            idApplicant={idApplicant}
                            init={this.componentDidMount}
                            close={this.closeModalE}
                          />
                        </Popup>{" "}
                      </div>

                      <div className="offers">
                        <ul className="jobs__list">
                          {this.state.experiences.map((data, key) => (
                            <li className="jobs__list-item" key={data["id"]}>
                              <section className="job">
                                <span
                                  style={{
                                    paddingTop: "10px",
                                    fontSize: "15px",
                                    fontStyle: "bold",
                                    fontWeight: "600",
                                  }}
                                >
                                  {data["startAt"] !== null
                                    ? dateFormat(data["endAt"], "yyyy-mm") +
                                      " | " +
                                      dateFormat(data["startAt"], "yyyy-mm")
                                    : null}
                                </span>
                                <div className="job__content">
                                  <h2 className="job__title">
                                    <a
                                      href={"/offers/details/" + data["id"]}
                                      rel="noopener"
                                      target="_blank"
                                    >
                                      {data["jobTitle"] !== null
                                        ? data["jobTitle"]
                                        : null}
                                    </a>
                                  </h2>
                                  <div className="job__info">
                                    <strong>
                                      {data["company"] !== null
                                        ? data["company"]
                                        : null}
                                    </strong>
                                    <span className="job__separator"> · </span>{" "}
                                    {data["duration"] !== null
                                      ? data["duration"]
                                      : null}
                                  </div>
                                  <p className="job__description">
                                    {data["description"] !== null
                                      ? data["description"]
                                      : null}
                                  </p>
                                  <div className="actionButtons">
                                    <div
                                      style={{
                                        float: "right",
                                        paddingTop: "10px",
                                      }}
                                    >
                                      {" "}
                                      <button
                                                                          onClick={this.deleteE.bind(
                                          this,
                                          data["id"],
                                          data["id"]
                                        )}
                                        className="btn btn-danger"
                                        style={{
                                          marginRight: "5px",
                                        }}
                                      >
                                        <i className="fa fa-trash-o" />
                                      </button>
                                    </div>
                                    <div
                                      style={{
                                        float: "right",
                                        paddingRight: "5px",
                                        paddingTop: "10px",
                                      }}
                                    >
                                      <EditExperience
                                        Id={data["id"]}
                                        idApplicant={idApplicant}
                                        init={this.componentDidMount}
                                        close={this.closeModal}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>

                    <div
                      className="services-single"
                      style={{ paddingTop: "40px" }}
                    >
                      <h4
                        style={{
                          display: "inline-block",
                        }}
                      >
                        Formation
                      </h4>

                      <div
                        style={{
                          display: "inline-block",
                          float: "right",
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={this.openModalD}
                        >
                          <i className="fa fa-plus" />
                        </button>
                        <Popup
                          open={this.state.openD}
                          modal
                          closeOnDocumentClick
                          onClose={this.closeModalD}
                        >
                          <AddDegree
                            idApplicant={idApplicant}
                            init={this.componentDidMount}
                            close={this.closeModalD}
                          />
                        </Popup>
                      </div>

                      <div className="offers">
                        <ul className="jobs__list">
                          {this.state.diplomas.map((data, key) => (
                            <li className="jobs__list-item" key={data["id"]}>
                              <section className="job">
                                <span
                                  style={{
                                    paddingTop: "10px",
                                    fontSize: "15px",
                                    fontStyle: "bold",
                                    fontWeight: "600",
                                  }}
                                >
                                  {data["startAt"] !== null
                                    ? dateFormat(data["endAt"], "yyyy-mm") +
                                      " | " +
                                      dateFormat(data["startAt"], "yyyy-mm")
                                    : null}
                                </span>
                                <div className="job__content">
                                  <h2 className="job__title">
                                    <a
                                      href={"/offers/details/" + data["id"]}
                                      rel="noopener"
                                      target="_blank"
                                    >
                                      {data["diplomaName"] !== null
                                        ? data["diplomaName"]
                                        : null}
                                    </a>
                                  </h2>

                                  <div className="job__info">
                                    <strong>
                                      {data["schoolName"] !== null
                                        ? data["schoolName"]
                                        : null}
                                    </strong>
                                  </div>

                                  <div
                                    style={{
                                      float: "right",
                                      paddingTop: "10px",
                                    }}
                                  >
                                    {" "}
                                    <button
                                      onClick={this.deleteD.bind(
                                        this,
                                        data["id"],
                                        data["id"]
                                      )}
                                      className="btn btn-danger"
                                      style={{
                                        marginRight: "5px",
                                      }}
                                    >
                                      <i className="fa fa-trash-o" />
                                    </button>
                                  </div>
                                  <div
                                    style={{
                                      float: "right",
                                      paddingRight: "5px",
                                      paddingTop: "10px",
                                    }}
                                  >
                                    <EditDegree
                                      Id={data["id"]}
                                      idApplicant={idApplicant}
                                      init={this.componentDidMount}
                                      close={this.closeModal}
                                    />
                                  </div>
                                </div>
                              </section>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>

                    <div
                      className="services-single"
                      style={{ paddingTop: "40px" }}
                    >
                      <h4
                        style={{
                          display: "inline-block",
                        }}
                      >
                        Skills{" "}
                      </h4>

                      <div
                        style={{
                          display: "inline-block",
                          float: "right",
                        }}
                      >
                        {this.state.manualResumeStatus ? (
                          <button
                                                  onClick={this.deleteManualResume.bind(
                              this,
                              this.state.manualResume.id
                            )}
                            className="btn btn-danger"
                            style={{
                              marginRight: "5px",
                            }}
                          >
                            <i className="fa fa-trash-o" />
                          </button>
                        ) : (
                          <div>
                            {" "}
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={this.openModalS}
                            >
                              <i className="fa fa-plus" />
                            </button>
                            <Popup
                              open={this.state.openS}
                              modal
                              closeOnDocumentClick
                              onClose={this.closeModalS}
                            >
                              <AddSkills
                                idApplicant={idApplicant}
                                init={this.componentDidMount}
                                close={this.closeModalS}
                              />
                            </Popup>
                          </div>
                        )}
                      </div>

                      <div className="offers">
                        <ul className="jobs__list">
                          {this.state.subskillNames.join(" | ")}
                        </ul>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
            {/*End Sidebar Page Container*/}
          </div>
        </div>{" "}
      </React.Fragment>
    );
  }
}
