import React, { Component } from "react";
import { FiChevronUp } from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import { withTranslation } from "react-i18next";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import SliderOne from "../component/slider/SliderOne";
import ServiceTwo from "../elements/service/ServiceTwo";
import CounterOne from "../elements/counters/CounterOne";
import About from "../component/HomeLayout/homeOne/About";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import Helmet from "../component/common/Helmet";
import Product from "../elements/product/Product";
import BrandTwo from "../elements/BrandTwo";

class MainDemo extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <Helmet pageTitle="Main Demo" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.pngX"
          {...this.props}
        />

        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          <SliderOne />
        </div>
        {/* End Slider Area   */}

        {/* Start About Area */}
        <div className="about-area">
          <About />
        </div>
        {/* End About Area */}

        <div className="service-area ptb--80 bg_image bg_image--3">
          <div id="nosService">
            <ServiceTwo />
          </div>
        </div>

        {/* Start Products Area */}
        <div className="product-area ptb--80">
          <div id="ourProducts">
            <Product />
          </div>
        </div>
        {/* Start Products Area */}

        {/* Start Success Stories Area */}
        <div className="portfolio-area ptb--120 bg_color--5">
          <div className="portfolio-sacousel-inner mb--55">
            <Portfolio />
          </div>
        </div>
        {/* End Success Stories Area */}

        {/* Start CounterUp Area */}
        <div className="rn-counterup-area pt--25 pb--110 bg_color--5">
          <div className="container" style={{ paddingTop: "50px" }}>
            <div className="row">
              <div className="col-lg-12">
                <div
                  id="nosChiffres"
                  className="section-title text-center mt--30 mt_md--5 mt_mobile--5 mb_mobile--10"
                >
                  <h3 className="fontWeight500">{t("figuresTitle")}</h3>
                </div>
              </div>
            </div>
            <CounterOne />
          </div>
          {/* </ScrollAnimation> */}
        </div>
        {/* End CounterUp Area */}

        {/* Start Brand Area */}
        <div
          id="nosPartenaires"
          className="rn-brand-area brand-separation bg_color--5 pb--120"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <BrandTwo />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        {/* footer area */}
        <div id="footer">
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}
export default withTranslation()(MainDemo);
