import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import emailjs from "emailjs-com";

class ContactTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from_name: "",
      from_email: "",
      from_subject: "",
      from_message: "",
      response: "",
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const { from_name, from_email, from_subject, from_message } = this.state;

    // email params
    const templateParams = {
      from_name,
      from_email,
      from_subject,
      from_message,
    };

    // send email with emailjs library
    emailjs
      .send(
        "service_wb294bm",
        "template_kmcy167",
        templateParams,
        "vM1bvwI5Unz2ZQflP"
      )
      .then((response) => {
        this.setState({ response: "Email sent successfully!" });
      })
      .catch((error) => {
        this.setState({ response: "Failed to send email." });
      });

    // clear state
    this.setState({
      from_name: "",
      from_email: "",
      from_subject: "",
      from_message: "",
    });
  };

  render() {
    const { t } = this.props;

    return (
      <div className="contact-form--1">
        <div className="container">
          <div className="row row--35 align-items-start">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="section-title text-left mb--50">
                <h2 className="title">{t("contactFormTitle")}</h2>
              </div>
              <div className="form-wrapper">
                <form onSubmit={this.handleSubmit}>
                  <label htmlFor="item01">
                    <input
                      type="text"
                      id="item01"
                      name="from_name"
                      placeholder="Nom *"
                      value={this.state.from_name}
                      onChange={this.handleChange}
                      required
                    />
                  </label>

                  <label htmlFor="item02">
                    <input
                      type="email"
                      id="item02"
                      name="from_email"
                      placeholder="Mail *"
                      value={this.state.from_email}
                      onChange={this.handleChange}
                      required
                    />
                  </label>

                  <label htmlFor="item03">
                    <input
                      type="text"
                      id="item03"
                      name="from_subject"
                      placeholder="Objet"
                      value={this.state.from_subject}
                      onChange={this.handleChange}
                      required
                    />
                  </label>

                  <label htmlFor="item04">
                    <textarea
                      id="item04"
                      name="from_message"
                      placeholder="Message..."
                      value={this.state.from_message}
                      onChange={this.handleChange}
                      required
                    ></textarea>
                  </label>

                  <button
                    type="submit"
                    id="mc-embedded-subscribe"
                    className="rn-button-style--2 btn-solid"
                  >
                    {t("contactFormBtnValue")}
                  </button>
                </form>

                <br></br>
                {this.state.response && <p>{this.state.response}</p>}
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="thumbnail mb_md--30 mb_sm--30">
                <div className="mapouter">
                  <div className="gmap_canvas">
                    <iframe
                      style={{
                        width: "100%",
                        height: "530px",
                        frameborder: "0",
                        scrolling: "no",
                        marginheight: "0",
                        marginwidth: "0",
                      }}
                      id="gmap_canvas"
                      src="https://maps.google.com/maps?q=Neo Tech IT, Florida Parc en face porte sud CASANEARSHOR, Casablanca, Maroc&t=&z=10&ie=UTF8&iwloc=&output=embed"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(ContactTwo);
