import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";
import { withTranslation } from "react-i18next";
import RequestDemoModal from "./RequestDemoModal";
import "./productDetails.css";

class ProductDetailsFour extends Component {
  constructor() {
    super();
    this.state = {
      phoneValue: "",
    };
  }

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Generative AI Chatbot" />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div className="bg-gradient pt--120 pb--190">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="text-center pt--100">
                  <h2 className="product-header-title text-white">
                    {t("productChatbotHeaderDescription")}
                  </h2>
                  <p className="product-header-title-description text-white">
                    {t("productChatbotHeaderSubDescription")}
                  </p>

                  <RequestDemoModal productName="Generative AI Chatbot" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        <div className="product-header-img text-center">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/chatbot_header.png`}
            alt="chatbot_header"
            className="rounded"
          />
        </div>

        {/* product overview */}
        <div className="overview">
          <div className="container">
            <div className="div-2 text-center my-5">
              <p>{t("productChatbotOverview")}</p>
            </div>

            <div className="div-3 chatbot text-center">
              <p className="font-weight-bold text-white">
                <span> &#x275D;</span> {t("productChatbotOverviewQuote")}
                <span> &#x275E;</span>
              </p>
            </div>
          </div>
        </div>

        {/* features */}
        <div className="features pt--50 mt-5">
          <div className="container">
            <h4 className="text-center">{t("productChatbotFeaturesTitle")}</h4>
            <div className="row justify-content-center mt-5">
              <div className="col-xs-12 col-md-6 mb-3">
                <div className="feature text-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/product/p3/check_circle.png`}
                    alt="..."
                  />
                  <div className="feature-content">
                    <h6>{t("productChatbotFeatureOneTitle")}</h6>
                    <p>{t("productChatbotFeatureOneDescription")}</p>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-6 mb-3">
                <div className="feature text-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/product/p3/check_circle.png`}
                    alt="..."
                  />
                  <div className="feature-content">
                    <h6>{t("productChatbotFeatureTwoTitle")}</h6>
                    <p>{t("productChatbotFeatureTwoDescription")}</p>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-6 mb-3">
                <div className="feature text-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/product/p3/check_circle.png`}
                    alt="..."
                  />
                  <div className="feature-content">
                    <h6>{t("productChatbotFeatureThreeTitle")}</h6>
                    <p>{t("productChatbotFeatureThreeDescription")}</p>
                  </div>
                </div>
              </div>

              <div className="col-xs-12 col-md-6">
                <div className="feature text-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/product/p3/check_circle.png`}
                    alt="..."
                  />
                  <div className="feature-content">
                    <h6>{t("productChatbotFeatureFourTitle")}</h6>
                    <p>{t("productChatbotFeatureFourDescription")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* key features */}
        <div className="chatbot-screenshot-1 mt-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-7">
                <div className="screenshot">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/product/p4/img2.png`}
                    alt="..."
                  />
                </div>
              </div>
              <div className="col-12 col-lg-5">
                <div className="description">
                  <h3 className="mb-3">
                    {t("productChatbotScreenshotOneTitle")}
                  </h3>
                  <div className="mb-3">
                    <h5>{t("productChatbotScreenshotOneItemOneTitle")}</h5>
                    <p>{t("productChatbotScreenshotOneItemOneDescription")}</p>
                  </div>
                  <div className="mb-3">
                    <h5>{t("productChatbotScreenshotOneItemTwoTitle")}</h5>
                    <p>{t("productChatbotScreenshotOneItemTwoDescription")}</p>
                  </div>
                  <div className="mb-3">
                    <h5>{t("productChatbotScreenshotOneItemThreeTitle")}</h5>
                    <p>
                      {t("productChatbotScreenshotOneItemThreeDescription")}
                    </p>
                  </div>
                  <div>
                    <h5>{t("productChatbotScreenshotOneItemFourTitle")}</h5>
                    <p>{t("productChatbotScreenshotOneItemFourDescription")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* benifits */}
        <div className="chatbot-screenshot-2 mt-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-5">
                <div className="description">
                  <h3 className="mb-3">
                    {t("productChatbotScreenshotTwoTitle")}
                  </h3>
                  <div className="mb-3">
                    <h5>{t("productChatbotScreenshotTwoItemOneTitle")}</h5>
                    <p>{t("productChatbotScreenshotTwoItemOneDescription")}</p>
                  </div>
                  <div className="mb-3">
                    <h5>{t("productChatbotScreenshotTwoItemTwoTitle")}</h5>
                    <p>{t("productChatbotScreenshotTwoItemTwoDescription")}</p>
                  </div>
                  <div className="mb-3">
                    <h5>{t("productChatbotScreenshotTwoItemThreeTitle")}</h5>
                    <p>
                      {t("productChatbotScreenshotTwoItemThreeDescription")}
                    </p>
                  </div>
                  <div>
                    <h5>{t("productChatbotScreenshotTwoItemFourTitle")}</h5>
                    <p>{t("productChatbotScreenshotTwoItemFourDescription")}</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-7">
                <div className="screenshot">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/product/p4/img3.png`}
                    alt="..."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* product details footer */}
        <div className="product-footer bg-gradient text-center py-5 my-5">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/isa_footer.png`}
            alt="chatbot_footer"
            className="picture rounded"
          />

          <p className="text-white">{t("productChatbotFooterDescription")}</p>

          <button
            className="btn text-uppercase font-weight-bold"
            data-toggle="modal"
            data-target="#exampleModal"
            style={{
              fontSize: "12px",
              fontFamily: "inherit",
              padding: "10px",
              backgroundColor: "#00d1ff",
            }}
          >
            {t("requestDemoBtnValue")}
          </button>
        </div>

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default withTranslation()(ProductDetailsFour);
