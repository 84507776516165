export function checkFileExtension(file) {
  var parts = file.name.split(".");
  switch (parts[parts.length - 1].toLowerCase()) {
    case "pdf":
    case "doc":
    case "docx":
      return true;
    default:
  }
  return false;
}

export function checkFileSize(file) {
  console.log(file.size);
  return file.size < 1048576;
}

export function arraySorterBy(array, attribut) {
  var arraySorted = [...array];

  arraySorted = arraySorted.sort(function (a, b) {
    if (a[attribut] === null) {
      return -1;
    }
    if (b[attribut] === null) {
      return 1;
    }
    if (a[attribut] < b[attribut]) {
      return 1;
    } else if (a[attribut] > b[attribut]) {
      return -1;
    } else {
      return 0;
    }
  });

  return arraySorted;
}
