import React, { Component } from "react";
import PropTypes from "prop-types";
import { offers } from "../../elements/offer/data";

import { FiMapPin, FiBriefcase } from "react-icons/fi";
import { AiOutlineHourglass } from "react-icons/ai";
import { RiHome6Line } from "react-icons/ri";

import {
  getApplicationByApplicantIdAndOfferId,
  getJob,
} from "../util/APIUtils";

import { Link } from "react-router-dom";
import { Card, CardBody, Table, Row, Col } from "reactstrap";

// import AddApplication from "../application/add";
// import Login from "../user/login/ModalLogin";
// import FunctionHOC from "../Modal/FunctionHOC";

const ContactType = {
  FIXEDTERM: "CDD",
  PERMANENT: "CDI",
  FREELANCE: "Freelance",
};

// const AddApplicationModal = FunctionHOC(
//   AddApplication,
//   <React.Fragment>Postuler</React.Fragment>,
//   (props) => (
//     <button
//       id="addDegree"
//       type="button"
//       className="btn"
//       onClick={props.toggleModal}
//       style={{ background: "#5841D9", color: "#ffffff" }}
//     >
//       Postuler
//     </button>
//   )
// );

// const LoginModal = FunctionHOC(
//   Login,
//   <React.Fragment>Postuler</React.Fragment>,
//   (props) => (
//     <button
//       id="addDegree"
//       type="button"
//       className="btn"
//       onClick={props.toggleModal}
//       style={{ background: "#5841D9", color: "#ffffff" }}
//     >
//       Postuler
//     </button>
//   )
// );

export default class view extends Component {
  constructor(props) {
    super(props);
    this.state = {
      referenceNumber: "",
      externalReferenceNumber: "",
      title: "Développeur Drupal 8 Sénior",
      summary: "",
      seniority: "",
      status: "",
      type: "FREELANCE",
      location: "Rabat",
      duration: "6 Mois",
      source: "",
      sector: "Secteur IT",
      startAt: "",
      endAt: "",
      createdAt: "",
      updatedAt: "",
      profil: {
        name: "",
        skills: [
          { name: "php" },
          { name: "drupal" },
          { name: "javascript" },
          // { name: "apache " },
          // { name: "shell" },
        ],
      },
      company: {
        id: "",
        name: "",
        address: "",
        phone: "",
        mail: "",
        email: "",
      },
      skillMarks: [],
      etat: "",
      offer: { endAt: "" },
      formErrors: {
        endAt: "",
      },
    };
  }
  static propTypes = {
    prop: PropTypes,
  };

  componentDidMount() {
    console.log(this.props);
    // getJob(this.props.match.params.Id)
    //   .then((response) => {
    //     console.log(response);
    //     console.log("this.props", this.props);
    //     if (this.props.currentUser !== null) {
    //       getApplicationByApplicantIdAndOfferId(
    //         this.props.currentUser.id,
    //         this.props.match.params.Id
    //       )
    //         .then((res) => {
    //           console.log("application", res);
    //           var applied;
    //           if (res.content === null) {
    //             applied = false;
    //           } else {
    //             applied = true;
    //           }
    //           this.setState({
    //             id: response.content.id,
    //             referenceNumber: response.content.referenceNumber,
    //             externalReferenceNumber:
    //               response.content.externalReferenceNumber,
    //             title: response.content.title,
    //             summary: response.content.summary,
    //             seniority: response.content.seniority,
    //             status: response.content.status,
    //             type: response.content.type,
    //             location: response.content.location,
    //             duration: response.content.duration,
    //             source: response.content.source,
    //             startAt: response.content.startAt,
    //             endAt: response.content.endAt,
    //             createdAt: response.content.createdAt,
    //             updatedAt: response.content.updatedAt,
    //             profil: response.content.profil,
    //             company: response.content.company,
    //             skillMarks: response.content.skillMarks,
    //             applied,
    //           });
    //         })
    //         .catch((err) => console.log(err));
    //     } else {
    //       this.setState({
    //         id: response.content.id,
    //         referenceNumber: response.content.referenceNumber,
    //         externalReferenceNumber: response.content.externalReferenceNumber,
    //         title: response.content.title,
    //         summary: response.content.summary,
    //         seniority: response.content.seniority,
    //         status: response.content.status,
    //         type: response.content.type,
    //         location: response.content.location,
    //         duration: response.content.duration,
    //         source: response.content.source,
    //         startAt: response.content.startAt,
    //         endAt: response.content.endAt,
    //         createdAt: response.content.createdAt,
    //         updatedAt: response.content.updatedAt,
    //         profil: response.content.profil,
    //         company: response.content.company,
    //         skillMarks: response.content.skillMarks,
    //       });
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    console.log(this.props.match.params.Id);
    console.log(offers);
    let offer = offers.filter(
      (item) => item.id === Number(this.props.match.params.Id)
    );
    console.log(offer);
    this.setState({ offer: offer[0] });
  }

  toHashTag(text) {
    return "#" + text.toLowerCase().split(" ").join("_") + " ";
  }

  render() {
    var dateFormat = require("dateformat");
    return (
      <React.Fragment>
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title" style={{ color: "#ffffff" }}>
                    Offre
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*End Page Title*/}
        {/*Sidebar Page Container*/}
        <div className="service-area ptb--120 bg_color--5">
          <div className="container">
            <Card style={{ marginTop: "10px", marginBottom: "10px" }}>
              <CardBody>
                <Row>
                  <Col md={6}>
                    <h3
                      style={{
                        fontSize: "32px",
                        display: "inline-block",
                        color: "#5841D9",
                      }}
                    >
                      {this.state.title}
                    </h3>
                  </Col>
                  <Col md={6}>
                    {(this.state.type === "FIXEDTERM" ||
                      this.state.type === "FREELANCE") && (
                      <Row>
                        <Col md={3}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div>{this.state.sector}</div>
                            <div style={{ paddingBottom: "5px" }}>
                              <RiHome6Line
                                style={{
                                  marginLeft: "5px",
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div>{ContactType[this.state.type]}</div>
                            <div style={{ paddingBottom: "5px" }}>
                              <FiBriefcase
                                style={{
                                  marginLeft: "5px",
                                }}
                              />
                            </div>
                          </div>
                        </Col>

                        <Col md={3}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div>{this.state.location}</div>
                            <div style={{ paddingBottom: "5px" }}>
                              <FiMapPin
                                style={{
                                  marginLeft: "5px",
                                }}
                              />
                            </div>
                          </div>
                        </Col>

                        <Col md={3}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div>{this.state.duration}</div>
                            <div style={{ paddingBottom: "5px" }}>
                              <AiOutlineHourglass
                                style={{
                                  marginLeft: "5px",
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )}

                    {this.state.type === "PERMANENT" && (
                      <Row>
                        <Col md={4}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div>{this.state.sector}</div>
                            <div style={{ paddingBottom: "5px" }}>
                              <RiHome6Line
                                style={{
                                  marginLeft: "5px",
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div>{ContactType[this.state.type]}</div>
                            <div style={{ paddingBottom: "5px" }}>
                              <FiBriefcase
                                style={{
                                  marginLeft: "5px",
                                }}
                              />
                            </div>
                          </div>
                        </Col>

                        <Col md={4}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div>{this.state.location}</div>
                            <div style={{ paddingBottom: "5px" }}>
                              <FiMapPin
                                style={{
                                  marginLeft: "5px",
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>

                <p>
                  {this.state.profil.skills.map((skill) => {
                    return this.toHashTag(skill.name);
                  })}
                </p>
                <p>
                  Neo Tech IT recrute pour le compte de son client basé sur
                  Rabat des profils séniors Drupal 8 pour une mission Freelance
                  de 6 Mois
                </p>

                <p>
                  <h4> Mission </h4>
                  - Conception technique & analyse d’impacts
                  <br />
                  - Prise en compte des besoins métiers, approfondissement avec
                  les référents fonctionnels
                  <br />
                  - Réalisation des solutions
                  <br />
                  - Livraison & support
                  <br />
                  - Gestion des anomalies (recette et production)
                  <br />
                  - Support à la production
                  <br />
                  - Accompagnement des collaborateurs dans leurs travaux
                  <br />
                </p>

                <p>
                  <h4> Profil </h4>
                  - De formation Bac+5, École de commerce ou d’ingénieur ou
                  Université <br />- Excellente Maitrise du du langage PHP et du
                  framework Drupal
                  <br /> - Bonnes connaissances de HTML/CSS/JS
                  <br />- Maitrise des bonnes pratiques de développement :
                  pragmatic programming, clean code, design patterns ...
                  <br />- Excellente communication en anglais, verbal et écrite
                  <br />
                </p>

                <p>
                  Si votre profil correspond, merci d'envoyer votre candidature
                  à l'adresse recrutement@neo-techit.com
                </p>
                {/* <Row>
                  <Col md={12}>
                    {this.props.currentUser !== null ? (
                      this.state.applied ? (
                        <div className="float-right">
                          <Link
                            to={"/suivie/" + this.props.match.params.Id}
                            className="btn"
                            style={{
                              background: "#5841D9",
                              color: "#ffffff",
                            }}
                          >
                            Suivie
                          </Link>
                        </div>
                      ) : (
                        <div className="float-right">
                          <AddApplicationModal
                            idApplicant={this.props.currentUser.id}
                            idOffer={this.state.id}
                            init={this.componentDidMount}
                            close={() => {
                              this.componentDidMount();
                            }}
                          />
                        </div>
                      )
                    ) : (
                      <div className="float-right"> 
                        <LoginModal {...this.props} />
                      </div>
                    )}
                  </Col>
                </Row> */}
              </CardBody>
            </Card>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
