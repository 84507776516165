import React, { Component } from "react";
import PropTypes from "prop-types";
import { offers } from "./data";

import {
  getApplicationByApplicantIdAndOfferId,
  getJob,
} from "../util/APIUtils";

import { Link } from "react-router-dom";
import { Card, CardBody, Table } from "reactstrap";

import AddApplication from "../application/add";
import FunctionHOC from "../Modal/FunctionHOC";

const AddApplicationModal = FunctionHOC(
  AddApplication,
  <React.Fragment>Postuler</React.Fragment>,
  (props) => (
    <button
      id="addDegree"
      type="button"
      className="btn"
      onClick={props.toggleModal}
      style={{ background: "#5841D9", color: "#ffffff" }}
    >
      Postuler
    </button>
  )
);

export default class view extends Component {
  constructor(props) {
    super(props);
    this.state = {
      referenceNumber: "",
      externalReferenceNumber: "",
      title: "",
      summary: "",
      seniority: "",
      status: "",
      type: "",
      location: "",
      duration: "",
      source: "",
      startAt: "",
      endAt: "",
      createdAt: "",
      updatedAt: "",
      profil: { name: "" },
      company: {
        id: "",
        name: "",
        address: "",
        phone: "",
        mail: "",
        email: "",
      },
      skillMarks: [],
      etat: "",
      offer: { endAt: "" },
      formErrors: {
        endAt: "",
      },
    };
  }
  static propTypes = {
    prop: PropTypes,
  };

  componentDidMount() {
    console.log(this.props);
    getJob(this.props.match.params.Id)
      .then((response) => {
        console.log(response);
        console.log("this.props", this.props);
        getApplicationByApplicantIdAndOfferId(
          this.props.currentUser.id,
          this.props.match.params.Id
        )
          .then((res) => {
            console.log("application", res);
            var applied;
            if (res.content === null) {
              applied = false;
            } else {
              applied = true;
            }
            this.setState({
              id: response.content.id,
              referenceNumber: response.content.referenceNumber,
              externalReferenceNumber: response.content.externalReferenceNumber,
              title: response.content.title,
              summary: response.content.summary,
              seniority: response.content.seniority,
              status: response.content.status,
              type: response.content.type,
              location: response.content.location,
              duration: response.content.duration,
              source: response.content.source,
              startAt: response.content.startAt,
              endAt: response.content.endAt,
              createdAt: response.content.createdAt,
              updatedAt: response.content.updatedAt,
              profil: response.content.profil,
              company: response.content.company,
              skillMarks: response.content.skillMarks,
              applied,
            });
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(this.props.match.params.Id);
    console.log(offers);
    let offer = offers.filter(
      (item) => item.id === Number(this.props.match.params.Id)
    );
    console.log(offer);
    this.setState({ offer: offer[0] });
  }

  render() {
    var dateFormat = require("dateformat");
    return (
      <React.Fragment>
        {/*Page Title*/}
        {/* <div
          className="call-to-action-wrapper text-white-wrapper call-to-action ptb--90 with-particles"
          data-black-overlay="5"
          style={{ height: "50px" }}
        >
          <div className="frame-layout__particles" style={{ height: "50px" }}>
            <Particles
              params={{
                particles: {
                  number: {
                    value: 50,
                  },
                  size: {
                    value: 4,
                  },
                },
                interactivity: {
                  events: {
                    onhover: {
                      enable: true,
                      mode: "repulse",
                    },
                  },
                },
              }}
            />
          </div>
        </div> */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title" style={{ color: "#ffffff" }}>
                    Offre
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*End Page Title*/}
        {/*Sidebar Page Container*/}
        <div className="service-area ptb--120 bg_color--5">
          <div className="container">
            <Card style={{ marginTop: "10px", marginBottom: "10px" }}>
              <CardBody>
                <h3
                  style={{
                    marginBottom: "40px",
                    display: "inline-block",
                  }}
                >
                  Détails Offre :
                </h3>
                <div className="float-right">
                  {this.state.applied ? (
                    <Link
                      to={"/suivie/" + this.props.match.params.Id}
                      className="btn"
                      style={{
                        background: "#5841D9",
                        color: "#ffffff",
                      }}
                    >
                      Suivie
                    </Link>
                  ) : (
                    <AddApplicationModal
                      idApplicant={this.props.currentUser.id}
                      idOffer={this.state.id}
                      init={this.componentDidMount}
                      close={() => {
                        this.componentDidMount();
                      }}
                    />
                  )}
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="general-info">
                      <div className="row">
                        <div className="col-lg-12 col-xl-6">
                          <div className="table-responsive">
                            <Table responsive>
                              <tbody>
                                <tr>
                                  <th
                                    style={{
                                      color: "rgba(95, 66, 253, 0.84)",
                                    }}
                                  >
                                    Intitulé
                                  </th>
                                  <td>{this.state.title}</td>
                                </tr>
                                <tr>
                                  <th
                                    style={{
                                      color: "rgba(95, 66, 253, 0.84)",
                                    }}
                                  >
                                    Réference
                                  </th>
                                  <td>{this.state.referenceNumber}</td>
                                </tr>
                                <tr>
                                  <th
                                    style={{
                                      color: "rgba(95, 66, 253, 0.84)",
                                    }}
                                  >
                                    Réference Externe
                                  </th>
                                  <td>{this.state.externalReferenceNumber}</td>
                                </tr>
                                <tr>
                                  <th
                                    style={{
                                      color: "rgba(95, 66, 253, 0.84)",
                                    }}
                                  >
                                    Client
                                  </th>
                                  <td>{this.state.company.name}</td>
                                </tr>
                                <tr>
                                  <th
                                    style={{
                                      color: "rgba(95, 66, 253, 0.84)",
                                    }}
                                  >
                                    Etat
                                  </th>
                                  {this.state.status === "ACTIVE" ? (
                                    <td>Active</td>
                                  ) : (
                                    <td>Archivé</td>
                                  )}
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </div>
                        <div className="col-lg-12 col-xl-6">
                          <div className="table-responsive" />
                          <Table responsive>
                            <tbody>
                              <tr>
                                <th
                                  style={{
                                    color: "rgba(95, 66, 253, 0.84)",
                                  }}
                                >
                                  Date Début
                                </th>
                                <td>
                                  {dateFormat(this.state.startAt, "yyyy-mm-dd")}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  style={{
                                    color: "rgba(95, 66, 253, 0.84)",
                                  }}
                                >
                                  Date Limite
                                </th>
                                <td>
                                  {dateFormat(this.state.endAt, "yyyy-mm-dd")}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  style={{
                                    color: "rgba(95, 66, 253, 0.84)",
                                  }}
                                >
                                  Séniorité
                                </th>
                                <td>{this.state.seniority}</td>
                              </tr>
                              <tr>
                                <th
                                  style={{
                                    color: "rgba(95, 66, 253, 0.84)",
                                  }}
                                >
                                  Profil
                                </th>
                                <td>{this.state.profil.name}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card style={{ marginTop: "10px", marginBottom: "10px" }}>
              <CardBody>
                <h5 style={{ marginBottom: "20px" }}>Description :</h5>
                <p>
                  {this.state.summary} Inter haec Orfitus praefecti potestate
                  regebat urbem aeternam ultra modum delatae dignitatis sese
                  efferens insolenter, vir quidem prudens et forensium
                  negotiorum oppido gnarus, sed splendore liberalium doctrinarum
                  minus quam nobilem decuerat institutus, quo administrante
                  seditiones sunt concitatae graves ob inopiam vini: huius
                  avidis usibus vulgus intentum ad motus asperos excitatur et
                  crebros. Et olim licet otiosae sint tribus pacataeque
                  centuriae et nulla suffragiorum certamina set Pompiliani
                  redierit securitas temporis, per omnes tamen quotquot sunt
                  partes terrarum, ut domina suscipitur et regina et ubique
                  patrum reverenda cum auctoritate canities populique Romani
                  nomen circumspectum et verecundum. Ultima Syriarum est
                  Palaestina per intervalla magna protenta, cultis abundans
                  terris et nitidis et civitates habens quasdam egregias, nullam
                  nulli cedentem sed sibi vicissim velut ad perpendiculum
                  aemulas: Caesaream, quam ad honorem Octaviani principis
                  exaedificavit Herodes, et Eleutheropolim et Neapolim itidemque
                  Ascalonem Gazam aevo superiore exstructas.
                </p>
              </CardBody>
            </Card>
          </div>
        </div>{" "}
      </React.Fragment>
    );
  }
}
