import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "./style.css";

class Portfolio extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <div className="container">
          <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
            <h2 className="title">{t("storiesTitle")}</h2>
          </div>

          <div className="row justify-content-center row mt--60 mt_sm--40">
            {/* <!-- flip-card-container --> */}
            <div className="m-2">
              <div className="flip-card-container" style={{ "--hue": 220 }}>
                <div className="flip-card">
                  <div className="card-front">
                    <figure>
                      <div className="img-bg"></div>
                      <img
                        src="https://img.freepik.com/free-vector/isometric-laptop-with-shopping-cart-keypad_1262-16544.jpg?w=900&t=st=1685500103~exp=1685500703~hmac=d727fb0a6c85e285173ab56944f954f438490eb3f302a903e5f7aae95748a8bd"
                        alt="Brohm Lake"
                      ></img>
                      <figcaption>{t("storiesSectionOneAppName")}</figcaption>
                    </figure>

                    <div className="story-desc-div">
                      <p>{t("storiesSectionOneTitle")}</p>
                    </div>
                  </div>

                  <div className="card-back">
                    <figure>
                      <div
                        className="img-bg"
                        style={{
                          backgroundImage:
                            "linear-gradient(145deg, #f3f1f1 0%, #d3d3d3 100%)",
                        }}
                      ></div>
                    </figure>
                    <p className="p-3">
                      {t("storiesSectionOneDescription")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- /flip-card-container --> */}

            {/* <!-- flip-card-container --> */}
            <div className="m-2">
              <div className="flip-card-container" style={{ "--hue": 170 }}>
                <div className="flip-card">
                  <div className="card-front">
                    <figure>
                      <div className="img-bg"></div>
                      <img
                        src="https://img.freepik.com/free-vector/cryptocurrency-blockchain-isometric-composition_1284-20548.jpg?w=1060&t=st=1685500354~exp=1685500954~hmac=a5bda329a9199b074a86fa6d4fefe1d730f4a7cde4dfa0b210c22ab4ad0ee2c3"
                        alt="Image 2"
                      ></img>
                      <figcaption>{t("storiesSectionTwoAppName")}</figcaption>
                    </figure>
                    <div className="story-desc-div">
                      <p>{t("storiesSectionTwoTitle")}</p>
                    </div>
                  </div>

                  <div className="card-back">
                    <figure>
                      <div
                        className="img-bg"
                        style={{
                          backgroundImage:
                            "linear-gradient(145deg, #f3f1f1 0%, #d3d3d3 100%)",
                        }}
                      ></div>
                    </figure>
                    <p className="p-3">
                      {t("storiesSectionTwoDescription")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- /flip-card-container --> */}

            {/* <!-- flip-card-container --> */}
            <div className="m-2">
              <div className="flip-card-container" style={{ "--hue": 350 }}>
                <div className="flip-card">
                  <div className="card-front">
                    <figure>
                      <div className="img-bg"></div>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/bg/chatbot_ai.jpg`}
                        alt="Brohm Lake"
                      ></img>
                      <figcaption>{t("storiesSectionThreeAppName")}</figcaption>
                    </figure>

                    <div className="story-desc-div">
                      <p>{t("storiesSectionThreeTitle")}</p>
                    </div>
                  </div>

                  <div className="card-back">
                    {/* <!-- only if the image is necessary --> */}
                    <figure>
                      <div
                        className="img-bg"
                        style={{
                          backgroundImage:
                            "linear-gradient(145deg, #f3f1f1 0%, #d3d3d3 100%)",
                        }}
                      ></div>
                    </figure>
                    <p className="p-3">
                      {t("storiesSectionThreeDescription")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- /flip-card-container --> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default withTranslation()(Portfolio);
