import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  USERNAME_MIN_LENGTH,
  USERNAME_MAX_LENGTH,
  GOOGLE_AUTH_URL,
  LINKEDIN_AUTH_URL,
} from "../../constants";
import linkedinLogo from "../../img/linkedin.png";
import googleLogo from "../../img/google.png";
import {
  signup,
  checkUsernameAvailability,
  checkEmailAvailability,
} from "../../util/APIUtils";

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      email: "",
      password: "",
      retypePassword: "",
      formErrors: { username: "", email: "", password: "", retypePassword: "" },
      errors: { message: "" },
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.validateUsernameAvailability = this.validateUsernameAvailability.bind(
      this
    );
    this.validateEmailAvailability = this.validateEmailAvailability.bind(this);
  }

  componentDidMount() {
    console.log("this.props", this.props);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  // Validation Functions

  validateEmail(email) {
    var regexMail = /.+@.+\.[A-Za-z]+$/;
    return regexMail.test(email);
  }

  validatePassword(password) {
    var regexMail = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
    return regexMail.test(password);
  }

  validateUsername(username) {
    return (
      username.length > USERNAME_MIN_LENGTH &&
      username.length < USERNAME_MAX_LENGTH
    );
  }

  validateUsernameAvailability() {
    // First check for client side errors in username
    const usernameValue = this.state.username;
    const usernameValidation = this.validateUsername(usernameValue);

    if (usernameValidation === false) {
      this.setState({
        formErrors: {
          username: `Veuillez saisir un nom utilisateur compris entre ${USERNAME_MIN_LENGTH} et ${USERNAME_MIN_LENGTH}.`,
        },
      });
      return;
    }

    checkUsernameAvailability(usernameValue)
      .then((response) => {
        console.log(response);
        if (response.available) {
          this.setState({
            formErrors: {
              username: "",
            },
          });
        } else {
          console.log(this.state);
          this.setState({
            formErrors: {
              username: "Ce nom d'utilisateur est déjà réservé",
            },
          });
          console.log(this.state);
        }
      })
      .catch((error) => {
        // Marking validateStatus as success, Form will be recchecked at server
        console.log(error);
        this.setState({
          formErrors: {
            username: "",
          },
        });
      });
  }

  validateEmailAvailability() {
    // First check for client side errors in email
    const emailValue = this.state.email;
    const emailValidation = this.validateEmail(emailValue);

    if (emailValidation === false) {
      this.setState({
        formErrors: {
          email: "Veuillez saisir un email valide",
        },
      });
      return;
    }

    checkEmailAvailability(emailValue)
      .then((response) => {
        if (response.available) {
          this.setState({
            formErrors: {
              email: "",
            },
          });
        } else {
          this.setState({
            formErrors: {
              email: "Cet email est déjà réservé",
            },
          });
        }
      })
      .catch((error) => {
        // Marking validateStatus as success, Form will be recchecked at server
        this.setState({
          formErrors: {
            email: "",
          },
        });
      });
  }

  validateForm() {
    let { username, email, password, retypePassword } = this.state;
    let formErrors = this.state.formErrors;

    formErrors.username =
      username.length > 0 ? "" : "Veuillez entrez un nom utilisateur";

    formErrors.email =
      email.length > 0 ? "" : "Veuillez entrez un email de connexion";

    formErrors.password =
      password.length > 0 ? "" : "Veuillez entrez un mot de passe";
    formErrors.retypePassword =
      retypePassword.length > 0 ? "" : "Veuillez retaper le mot de passe";

    if (username.length > 0) {
      formErrors.email = this.validateEmail(email)
        ? ""
        : `Veuillez saisir un nom utilisateur compris entre ${USERNAME_MIN_LENGTH} et ${USERNAME_MIN_LENGTH}.`;
    }

    if (email.length > 0) {
      formErrors.email = this.validateEmail(email)
        ? ""
        : "Veuillez saisir un email valide";
    }

    if (password.length > 0) {
      formErrors.password = this.validatePassword(password)
        ? ""
        : "Veuillez saisir un mot de passe valide, contenant au moins 8 caractères, une lettre majuscule, une lettre miniscule et un digit";
      formErrors.retypePassword =
        password === retypePassword
          ? ""
          : "les mots de passe saisie ne sont pas authentiques";
    }

    this.setState({
      formErrors,
    });

    return (
      formErrors.username === "" &&
      formErrors.email === "" &&
      formErrors.password === "" &&
      formErrors.retypePassword === ""
    );
  }

  onSubmit(e) {
    e.preventDefault();

    const user = {
      username: this.state.username,
      email: this.state.email,
      password: this.state.password,
    };

    if (this.validateForm()) {
      signup(user)
        .then((res) =>
          this.setState({
            errors: {
              message: "Un lien vous a était envoyer pour vérifier votre email",
            },
          })
        )
        .catch((err) => this.setState({ errors: { message: err.message } }));
    }
  }

  alert(message) {
    return (
      <div className="alert alert-danger" role="alert">
        {message}
      </div>
    );
  }

  render() {
    if (this.props.authenticated) {
      return (
        <Redirect
          to={{
            pathname: "/",
            state: { from: this.props.location },
          }}
        />
      );
    }
    return (
      <React.Fragment>
        <div>
          <div
            className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"
            data-black-overlay="5"
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="rn-page-title text-center pt--100">
                    {/* <h2 className="title" style={{ color: "#ffffff" }}>CONNEXION</h2> */}
                    {/* <p>Fresh From The Press Discription</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <section
              className="contact-page-section"
              style={{
                height: "60vh",
                width: "600px",
                margin: "auto",
                marginTop: "130px",
              }}
            >
              <div className="auto-container">
                <div className="inner-container">
                  <h2>
                    Créer un compte sur l'espace <span>membre</span>
                  </h2>
                  <div className="row clearfix">
                    <div
                      className="form-column col-lg-6"
                      style={{ marginBottom: "10px" }}
                    >
                      <a href={GOOGLE_AUTH_URL}>
                        <button
                          style={{
                            border: "1px solid #F55549",
                            width: "100%",
                            borderRadius: "5px",
                            height: "50px",
                          }}
                        >
                          <img
                            src={googleLogo}
                            alt="Google"
                            style={{ width: "30px", margin: "5px" }}
                          />
                          Créer un compte avec Google
                        </button>
                      </a>
                    </div>
                    <div
                      className="form-column col-lg-6"
                      style={{ marginBottom: "10px" }}
                    >
                      <a href={LINKEDIN_AUTH_URL}>
                        <button
                          style={{
                            border: "1px solid #0076B4",
                            width: "100%",
                            borderRadius: "5px",
                            height: "50px",
                          }}
                        >
                          <img
                            src={linkedinLogo}
                            alt="Linkedin"
                            style={{ width: "30px", margin: "5px" }}
                          />
                          Créer un compte avec LinkedIn
                        </button>
                      </a>
                    </div>

                    {/* Form Column */}
                    <div className="form-column col-lg-12 col-md-12 col-sm-12">
                      <div className="inner-column">
                        {this.state.errors.message === ""
                          ? null
                          : this.alert(this.state.errors.message)}
                        {/*Contact Form*/}
                        <div className="contact-form">
                          <form onSubmit={this.onSubmit} id="contact-form">
                            <div className="form-group">
                              <input
                                type="text"
                                placeholder="Username"
                                name="username"
                                value={this.state.username}
                                onChange={this.onChange}
                                onBlur={this.validateUsernameAvailability}
                                required
                              />
                              <p className="form-text error">
                                {this.state.formErrors.username}
                              </p>
                            </div>
                            <div className="form-group">
                              <input
                                type="email"
                                placeholder="Email"
                                name="email"
                                value={this.state.email}
                                onChange={this.onChange}
                                onBlur={this.validateEmailAvailability}
                                required
                              />
                              <p className="form-text error">
                                {this.state.formErrors.email}
                              </p>
                            </div>
                            <div className="form-group">
                              <input
                                type="password"
                                placeholder="Mot de passe"
                                name="password"
                                value={this.state.password}
                                onChange={this.onChange}
                              />
                              <p className="form-text error">
                                {this.state.formErrors.password}
                              </p>
                            </div>
                            <div className="form-group">
                              <input
                                type="password"
                                placeholder="Retaper le Mot de passe"
                                name="retypePassword"
                                value={this.state.retypePassword}
                                onChange={this.onChange}
                              />
                              <p className="form-text error">
                                {this.state.formErrors.retypePassword}
                              </p>
                            </div>
                            <div className="form-group">
                              <button
                                type="submit"
                                className="btn theme-btn"
                                style={{
                                  color: "#ffffff",
                                  background: "rgba(95, 66, 253, 0.84)",
                                  float: "right",
                                }}
                              >
                                S'enregistrer
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SignUp;
