import React, { Component, Fragment } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { FiDownload } from "react-icons/fi";

import {
  getResourcesBySupplier,
  deleteActivityReport,
  getResumeFile,
} from "../../util/APIUtils";

class TableReact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      errors: { message: "" },
    };

    this.delete = this.delete.bind(this);
  }

  componentDidMount() {
    var dateFormat = require("dateformat");
    var supplierId = this.props.currentUser.id;
    getResourcesBySupplier(supplierId)
      .then((Response) => {
        console.log(Response);
        this.setState({ data: Response });
      })
      .catch((err) => console.log(err));
  }

  delete(arrayId, id) {
    confirmAlert({
      title: "Confirmation suppression",
      message: "Etes vous sure ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteActivityReport(id)
              .then((res) => {
                let data = this.state.data;
                data = data.filter((item) => item.id != id);
                this.setState({ data: [] });
                this.setState({ data: data });
              })
              .catch((err) =>
                this.setState({ errors: { message: err.message } })
              );
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    console.log(filter);
    console.log(row[id]);
    console.log(typeof row[id]);
    if (row[id] !== null && typeof row[id] === "string") {
      return row[id] !== undefined
        ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
        : true;
    }
  };

  downloadDocument(documentName) {
    console.log(true);
    getResumeFile(documentName)
      .then((response) => response.blob())
      .then(function (myBlob) {
        const url = window.URL.createObjectURL(myBlob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", documentName); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  }

  alert(message) {
    return (
      <div className="alert alert-danger" role="alert">
        {message}
      </div>
    );
  }

  render() {
    var dateFormat = require("dateformat");
    let data;
    if (!this.state.data.length) {
      data = [];
    } else {
      data = this.state.data;
    }

    const columns = [
      {
        Header: "Prénom",
        filterable: true,
        accessor: "firstName",
        className: "mid",
      },
      {
        Header: "Nom",
        filterable: true,
        accessor: "lastName",
        className: "mid",
      },
      {
        Header: "Email",
        filterable: true,
        accessor: "email",
        className: "mid",
      },
      {
        Header: "Téléphone",
        filterable: true,
        accessor: "phone",
        className: "mid",
      },
      {
        Header: "Profil",
        id: "row",
        className: "mid",
        Cell: (row) => {
          return (
            <div
              style={{
                textAlign: "center",
              }}
            >
              {row.original.profil.name}
            </div>
          );
        },
      },
      {
        Header: "CV",
        id: "row",
        className: "mid",
        Cell: (row) => {
          return (
            <div
              style={{
                textAlign: "center",
              }}
            >
              <button
                style={{
                  color: "#ffffff",
                  background: "rgba(95, 66, 253, 0.84)",
                }}
                // onClick={this.downloadDocument.bind(
                //   this,
                //   row.original.resume.name
                // )}
                className="btn"
              >
                <FiDownload />
              </button>
            </div>
          );
        },
      },
      {
        Header: "Etat",
        filterable: true,
        className: "mid",
        Cell: (row) => {
          let active = <span className="badge badge-primary">ACTIVE</span>;
          let blacklist = (
            <span className="badge badge-secondary">BLACKLISTER</span>
          );
          return (
            <div
              style={{
                textAlign: "center",
              }}
            >
              {row.original.status !== "BLACKLIST" ? active : blacklist}
            </div>
          );
        },
      },
    ];
    return (
      <React.Fragment>
        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title" style={{ color: "#ffffff" }}>
                    Ressources
                  </h2>
                  {/* <p>Fresh From The Press Discription</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="service-area ptb--80  bg_image bg_image--3"
          style={{ paddingTop: "200px", paddingBottom: "200px" }}
        >
          <div className="container">
            <div>
              <div>
                <h4>Ressources</h4>

                <ReactTable
                  className="-highlight"
                  columns={columns}
                  data={data}
                  minRows={0}
                  defaultPageSize={10}
                  defaultFilterMethod={this.filterCaseInsensitive}
                  getProps={() => {
                    return { id: "resources" };
                  }}
                  previousText="Précédent"
                  nextText="Prochain"
                  loadingText="Chargement..."
                  noDataText="Aucune ligne trouvée"
                  pageText="Page"
                  ofText="de"
                  rowsText="Lignes"
                  getTdProps={(state, rowInfo, column, instance) => {
                    return {
                      onClick: (e) => {
                        if (
                          column.Header !== "CV" &&
                          column.Header !== "Opération" &&
                          column.Header !== "Action" &&
                          rowInfo !== undefined
                        ) {
                          let path =
                            "/supplier/resource/" + rowInfo.original.id;
                          this.props.history.push(path);
                        }
                      },
                    };
                  }}
                ></ReactTable>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default TableReact;
