import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class ServiceDetails extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Service Details" />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
          currentUser={this.props.currentUser}
          isAuthenticated={this.props.isAuthenticated}
          handleLogout={this.props.handleLogout}
          {...this.props}
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title" style={{ color: "#ffffff" }}>
                    Développement applicatif
                  </h2>
                  {/* <p>Fresh From The Press Discription</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--50 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="w-100"
                            src={`/assets/images/service/service-01.jpg`}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>
                            NEO TECH IT vous accompagne dans toutes les étapes
                            de la gestion et de développement de votre projets
                            ou de vos clients
                          </p>
                          <p>
                            Notre équipe est composée des experts dans la veille
                            technologie, ils ont pu se forger leur propre
                            expérience dans le maintien et développement des
                            applications critiques dans des entreprises
                            hautement sensibilisées notamment dans des domaines
                            finance, agriculture, médecine, gestion et sécurité
                            biométrique
                          </p>

                          <ul className="liststyle">
                            <li>
                              Réalisation des architectures des solutions et des
                              produits dans différents métiers.
                            </li>
                            <li>
                              Développements des solutions par des nouvelles
                              Technologies ( JAVA/JEE, .NET, PHP ….).
                            </li>
                            <li>
                              Maintenance des solutions et des produits
                              existants.
                            </li>
                            <li>Migration des applications et des données.</li>
                            <li>
                              Application mobile développée pour Android et iOS
                            </li>
                            <li>
                              Application intranet / extranet sur smartphone et
                              tablette
                            </li>
                            <li>
                              Développement de thèmes sur smartphone et tablette
                            </li>
                            <li>
                              Développement d’applicatifs, interconnexions avec
                              des systèmes (gestion ,compta , facturation,…)
                            </li>
                            <li>
                              Création d’une administration de suivi d’activité
                            </li>
                            <li>
                              Création de catalogues produits et services sur
                              applications mobiles
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default ServiceDetails;
