import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { FiX, FiMenu } from "react-icons/fi";
import FunctionHOC from "../../old/Modal/FunctionHOC";
import Login from "../../old/user/login/ModalLogin";
import { withTranslation } from "react-i18next";
import ReactFlagsSelect from "react-flags-select";

const LoginModal = FunctionHOC(
  Login,
  <React.Fragment>Se connecter</React.Fragment>,
  (props) => (
    <li style={{ cursor: "pointer" }}>
      <div className="header-title" onClick={props.toggleModal}>
        Se connecter
      </div>
    </li>
  )
);

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedLang: "US",
    };

    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
  }

  // Change language
  changeLang = (code) => {
    this.setState({ selectedLang: code });
    this.props.i18n.changeLanguage(code);

    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  };

  // Handle click nav item (scroll or navigate)
  handleLinkClick = (e, sectionId) => {
    e.preventDefault();
    const { location } = this.props;

    if (location.pathname !== "/") {
      this.props.history.push("/");
    }

    setTimeout(() => {
      document.getElementById(sectionId).scrollIntoView({
        behavior: "smooth",
      });
    }, 500);

    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  };

  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }

  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }

  render() {
    const { t } = this.props;

    //
    var elements = document.querySelectorAll(".has-droupdown > a");

    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }

    // set logo neotechit
    const logoUrl = (
      <img
        src={`${process.env.PUBLIC_URL}/assets/images/logo/logo_neotechit_white.svg`}
        alt="neotechit"
      />
    );

    return (
      <header
        className={
          "header-area formobile-menu header--transparent default-color"
        }
      >
        <div className="header-wrapper" id="header-wrapper">
          <div className="header-left">
            <div className="logo">
              <a href="/">{logoUrl}</a>
            </div>
          </div>
          <div className="header-right">
            <nav className="mainmenunav d-lg-block">
              <ul className="mainmenu">
                <li>
                  <Link to="/">{t("homeNavItem")}</Link>
                </li>
                {/* ******** applicant ******** */}
                {this.props.currentUser !== undefined &&
                  this.props.currentUser !== null &&
                  this.props.currentUser.authorities[0].authority ===
                    "ROLE_APPLICANT" && (
                    <React.Fragment>
                      <li>
                        <Link to="/interview">Entretiens</Link>
                      </li>
                      <li>
                        <Link to="/offres">Offres</Link>
                      </li>
                      <li>
                        <Link to="/profilv">Profil</Link>
                      </li>
                    </React.Fragment>
                  )}
                {/* ******** ressource ******** */}
                {this.props.currentUser !== undefined &&
                  this.props.currentUser !== null &&
                  this.props.currentUser.authorities[0].authority ===
                    "ROLE_RESOURCE" && (
                    <React.Fragment>
                      <li className="has-droupdown">
                        <Link to="/activity-report">CRAs</Link>
                      </li>
                      <li className="has-droupdown">
                        <Link to="/ordersheet">Bons de commande</Link>
                      </li>
                      <li className="has-droupdown">
                        <Link to="/files">Profil</Link>
                      </li>
                    </React.Fragment>
                  )}
                {/* ******** supplier ******** */}
                {this.props.currentUser !== undefined &&
                  this.props.currentUser !== null &&
                  this.props.currentUser.authorities[0].authority ===
                    "ROLE_SUPPLIER" && (
                    <React.Fragment>
                      <li className="has-droupdown">
                        <Link to="/supplier/resource">Ressources</Link>
                      </li>
                      <li className="has-droupdown">
                        <Link to="/supplier/activity-report">CRAs</Link>
                      </li>
                      <li className="has-droupdown">
                        <Link to="/supplier/ordersheet">Bons de commande</Link>
                      </li>
                    </React.Fragment>
                  )}
                {/* {(this.props.currentUser === undefined ||
                  this.props.currentUser === null) && (
                    <React.Fragment>
                      <li className="has-droupdown">
                      <li>
                        <Link to="/">Accueil</Link>
                      </li>
                        <ul className="submenu">
                          <li>
                            <Link to="/main-demo">Main Demo</Link>
                          </li>
                          <li>
                            <Link to="/dark-main-demo">Main Demo Dark</Link>
                          </li>
                          <li>
                            <Link to="/creative-agency">Creative Agency</Link>
                          </li>
                          <li>
                            <Link to="/creative-landing">Creative One Page</Link>
                          </li>
                          <li>
                            <Link to="/creative-portfolio">
                              Creative Portfolio
                          </Link>
                          </li>
                          <li>
                            <Link to="/personal-portfolio">
                              Personal Portfolio
                          </Link>
                          </li>
                          <li>
                            <Link to="/portfolio-landing">
                              Portfolio One Page
                          </Link>
                          </li>
                          <li>
                            <Link to="/dark-portfolio-landing">
                              Portfolio One Page 02
                          </Link>
                          </li>
                          <li>
                            <Link to="/digital-agency">Digital Agency</Link>
                          </li>
                          <li>
                            <Link to="/startup">Startup</Link>
                          </li>
                          <li>
                            <Link to="/paralax">Paralax</Link>
                          </li>
                          <li>
                            <Link to="/portfolio-home">Minimal Portfolio</Link>
                          </li>
                          <li>
                            <Link to="/business">Business</Link>
                          </li>
                          <li>
                            <Link to="/home-particles">Accueil Particles</Link>
                          </li>
                          <li>
                            <Link to="/studio-agency">Studio Agency</Link>
                          </li>
                          <li>
                            <Link to="/designer-portfolio">
                              Designer Portfolio
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="has-droupdown">
                        <Link to="/service">Service</Link>
                        <ul className="submenu">
                          <li>
                            <Link to="/service">Service</Link>
                          </li>
                          <li>
                            <Link to="/service-details">Service Details</Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="/about">About</Link>
                      </li>

                      <li className="has-droupdown">
                        <Link to="#pages">Pages</Link>
                        <ul className="submenu">
                          <li>
                            <Link to="/blog">Blog List</Link>
                          </li>
                          <li>
                            <Link to="/blog-details">Blog Details</Link>
                          </li>
                          <li>
                            <Link to="/service">Service</Link>
                          </li>
                          <li>
                            <Link to="/service-details">Service Details</Link>
                          </li>
                          <li>
                            <Link to="/portfolio">Portfolio</Link>
                          </li>
                          <li>
                            <Link to="/portfolio-details">Portfolio Details</Link>
                          </li>
                          <li>
                            <Link to="/404">404</Link>
                          </li>
                        </ul>
                      </li>
                      <li className="has-droupdown">
                        <Link to="#">Blocks</Link>
                        <ul className="submenu">
                          <li>
                            <Link to="/portfolio">Portfolio</Link>
                          </li>
                          <li>
                            <Link to="/team">Team</Link>
                          </li>
                          <li>
                            <Link to="/service">Service</Link>
                          </li>
                          <li>
                            <Link to="/video-popup">Video Popup</Link>
                          </li>
                          <li>
                            <Link to="/progressbar">Progressbar</Link>
                          </li>
                          <li>
                            <Link to="/gallery">Gallery</Link>
                          </li>
                          <li>
                            <Link to="/counters">Counters</Link>
                          </li>
                          <li>
                            <Link to="/blog">Blog List</Link>
                          </li>
                          <li>
                            <Link to="/clint-logo">Clint Logo</Link>
                          </li>
                          <li>
                            <Link to="/contact-form">Contact Form</Link>
                          </li>
                          <li>
                            <Link to="/google-map">Google Map</Link>
                          </li>
                          <li>
                            <Link to="/columns">Columns</Link>
                          </li>
                          <li>
                            <Link to="/pricing-table">Pricing Table</Link>
                          </li>
                        </ul>
                      </li>


                    </React.Fragment>
                  )} */}
                {(this.props.currentUser === undefined ||
                  this.props.currentUser === null) && (
                  <li>
                    <a
                      href="#nosService"
                      onClick={(e) => this.handleLinkClick(e, "nosService")}
                    >
                      {t("servicesNavItem")}
                    </a>
                  </li>
                )}

                <li>
                  <a
                    href="#ourProducts"
                    onClick={(e) => this.handleLinkClick(e, "ourProducts")}
                  >
                    {t("productsNavItem")}
                  </a>
                </li>
                {/* <li className="has-droupdown">
                  <div
                    className={
                      this.state.service_dp
                        ? "header-title open"
                        : "header-title"
                    }
                    // class={this.state.service_dp ? "" : "open"}
                    onClick={() => {
                      this.setState((prevState) => ({
                        service_dp: !prevState.service_dp,
                      }));
                    }}
                  >
                    Nos partenaires
                  </div>
                  <ul
                    className={
                      this.state.service_dp ? "submenu active" : "submenu"
                    }
                  >
                    <li>
                      <Link to="/services/0">Election des compétences</Link>
                    </li>
                    <li>
                      <Link to="/services/1">Transofrmation Digital</Link>
                    </li>
                    <li>
                      <Link to="/services/2">Conseil et consulting</Link>
                    </li>
                    <li>
                      <Link to="/services/3">Développement applicatif</Link>
                    </li>
                  </ul>
                </li> */}
                {(this.props.currentUser === undefined ||
                  this.props.currentUser === null) && (
                  <li>
                    <a
                      href="#nosPartenaires"
                      onClick={(e) => this.handleLinkClick(e, "nosPartenaires")}
                    >
                      {t("partnersNavItem")}
                    </a>
                  </li>
                )}
                <li>
                  {/* <Link to="/contact">{t("contactNavItem")}</Link> */}
                  <a
                    href="#footer"
                    onClick={(e) => this.handleLinkClick(e, "footer")}
                  >
                    {t("contactNavItem")}
                  </a>
                </li>

                {/* Dropdown language selector */}
                <li>
                  <ReactFlagsSelect
                    selected={this.state.selectedLang}
                    onSelect={(code) => this.changeLang(code)}
                    countries={["FR", "US"]}
                    customLabels={{
                      FR: t("changeLangFrenchLabel"),
                      US: t("changeLangEnlishLabel"),
                    }}
                    selectButtonClassName="menu-flags-button"
                  />
                </li>

                {/* {this.props.currentUser !== undefined &&
                  this.props.currentUser !== null && (
                    <li className="has-droupdown">
                      <Link
                        onClick={() => {
                          console.log("this.props", this.props);
                          this.props.handleLogout(this.props.history);
                        }}
                        to="#"
                      >
                        Déconnexion
                      </Link>
                    </li>
                  )}
                {(this.props.currentUser === undefined ||
                  this.props.currentUser === null) && (
                  <React.Fragment>
                    <LoginModal {...this.props} /> 
                  </React.Fragment>
                )} */}
              </ul>
            </nav>
            {/* <div className="header-btn">
                            <a className="rn-btn" href="https://themeforest.net/checkout/from_item/25457315?license=regular">
                                <span>buy now</span>
                            </a>
                        </div> */}
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span
                onClick={this.menuTrigger}
                className="menutrigger text-white"
              >
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default withRouter(withTranslation()(Header));
