import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class ServiceDetails extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Service Details" />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
          currentUser={this.props.currentUser}
          isAuthenticated={this.props.isAuthenticated}
          handleLogout={this.props.handleLogout}
          {...this.props}
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title" style={{ color: "#ffffff" }}>
                    Transformation digital
                  </h2>
                  {/* <p>Fresh From The Press Discription</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--50 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="w-100"
                            src={`/assets/images/service/s_31.jpeg`}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title">Quoi</h4>
                          <p>
                            La transformation digitale d'une entreprise, est le
                            résultat de l'adoption de méthodes de travail
                            novatrices basées sur les avancées technologiques.
                            Pour une entreprise, il s'agit d'évoluer en
                            profondeur grâce à des outils numériques et
                            d'adopter de nouvelles technologies (et
                            potentiellement une nouvelle culture) afin de
                            remplacer ou d'améliorer les anciennes
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title">Accompagnement pas à pas</h4>

                          <p>
                            Neotech it est le partenaire de votre transformation
                            digitale. Fort d’offres dédiées aux PME et aux
                            grands comptes, notre équipe vous conseille sur
                            mesure dans les choix stratégiques que vous aurez à
                            faire, face au nouveau monde numérique Grace à la
                            grande expérience acquise par nos experts, nous vous
                            accompagnons pas à pas dans votre transformation
                            digitale ; du diagnostic jusqu’au déploiement de la
                            stratégie numérique et la gestion des risques qui en
                            résulte
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative">
                          <img
                            className="w-100"
                            src={`/assets/images/service/s_32.jpeg`}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div
                          className="thumb"
                          // style={{
                          //   border: "1px solid #8829C9",
                          //   borderRadius: "5px",
                          // }}
                        >
                          <img
                            className="w-100"
                            src={`/assets/images/service/s_331.png`}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title">Démarche et processus</h4>

                          <p>
                            Notre démarche consiste à Mettre en œuvre une
                            organisation de la gouvernance du SI qui permettra
                            de répondre aux futures attentes et d’introduire les
                            nouvelles TI dans les métiers des différentes
                            entités à travers un chemin bien organisé :
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default ServiceDetails;
