import React, { Component } from "react";
import Select from "react-select";
import { Form, FormGroup, FormText, Input, Label, Col, Row } from "reactstrap";
// import cities from "../../../util/cities.json";
import countries from "./util/countries.json";
import {
  editApplicant,
  getAllProfils,
  getApplicant,
  getAllSuppliers,
  getCurrentUser,
} from "./util/APIUtils";

class Editer extends Component {
  constructor() {
    super();
    this.state = {
      firstName: "",
      lastName: "",
      phone: "",
      address: "",
      seniority: "",
      email: "",
      isRecruited: false,
      isImported: true,
      applicantStatus: "",
      createdAt: "",
      profil: "",
      resume: "",
      password: "",
      adressTwo: "",
      supplier: "",
      city: "",
      country: "",
      zipCode: "",
      formErrors: {
        firstName: "",
        lastName: "",
        phone: "",
        address: "",
        seniority: "",
        isRecruited: "",
        email: "",
        applicantStatus: "",
        profil: "",
      },
      errors: { message: "" },
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onResumeChange = this.onResumeChange.bind(this);
    this.onChangeSelectProfil = this.onChangeSelectProfil.bind(this);
    this.onChangeSelectSupplier = this.onChangeSelectSupplier.bind(this);
  }

  componentDidMount() {
    getCurrentUser()
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    console.log(this.props.currentUser);
    getApplicant(this.props.currentUser.id)
      .then((response) => {
        console.log(response);
        var profil;
        if (response.content.profil !== null) {
          profil = response.content.profil;
          profil.label = profil.name;
          profil.value = profil.id;
        }
        var supplier = "";

        if (response.content.supplier != null) {
          supplier = response.content.supplier;
          supplier.label = supplier.name;
          supplier.value = supplier.id;
        }

        this.setState({
          referenceNumber: response.content.referenceNumber,
          firstName: response.content.firstName,
          lastName: response.content.lastName,
          phone: response.content.phone,
          address: response.content.address,
          seniority: response.content.seniority,
          email: response.content.email,
          isRecruited: response.content.isRecruited,
          isImported: response.content.isImported,
          applicantStatus: response.content.applicantStatus,
          createdAt: response.content.createdAt,
          profil: profil,
          adressTwo: response.content.adressTwo,
          city: response.content.city,
          country: response.content.country,
          zipCode: response.content.zipCode,
          password: response.content.password,
          supplier: supplier,
        });
      })
      .catch(function (error) {
        console.log(error);
      });

    getAllProfils()
      .then((Response) => {
        this.setState({ selectProfils: Response });
        this.state.selectProfils.map((obj) => {
          obj.value = obj.id;
          obj.label = obj.name;
          return obj;
        });
      })
      .catch((err) => console.log(err));

    getAllSuppliers()
      .then((Response) => {
        Response.map((obj) => {
          obj.value = obj.id;
          obj.label = obj.name;
          return obj;
        });
        Response.push({ value: "", label: " (Sans) " });
        this.setState({ selectSuppliers: Response });
      })
      .catch((err) => console.log(err));
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onResumeChange(event) {
    console.log(event);
    this.setState(
      {
        resume: event.target.files[0],
      },
      () => {
        console.log(this.state.resume);
      }
    );
  }

  onChangeSelectProfil(e) {
    this.setState({ profil: e });
  }

  validateEmail(email) {
    var regexMail = /.+@.+\.[A-Za-z]+$/;
    return regexMail.test(email);
  }

  validatePhoneNumber(phone) {
    var regexPhone = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
    return regexPhone.test(phone);
  }

  validateForm() {
    let {
      firstName,
      lastName,
      phone,
      address,
      seniority,
      isRecruited,
      email,
      // applicantStatus,
      profil,
    } = this.state;

    let formErrors = this.state.formErrors;

    formErrors.firstName =
      firstName.length > 0 ? "" : "Veuillez entrez le prénom";
    formErrors.lastName = lastName.length > 0 ? "" : "Veuillez entrez le nom";
    formErrors.seniority =
      seniority.length > 0 ? "" : "Veuillez séléctionner la seniorité";
    formErrors.phone =
      phone.length > 0 ? "" : "Veuillez séléctionner le numéro de téléphone";
    formErrors.address =
      address.length > 0 ? "" : "Veuillez entrez une adresse";
    formErrors.profil = profil === "" ? "Veuillez séléctionner un profil" : "";
    formErrors.email = email.length > 0 ? "" : "Veuillez entrez un email";
    // formErrors.applicantStatus =
    //   applicantStatus.length > 0
    //     ? ""
    //     : "Veuillez selectionner le statue du candidat";

    if (phone.length > 0) {
      formErrors.phone = this.validatePhoneNumber(phone)
        ? ""
        : "Veuillez saisir un numéro téléphone valide";
    }

    if (email.length > 0) {
      console.log(this.validateEmail(email));
      formErrors.email = this.validateEmail(email)
        ? ""
        : "Veuillez saisir un email valide";
    }
    console.log(formErrors);
    this.setState(
      {
        formErrors,
      },
      () => {}
    );

    return (
      JSON.stringify(formErrors) ===
      JSON.stringify({
        firstName: "",
        lastName: "",
        phone: "",
        address: "",
        seniority: "",
        isRecruited: "",
        email: "",
        applicantStatus: "",
        profil: "",
      })
    );
  }

  onChangeSelectSupplier(e) {
    this.setState({ supplier: e });
  }

  onSubmit(e) {
    e.preventDefault();
    console.log(this.props);

    let profil = Object.assign({}, this.state.profil);
    if (this.state.supplier === "" || this.state.supplier.value === "") {
      var Candidat = {
        id: this.props.currentUser.id,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        phone: this.state.phone,
        seniority: this.state.seniority,
        email: this.state.email,
        isRecruited: this.state.isRecruited,
        isImported: this.state.isImported,
        applicantStatus: "APPROVED",
        address: this.state.address,
        profil: profil,
        adressTwo: this.state.adressTwo,
        city: this.state.city,
        country: this.state.country,
        zipCode: this.state.zipCode,
      };

      delete Candidat.profil.value;
      delete Candidat.profil.label;
    } else {
      let supplier = Object.assign({}, this.state.supplier);

      var Candidat = {
        id: this.props.currentUser.id,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        phone: this.state.phone,
        seniority: this.state.seniority,
        email: this.state.email,
        isRecruited: this.state.isRecruited,
        isImported: this.state.isImported,
        applicantStatus: "APPROVED",
        address: this.state.address,
        profil: profil,
        supplier: supplier,
        adressTwo: this.state.adressTwo,
        city: this.state.city,
        country: this.state.country,
        zipCode: this.state.zipCode,
      };

      delete Candidat.profil.value;
      delete Candidat.profil.label;

      delete Candidat.supplier.value;
      delete Candidat.supplier.label;
    }

    const CandidatTxt = JSON.stringify(Candidat);
    let data = new FormData();
    data.append("resume", this.state.resume);
    data.append("applicant", CandidatTxt);

    console.log(CandidatTxt);

    if (this.validateForm()) {
      editApplicant(this.props.match.params.Id, data)
        .then((res) => this.props.history.push("/"))
        .catch((err) => this.setState({ errors: { message: err.message } }));
    }
  }

  alert(message) {
    return (
      <div className="alert alert-danger" role="alert">
        {message}
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        {/*Page Title*/}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title" style={{ color: "#ffffff" }}>
                    CRAs
                  </h2>
                  {/* <p>Fresh From The Press Discription</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*End Page Title*/}
        {/*Sidebar Page Container*/}
        <div
          className="service-area ptb--80  bg_image bg_image--3"
          style={{ paddingTop: "200px", paddingBottom: "200px" }}
        >
          <div className="container">
            <div>
              <div className="card">
                <div className="card-header">
                  <strong>Profil</strong>
                </div>
                <div className="card-body">
                  <div>
                    <form onSubmit={this.onSubmit}>
                      <FormGroup>
                        <Label for="lastName">Nom</Label>
                        <Input
                          id="lastName"
                          name="lastName"
                          onChange={this.onChange}
                          value={this.state.lastName}
                        />
                        <FormText color="danger">
                          {this.state.formErrors.lastName}
                        </FormText>
                      </FormGroup>

                      <FormGroup>
                        <Label for="firstName">Prénom</Label>
                        <Input
                          id="firstName"
                          name="firstName"
                          onChange={this.onChange}
                          value={this.state.firstName}
                        />
                        <FormText color="danger">
                          {this.state.formErrors.firstName}
                        </FormText>
                      </FormGroup>

                      <FormGroup>
                        <Label for="phone">Numéro de téléphone</Label>
                        <Input
                          id="phone"
                          name="phone"
                          onChange={this.onChange}
                          value={this.state.phone}
                        />
                        <FormText color="danger">
                          {this.state.formErrors.phone}
                        </FormText>
                      </FormGroup>

                      <FormGroup>
                        <Label for="address">Address</Label>
                        <Input
                          id="address"
                          name="address"
                          onChange={this.onChange}
                          value={this.state.address}
                        />
                        <FormText color="danger">
                          {this.state.formErrors.address}
                        </FormText>
                      </FormGroup>

                      <FormGroup>
                        <Label for="adressTwo">Adresse 2</Label>
                        <Input
                          id="adressTwo"
                          name="adressTwo"
                          onChange={this.onChange}
                          value={this.state.adressTwo}
                        />
                        <FormText color="danger">
                          {this.state.formErrors.adressTwo}
                        </FormText>
                      </FormGroup>

                      <Row form>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="city">Ville</Label>
                            <Input
                              name="city"
                              id="city"
                              onChange={this.onChange}
                              value={this.state.city}
                            ></Input>
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <FormGroup>
                            <Label for="country">Pays</Label>
                            <Input
                              type="select"
                              name="country"
                              id="country"
                              onChange={this.onChange}
                              value={this.state.country}
                            >
                              <option value="">Sélectionner Pays</option>
                              {countries.map((data) => {
                                return (
                                  <option value={data.name}>{data.name}</option>
                                );
                              })}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col md={2}>
                          <FormGroup>
                            <Label for="zipCode">Code Postal</Label>
                            <Input
                              name="zipCode"
                              id="zipCode"
                              onChange={this.onChange}
                              value={this.state.zipCode}
                            />
                            <FormText color="danger">
                              {this.state.formErrors.zipCode}
                            </FormText>
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* <FormGroup>
                        <Label for="applicantStatus">Statue</Label>
                        <Input
                          type="select"
                          name="applicantStatus"
                          id="applicantStatus"
                          value={this.state.applicantStatus}
                          onChange={this.onChange}
                        >
                          <option value="" disabled>
                            Select Status
                          </option>
                          <option value="APPROVED">Actif</option>
                          <option value="SUSPENDED">Suspendu</option>
                        </Input>
                        <FormText color="danger">
                          {this.state.formErrors.seniority}
                        </FormText>
                      </FormGroup> */}

                      <FormGroup>
                        <Label for="email">Email</Label>
                        <Input
                          id="email"
                          name="email"
                          onChange={this.onChange}
                          value={this.state.email}
                        />
                        <FormText color="danger">
                          {this.state.formErrors.email}
                        </FormText>
                      </FormGroup>

                      <FormGroup>
                        <Label for="seniority">Séniorité</Label>
                        <Input
                          type="select"
                          name="seniority"
                          id="seniority"
                          value={this.state.seniority}
                          onChange={this.onChange}
                        >
                          <option value="" disabled>
                            Select Seniority
                          </option>
                          <option value="TRAINEE">Stagiaire</option>
                          <option value="JUNIOR">Junior</option>
                          <option value="CONFIRMED">Confirmé</option>
                          <option value="SENIOR">Sénior</option>
                          <option value="EXPERT">Expert</option>
                          <option value="ARCHITECT">Architecte</option>
                          <option value="DIRECTOR">Directeur</option>
                        </Input>
                        <FormText color="danger">
                          {this.state.formErrors.seniority}
                        </FormText>
                      </FormGroup>

                      <FormGroup id="profil">
                        <Label for="profil">Profil </Label>
                        <Select
                          value={this.state.profil}
                          placeholder="Select Profil"
                          name="profil"
                          options={this.state.selectProfils}
                          classNamePrefix="select"
                          onChange={this.onChangeSelectProfil}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="resume">CV</Label>
                        <div class="custom-file" style={{ zIndex: 0 }}>
                          <input
                            id="resume"
                            type="file"
                            onChange={this.onResumeChange}
                            class="custom-file-input"
                          />
                          <label
                            class="custom-file-label"
                            for="inputGroupFile01"
                          >
                            {this.state.resume !== ""
                              ? this.state.resume.name
                              : "Sélectionner fichier"}
                          </label>
                        </div>
                        <FormText color="danger">
                          {this.state.formErrors.resume}
                        </FormText>
                      </FormGroup>
                      <input
                        type="submit"
                        value="Editer"
                        className="btn btn-success btn-block mt-4 col-md-2 float-right"
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Editer;
